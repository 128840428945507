import React, { useState } from 'react';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { InsertDriveFile, Save as SaveIcon, PictureAsPdf } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions, Button, Slide, Typography, FormControl, Select, MenuItem, Container, useMediaQuery } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import TextEditor from '../../../components/TextEditor';
import MyBackdrop from '../../../components/Backdrop';
import { gql, useMutation, useQuery } from '@apollo/client';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { mapReportTitle } from './entrada-documento';
import ReportConselho from 'src/pages/Reports/conselho/conselho';
import ReportCongregacao from 'src/pages/Reports/congregacao/congregacao';
import ReportEstatistica from 'src/pages/Reports/estatistica/estatistica';
import ReportMinistro from 'src/pages/Reports/ministro/ministro';
import HtmlView from './htmlView';
import { getMe } from 'src/services/auth';
import DisplayPdf from 'src/components/DisplayPdf';

const QUERY_LIST_COMMISSIONS = gql`
  query LIST_COMMISSIONS($meeting: String!, $subStep: String!) {
	listDocumentsLivroAtas(meeting: $meeting) {
		id
		text
		title
		number
		report
		status
		inputStep
		approvedStep
		type
	}
	getDocumentRelLivroAtas(meeting: $meeting) {
		id
		text
		title
		number
		report
		status
		inputStep
		approvedStep
		type
	}
    getCommissions(meeting: $meeting) {
      id
	  name
	  code
	  minRoles
      documents {
			id
			text
			title
			number
			report
			status
			inputStep
	  		approvedStep
			type
	  }
	  reports {
			id
			name
			type
			number
			reportPlenario
			text
			inputStep
			approvedStep
			church {
				id
				name
			}
	  }
	  commissionRoles {
		  id
		  name
		  user {
			id
		  }
	  }
    }
	getFieldManual(subStep: $subStep, fieldName: "hora_trabalho_comissoes") {
		id
		value
	}
  }
`;

const MUTATION_UPDATE_DOCUMENT = gql`
  mutation UPDATE_DOCUMENT ($id: String!, $report: String, $text: String) {
		updateDocument(id: $id, input: { report: $report, text: $text }) {
			id
		}
  }
`;

const MUTATION_UPDATE_REPORT = gql`
  mutation UPDATE_REPORT ($id: String!, $reportPlenario: String) {
		updateReport(id: $id, input: { reportPlenario: $reportPlenario }) {
			id
		}
  }
`;

const MUTATION_UPDATE_HORARIO_SUSPENSAO = gql`
  mutation UPDATE_HORARIO_SUSPENSAO ($subStep: String!, $fieldName: String!, $value: String!) {
		updateFieldManual(subStep: $subStep, fieldName: $fieldName value: $value) {
			id
		}
  }
`;

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			width: '100%',
			padding: 20,
			background: '#eee'
		},
		rootClean: {
			flexGrow: 1,
			width: '100%',
			padding: 5,
		},
		formControl: {
			width: '100%'
		},
		docTitle: {
			paddingTop: '30px !important',
			'& .MuiPaper-root': {
				height: '100%'
			}
		},
		textJustify: {
			textAlign: 'justify'
		},
		doTitleLink: {
			cursor: 'pointer',
			fontSize: 12,
			'& svg': {
				verticalAlign: 'sub',
				fontSize: 13,
			}
		},
		paper: {
			padding: 15,
			'& h6': {
				marginBottom: 15
			}
		},
		dialogContent: {
			display: 'flex',
			overflowY: 'hidden',
			minHeight: '84%'
		},
		editGrid: {
			display: 'flex'
		},
		editColumn: {
			height: '100%',
			overflowY: 'auto',
		},
		editColumnMobile: {
			height: '50%',
			overflowY: 'auto',
		},
		htmlEditor: {
			height: '100%'
		},
		textInfo: {
			fontSize: 12,
			opacity: 0.5,
			textAlign: 'center',
		},
		htmlView: {
			'& .MuiDialog-paper': {
			  height: '100%',
			  width: 'calc(100% - 10px) !important',
			  maxWidth: '100% !important',
			  margin: '0px !important',
			  '& .MuiGrid-item': {
				display: 'flex',
				flexDirection: 'column',
				'& > p': {
				  flex: 1,
				  display: 'flex',
				}
			  }
			}
		},
		reportView: {
			zoom: 0.6
		},
		doTitleInfo: {
			opacity: 0.6,
			marginTop: 5,
			borderBottom: '1px solid #ccc',
		},
		displayPdf: {
			background: '#eee',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
			alignItems: 'center'
		}
	}),
);

export default function Plenario({...props}) {
	const classes = useStyles();
	const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
	const me = getMe();
	const reportHistory: any = {};
	let totalExibido = 0;
	const [ trabalhoComissoesTime, setTrabalhoCommissoesTime ] = useState<any>(null);
	const { loading, error , data, refetch } = useQuery(QUERY_LIST_COMMISSIONS, { variables: { meeting: props.meeting?.id, subStep: props.subStep?.id } });
	const [ updateHorario ] = useMutation(MUTATION_UPDATE_HORARIO_SUSPENSAO);
	const [ updateDocument, { loading: loadingUpdateDocument, error: errorUpdateDocument } ]  = useMutation(MUTATION_UPDATE_DOCUMENT, {
		onCompleted: () => {
			setCurrentDocument({});
			refetch();
			setOpen(false);
			setOpenExameLivroAtas(false);
			setOpenRelExameLivroAtas(false);
		}
	});
	const [ updateReport, { loading: loadingUpdateReport, error: errorUpdateReport } ]  = useMutation(MUTATION_UPDATE_REPORT, {
		onCompleted: () => {
			setCurrentReport(null);
			setCurrentSelect(null);
			refetch();
			setOpenReport(false);
		}
	});
	const [open, setOpen] = React.useState(false);
	const [openExameLivroAtas, setOpenExameLivroAtas] = React.useState(false);
	const [openRelExameLivroAtas, setOpenRelExameLivroAtas] = React.useState(false);
	const [openReport, setOpenReport] = React.useState(false);
	const [currentDocument, setCurrentDocument] = React.useState<any>({});
	const [currentReport, setCurrentReport] = React.useState<any>(null);
	const [currentSelect, setCurrentSelect] = React.useState<any>(null);
	const [currentSelectExameLivroAtas, setCurrentSelectExameLivroAtas] = React.useState<any>(null);
	const [currentDocumentSlected, setCurrentDocumentSlected] = React.useState<any>(null);
	const [currentReportType, setCurrentReportType] = React.useState<any>(null);

	if(error) {
		return <div>Ocorreu algum erro ao carregar as commissões.</div>
	}

	if(errorUpdateDocument) {
		return <div>Ocorreu algum erro ao salvar o relatório.</div>
	}

	const handleClickOpen = (document: any) => {
		setCurrentDocument(document);
		setOpen(true);
	};

	const handleClickOpenExameLivroAtas = (document: any) => {
		setCurrentDocument(document);
		setOpenExameLivroAtas(true);
	};

	const handleClickOpenRelExameLivroAtas = (document: any) => {
		setCurrentDocument(document);
		setOpenRelExameLivroAtas(true);
	};

	const handleClickOpenReport = (type: string) => {
		setCurrentReportType(type);
		setOpenReport(true);
	};

	const handleClose = () => {
		setOpen(false);
		setOpenReport(false);
		setOpenExameLivroAtas(false);
		setOpenRelExameLivroAtas(false);
		setCurrentSelect(null);
	};

	const onSave = () => {
		updateDocument({
			variables: {id: currentDocument.id, report: currentDocument.report}
		});
	}

	const onSaveExameLivroAtas = () => {
		updateDocument({
			variables: {id: currentDocument.id, text: currentDocument.text}
		});
	}

	const onSaveReport = () => {
		updateReport({
			variables: {id: currentReport.id, reportPlenario: currentReport.reportPlenario}
		});
	}

	const onChanteTextEditor = (e: any) => {
		if(openReport) {
			return setCurrentReport({...currentReport, reportPlenario: e.target.getContent()});	
		}
		
		setCurrentDocument({...currentDocument, report: e.target.getContent()});
	}

	const getValueTextEditor = () => {
		return openReport ? currentReport?.reportPlenario : currentDocument.report
	}

	const buildTextEditor = (report?: string) => (
		<TextEditor
			height = "100%"
			initialValue = {currentDocument.report || `
				<p style="text-align: center;">&nbsp;</p>
				<p style="text-align: left;">&nbsp;</p>
				<p style="text-align: center;">&nbsp;</p>
				<p style="text-align: center;">&nbsp;</p>
				<p style="text-align: center;">&nbsp;</p>
			`}
			onChange={(e: any) => setCurrentDocument({...currentDocument, report: e.target.getContent()})}
			zoomOut
			header = {!currentDocument?.report ? `
				<h1 style="text-align: center;"><img style="float: left;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR9EBAdk6S5OSo5yb9kZn2y-AHVsdDPJCKWCpWS4gSeEIIusMuu&amp;usqp=CAU" alt="" width="157" height="109" /><span style="font-size: 14pt;">PRESBIT&Eacute;RIO DE IMBITUVA - PRIM</span></h1>
				<p style="text-align: center;"><span style="font-size: 12pt;">SEDE: IMBITUVA - S&Iacute;NODO VALE DO TIBAGE (SVT)</span></p>
			` : undefined}
		/>
	)

	const buildTextEditorView = (document?: any) => {
		if(!document) return (<div>Selecione um documento para visualizar</div>);
		return (<HtmlView
			height = "100%"
			initialValue = ""
			zoomOut
			disabled
			value = {document.text || ' Esse documento ainda não foi redigido '}
			onChange={() => {}}
		/>)
	}

	const buildTextEditorExameLivroAtas = (document?: string) => (
		<TextEditor
			height = "100%"
			initialValue = {currentDocument?.text || `
				<p style="text-align: center;">&nbsp;</p>
				<p style="text-align: left;">&nbsp;</p>
				<p style="text-align: center;">&nbsp;</p>
				<p style="text-align: center;">&nbsp;</p>
				<p style="text-align: center;">&nbsp;</p>
			`}
			header = {!currentDocument?.text ? `
				<h1 style="text-align: center;"><img style="float: left;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR9EBAdk6S5OSo5yb9kZn2y-AHVsdDPJCKWCpWS4gSeEIIusMuu&amp;usqp=CAU" alt="" width="157" height="109" /><span style="font-size: 14pt;">PRESBIT&Eacute;RIO DE IMBITUVA - PRIM</span></h1>
				<p style="text-align: center;"><span style="font-size: 12pt;">SEDE: IMBITUVA - S&Iacute;NODO VALE DO TIBAGE (SVT)</span></p>
			` : undefined}
			onChange={(e: any) => {
				return setCurrentDocument({...currentDocument, text: e.target.getContent()});	
			}}
		/>
	)

	const buildTextEditorReport = () => (
		<TextEditor
			height = "100%"
			initialValue = {currentReport?.reportPlenario || `
				<p style="text-align: center;">&nbsp;</p>
				<p style="text-align: left;">&nbsp;</p>
				<p style="text-align: center;">&nbsp;</p>
				<p style="text-align: center;">&nbsp;</p>
				<p style="text-align: center;">&nbsp;</p>
			`}
			header = {!currentReport?.reportPlenario ? `
				<h1 style="text-align: center;"><img style="float: left;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR9EBAdk6S5OSo5yb9kZn2y-AHVsdDPJCKWCpWS4gSeEIIusMuu&amp;usqp=CAU" alt="" width="157" height="109" /><span style="font-size: 14pt;">PRESBIT&Eacute;RIO DE IMBITUVA - PRIM</span></h1>
				<p style="text-align: center;"><span style="font-size: 12pt;">SEDE: IMBITUVA - S&Iacute;NODO VALE DO TIBAGE (SVT)</span></p>
				<pre style="text-align: center; padding-left: 120px;"><span style="font-size: 10pt; font-family: arial, helvetica, sans-serif;"><strong><br /><span style="font-size: 8pt;">PRESIDENTE:&nbsp;</span></strong><span style="font-size: 8pt;">Informa&ccedil;&otilde;es sobre</span></span><br /><span style="font-size: 8pt; font-family: arial, helvetica, sans-serif;">Outras informa&ccedil;&otilde;es, endere&ccedil;o, telefone, etc...<br /></span><br /><span style="font-size: 8pt; font-family: arial, helvetica, sans-serif;"><strong>SECRET&Aacute;RIO EXECUTIVO: </strong>Informa&ccedil;&otilde;es sobre</span><br /><span style="font-size: 8pt; font-family: arial, helvetica, sans-serif;">Outras informa&ccedil;&otilde;es, endere&ccedil;o, telefone, etc...<br /><br /></span></pre>
			` : undefined}
			onChange={(e: any) => {
				return setCurrentReport({...currentReport, reportPlenario: e.target.getContent()});	
			}}
		/>
	)

	const buildSelectChurch = (type: string, history: any) => {
		return (
			<Select
				value={currentSelect || ''}
				fullWidth
				label="Selecione a igreja"
				onChange={(e) => {
					setCurrentSelect(e.target.value);
					setCurrentReport(history[type][parseInt(`${e.target.value}`)])
				}}
			>
				{
					(history[type] || []).map((itemReport: any, idx: number) => {
						return <MenuItem key={idx} value={idx}>{itemReport.type === 'permanencia_pastoral' ? itemReport.name : itemReport.church.name}</MenuItem>
					})
				}
			</Select>
		);
	}

	const buildExameLivroAtasSelec = (dataSelect: any[]) => {
		return (
			<Select
				value={currentSelectExameLivroAtas || ''}
				fullWidth
				label="Selecione o Documento"
				onChange={(e) => {
					const value: any = e.target.value;
					setCurrentSelectExameLivroAtas(value);
					console.log('docuento selecionado --->', data?.listDocumentsLivroAtas[value]);
					setCurrentDocumentSlected(data?.listDocumentsLivroAtas[value]);
				}}
			>
				{
					dataSelect.map((itemDocument: any, idx: number) => {
						return <MenuItem key={idx} value={idx}>{itemDocument.title}</MenuItem>
					})
				}
			</Select>
		);
	}

	const buildReportView = (report: any) => {
		switch(report?.type) {
			case 'congregacao':
				return (<ReportCongregacao meeting={props.meeting} report={report} readOnly />)
			case 'conselho':
				return (<ReportConselho meeting={props.meeting} report={report} readOnly />)
			case 'estatistica':
				return (<ReportEstatistica meeting={props.meeting} report={report} readOnly />)
			case 'ministro':
				return (<ReportMinistro meeting={props.meeting} report={report} readOnly />)
			case 'permanencia_pastoral':
				return (<HtmlView value={report.text}/>)
			default:
				return (<Typography>Selecione uma igreja para ver o relatório</Typography>)
		}
	}

	return (
		<div className={props.rootClean ? classes.rootClean : classes.root}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}> 
			{
				(loading || loadingUpdateDocument || loadingUpdateReport)
					? (<MyBackdrop open={true} message={loading ? 'Carregando' : 'Salvando'} />)
					: (null)
			}
			{
				open ? (
					<Dialog
						open={open}
						keepMounted
						onClose={handleClose}
						maxWidth="xl"
						fullWidth
					>
						<DialogTitle>
							{currentDocument.title}
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<Grid container spacing={matchesSm ? 1 :  3} className={classes.editGrid}>
								<Grid item xs={matchesSm ? 12 : 6} className={classes.editColumn}>
								<DialogContentText className={classes.textJustify}>
									{ currentDocument.type === 'pdf' ? (
										<Container className={classes.displayPdf}>
											<DisplayPdf url={currentDocument.text} />
										</Container>
									) :
										<div dangerouslySetInnerHTML={{
											__html: currentDocument.text
										}}></div>
									}
								</DialogContentText>
								</Grid>
								<Grid item xs={matchesSm ? 12 : 6} className={classes.editColumn}>
										{ buildTextEditor() }
								</Grid>
							</Grid>
							
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Fechar
							</Button>
							<Button startIcon={<SaveIcon/>} variant="contained" onClick={(onSave)} color="primary">
								Salvar
							</Button>
						</DialogActions>
					</Dialog>
				) : (null)
			}
			
			{
				openExameLivroAtas ? (
					<Dialog
						open={openExameLivroAtas}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleClose}
						maxWidth="xl"
						fullWidth
						className={classes.htmlView}
					>
						<DialogTitle>
							Livro de atas e atos: {currentDocument.title}
						</DialogTitle>
						<DialogContent dividers className={classes.dialogContent}>
							<Grid container spacing={1} className={classes.editGrid}>
								<Grid item xs={12} className={classes.editColumn}>
									{ buildTextEditorExameLivroAtas() }
								</Grid>
							</Grid>
							
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Fechar
							</Button>
							<Button startIcon={<SaveIcon/>} variant="contained" onClick={(onSaveExameLivroAtas)} color="primary">
								Salvar
							</Button>
						</DialogActions>
					</Dialog>
				) : (null)
			}
								
			{
				// Se for admin ou master exibe campo para alterar a data/hora
				(me.role.includes('admin') || me.role.includes('master')) ? (
					<Grid container spacing={3}>
						<Grid item xs={8} className={classes.docTitle}>
							<Typography>{props.title || 'PLENÁRIO'}</Typography>
						</Grid>
						<Grid item xs={4}>
							<FormControl className={classes.formControl}>
								<TimePicker
									value={trabalhoComissoesTime || data?.getFieldManual.value || null}
									label="Horário da suspensão"
									margin="normal"
									ampm={false}
									format="H:mm"
									autoFocus
									onChange={(value) => {
										setTrabalhoCommissoesTime(value);
										updateHorario({
											variables: { subStep: props.subStep.id, fieldName: 'hora_trabalho_comissoes', value: value }
										});
									}}
								/>
							</FormControl>
						</Grid>
					</Grid>
				) : (null)
			}
			<Grid container spacing={1}>
				{
					(data?.getCommissions || []).map((item: any, idx: number) => {
						const isVisible = (
							item.commissionRoles && item.commissionRoles.length && item.commissionRoles.filter((f: any) => {
								return f.user?.id === me.id
							}).length
						) || me.role.includes('admin') || me.role.includes('master');

						if(isVisible) {
							totalExibido++;
						}

						return (isVisible) ?
						(
							<Grid item xs={matchesSm ? 12 : 4} className={classes.docTitle}>
								<Paper className={classes.paper}>
									<Typography variant="h6" >
										{item.name}
									</Typography>
									{
										(item.documents || []).map((itemDocument: any, idxDocument: number) => (
											<Typography variant="body1" className={classes.doTitleLink} onClick={() => handleClickOpen(itemDocument)} >
												{itemDocument.type === 'pdf' ? <PictureAsPdf/> : <InsertDriveFile/>} {itemDocument.title}
											</Typography>
										))
									}
									{
										(item.reports || []).map((itemReport: any, idxReport: number) => {
											if(!reportHistory[itemReport.type]) {
												reportHistory[itemReport.type] = [];
												reportHistory[itemReport.type].push(itemReport);
											}
											else {
												reportHistory[itemReport.type].push(itemReport);
												return (null);
											}

											return (
												<Typography variant="body1" className={classes.doTitleLink} onClick={() => handleClickOpenReport(itemReport.type)} >
													<InsertDriveFile/> {mapReportTitle[itemReport.type]}
												</Typography>
											)
										})
									}
									{
										item.code === 'exame_livro_atas' ? (
											<div>
												<Typography variant="body1" className={classes.doTitleInfo}  >
													Livro de atas e atos
												</Typography>
												{
													data?.listDocumentsLivroAtas.map((itemDocument: any, idxDocument: number) => (
														<Typography key={`doc-${idxDocument}`} variant="body1" className={classes.doTitleLink} onClick={() => handleClickOpenExameLivroAtas(itemDocument)} >
															<InsertDriveFile/> {itemDocument.title}
														</Typography>
													))
												}
												<Typography variant="body1" className={classes.doTitleInfo}  >
													Relatório
												</Typography>
												{
													data?.getDocumentRelLivroAtas ? (
														<Typography variant="body1" className={classes.doTitleLink} onClick={() => handleClickOpenRelExameLivroAtas(data?.getDocumentRelLivroAtas)} >
															<InsertDriveFile/> {data?.getDocumentRelLivroAtas.title}
														</Typography>
													) : (
														<Typography variant="body1" className={classes.doTitleLink}  >
															OPS! Relatório não encontrado.
														</Typography>
													)
												}
											</div>
										) : (null)
									}
									{
										(!item.documents?.length && !item.reports?.length && item.code !== 'exame_livro_atas')
											? (
												<p className={classes.textInfo}>Nenhum documento inserido nessa comissão.</p>
											) : (null)
									}
								</Paper>
							</Grid>
						) : (null)
					})
				}
				{
					totalExibido !== 0 ? (null) : (
						<Typography align="center">Você não está relacionado com nenhuma comissão.</Typography>
					)
				}
			</Grid>
			</MuiPickersUtilsProvider>


			{
				openReport ? (
					<Dialog
						open={openReport}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleClose}
						maxWidth="xl"
						fullWidth
						className={classes.htmlView}
					>
						<DialogTitle>
							{mapReportTitle[currentReportType]}
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<Grid container spacing={0} className={classes.editGrid}>
								<Grid item xs={6} className={classes.editColumn}>
									<div>
										{ buildSelectChurch(currentReportType, reportHistory) }
									</div>
									<DialogContentText className={classes.reportView}>
										{
											buildReportView(currentReport)
										}
									</DialogContentText>
								</Grid>
								<Grid item xs={6} className={classes.editColumn}>
										{ buildTextEditorReport() }
								</Grid>
							</Grid>
							
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Fechar
							</Button>
							<Button startIcon={<SaveIcon/>} variant="contained" onClick={(onSaveReport)} color="primary">
								Salvar
							</Button>
						</DialogActions>
					</Dialog>
				) : (null)
			}


			{
				openRelExameLivroAtas && data?.getDocumentRelLivroAtas ? (
					<Dialog
						open={openRelExameLivroAtas}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleClose}
						maxWidth="xl"
						fullWidth
						className={classes.htmlView}
					>
						<DialogTitle>
							Relatório Livro de atas
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<Grid container spacing={0} className={classes.editGrid}>
								<Grid item xs={6} className={classes.editColumn}>
									<div>
										{ buildExameLivroAtasSelec(data?.listDocumentsLivroAtas || []) }
									</div>
									<DialogContentText>
										{
											currentDocumentSlected ? buildTextEditorView(currentDocumentSlected) : (null)
										}
									</DialogContentText>
								</Grid>
								<Grid item xs={6} className={classes.editColumn}>
										{ buildTextEditor() }
								</Grid>
							</Grid>
							
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Fechar
							</Button>
							<Button startIcon={<SaveIcon/>} variant="contained" onClick={(onSave)} color="primary">
								Salvar
							</Button>
						</DialogActions>
					</Dialog>
				) : (null)
			}
		</div>
	)
}