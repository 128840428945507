import { Reducer } from 'redux';
import { getMe } from '../../../services/auth';
import { MainState, MainTypes, Menu } from './types';

const buildMenu = (): Menu[] => {
    const me = getMe();
    const menu = [{
        title: 'Ordinária',
        level: 1,
        parent: null,
        route: '/app/ordinaria',
        menu: [{
            title: 'Convocação da R.O.',
            level: 1,
            parent: null,
            route: '/app/ordinaria/convocaca-ro',
            role: ['presidente', 'vice_presidente', 'secretario_executivo', 'admin', 'master']
        },{
            title: 'Iniciar Ordinária',
            level: 1,
            parent: null,
            route: '/app/ordinaria/nova-ordinaria',
            role: ['presidente', 'vice_presidente', 'secretario_executivo', 'admin', 'master']
        },{
            title: 'Inserir Documentos',
            level: 1,
            parent: null,
            route: '/app/ordinaria/documentos',
        },{
            title: 'Arquivo',
            level: 1,
            parent: null,
            route: '/app/ordinaria/arquivo',
            menu: []
        },{
            title: 'Relatórios',
            level: 1,
            parent: null,
            route: '/app/ordinaria/relatorios',
            menu: [{
                title: 'Ministro',
                level: 1,
                parent: null,
                route: '/app/ordinaria/relatorios/ministro',
                menu: [],
                role: ['reverendo']
            },{
                title: 'Conselho',
                level: 1,
                parent: null,
                route: '/app/ordinaria/relatorios/conselho',
                menu: []
            },{
                title: 'Congregação',
                level: 1,
                parent: null,
                route: '/app/ordinaria/relatorios/congregacao',
                menu: []
            },{
                title: 'Estatística',
                level: 1,
                parent: null,
                route: '/app/ordinaria/relatorios/estatistica',
                menu: []
            },{
                title: 'Permanência Pastoral',
                level: 1,
                parent: null,
                route: '/app/ordinaria/documentos/permanencia-pastoral',
                menu: []
            }]
        }]
    },{
        title: 'Extraordinária',
        level: 1,
        parent: null,
        route: '/app/extraordinaria',
        menu: []
    }];

    if(me && me.role && me.role.includes('master') ) {
        menu.push({
            title: 'Igrejas',
            level: 1,
            parent: null,
            route: '/app/igrejas',
            menu: []
        });
        menu.push({
            title: 'Usuários',
            level: 1,
            parent: null,
            route: '/app/usuarios',
            menu: []
        });
    }

    return menu;
}

const INITIAL_STATE: MainState = {
    drawerOpen: true,
    breadcrumbs: [{
        text: 'Início',
        href: '/app'
    }],
    listCurrentMenu: [],
    selectedMenu: {},
    backMenu:[],
    menu: buildMenu()
}

const reducer: Reducer<MainState> = (state= INITIAL_STATE, action) => {
    console.log('ACTION--->',action);
    switch (action.type) {
        case MainTypes.TOGGLE_DRAWER:
            return {...state, drawerOpen: !state.drawerOpen};
        case MainTypes.GO_TO:
            if(action.payload.menu && action.payload.menu.menu && action.payload.menu.menu.length) {
                const newState = {
                    ...state,
                    listCurrentMenu: [
                        ...state.listCurrentMenu,
                        action.payload.menu
                    ],
                    backMenu: [
                        ...state.backMenu,
                        state.menu
                    ],
                    menu: action.payload.menu.menu,
                    breadcrumbs: [
                        ...state.breadcrumbs,
                        {
                            text: action.payload.menu.title,
                            href: ''
                        }
                    ]
                };

                return newState;
            }
            else {
                let breadcrumbs = state.breadcrumbs;

                if(action.payload?.menu?.level === 0) {
                    breadcrumbs = [{
                        text: 'Início',
                        href: '/app'
                    }];

                    return {
                        ...state,
                        // backMenu: state.menu,
                        selectedMenu: {},
                        listCurrentMenu: [],
                        breadcrumbs, 
                        menu: buildMenu()
                    };
                }
                else {
                    //TODO
                    //check breadcrumbs
                    
                    if(breadcrumbs.length >= 3) {
                        breadcrumbs.splice(breadcrumbs.length-1,1);
                    }

                    breadcrumbs.push({
                        text: action.payload.menu.title,
                        href: ''
                    });

                    return {
                        ...state,
                        // backMenu: state.menu,
                        selectedMenu: action.payload.menu,
                        breadcrumbs
                    };
                }
            }
        case MainTypes.GO_BACK:
            let breadcrumbs = state.breadcrumbs;
            breadcrumbs.splice(breadcrumbs.length-1,1);

            const newState = {
                ...state,
                breadcrumbs,
                listCurrentMenu: state.listCurrentMenu.slice(0, state.listCurrentMenu.length-1),
                backMenu: state.backMenu.slice(0, state.backMenu.length-1),
                menu: action.payload.menu
            };

            return newState;
        default:
            return state;
    }
}

export default reducer;