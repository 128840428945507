import { AppBar, Backdrop, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, Paper, TextField, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    Save as SaveIcon,
	Delete as DeleteIcon,
    Print as PrintIcon,
	Visibility as VisibilityIcon,
	Cancel as CancelIcon,
	Add as AddIcon,
	PictureAsPdf as PdfIcon,
} from '@material-ui/icons';
import TextEditor from '../../components/TextEditor';
import { Alert } from '@material-ui/lab';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Editor } from '@tinymce/tinymce-react';
import UploadDocument from 'src/components/UploadDocument';
import DisplayPdf from 'src/components/DisplayPdf';
import { getMe } from 'src/services/auth';

const QUERY_MEETINGS = gql`
  query ListMeetings {
    listMeeting(filter: "Criada,Convocada,Iniciada") {
	  id
	  name
	  date
	  convocation
	  status
    }
  }
`;

const QUERY_DOCUMENTS = gql`
  query ListDocuments($meeting: String!) {
    listDocuments(meeting: $meeting) {
	  id
	  title
	  text
	  number
	  report
	  status
	  type
	  createdAt
	  user {
		  id
		  name
	  }
    }
  }
`;

const MUTATION_CREATE_DOCUMENT = gql`
  mutation CreateDocument($id: String!, $title: String!, $text: String!, $meeting: String!) {
    createDocument(input: {id: $id, title: $title, text: $text, meeting: $meeting}) {
      id
	  title
	  text
	  meeting {
		  id
		  name
	  }
    }
  }
`;

const MUTATION_DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: String!) {
    deleteDocument(id: $id) {
      id
    }
  }
`;

const SINGLE_UPLOAD = gql`
  mutation($file: Upload!, $title: String!, $meeting: String!) {
    uploadDocument(file: $file, title: $title, meeting: $meeting) {
      filename
      mimetype
      encoding
      url
    }
  }
`;

function Documents({...props}) {
	const classes = styles();
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
	const [ currentMeeting, setCurrentMeeting ] = useState<any>({});
	const [ currentRecord, setCurrentRecord ] = useState<any>({});
	const [ isPdfUpload, setPdfUpload ] = useState<boolean>(false);
	const [ pdfFile, setPdfFile ] = useState<any>();
	const [ alertCancel, setAlertCancel ] = useState(false);
	const [ alertDelete, setAlertDelete ] = useState(false);
	const { loading: loadingMeetings, error: errorMeetings , data: dataMeetings } = useQuery(QUERY_MEETINGS);
	const [ getDocuments, { loading: loadingDocuments, error: errorDocuments , data: dataDocuments, refetch: refetchDocuments }] = useLazyQuery(QUERY_DOCUMENTS);
	const [ uploadDocument, { data: dataUpload, loading: loadingUpload, error: errorUpload }] = useMutation(SINGLE_UPLOAD, {
		onCompleted: () => {
			refetchDocuments && refetchDocuments();
			setCurrentRecord({});
		}
	});
	const [ createDocument, { data: dataCreate, loading: loadingCreate, error: errorCreate }] = useMutation(MUTATION_CREATE_DOCUMENT);
	const [ deleteDocument, { data: dataDelete, loading: loadingDelete, error: errorDelete }] = useMutation(MUTATION_DELETE_DOCUMENT, {
		onCompleted: () => {
			refetchDocuments && refetchDocuments();
			setCurrentRecord({});
		}
	});
	const me = getMe();
	const componentRef: any = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef?.current,
	});
	
	useEffect(() => {
		currentMeeting?.id && getDocuments({ variables: { meeting: currentMeeting.id } })
	}, [currentMeeting, getDocuments]);

    if(loadingMeetings || loadingDocuments || loadingCreate || loadingDelete || loadingUpload) {
		return <Backdrop className={classes.backdrop} open><CircularProgress color="inherit" /><Typography>Carregando</Typography></Backdrop>
	}
	
	if(errorMeetings) {
		return <div>Erro ao carregar as reuniões!</div>
	}
	
	if(errorDocuments) {
		return <div>Erro ao carregar os documentos!</div>
	}

	if(errorUpload) {
		return <div>Erro ao salvar o PDF!</div>
	}

    const createListMeetings = () => {
		return dataMeetings.listMeeting.map((item: any, idx: any) => {
			return <Grid item key={idx} xs={matchesSm ? 12 : 4} className={classes.gridItem}>
				<Paper
					onClick={() => {
						setCurrentMeeting({ id: item.id, name: item.name, date: item.date, convocation: item.convocation, status: item.status })
					}}
					className={classes.paperItem}
				>
				<Typography variant="h5">{item.name}</Typography>
				<Typography variant="h6"><span>Status:</span> {item.status}</Typography>	
				<Typography variant="h6"><span>Data:</span> {item.date}</Typography>	
			</Paper></Grid>
		});
	}

	const createListDocuments = () => {
		return dataDocuments.listDocuments.map((item: any, idx: any) => {
			return <Grid item key={idx} className={classes.gridItem}>
				<Paper
					onClick={() => {
						setCurrentRecord({ ...item })
					}}
					className={classes.paperItem}
				>
				<Typography variant="h5">{item.type === 'pdf' && <PdfIcon fontSize="small" />} {item.title}</Typography>
				<Typography variant="h6"><span>Número:</span> {item.number || 'Não definido'}</Typography>	
			</Paper></Grid>
		});
	}

	const doDeleteDocument = () => {
		setAlertDelete(false);
		deleteDocument({ variables: { id: currentRecord.id }});
	}

	const doCancelDocument = () => {
		setAlertCancel(false);
		refetchDocuments && refetchDocuments();
		setCurrentRecord({});
	}

	const saveDocument = () => {
		if(!currentRecord.title) {
			return alert('Você precisa preencher o título do documento.');
		}

		if(isPdfUpload) {
			return uploadDocument({
				variables: { file: pdfFile, title: currentRecord.title, meeting: currentMeeting.id }
			});
		}

		createDocument({
			variables: { ...currentRecord, meeting: currentMeeting.id }
		});
	}

	const onChangePdfUpload = (file: any) => setPdfFile(file);
	const documentText = currentRecord.text;

	if(dataMeetings?.listMeeting?.length === 0) {
		return <Alert severity="warning">Não existe nenhuma reunião disponível para inserir documento.</Alert>
	}

    if(dataMeetings?.listMeeting.length && ( !dataDocuments || !currentMeeting ) && !currentRecord?.id) {
		return (
			<div className={classes.rootCentered}>
			<Grid container>
				<Grid item xs={12} className={classes.gridItem}>
					<Typography variant="h6">Reuniões disponíveis para inserir documentos:</Typography>
				</Grid>
				{ createListMeetings() }
			</Grid>
			</div>
		);
	}

	if(dataDocuments?.listDocuments.length && !currentRecord?.id) {
		return (
			<Container maxWidth="lg">
			<div className={classes.rootCentered}>
				<Grid container>
					<Grid item xs={12}>
						<Button startIcon={<AddIcon />} color="primary"  className={classes.btnAdd} variant="contained" onClick={() => setCurrentRecord({id: 'new', text: ''})}>Criar novo documento</Button>
					</Grid>
					<hr/>
					<Grid item xs={12} className={classes.gridItem}>
						<Typography variant="h6">
							Documentos inseridos na reunião: <b>"{currentMeeting.name}" </b>
							<a className={classes.textLink} onClick={() => { setCurrentRecord(null); setCurrentMeeting(null); }}>
								[trocar de reunião]
							</a>	
						</Typography>
					</Grid>
					{ createListDocuments() }
				</Grid>
			</div>
			</Container>
		);
	}

	if((!dataDocuments || !dataDocuments?.listDocuments || !dataDocuments?.listDocuments?.length) && !currentRecord?.id) {
		return <div className={classes.rootCentered}>
			<Alert severity="info">Você ainda não inseriu nenhum documento para a reunião: <b>"{currentMeeting?.name}"</b>.</Alert>
			<Button  startIcon={<AddIcon />} className={classes.btnAdd} variant="contained" onClick={() => setCurrentRecord({id: 'new', text: ''})}>Inserir documento</Button>
			<p className={classes.textLink} onClick={() => { setCurrentRecord(null); setCurrentMeeting(null); }}>
				Trocar de reunião
			</p>
		</div>
	}

	const getAppBarConvocacaoRO = () => {
		return <AppBar position="fixed" color="default" className={classes.footer}>
			<Toolbar>
				<Button
					variant="contained"
					color="default"
					size="small"
					startIcon={<CancelIcon />}
					onClick={() => setAlertCancel(true)}
				>
					Voltar
				</Button>
				{
					currentRecord?.id && currentRecord?.id !== 'new'
					? (
						<Button
							variant="contained"
							color="secondary"
							size="small"
							startIcon={<DeleteIcon />}
							onClick={() => setAlertDelete(true)}
						>
							Deletar
						</Button>
					) : (null)
				}
				<Button
					variant="contained"
					color="primary"
					size="small"
					startIcon={<SaveIcon />}
					onClick={saveDocument}
				>
					Salvar
				</Button>
			</Toolbar>
		</AppBar>
	}

    return (
		<div className={classes.root}>
			<div className={classes.formContent}>
				<Paper className={classes.basePaperMargin}>
					<Grid className={classes.gridContainer} container spacing={5}>
					<Grid item xs={matchesSm ? 8 : 3}>
							<FormControl fullWidth>
								<TextField 
									margin="normal" 
									autoFocus 
									label="Nome do documento"
									placeholder="Exemplo: Documento de Teste"
									value={currentRecord?.title || ''}
									onChange={(e) => {setCurrentRecord({...currentRecord, title: e.target.value})}}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={matchesSm ? 4 : 3}>
							{
								!currentRecord.type && <FormControlLabel
									control={<Checkbox/>}
									label="Enviar PDF?"
									name="isPdfUpload" checked={isPdfUpload} onChange={(e: any) => setPdfUpload(e.target.checked)}   
								/>
							}
						</Grid>
					</Grid>
				</Paper>
				{
					currentRecord.type === 'pdf' ? (
						<Container className={classes.containerPdf}>
							<DisplayPdf url={currentRecord.text} className={classes.containerPdfCanvas}/>
						</Container>
					) :
					isPdfUpload ? (
						<Container className={classes.containerUpload}>
							<UploadDocument onChange={onChangePdfUpload} />
						</Container>
					) : (
						<TextEditor
							height = "100%"
							initialValue = {documentText}
							// value = {currentRecord.text || '<p></p>'}
							onChange={(e: any) => { console.log('onChangeInThedocumet', e); setCurrentRecord({...currentRecord, text: e.target.getContent()});}}
							header={  documentText ? '' : me.church?.documentHeader || ''} 
						/>
					)
				}
				
				{ getAppBarConvocacaoRO() }
			</div>
			<Dialog
				open={alertCancel || alertDelete}
				onClose={doCancelDocument}
			>
				<DialogTitle>{alertDelete ? 'Deletar' : 'Voltar para Lista' } </DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{
						currentRecord?.id
							? alertDelete ? 'Tem certeza que deseja deletar o documento?' : 'Você perderá todas as informações não salvas. Tem certeza?'
							: 'Cancelar edição do documento?'
					}
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => alertDelete ? setAlertDelete(false) : setAlertCancel(false)} color="primary">
						Não
					</Button>
					<Button onClick={alertDelete ? doDeleteDocument : doCancelDocument} color="primary" autoFocus>
						Sim
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default Documents;