import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from "date-fns/locale/pt-BR";
import FormControl from '@material-ui/core/FormControl';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
    TimePicker,
} from '@material-ui/pickers'
import { TextField, InputLabel, Select, MenuItem, Box } from '@material-ui/core';
import { gql, useQuery, useMutation } from '@apollo/client';

const QUERY_FIELDS = gql`
  query Field($subStep: String!) {
    listFields(subStep: $subStep) {
      id
      name
      label
      value
      type
      order
      raw
    }
  }
`;

const QUERY_USERS = gql`
  query Users {
    getUsers {
      id
      name
    }
  }
`;

const UPDATE_FIELD = gql`
  mutation UpdateField($field: String!, $value: String, $raw: Boolean) {
    updateField(field: $field, value: $value, raw: $raw) {
      id
      type
      value
      raw
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 500,
    },
    formControl: {
      margin: theme.spacing(3),
    },
    formControlFlexForm: {
        margin: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row'
    },
    box: {
        flexGrow: 1,
        display: 'flex',
        '& > div': {
            flexGrow: 1
        }
    },
    boxOutro: {
        marginTop: 20,
        marginLeft: 20,
        cursor: 'pointer',
        textDecoration: 'underline',
        opacity: 0.4,
    }
  }),
);

interface IField {id: string, label: string, name: string, value: string, type: string, raw: boolean}
const buildField = (field: IField, key: number, classes: any, fieldsState:any, hideFieldsState:any, setHideFieldsSatate: any, setFieldsSatate: any, updateField: any, dataUsers:any) => {
    let timer: any = null;

    switch(field.type) {
        case 'text': 
            return (
                <FormControl key={key} className={classes.formControl}>
                    <TextField 
                        label={field.label}
                        value={fieldsState[field.id]} 
                        onChange={(e) => {
                            const value = e.target.value;
                            console.log('@value-->', value);
                            console.log('@fieldsState-->', fieldsState);
                            setFieldsSatate({
                                ...fieldsState,
                                [field.id]: value
                            });
                            // Clears running timer and starts a new one each time the user types
                            // clearTimeout(timer);
                            // timer = setTimeout(() => {
                            //     updateField({ variables: {field: field.id, value: value } })
                            // }, 2000);
                            // updateField({ variables: {field: field.id, value: e.target.value } })
                        }} 
                        onBlur={(e) => {
                            updateField({ variables: {field: field.id, value: e.target.value } })
                        }}
                    />
                </FormControl>
            )
        case 'dateTime': 
            return (
                <FormControl key={key} className={classes.formControl}>
                    <DateTimePicker
                        value={new Date(fieldsState[field.id])}
                        label={field.label}
                        margin="normal"
                        ampm={false}
                        format="dd/MM/yyyy H:mm"
                        autoFocus
                        onChange={(value) => {
                            setFieldsSatate({
                                ...fieldsState,
                                [field.id]: value
                            });
                            updateField({ variables: {field: field.id, value: value } })
                        }}
                    />
                </FormControl>
            )
        case 'time': 
            return (
                <FormControl key={key} className={classes.formControl}>
                    <TimePicker
                        value={new Date(fieldsState[field.id])}
                        label={field.label}
                        margin="normal"
                        ampm={false}
                        format="H:mm"
                        autoFocus
                        onChange={(value) => {
                            setFieldsSatate({
                                ...fieldsState,
                                [field.id]: value
                            });
                            updateField({ variables: {field: field.id, value: value } })
                        }}
                    />
                </FormControl>
            )
        case 'selectUser':
            return <>
                {
                    !hideFieldsState[field.id]
                        ? 
                        <FormControl className={classes.formControlFlexForm}>
                            <Box className={classes.box}>
                                <InputLabel id="demo-simple-select-label">{field.label}</InputLabel>
                                <Select
                                    value={fieldsState[field.id] || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFieldsSatate({
                                            ...fieldsState,
                                            [field.id]: value
                                        });
                                        updateField({ variables: {field: field.id, value: value, raw: false } })
                                        // Clears running timer and starts a new one each time the user types
                                        // clearTimeout(timer);
                                        // timer = setTimeout(() => {
                                        //     updateField({ variables: {field: field.id, value: value } })
                                        // }, 2000);
                                    }}
                                >
                                    {
                                        //  selected={user.id == fieldsState[field.id]}
                                        dataUsers?.getUsers?.map((user:any, idx: number) => <MenuItem key={`user-${idx}`} value={user.id}>{user.name}</MenuItem>)
                                    }
                                </Select>
                            </Box>
                            <Box onClick={() => {
                                    updateField({ variables: {field: field.id, value: null, raw: true } })
                                    setHideFieldsSatate({...hideFieldsState, [field.id]: true});
                                }} className={classes.boxOutro}>digitar</Box>
                        </FormControl>
                        :
                        <FormControl className={classes.formControlFlexForm}>
                                <Box className={classes.box}>
                                <TextField label={field.label} value={fieldsState[field.id]} onChange={(e) => {
                                        const value = e.target.value;
                                        setFieldsSatate({
                                            ...fieldsState,
                                            [field.id]: value
                                        });
                                        updateField({ variables: {field: field.id, value: value, raw: true } })
                                        // Clears running timer and starts a new one each time the user types
                                        // clearTimeout(timer);
                                        // timer = setTimeout(() => {
                                        //     updateField({ variables: {field: field.id, value: value } })
                                        // }, 2000);
                                    }}  />
                                </Box>
                                <Box onClick={() => {
                                    updateField({ variables: {field: field.id, value: null, raw: false } })
                                    setHideFieldsSatate({...hideFieldsState, [field.id]: false});
                                }} className={classes.boxOutro}>selecionar</Box>
                            </FormControl>
                }
            </>
    }
}

const makeFields = (fields: any, classes: any, fieldsState: {}, hideFieldsState: {}, setFieldsSatate: React.Dispatch<React.SetStateAction<{}>>, setHideFieldsSatate: React.Dispatch<React.SetStateAction<{}>>, updateField: any, dataUsers: any) => {
    return fields.map((item: IField, idx: any) => {
        return buildField(item, idx, classes, fieldsState, hideFieldsState, setHideFieldsSatate, setFieldsSatate, updateField, dataUsers);
    });
}

export default function GenericFields({...props}) {
    const classes = useStyles();
    const [ fieldsState, setFieldsSatate ] = React.useState({});
    const [ hideFieldsState, setHideFieldsSatate ] = React.useState({});
    const [ updateField, { data: dataField }] = useMutation(UPDATE_FIELD);
    const { loading: loadingUsers, error: errorUsers, data: dataUsers } = useQuery(QUERY_USERS);
    const { loading, error, data } = useQuery(QUERY_FIELDS, {
        variables: { subStep: props.subStepId || '' },
    });

    useEffect(() => {
        if(data && data.listFields) {
            const fieldsStateValue: {[x: string]: any} = {};
            const hideFieldsStateValue: {[x: string]: any} = {};
            data.listFields.map((item: IField) => {
                fieldsStateValue[item.id] = item.value;
                if(item.type == 'selectUser') {
                    hideFieldsStateValue[item.id] = item.raw;
                }
            });
            setFieldsSatate(fieldsStateValue);
            setHideFieldsSatate(hideFieldsStateValue);
        }
    }, [data]);

    if(!props.subStepId) {
        return <div>Erro: Sub Etapa não encontrada.</div>
    }

    if(loading) {
        return <div>carregando...</div>
      }
    
      if(error) {
        return <div>Erro!</div>
      }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}> 
    <div className={classes.root}>
        {makeFields(data.listFields, classes, fieldsState, hideFieldsState, setFieldsSatate, setHideFieldsSatate, updateField, dataUsers)}
    </div>
    </MuiPickersUtilsProvider>
  );
}