import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import styles from '../styles';

function RolMembros({...props}) {
    const classes = styles();
    const isDisabled = (props.readOnly === true);
    const { record, onSetField, onChangeField } = props;

    const sumAdmissaoMasc = () => {
        return Number(record?.iv_profissao_fe_masc || 0 ) +
        Number(record?.iv_profissao_fe_batismo_masc || 0 ) +
        Number(record?.iv_transferencia_masc || 0 ) +
        Number(record?.iv_jurisdicao_masc || 0 ) +
        Number(record?.iv_designacao_masc || 0 ) +
        Number(record?.iv_restauracao_masc || 0 ) +
        Number(record?.iv_designacao_masc || 0 );
    }

    const sumAdmissaoFem = () => {
        return Number(record?.iv_profissao_fe_masc || 0 ) +
        Number(record?.iv_profissao_fe_batismo_fem || 0 ) +
        Number(record?.iv_transferencia_fem || 0 ) +
        Number(record?.iv_jurisdicao_fem || 0 ) +
        Number(record?.iv_designacao_fem || 0 ) +
        Number(record?.iv_restauracao_fem || 0 ) +
        Number(record?.iv_designacao_fem || 0 );
    }

    const sumDemissaoMasc = () => {
        return Number(record?.iv_d_transferencia_masc || 0 ) + 
        Number(record?.iv_d_falecimento_masc || 0 ) + 
        Number(record?.iv_d_exclusao_masc || 0 ) + 
        Number(record?.iv_d_ordenacao_masc || 0 );
    }

    const sumDemissaoFem = () => {
        return Number(record?.iv_d_transferencia_fem || 0 ) +
        Number(record?.iv_d_falecimento_fem || 0 ) +
        Number(record?.iv_d_exclusao_fem || 0 ) +
        Number(record?.iv_d_ordenacao_fem || 0 );
    }

    const sumAdmissaoMasc_n = () => {
        return Number(record?.iv_n_batismo_masc || 0 ) +
        Number(record?.iv_n_transferencia_masc || 0 ) +
        Number(record?.iv_n_jurisdicao_masc || 0 );
    }

    const sumAdmissaoFem_n = () => {
        return Number(record?.iv_n_batismo_fem || 0 ) +
        Number(record?.iv_n_transferencia_fem || 0 ) +
        Number(record?.iv_n_jurisdicao_fem || 0 );
    }

    const sumDemissaoMasc_n = () => {
        return Number(record?.iv_nd_profissao_fe_masc || 0 ) +
        Number(record?.iv_ne_transferencia_masc || 0 ) +
        Number(record?.iv_ne_falecimento_masc || 0 ) +
        Number(record?.iv_ne_exclusao_masc || 0 );
    }

    const sumDemissaoFem_n = () => {
        return Number(record?.iv_nd_profissao_fe_fem || 0 ) +
        Number(record?.iv_ne_transferencia_fem || 0 ) +
        Number(record?.iv_ne_falecimento_fem || 0 ) +
        Number(record?.iv_ne_exclusao_fem || 0 );
    }

    const calcDiferencaAdmissaoDemissaoMasc = () => {
        return sumAdmissaoMasc() - sumDemissaoMasc();
    }

    const calcDiferencaAdmissaoDemissaoFem = () => {
        return sumAdmissaoFem() - sumDemissaoFem();
    }
    
    const calcDiferencaAdmissaoDemissaoTotal = () => {
        return (sumAdmissaoMasc() + sumAdmissaoFem()) - (sumDemissaoMasc() + sumDemissaoFem()) ;
    }

    const calcDiferencaAdmissaoDemissaoMasc_n = () => {
        return sumAdmissaoMasc_n() - sumDemissaoMasc_n();
    }

    const calcDiferencaAdmissaoDemissaoFem_n = () => {
        return sumAdmissaoFem_n() - sumDemissaoFem_n();
    }
    
    const calcDiferencaAdmissaoDemissaoTotal_n = () => {
        return (sumAdmissaoMasc_n() + sumAdmissaoFem_n()) - (sumDemissaoMasc_n() + sumDemissaoFem_n()) ;
    }
    

	return (
        <>
            <Grid item xs={12} className={classes.sectionTitle}>
                IV - Rol de Membros
            </Grid>

            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={6}>
                        <b>COMUNGANTES</b>
                    </Grid>
                    <Grid item xs={2}>
                        MASC.
                    </Grid>
                    <Grid item xs={2}>
                        FEM.
                    </Grid>
                    <Grid item xs={2}>
                        TOTAL
                    </Grid>

                    <Grid item xs={12} className={classes.textInfoBox}>
                        Admissão
                    </Grid>

                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Profissão de Fé:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_profissao_fe_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_profissao_fe_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_profissao_fe_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_profissao_fe_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_profissao_fe_masc || 0 ) + Number(record?.iv_profissao_fe_fem || 0 )}
                    </Grid>

                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Profissão de Fé e Batismo:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_profissao_fe_batismo_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_profissao_fe_batismo_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_profissao_fe_batismo_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_profissao_fe_batismo_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_profissao_fe_batismo_masc || 0 ) + Number(record?.iv_profissao_fe_batismo_fem || 0 )}
                    </Grid>

                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Transferência:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_transferencia_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_transferencia_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_transferencia_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_transferencia_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_transferencia_masc || 0 ) + Number(record?.iv_transferencia_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Jurisdição:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_jurisdicao_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_jurisdicao_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_jurisdicao_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_jurisdicao_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_jurisdicao_masc || 0 ) + Number(record?.iv_jurisdicao_fem || 0 )}
                    </Grid>

                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Restauração:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_restauracao_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_restauracao_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_restauracao_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_restauracao_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_restauracao_masc || 0 ) + Number(record?.iv_restauracao_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Designação do Presbitério:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_designacao_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_designacao_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_designacao_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_designacao_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_designacao_masc || 0 ) + Number(record?.iv_designacao_fem || 0 )}
                    </Grid>


                    <Grid item xs={12} className={classes.textInfoBox}>
                        Demissão
                    </Grid>

                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Transferência:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_d_transferencia_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_d_transferencia_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_d_transferencia_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_d_transferencia_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_d_transferencia_masc || 0 ) + Number(record?.iv_d_transferencia_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Falecimento:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_d_falecimento_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_d_falecimento_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_d_falecimento_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_d_falecimento_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_d_falecimento_masc || 0 ) + Number(record?.iv_d_falecimento_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Exclusão:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_d_exclusao_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_d_exclusao_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_d_exclusao_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_d_exclusao_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_d_exclusao_masc || 0 ) + Number(record?.iv_d_exclusao_fem || 0 )}
                    </Grid>



                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Ordenação:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_d_ordenacao_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_d_ordenacao_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_d_ordenacao_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_d_ordenacao_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_d_ordenacao_masc || 0 ) + Number(record?.iv_d_ordenacao_fem || 0 )}
                    </Grid>

                    <Grid item xs={12} className={classes.textInfoBox}>
                        {' '}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Rol Separado:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_rol_separado_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_rol_separado_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_rol_separado_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_rol_separado_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_rol_separado_masc || 0 ) + Number(record?.iv_rol_separado_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        DIFERENÇA (Admissão - Demissão):
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {calcDiferencaAdmissaoDemissaoMasc()}
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {calcDiferencaAdmissaoDemissaoFem()}
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {calcDiferencaAdmissaoDemissaoTotal()}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Comungantes Ano Anterior:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_comungantes_ano_ant_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_comungantes_ano_ant_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_comungantes_ano_ant_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_comungantes_ano_ant_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_comungantes_ano_ant_masc || 0 ) + Number(record?.iv_comungantes_ano_ant_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Comungantes Ano Atual:
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_comungantes_ano_ant_masc || 0 ) + calcDiferencaAdmissaoDemissaoMasc()}
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_comungantes_ano_ant_fem || 0 ) + calcDiferencaAdmissaoDemissaoFem()}
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_comungantes_ano_ant_masc || 0 ) + Number(record?.iv_comungantes_ano_ant_fem || 0 ) + calcDiferencaAdmissaoDemissaoTotal()}
                    </Grid>
                    


                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid container className={classes.rootGridContainer}>
                    <Grid item xs={6}>
                        <b>NÃO-COMUNGANTES</b>
                    </Grid>
                    <Grid item xs={2}>
                        MASC.
                    </Grid>
                    <Grid item xs={2}>
                        FEM.
                    </Grid>
                    <Grid item xs={2}>
                        TOTAL
                    </Grid>


                    <Grid item xs={12} className={classes.textInfoBox}>
                        Admissão
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Batismo:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_n_batismo_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_n_batismo_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_n_batismo_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_n_batismo_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_n_batismo_masc || 0 ) + Number(record?.iv_n_batismo_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Transferência:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_n_transferencia_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_n_transferencia_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_n_transferencia_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_n_transferencia_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_n_transferencia_masc || 0 ) + Number(record?.iv_n_transferencia_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Jurisdição ex-officio:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_n_jurisdicao_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_n_jurisdicao_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_n_jurisdicao_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_n_jurisdicao_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_n_jurisdicao_masc || 0 ) + Number(record?.iv_n_jurisdicao_fem || 0 )}
                    </Grid>

                    <Grid item xs={12} className={classes.emptyRowHeight}>{' '}</Grid>
                    <Grid item xs={12} className={classes.emptyRowHeight}>{' '}</Grid>
                    <Grid item xs={12} className={classes.emptyRowHeight}>{' '}</Grid>

                    <Grid item xs={12} className={classes.textInfoBox}>
                        Demissão
                    </Grid>

                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Profissão de Fé:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_nd_profissao_fe_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_nd_profissao_fe_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_nd_profissao_fe_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_nd_profissao_fe_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_nd_profissao_fe_masc || 0 ) + Number(record?.iv_nd_profissao_fe_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Transferência:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_ne_transferencia_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_ne_transferencia_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_ne_transferencia_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_ne_transferencia_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_ne_transferencia_masc || 0 ) + Number(record?.iv_ne_transferencia_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Falecimento:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_ne_falecimento_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_ne_falecimento_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_ne_falecimento_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_ne_falecimento_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_ne_falecimento_masc || 0 ) + Number(record?.iv_ne_falecimento_fem || 0 )}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Exclusão:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_ne_exclusao_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_ne_exclusao_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_ne_exclusao_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_ne_exclusao_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_ne_exclusao_masc || 0 ) + Number(record?.iv_ne_exclusao_fem || 0 )}
                    </Grid>


                    <Grid item xs={12} className={classes.textInfoBox}>
                        {' '}
                    </Grid>
                    

                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        <b>DIFERENÇA (Admissão - Demissão):</b>
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {calcDiferencaAdmissaoDemissaoMasc_n()}
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {calcDiferencaAdmissaoDemissaoFem_n()}
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {calcDiferencaAdmissaoDemissaoTotal_n()}
                    </Grid>


                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        Não-Comungantes Ano anterior:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_nao_comungante_ano_ant_masc || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_nao_comungante_ano_ant_masc"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={record?.iv_nao_comungante_ano_ant_fem || ''}
                            onChange={onChangeField}
                            onBlur={(e) => {onSetField(e)}}
                            name="iv_nao_comungante_ano_ant_fem"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_nao_comungante_ano_ant_masc || 0 ) + Number(record?.iv_nao_comungante_ano_ant_fem || 0 )}
                    </Grid>



                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        <b>Não-Comungantes Ano atual:</b>
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_nao_comungante_ano_ant_masc || 0 ) + calcDiferencaAdmissaoDemissaoMasc_n()}
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_nao_comungante_ano_ant_fem || 0 ) + calcDiferencaAdmissaoDemissaoFem_n()}
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {Number(record?.iv_nao_comungante_ano_ant_masc || 0 ) + Number(record?.iv_nao_comungante_ano_ant_fem || 0 ) + calcDiferencaAdmissaoDemissaoTotal_n()}
                    </Grid>

                    <Grid item xs={6} className={classes.labelPlainSmall}>
                        <b>ROL ATUAL (Comungantes + Não Comungantes):</b>
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {
                            (Number(record?.iv_comungantes_ano_ant_masc || 0 ) + calcDiferencaAdmissaoDemissaoMasc())
                            +
                            (Number(record?.iv_nao_comungante_ano_ant_masc || 0 ) + calcDiferencaAdmissaoDemissaoMasc_n())
                        }
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {
                            (Number(record?.iv_comungantes_ano_ant_fem || 0 ) + calcDiferencaAdmissaoDemissaoFem())
                            +
                            (Number(record?.iv_nao_comungante_ano_ant_fem || 0 ) + calcDiferencaAdmissaoDemissaoFem_n())
                        }
                    </Grid>
                    <Grid item xs={2} className={classes.labelPlainSmall}>
                        {
                            (Number(record?.iv_comungantes_ano_ant_masc || 0 ) + Number(record?.iv_comungantes_ano_ant_fem || 0 ) + calcDiferencaAdmissaoDemissaoTotal())
                            +
                            (Number(record?.iv_nao_comungante_ano_ant_masc || 0 ) + Number(record?.iv_nao_comungante_ano_ant_fem || 0 ) + calcDiferencaAdmissaoDemissaoTotal_n())
                        }
                    </Grid>

                </Grid>
            </Grid>
        </>
	);
}

export default RolMembros;