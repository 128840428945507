import Cookies from 'js-cookie';
import { ExecutiveCommissionEnum } from 'src/store/ducks/Main/types';

export const TOKEN_KEY = "spiapp-Token";
export const ME_KEY = "spiapp-Me";

export const isAuthenticated = () => {
    return !!Cookies.get(TOKEN_KEY);
};
export const getToken = () => Cookies.get(TOKEN_KEY);

export const login = (token: string) => {
  Cookies.set(TOKEN_KEY, token)
};
export const logout = () => {
    Cookies.remove(TOKEN_KEY);
};

export interface IMe {
  id: string;
  name: string;
  email: string;
  role: string;
  accessToken: string;
  avatar: string;
  executiveCommission: ExecutiveCommissionEnum;
  church?: {
    id: string;
    name: string;
    documentHeader: string;
  }
}

export const setMe = (user: any) => {
  console.log('setMe--->', JSON.stringify(user));
  return localStorage.setItem(ME_KEY, JSON.stringify(user));
}

export const getMe = (): IMe => {
  const json = localStorage.getItem(ME_KEY) || '{}';
  return JSON.parse(json);
}