import { Avatar, Button, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { 
    InsertDriveFile as FileIcon,
    KeyboardBackspace,
} from '@material-ui/icons';
import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import useStyles from './styles';

function DownloadImages({...props}) {
    const classes = useStyles();
    let match = useRouteMatch();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Switch>
            <Route path={`${match.path}`}>
                <Container maxWidth="xl">
                    <Link to="/app/ordinaria/downloads" className={classes.link}>
                        <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                        <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Você está Imagens</Button>
                    </Link>
                    <Grid container spacing={3}>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-branco.png" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar
                                        className={classes.avatarLarge} 
                                        src="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-branco.png"
                                    />
                                    <Typography>
                                        Logo Branco
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-preto.png" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar
                                        className={classes.avatarLarge} 
                                        src="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-preto.png"
                                    />
                                    <Typography>
                                        Logo Preto
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-text-verde-cinza.png" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar
                                        className={classes.avatarLarge} 
                                        src="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-text-verde-cinza.png"
                                    />
                                    <Typography>
                                        Logo Verde Texto Cinza
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-texto-verde-preto.png" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar
                                        className={classes.avatarLarge} 
                                        src="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-texto-verde-preto.png"
                                    />
                                    <Typography>
                                        Logo Verde Texto Preto
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-verde.png" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar
                                        className={classes.avatarLarge} 
                                        src="https://storage.googleapis.com/concilio-app.appspot.com/imagens/logo-verde.png"
                                    />
                                    <Typography>
                                        Logo Verde Texto Preto
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/imagens/texto-branco.png" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar
                                        className={classes.avatarLarge} 
                                        src="https://storage.googleapis.com/concilio-app.appspot.com/imagens/texto-branco.png"
                                    />
                                    <Typography>
                                        Texto Branco
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/imagens/texto-preto.png" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar
                                        className={classes.avatarLarge} 
                                        src="https://storage.googleapis.com/concilio-app.appspot.com/imagens/texto-preto.png"
                                    />
                                    <Typography>
                                        Texto Preto
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </Route>
        </Switch>
    );
}

export default DownloadImages;