import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';

function DisplayPdf({...props}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  console.log('props', props.url);
  function onDocumentLoadSuccess(e: any) {
    setNumPages(e.numPages);
  }

  return (
    <>
      <div>
        <Button variant="contained" size="small" disabled={pageNumber === 1} color="primary" onClick={() => setPageNumber(pageNumber - 1)}>Anterior</Button>
        <span> Página {pageNumber} de {numPages} </span>
        <Button variant="contained" size="small" disabled={pageNumber === numPages} color="primary" onClick={() => setPageNumber(pageNumber+1)}>Próxima</Button>
      </div>
      <Document
        file={props.url}
        options={{  }}
        onLoadSuccess={onDocumentLoadSuccess}
        className={props.className}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </>
  );
}

export default DisplayPdf;