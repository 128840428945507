import { Grid } from '@material-ui/core';
import React, { useState } from 'react'
import Avatar from 'react-avatar-edit'

function AvatarUpload({...props}) {
  const [preview, setPreview] = useState<string>();
  const onClose = () => {
    setPreview('');
  }
  
  const onCrop = (preview: any) => {
    setPreview(preview);
    props.onChange && props.onChange(preview)
  }

  const onBeforeFileLoad = (elem: any) => {
    if(elem.target.files[0].size > 3000000){
      alert("File is too big!");
      elem.target.value = "";
    };
  }
  
  return (
    <Grid container>
      <Grid item xs={6}>
        <Avatar
          width={290}
          height={195}
          imageWidth={290}
          cropRadius={0}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
          label={props.currentAvatar ? 'Escolher outra imagem' : 'Escolha uma imagem'}
        />
      </Grid>
      <Grid item xs={6} style={{textAlign: 'right'}}>
        { 
          preview   
            ? (
              <img src={preview} alt="Preview" width={100} />
            ) : props.currentAvatar ? (
              <img src={props.currentAvatar} alt="Preview" width={100} />
            ) : (null) }
      </Grid>
    </Grid>
  )
}

export default AvatarUpload;