import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import CurrencyInput from 'react-currency-input-field';
import styles from '../styles';
import NumberFormatCustom from 'src/components/NumberFormat';

function InformacoesFinanceiras({...props}) {
    const classes = styles();
    const isDisabled = (props.readOnly === true);
    const [start, setStart] = React.useState(true);
    const [percentage, setPercentage] = React.useState<any>({});
    const [percentageAtual, setPercentageAtual] = useState<any>({});
    const [percentageDespesa, setPercentageDespesa] = React.useState<any>({});
    const [percentageDespesaAtual, setPercentageDespesaAtual] = React.useState<any>({});
    const { record, onSetField, onChangeField, onSetCurrencyField } = props;
    let totalReceitasAnoAnterior = 0;
    let totalReceitasAnoAtual = 0;
    let totalDespesasAnoAnterior = 0;
    let totalDespesasAnoAtual = 0;


    const sumReceitasAnoAnterior = () => {
        const totalReceitasAnoAnterior = Number(record?.ant_dizimos?.replace(',','.') || 0) +
        Number(record?.ant_ofertas?.replace(',','.') || 0) +
        Number(record?.ant_ofertas_missionarias?.replace(',','.') || 0) +
        Number(record?.ant_ofertas_especificas?.replace(',','.') || 0) +
        Number(record?.ant_receitas_financeiras?.replace(',','.') || 0) +
        Number(record?.ant_emprestimos_ipb_jpef?.replace(',','.') || 0) +
        Number(record?.ant_parcerias?.replace(',','.') || 0) +
        Number(record?.ant_outras_receitas?.replace(',','.') || 0);
        return totalReceitasAnoAnterior;
    }

    const sumReceitasAnoAtual = () => {
        const totalReceitasAnoAtual = Number(record?.atual_dizimos?.replace(',','.') || 0) +
        Number(record?.atual_ofertas?.replace(',','.') || 0) +
        Number(record?.atual_ofertas_missionarias?.replace(',','.') || 0) +
        Number(record?.atual_ofertas_especificas?.replace(',','.') || 0) +
        Number(record?.atual_receitas_financeiras?.replace(',','.') || 0) +
        Number(record?.atual_emprestimos_ipb_jpef?.replace(',','.') || 0) +
        Number(record?.atual_parcerias?.replace(',','.') || 0) +
        Number(record?.atual_outras_receitas?.replace(',','.') || 0);

        return totalReceitasAnoAtual;
    }

    const sumDespesasAnoAnterior = () => {
        const totalDespesasAnoAnterior = Number(record?.ant_patrimonio?.replace(',','.') || 0) +
        Number(record?.ant_causas_locais?.replace(',','.') || 0) +
        Number(record?.ant_evangelismo_local?.replace(',','.') || 0) +
        Number(record?.ant_missoes?.replace(',','.') || 0) +
        Number(record?.ant_acao_social?.replace(',','.') || 0) +
        Number(record?.ant_sustento_pastoral?.replace(',','.') || 0) +
        Number(record?.ant_verba_presbiterial?.replace(',','.') || 0) +
        Number(record?.ant_dizimo_supremo_concilio?.replace(',','.') || 0) +
        Number(record?.ant_emprestimos_ipb_jpef?.replace(',','.') || 0) +
        Number(record?.ant_outras_despesas?.replace(',','.') || 0);

        return totalDespesasAnoAnterior;
    }

    const sumDespesasAnoAtual = () => {
        const totalDespesasAnoAtual = Number(record?.atual_patrimonio?.replace(',','.') || 0) +
        Number(record?.atual_causas_locais?.replace(',','.') || 0) +
        Number(record?.atual_evangelismo_local?.replace(',','.') || 0) +
        Number(record?.atual_missoes?.replace(',','.') || 0) +
        Number(record?.atual_acao_social?.replace(',','.') || 0) +
        Number(record?.atual_sustento_pastoral?.replace(',','.') || 0) +
        Number(record?.atual_verba_presbiterial?.replace(',','.') || 0) +
        Number(record?.atual_dizimo_supremo_concilio?.replace(',','.') || 0) +
        Number(record?.atual_emprestimos_ipb_jpef?.replace(',','.') || 0) +
        Number(record?.atual_outras_despesas?.replace(',','.') || 0);

        return totalDespesasAnoAtual;
    }

    const sumGrandeTotalAnterior = () => {
        return sumReceitasAnoAnterior() + Number(record?.ant_saldo_ano_anterior?.replace(',','.') || 0) ;
    }

    const sumGrandeTotalAtual = () => {
        return sumReceitasAnoAtual() + Number(record?.atual_saldo_ano_anterior?.replace(',','.') || 0) ;
    }

    const calcTotalPercentage = () => {
        const percentMap = ['ant_saldo_ano_anterior','ant_dizimos','ant_ofertas','ant_ofertas_missionarias','ant_ofertas_especificas','ant_receitas_financeiras','ant_parcerias','ant_outras_receitas','ant_patrimonio','ant_causas_locais','ant_evangelismo_local','ant_missoes','ant_acao_social','ant_sustento_pastoral','ant_verba_presbiterial','ant_dizimo_supremo_concilio','ant_emprestimos_ipb_jpef','ant_outras_despesas','ant_total_despesa_anual','ant_saldo_ano_seguinte','ant_grande_total'];
        const totalAnterior =  sumGrandeTotalAnterior();
        let totalPercentageAnt = 0;
        let newPercent: any = {};

        percentMap.map((field) => {
            const percent = (Number(record?.[field]?.replace(/\./g, '').replace(',', '.') || 0) / sumGrandeTotalAnterior());
            newPercent[field] = percent.toFixed(2);
            totalPercentageAnt += Number(percent);
        });

        setPercentage(newPercent);
        return totalPercentageAnt;
    };

    const calcTotalPercentageAtual = () => {
        const percentMap = ['atual_saldo_ano_anterior','atual_dizimos','atual_ofertas','atual_ofertas_missionarias','atual_ofertas_especificas','atual_receitas_financeiras','atual_parcerias','atual_outras_receitas','atual_patrimonio','atual_causas_locais','atual_evangelismo_local','atual_missoes','atual_acao_social','atual_sustento_pastoral','atual_verba_presbiterial','atual_dizimo_supremo_concilio','atual_emprestimos_ipb_jpef','atual_outras_despesas','atual_total_despesa_anual','atual_saldo_ano_seguinte','atual_grande_total'];
    const _sumGrandeTotalAtual = sumGrandeTotalAtual();
        let totalPercentageAtual = 0;
        let newPercent: any = {};

        percentMap.map((field) => {
            console.log(field, '-->', record?.[field]);
            const percent = (Number(record?.[field]?.replace(/\./g, '').replace(',', '.') || 0) / _sumGrandeTotalAtual);
            newPercent[field] = percent.toFixed(2);
            totalPercentageAtual += Number(percent);
        });

        setPercentageAtual(newPercent);
        return totalPercentageAtual;
    };

    const calcTotalPercentageDespAnt = () => {
        const percentMap = ['ant_patrimonio','ant_causas_locais','ant_evangelismo_local','ant_missoes','ant_acao_social','ant_sustento_pastoral','ant_verba_presbiterial','ant_dizimo_supremo_concilio','ant_emprestimos_ipb_jpef','ant_outras_despesas'];
        sumGrandeTotalAtual();
        let newPercent: any = {};
        percentMap.map((field) => {
            newPercent[field] = (Number(record?.[field]?.replace(/\./g, '').replace(',', '.') || 0) / sumDespesasAnoAnterior()).toFixed(2)
        });

        setPercentageDespesa(newPercent);
    };

    const calcTotalPercentageDespAtual = () => {
        const percentMap = ['atual_patrimonio','atual_causas_locais','atual_evangelismo_local','atual_missoes','atual_acao_social','atual_sustento_pastoral','atual_verba_presbiterial','atual_dizimo_supremo_concilio','atual_emprestimos_ipb_jpef','atual_outras_despesas'];
        const _sumDespesasAnoAtual = sumDespesasAnoAtual();
        console.log('_sumDespesasAnoAtual', _sumDespesasAnoAtual);
        let newPercent: any = {};
        percentMap.map((field) => {
            newPercent[field] = (Number(record?.[field]?.replace(/\./g, '').replace(',', '.') || 0) / (_sumDespesasAnoAtual || 1)).toFixed(2);
        });

        setPercentageDespesaAtual(newPercent);
    };

    const calcSaldoAntoSeguinteAnt = () => {
        return sumGrandeTotalAnterior() - sumDespesasAnoAnterior();
    }

    const calcGrandeTotalDespesasAnt = () => {
        return calcSaldoAntoSeguinteAnt() + totalDespesasAnoAnterior;
    }

    const calcSaldoAntoSeguinteAtual = () => {
        return Number(sumGrandeTotalAtual() || 0) - sumDespesasAnoAtual();
    }

    const calcGrandeTotalDespesasAtual = () => {
        return calcSaldoAntoSeguinteAtual() + totalDespesasAnoAtual;
    }

    if(start && record) {
        setStart(false);
        calcTotalPercentage();
        calcTotalPercentageAtual();
        calcTotalPercentageDespAnt();
        calcTotalPercentageDespAtual();
    }

	return (
        <>
            <Grid item xs={12} className={classes.sectionTitle}>
                <Typography align="right">
                    IPB - Informações Cadastrais e Estatísticas de Comunidade Presbiteriana - 2
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.sectionTitle}>
                <Typography>
                    V - Informações Financeiras
                </Typography>
            </Grid>

            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <Typography align="center">
                            <b>MOVIMENTO FINANCEIRO DO ANO ANTERIOR</b>
                        </Typography>
                    </Grid>
                    <Grid xs={6} className={classes.subSectionTitle}>
                        ITENS
                    </Grid>
                    <Grid xs={4} className={classes.subSectionTitle}>
                        Valores
                    </Grid>
                    <Grid xs={2} className={classes.subSectionTitle}>
                        %
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        <b>Saldo - Ano anterior:</b>
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_saldo_ano_anterior || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentage(); onSetCurrencyField(e)}}
                            name="ant_saldo_ano_anterior"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2}>
                        {percentage.ant_saldo_ano_anterior}%
                    </Grid>

                    <Grid xs={12} className={classes.hightlightBlue}>
                        RECEITAS
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Dízimos:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_dizimos || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentage(); onSetCurrencyField(e)}}
                            name="ant_dizimos"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentage.ant_dizimos}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Ofertas:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_ofertas || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentage(); onSetCurrencyField(e)}}
                            name="ant_ofertas"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentage.ant_ofertas || calcTotalPercentage()} %
                    </Grid>
                        
                    <Grid xs={6} className={classes.labelPlain}>
                        Ofertas Missionárias:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_ofertas_missionarias || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentage(); onSetCurrencyField(e)}}
                            name="ant_ofertas_missionarias"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentage.ant_ofertas_missionarias}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Ofertas Específicas:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_ofertas_especificas || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentage(); onSetCurrencyField(e)}}
                            name="ant_ofertas_especificas"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentage.ant_ofertas_especificas}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Receitas Financeiras:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_receitas_financeiras || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentage(); onSetCurrencyField(e)}}
                            name="ant_receitas_financeiras"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentage.ant_receitas_financeiras}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Parcerias:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_parcerias || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentage(); onSetCurrencyField(e)}}
                            name="ant_parcerias"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentage.ant_parcerias}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Outras Receitas:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_outras_receitas || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentage(); onSetCurrencyField(e)}}
                            name="ant_outras_receitas"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentage.ant_outras_receitas}%
                    </Grid>

                    <Grid xs={6} className={classes.financeiroSummario}>
                        Total da Receita Anual
                    </Grid>
                    <Grid xs={5} className={classes.financeiroSummario}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={sumReceitasAnoAnterior().toFixed(2)}
                            name="ant_total_receita_anual"
                            InputProps={{
                                readOnly: true,
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.financeiroSummario}>
                        {/* {calcTotalPercentage().toFixed(2)} */}
                    </Grid>

                    <Grid xs={6} className={classes.financeiroSummario}>
                        Grande Total
                    </Grid>
                    <Grid xs={5} className={classes.financeiroSummario}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={sumGrandeTotalAnterior().toFixed(2)}
                            name="ant_grande_total"
                            InputProps={{
                                readOnly: true,
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.financeiroSummario}>
                        {/* 0% */}
                    </Grid>

                    
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <Typography align="center">
                            <b>PREVISÃO ORÇAMENTÁRIA AO PRÓXIMO EXERCÍCIO</b>
                        </Typography>
                    </Grid>
                    <Grid xs={6} className={classes.subSectionTitle}>
                        ITENS
                    </Grid>
                    <Grid xs={5} className={classes.subSectionTitle}>
                        Valores
                    </Grid>
                    <Grid xs={1} className={classes.subSectionTitle}>
                        %
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        <b>Saldo - Ano anterior:</b>
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_saldo_ano_anterior || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageAtual(); onSetCurrencyField(e)}}
                            name="atual_saldo_ano_anterior"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentageAtual.atual_saldo_ano_anterior}%
                    </Grid>

                    <Grid xs={12} className={classes.hightlightBlue}>
                        RECEITAS
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Dízimos:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_dizimos || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageAtual(); onSetCurrencyField(e)}}
                            name="atual_dizimos"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentageAtual.atual_dizimos}%
                    </Grid>

                     <Grid xs={6} className={classes.labelPlain}>
                        Ofertas:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_ofertas || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageAtual(); onSetCurrencyField(e)}}
                            name="atual_ofertas"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentageAtual.atual_ofertas}%
                    </Grid>
                    
                    <Grid xs={6} className={classes.labelPlain}>
                        Ofertas Missionárias:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_ofertas_missionarias || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageAtual(); onSetCurrencyField(e)}}
                            name="atual_ofertas_missionarias"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentageAtual.atual_ofertas_missionarias}%
                    </Grid>
                    
                    <Grid xs={6} className={classes.labelPlain}>
                        Ofertas Específicas:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_ofertas_especificas || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageAtual(); onSetCurrencyField(e)}}
                            name="atual_ofertas_especificas"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentageAtual.atual_ofertas_especificas}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Receitas Financeiras:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_receitas_financeiras || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageAtual(); onSetCurrencyField(e)}}
                            name="atual_receitas_financeiras"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentageAtual.atual_receitas_financeiras}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Parcerias:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_parcerias || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageAtual(); onSetCurrencyField(e)}}
                            name="atual_parcerias"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentageAtual.atual_parcerias}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Outras Receitas:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_outras_receitas || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageAtual(); onSetCurrencyField(e)}}
                            name="atual_outras_receitas"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {percentageAtual.atual_outras_receitas}%
                    </Grid>

                    <Grid xs={6} className={classes.financeiroSummario}>
                        Total da Receita Anual
                    </Grid>
                    <Grid xs={5} className={classes.financeiroSummario}>
                        <TextField
                            label=""
                            value={sumReceitasAnoAtual().toFixed(2)}
                            name="atual_total_receita"
                            InputProps={{
                                readOnly: true,
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                        
                    </Grid>
                    <Grid xs={1} className={classes.financeiroSummario}>
                        
                    </Grid>
                    
                    <Grid xs={6} className={classes.financeiroSummario}>
                        Grande Total
                    </Grid>
                    <Grid xs={5} className={classes.financeiroSummario}>
                        <TextField
                            label=""
                            value={sumGrandeTotalAtual().toFixed(2)}
                            name="atual_grande_total"
                            InputProps={{
                                readOnly: true,
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.financeiroSummario}>
                        {/* 0% */}
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12} className={classes.hightlightRed}>
                        DESPESAS
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Patrimônio:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_patrimonio || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_patrimonio"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_patrimonio}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Causas Locais:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_causas_locais || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_causas_locais"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_causas_locais}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Evangelismo Local:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_evangelismo_local || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_evangelismo_local"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_evangelismo_local}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Missões:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_missoes || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_missoes"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_missoes}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Ação Social:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_acao_social || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_acao_social"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_acao_social}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Sustento Pastoral:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_sustento_pastoral || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_sustento_pastoral"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_sustento_pastoral}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Verba Presbiterial:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_verba_presbiterial || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_verba_presbiterial"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_verba_presbiterial}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Dízimo ao Supremo Concílio:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_dizimo_supremo_concilio || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_dizimo_supremo_concilio"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_dizimo_supremo_concilio}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Empréstimos IPB / JPEF:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_emprestimos_ipb_jpef || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_emprestimos_ipb_jpef"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_emprestimos_ipb_jpef}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Outras Despesas:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.ant_outras_despesas || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAnt(); onSetCurrencyField(e)}}
                            name="ant_outras_despesas"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesa.ant_outras_despesas}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Total da Despesa Anual:
                    </Grid>
                    <Grid xs={4} className={classes.labelPlain}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={ sumDespesasAnoAnterior().toFixed(2) }
                            name="atual_total_despesas"
                            InputProps={{
                                readOnly: true,
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {/* 0% */}
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        <b>Saldo - Ano seguinte:</b>
                    </Grid>
                    <Grid xs={4} className={classes.labelPlain}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={calcSaldoAntoSeguinteAnt().toFixed(2)}
                            // onChange={onChangeField}
                            // onBlur={(e) => {calcTotalPercentageAtual(); onSetCurrencyField(e)}}
                            name="atual_saldo_ano_seguinte"
                            InputProps={{
                                readOnly: true,
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {/* 0% */}
                    </Grid>

                    {/* <Grid xs={6} className={classes.hightlightGray}>
                        Grande Total:
                    </Grid>
                    <Grid xs={4} className={classes.hightlightGray}>
                        {calcGrandeTotalDespesasAnt().toFixed(2)}
                    </Grid>
                    <Grid xs={2} className={classes.hightlightGray}>
                        
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid xs={12} className={classes.hightlightRed}>
                        DESPESAS
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Patrimônio:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_patrimonio || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_patrimonio"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_patrimonio}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Causas Locais:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_causas_locais || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_causas_locais"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_causas_locais}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Evangelismo Local:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_evangelismo_local || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_evangelismo_local"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_evangelismo_local}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Missões:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_missoes || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_missoes"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_missoes}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Ação Social:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_acao_social || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_acao_social"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_acao_social}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Sustento Pastoral:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_sustento_pastoral || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_sustento_pastoral"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_sustento_pastoral}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Verba Presbiterial:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_verba_presbiterial || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_verba_presbiterial"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_verba_presbiterial}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Dízimo ao Supremo Concílio:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_dizimo_supremo_concilio || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_dizimo_supremo_concilio"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_dizimo_supremo_concilio}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Empréstimos IPB / JPEF:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_emprestimos_ipb_jpef || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_emprestimos_ipb_jpef"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_emprestimos_ipb_jpef}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Outras Despesas:
                    </Grid>
                    <Grid xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="R$"
                            value={record?.atual_outras_despesas || '0.00'}
                            onChange={onChangeField}
                            onBlur={(e) => {calcTotalPercentageDespAtual(); onSetCurrencyField(e)}}
                            name="atual_outras_despesas"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={1} className={classes.labelPlain}>
                        {percentageDespesaAtual.atual_outras_despesas}%
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        Total da Despesa Anual:
                    </Grid>
                    <Grid xs={4} className={classes.labelPlain}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={ sumDespesasAnoAtual().toFixed(2) }
                            name="atual_total_despesas_anual"
                            InputProps={{
                                readOnly: true,
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.labelPlain}>
                        {/* 0% */}
                    </Grid>

                    <Grid xs={6} className={classes.labelPlain}>
                        <b>Saldo - Ano seguinte:</b>
                    </Grid>
                    <Grid xs={4} className={classes.labelPlain}>
                        <TextField
                            disabled={isDisabled}
                            label=""
                            value={calcSaldoAntoSeguinteAtual().toFixed(2)}
                            name="atual_saldo_ano_seguinte"
                            InputProps={{
                                readOnly: true,
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid xs={2} className={classes.financeiroSummario}>
                        {/* 0% */}
                    </Grid>

                    {/* <Grid xs={6} className={classes.hightlightGray}>
                        Grande Total:
                    </Grid>
                    <Grid xs={4} className={classes.hightlightGray}>
                        {calcGrandeTotalDespesasAtual().toFixed(2)}
                    </Grid>
                    <Grid xs={2} className={classes.hightlightGray}>
                        
                    </Grid> */}
                </Grid>
            </Grid>
        
        </>
	);
}

export default InformacoesFinanceiras;