import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Input, InputLabel, List, ListItem, MenuItem, Paper, Select, Typography, useMediaQuery } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getMe } from '../../../services/auth';
import {
    HowToVote as HowToVoteIcon,
    Check as CheckIcon,
    Restore as RestoreIcon,
    Clear as ClearIcon,
} from '@material-ui/icons';
import { gql, useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    MuiPickersUtilsProvider,
    TimePicker,
} from '@material-ui/pickers'
import ptBrLocale from "date-fns/locale/pt-BR";
import { subscribe } from 'graphql';

enum ElectionTypeEnum {
	PRESIDENTE = 'Presidente',
	VICE_PRESIDENTE = 'Vice-presidente',
	SECRETARIO_ATAS = 'Secretário de atas',
	SECRETARIO_PROTOCOLO = 'Secretário de protocolo',
	SECRETARIO_EXECUTIVO = 'Secretário executivo',
	TESOUREIRO = 'Tesoureiro',
}

export enum ElectionSecTypeEnum {
	SAF = 'Secretário da SAF',
	UPH = 'Secretário da UPH',
	UMP = 'Secretário da UMP',
	UPA = 'Secretário da UPA',
	UCP = 'Secretário da UCP',
	EVANGELISMO = 'Secretário de Evangelismo',
	CUIDADO_PASTORAIS = 'Secretário Cuidado Pastorais',
}

enum ElectionStatusEnum {
	CRIADA = 'Criada',
	EM_ANDAMENTO = 'Em andamento',
	CONCLUIDA = 'Concluída', // pode ser reiniciada caso não atinja os mínimo necessário de votos
	FINALIZADA = 'Finalizada',
}

export enum SubStepStatusEnum {
	ELECTION_ENDED = 'Eleição finalizada'
}

const QUERY_GET_ELECTION_RESULT = gql`
    query GetElectionResult($subStep: String!) {
        getElectionResult(subStep: $subStep) {
            id
            electionId
            type
            user {
                id
                name
            }
            votes
            percentage
        }
    }
`;

const QUERY_ELECTION = gql`
  query Users($subStep: String!) {
        getCurrentElection(subStep: $subStep) {
            id
            type
            status
            order
            electionVotes {
                id
                user {
                    id
                    name
                }
            }
            electionUsers {
                id
                votes
                user {
                    id
                    name
                }
            }
        }
        getResultsElection(subStep: $subStep) {
            id
            type
            electionId
            user {
                id
                name
            }
        }
        getNextElection(subStep: $subStep) {
            id
            type
            status
            order
        }
        getUsers {
            id
            name
            type
            church {
                name
            }
        }
        getSubStep(id: $subStep) {
            id
            name
            status
        }
  }
`;

const MUTATION_SET_ELECTION_USER = gql`
    mutation SetElectionUser($subStep: String!, $time: String!, $users: [String!]!) {
        setElectionUser(subStep: $subStep, time: $time, users: $users) {
            id
        }
    }
`;

const MUTATION_COMPLETE_ELECTION = gql`
    mutation CompleteElection($subStep: String!) {
        completeElection(subStep: $subStep) {
            id
        }
    }
`;

const MUTATION_FINALIZE_ELECTION = gql`
    mutation FinalizeElection($subStep: String!) {
        finalizeElection(subStep: $subStep) {
            id
        }
    }
`;

const MUTATION_USER_VOTE = gql`
    mutation UserVote($electionUserId: String!) {
        userVote(electionUserId: $electionUserId) {
            id
        }
    }
`;

const MUTATION_RESET_ELECTION = gql`
    mutation ResetElection($electionId: String!, $resetAll: Boolean) {
        resetElection(electionId: $electionId, resetAll: $resetAll) {
            id
        }
    }
`;

const SUBSCRIPTION_ELECTION = gql`
  subscription OnElectionUpdated {
    electionUpdated {
      id
      status
    }
  }
`;

const SUBSCRIPTION_ELECTION_VOTE = gql`
    subscription OnElectionVoteUpdated {
        electionVoteUpdated {
            id
            election {
                id
            }
            user {
                id
                name
            }
        }
    }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      padding: 20,
      '& .MuiAlert-message': {
        flex: 1
        }
    },
    rootMobile: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: 0,
        '& .MuiAlert-message': {
          flex: 1
          }
      },
    rootCentered: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        '& .MuiAlert-message': {
            flex: 1
        }
    },
    formControl: {
      margin: theme.spacing(3),
    },
    formControlFlexForm: {
        margin: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row'
    },
    box: {
        flexGrow: 1,
        display: 'flex',
        '& > div': {
            flexGrow: 1
        }
    },
    boxOutro: {
        marginTop: 20,
        marginLeft: 20,
        cursor: 'pointer',
        textDecoration: 'underline',
        opacity: 0.4,
    },
    grid: {},
    gridItem: {},
    paper: {
        textAlign: 'center',
    },
    textInfo: {
        margin: '30px 0'
    },
    btnStart: {
        marginTop: 30
    },
    selectUsers: {
        maxWidth: '700px',
        '& li > span': {
            opacity: 0.5
        }
    },
    textType: {
        fontSize: 14,
        textAlign: 'center',
    },
    textStatus: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 20,
    },
    textNumVotes: {
        fontSize: 12,
        textAlign: 'center',
    },
    containerCenter: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textViewResults: {
        marginBottom: 15,
        textAlign: 'center'
    },
    userElected: {
         '& p ': {
            fontSize: 18,
            fontWeight: 'bold',
            color: 'rgb(30, 70, 32)',
            backgroundColor: 'rgb(237, 247, 237)',
            padding: 5
         }
    },
    gridResults: {
        background: '#eee',
        padding: 10,
        borderRadius: 10,
        '& h2': {
            fontSize: 16,
            fontWeight: 'bold',
            marginBottom: 20,
        },
        '& .MuiGrid-container': {
            marginBottom: 15,
            '& span': {
                opacity: 0.4
            }
        }
    },
    textRight: {
        textAlign: 'right'
    },
    bold: {
        fontWeight: 'bold'
    },
    resultElectionName: {
        '& span': {
            cursor: 'pointer',
            fontSize: 12,
            opacity: 0.6,
            display: 'inline-block',
            background: '#ccc',
            borderRadius: 5,
            padding: '1px 5px',
            '& svg': {
                fontSize: 14,
                verticalAlign: 'text-bottom',
            }
        }
    }
  }),
);

export default function Eleicao({...props}) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
    const me = getMe();
    const isElectionAdmin = me && me.role && ( me.role.includes('master') ) ;
    const [isLoading, setIsLoading] = useState(false);

    const [ electionTime, setElectionTime ] = useState<any>(null);
    const [ showAlertResetElection, setShowAlertResetElection ] = useState<any>({});
    const { data: dataElectionUpdated, loading: updatingElection } = useSubscription(SUBSCRIPTION_ELECTION);
    const { data: dataElectionVoteUpdated, loading: updatingElectionVote, error: errorElectionVote  } = useSubscription(SUBSCRIPTION_ELECTION_VOTE, { onSubscriptionData: (a) => {console.log('SUBSCRIPTION_ELECTION======>', a);} });
    const { loading, error, data, refetch, subscribeToMore } = useQuery(QUERY_ELECTION, { variables: { subStep: props.subStepId } });
    const [ getElectionResult, { loading: loadingElectionResult, error: errorElectionResult , data: dataElectionResult }] = useLazyQuery(QUERY_GET_ELECTION_RESULT);
    const [ userVote, { loading: loadingUserVote, error: errorUserVote } ] = useMutation(MUTATION_USER_VOTE, {
        onCompleted: () => {
            if(errorUserVote) {
                alert('OPS! Ocorreu algum problema na votação. Atualize a página e tente novamente.');
            }
            setIsLoading(true);
            refetch().then(() => { setIsLoading(false) });
        },
        onError: ({ graphQLErrors}) => {
            if (graphQLErrors) {
                for (let err of graphQLErrors) {
                  switch (err?.extensions?.code) {
                    case 'INTERNAL_SERVER_ERROR':
                      alert(err?.extensions?.exception?.response?.errors?.email || err?.extensions?.exception?.response?.errors?.token);
                      break;
                  }
                }
            }
        }
    });
    const [ setElectionUser, { loading: loadingElectionUser } ] = useMutation(MUTATION_SET_ELECTION_USER, {
        onCompleted: () => {
            setIsLoading(true);
            refetch().then(() => { setIsLoading(false) });
        }
    });
    const [ setResetElection, { loading: loadingResetElection } ] = useMutation(MUTATION_RESET_ELECTION, {
        onCompleted: () => {
            setIsLoading(true);
            refetch().then(() => { setIsLoading(false) });
        }
    });
    const [ completeElection, { loading: loadingCompleteElection } ] = useMutation(MUTATION_COMPLETE_ELECTION, {
        onCompleted: () => {
            setIsLoading(true);
            refetch().then(() => { setIsLoading(false) });
        }
    });
    const [ finalizeElection, { loading: loadingFinalizeElection } ] = useMutation(MUTATION_FINALIZE_ELECTION, {
        onCompleted: () => {
            setIsLoading(true);
            refetch().then(() => { setIsLoading(false) });
        }
    });

    const [ currentElection, setCurrentElection ] = useState<any>(null);
    const [ users, setUsers ] = useState<any>([]);
    const [ currentVote, setCurrentVote ] = useState('');

    useEffect(() => {
        if(dataElectionVoteUpdated && dataElectionVoteUpdated.electionVoteUpdated) {
            refetch();
        }
    }, [dataElectionVoteUpdated, refetch]);

    useEffect(() => {
        if(!isElectionAdmin && dataElectionUpdated && dataElectionUpdated.electionUpdated) {
            refetch();
        }
    }, [dataElectionUpdated, refetch, isElectionAdmin]);
    
    
    if(error) {
        return <div>Ocorreu algum erro ao carregar a eleição...</div>
    }

    if(errorUserVote) {
        return <div>Ocorreu algum erro ao votar. Atualize a página e tente novamente.</div>
    }

    // if(updatingElectionVote && !isElectionAdmin) {
    //     return <div>Computando voto...</div>
    // }

    //TODO: verificar se precisa alguma ação aqui
    // if(dataElectionVoteUpdated && !isElectionAdmin) {
    //     return <div>test: {dataElectionVoteUpdated.election}</div>
    // }

    if(loading || isLoading) {
        return <div>Carregando... Aguarde!</div>
    }

    if(loadingElectionUser || loadingCompleteElection || loadingFinalizeElection || loadingResetElection) {
        return <div>Carregando...</div>
    }

    if(loadingUserVote) {
        return <div>Computando seu voto...</div>
    }

    if(loadingElectionResult)  {
        return <div>Carregando resultado...</div>
    }
    if(errorElectionResult) {
        return <div>Ocorreu algum erro ao carregar o resultado.</div>
    }

    // subscribeToMore({
    //     document: SUBSCRIPTION_ELECTION_VOTE,
    //     updateQuery: (prev, { subscriptionData }) => {
    //         console.log('@@@@@@subdata--->', subscriptionData);
    //       if (!subscriptionData.data) return prev;
    //     }
    // });

    const onGetElectionResult = () => {
        !loadingElectionResult && !dataElectionResult && getElectionResult({
            variables: { subStep: props.subStepId }
        });
    }

    const onSetElectionUsers = () => {
        if(!users.length) {
            return alert('Você precisa selecionar os usuários primeiro.');
        }
        if(!electionTime && props.electionType !== 'sec' && props.electionType !== 'tesoureiro') {
            return alert('Você precisa definir um horário.');
        }
        setElectionUser({
            variables: {
                subStep: props.subStepId,
                time: electionTime || '',
                users
            }
        });
    }

    const onUserVote = () => {
        if(!currentVote) {
            return alert('Você precisa selecionar um candidato para votar.');
        }
        userVote({
            variables: { electionUserId: currentVote }
        });

        const audio = new Audio('/assets/urna.mp3');
        audio.play();
    }

    const onCompleteElection = () => {
        completeElection({
            variables: { subStep: props.subStepId }
        });
    }

    const onFinalizeElection = () => {
        finalizeElection({
            variables: { subStep: props.subStepId }
        });
    }

    const onResetElection = (electionId: string, resetAll?: boolean) => {
        setResetElection({
            variables: {
                electionId,
                resetAll
            }
        });
    }


    let userVoted = false;
    data?.getCurrentElection?.electionVotes?.map((item: any, idx: number) => {
        if(item.user?.id === me.id) userVoted = true;
    });


    if(data?.getSubStep?.status === SubStepStatusEnum.ELECTION_ENDED) {
        !dataElectionResult && onGetElectionResult();
        return (
            <Container maxWidth="lg">
                <Dialog open={!!showAlertResetElection.id}>
                    <DialogTitle>Refazer eleição para {showAlertResetElection.type}?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Isso irá apagar todos os votos para eleição de {showAlertResetElection.type}.
                            Tem certeza que deja continuar?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowAlertResetElection({})} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={() => {
                            setShowAlertResetElection({});
                            onResetElection(showAlertResetElection.electionId, true);
                        }} color="primary" autoFocus>
                            Sim, continuar.
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Alert severity="success">
                            <Typography align="center">A eleição foi concluída com sucesso.</Typography>
                        </Alert>
                    </Grid>

                    { matchesSm ? (null) : (
                        <>
                            <Grid item xs={4}> <Typography>Cargo</Typography> </Grid>
                            <Grid item xs={4}> <Typography>Eleito</Typography> </Grid>
                            <Grid item xs={1}> <Typography>Votos</Typography> </Grid>
                            <Grid item xs={1}> <Typography>%</Typography> </Grid>
                            <Grid item xs={2}> <Typography>{ isElectionAdmin ? 'Opção' : ' '}</Typography> </Grid>
                        </>
                    ) }

                    <Grid xs={12}><hr/></Grid>

                {
                    dataElectionResult?.getElectionResult?.map((electionUser: any, idx: number) => (
                        <>
                            <Grid item xs={ matchesSm ? 12 : 4 } className={ matchesSm ? classes.bold : '' }>
                                {electionUser.type}
                            </Grid>
                            <Grid item xs={matchesSm ? 12 : 4}>
                                {electionUser.user?.name}
                            </Grid>
                            <Grid item xs={matchesSm ? 12 : 1} className={matchesSm ? '' : classes.textRight}>
                                { matchesSm ? 'Votos:' : null }
                                {electionUser.votes || 0}
                            </Grid>
                            <Grid item xs={matchesSm ? 12 : 1} className={matchesSm ? '' : classes.textRight}>
                                {electionUser?.percentage?.toFixed(2) || 0}%
                            </Grid>
                            <Grid item xs={matchesSm ? 12 : 2} className={matchesSm ? '' : classes.textRight}>
                                {
                                    isElectionAdmin ? (
                                        <span onClick={() => setShowAlertResetElection({...electionUser})}><ClearIcon/> refazer</span>
                                    ) : (null)
                                }
                            </Grid>
                            { matchesSm ? (<Grid item xs={12}><hr /></Grid>) : (null) }
                        </>
                    ))
                }
                </Grid>
            </Container>
        );
    }

    if(!data.getCurrentElection && data?.getSubStep?.status !== SubStepStatusEnum.ELECTION_ENDED) {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}> 
            <Container maxWidth="lg">
                <div className={classes.rootCentered}>
                    <Alert severity="info">
                        <Typography align="center">A eleição ainda não foi iniciada.</Typography>
                    </Alert>
                    {
                        (isElectionAdmin) 
                            ? (
                                <>
                                {
                                    (props.electionType !== 'sec' && props.electionType !== 'tesoureiro') ? (
                                        <FormControl className={classes.formControl}>
                                            <TimePicker
                                                value={electionTime || new Date()}
                                                label="Horário"
                                                margin="normal"
                                                ampm={false}
                                                format="H:mm"
                                                autoFocus
                                                onChange={(value) => {
                                                    setElectionTime(value)
                                                }}
                                            />
                                        </FormControl>
                                    ) : ( null )
                                }

                                <Typography align="center" className={classes.textInfo}>Escolha abaixo os usuários que irão participar do pleito</Typography>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-mutiple-name-label">Usuários</InputLabel>
                                    <Select
                                        multiple
                                        value={users || []}
                                        onChange={(e) => setUsers(e.target.value)}
                                        input={<Input />}
                                        fullWidth
                                        className={classes.selectUsers}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 250,
                                                    marginTop: 50
                                                },
                                            },
                                        }}
                                    >	
                                        {
                                            data?.getUsers.map((item: any, idx: any) => (
                                                <MenuItem key={idx} value={item.id} >
                                                    {`${item.name} `} 
                                                    {/* <span style={{opacity: 0.5, marginLeft: 5, fontSize: 12}}> ({item.type})</span>
                                                    <span style={{opacity: 0.5, marginLeft: 5}}> - {item.church?.name}</span> */}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                                <Button
                                    startIcon={<HowToVoteIcon />}
                                    className={classes.btnStart}
                                    variant="contained"
                                    color="primary" onClick={() => onSetElectionUsers()}>Iniciar eleição para {props.electionType === 'sec' ? ElectionSecTypeEnum.SAF : props.electionType === 'tesoureiro' ? ElectionTypeEnum.TESOUREIRO : ElectionTypeEnum.PRESIDENTE }</Button>
                                </>
                            ) : ( 
                                <Typography align="center" className={classes.textInfo}>Aguarde o responsável liberar a votação.</Typography>
                             )
                    }
                </div>  
            </Container>
            </MuiPickersUtilsProvider>
        )
    }

    const getResultTable = () => {
        const sortByVote: any = data.getCurrentElection?.electionUsers?.slice().sort((a: any, b: any) => {
            if(a.votes <= b.votes) return 1;
            else if(b.votes < a.votes) return -1;
            else return 0;
        });

        return sortByVote.map((electionUser: any, idx: number) => (
            <Grid container key={`rowUser-${idx}`} className={(idx === 0 || sortByVote[0].votes === electionUser.votes) ? classes.userElected : undefined}>
                <Grid item xs={matchesSm ? 12 : 6}>
                    <Typography>{electionUser.user.name}</Typography>
                </Grid>
                <Grid item xs={matchesSm ? 6 : 3}>
                    <Typography>{(((electionUser.votes || 0) / data.getCurrentElection?.electionVotes?.length)*100).toFixed(2)}%</Typography>
                </Grid>
                <Grid item xs={matchesSm ? 6 : 3}>
                    <Typography>{matchesSm ? 'Votos:' : (null)} {(electionUser.votes || 0)}</Typography>
                </Grid>
                {  matchesSm ? <Grid item xs={12}><hr/></Grid> : (null) }
            </Grid>
        ))
    }

  return (
    <div className={matchesSm ? classes.rootMobile : classes.root}>
        <Dialog open={!!showAlertResetElection.id}>
            <DialogTitle>Refazer eleição para {showAlertResetElection.type}?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Isso irá apagar todos os votos para eleição de {showAlertResetElection.type}.
                    Tem certeza que deja continuar?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowAlertResetElection({})} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={() => {
                    setShowAlertResetElection({});
                    onResetElection(showAlertResetElection.electionId, true);
                }} color="primary" autoFocus>
                    Sim, continuar.
                </Button>
            </DialogActions>
        </Dialog>
        <Typography variant="h3" className={classes.textType}>
            Eleição para: <b>{data.getCurrentElection.type}</b>
        </Typography>
        <Typography variant="h4"  className={classes.textStatus}>
            Status: <b>{data.getCurrentElection.status}</b>
        </Typography>
        <Grid container className={classes.containerCenter}>
        <Grid item xs={matchesSm ? 12 : data?.getResultsElection?.length ? 9 : 12}>
        <Container maxWidth="sm" className={classes.containerCenter}>
            {
                (isElectionAdmin) 
                    ? (
                        <Grid container spacing={matchesSm ? 2 : 5}>
                            {
                                (data.getCurrentElection.status === ElectionStatusEnum.EM_ANDAMENTO)
                                    ? (
                                        <>
                                        <Grid item xs={12}>
                                            <Typography className={classes.textViewResults}>
                                                A eleição está em andamento, você pode finaliza-la a qualquer momento.
                                            </Typography>
                                            <Typography className={classes.textViewResults}>
                                                Total de votos computados: <b>{data?.getCurrentElection?.electionVotes?.length || 0}</b> 
                                            </Typography>
                                    </Grid>
                                    <Grid item xs={12} container direction="column" alignItems="center">
                                            <Button onClick={() => onCompleteElection()} startIcon={<CheckIcon/>} variant="contained" color="primary">
                                                Finalizar eleição para {data.getCurrentElection.type} e exibir o resultado
                                            </Button>
                                        </Grid>
                                        </>
                                    ) : (
                                        <>
                                        <Grid item xs={12}>     
                                            <Typography className={classes.textViewResults}>
                                                Confira abaixo o resultado da votação para <b>{data.getCurrentElection.type}</b>:
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <b>Usuário</b>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <b>Porcentagem</b>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <b>Votos</b>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <hr/>
                                                </Grid>
                                                {
                                                    getResultTable()
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} container direction="column" alignItems="center">
                                            <Button startIcon={<RestoreIcon/>} onClick={() => onResetElection(data?.getCurrentElection?.id)} variant="contained" color="secondary">
                                                Refazer eleição para {data.getCurrentElection.type}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} container direction="column" alignItems="center">
                                            <Button startIcon={<CheckIcon/>} onClick={() => onFinalizeElection()} variant="contained" color="primary">
                                                Concluir eleição e iniciar eleição para {data.getNextElection?.type}
                                            </Button>
                                        </Grid>
                                        </>
                                    )
                            }
                        </Grid>
                    ) : ( //!isElectionAdmin
                        <Grid container spacing={5}>
                        {
                            (data.getCurrentElection.status === ElectionStatusEnum.EM_ANDAMENTO)
                                ? (
                                    (userVoted)
                                        ? (
                                            <Grid item xs={12}>
                                            <Alert severity="success">
                                                <Typography align="center">Seu voto foi computado com sucesso.</Typography>
                                            </Alert>
                                            <Typography align="center">Aguarde o responsável dar andamento na eleição.</Typography>
                                            </Grid>
                                        ) : (
                                            <>
                                            <Grid item xs={12}>
                                                <Typography className={classes.textViewResults}>
                                                    A eleição está em andamento, escolha seu candidato à {<b>{data.getCurrentElection.type}</b>} abaixo.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                    <InputLabel id="demo-mutiple-name-label">Votar em</InputLabel>
                                                    <Select
                                                        value={currentVote || ''}
                                                        onChange={(e) => setCurrentVote(`${e.target.value}`)}
                                                        input={<Input />}
                                                        fullWidth
                                                        className={classes.selectUsers}
                                                    >	
                                                        {
                                                            data?.getCurrentElection?.electionUsers?.map((item: any, idx: any) => {
                                                                return (
                                                                    <MenuItem key={`userElection-${idx}`} value={item.id} >
                                                                        {`${item.user.name}`} 
                                                                        {/* <span style={{opacity: 0.5, marginLeft: 5, fontSize: 12}}> ({item.type})</span>
                                                                        <span style={{opacity: 0.5, marginLeft: 5}}> - {item.church?.name}</span> */}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} container direction="column" alignItems="center" style={{marginBottom: 20}}>
                                                <Button onClick={() => onUserVote()} startIcon={<CheckIcon/>} variant="contained" color="primary">
                                                    Votar para {data.getCurrentElection.type}
                                                </Button>
                                            </Grid>
                                            </>
                                        )
                                ) : (
                                    <>
                                    <Grid item xs={12}>     
                                        <Typography className={classes.textViewResults}>
                                            Confira abaixo o resultado da votação para <b>{data.getCurrentElection.type}</b>:
                                        </Typography>
                                    </Grid>
                                    {
                                        getResultTable()
                                    }
                                    <Grid item xs={12}>     
                                        <Typography className={classes.textViewResults}>
                                            Aguarde o responsável dar andamento na eleição.
                                        </Typography>
                                    </Grid>
                                    </>
                                )
                        }
                        </Grid>
                    )
            }
                
        </Container>
        </Grid>
            {
                (data?.getResultsElection?.length)
                    ? (
                        <Grid item xs={matchesSm ? 12 : 3}  className={classes.gridResults}>
                            <Typography variant="h2" align="center">RESULTADO</Typography>
                            {data?.getResultsElection.map((electionResult: any, idx: number) => (
                                <Grid key={`electionResult-${idx}`} container direction="column">
                                    <Typography align="center" className={classes.resultElectionName}>
                                        <b>{electionResult.type}</b>
                                        {
                                            electionResult.user && isElectionAdmin ? (
                                                <span onClick={() => setShowAlertResetElection({...electionResult})}><ClearIcon/> refazer</span>
                                            ) : (null)
                                        }
                                    </Typography>
                                    <Typography align="center">
                                        {electionResult.user ? electionResult.user.name : (<span>aguardando</span>)}
                                    </Typography>
                                </Grid>
                            ))} 
                        </Grid>
                    ) : (null)
            }
        </Grid>
    </div>
  );
}