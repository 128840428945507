import { gql, useMutation, useQuery } from '@apollo/client';
import { Checkbox, Container, Grid, Typography, TextareaAutosize, TextField, FormControlLabel, Radio, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MyBackdrop from 'src/components/Backdrop';
import NumberFormatCustom from 'src/components/NumberFormat';
import styles from '../styles';

const logo = require('../../../assets/ipb_logo.png');

const QUERY_REPORT_FIELDS = gql`
  query GetReportFields($report: String!) {
    getReportFields(report: $report) {
	  id
	  name
	  label
      type
      value
    }
  }
`;

const MUTATION_SET_FIELD = gql`
	mutation SetField($report: String!, $value: String!, $name: String!) {
		setField(report: $report, value: $value, name: $name) {
			id
			name
			value
		}
	}
`;

function ReportMinistro({...props}) {
	const classes = styles();
	const isDisabled = (props.readOnly === true);
    const [ record, setRecord ] = useState<any>(null);
    const { loading, error , data } = useQuery(QUERY_REPORT_FIELDS, { variables: { report: props.report.id } });
    const [ setField, { loading: loadingField } ] = useMutation(MUTATION_SET_FIELD);

    useEffect(() => {
        if(!data) return;

        const newRecord: any =  {};
        data.getReportFields?.map((item: any) => {
            newRecord[item.name] = item.value;
        });
        setRecord(newRecord);
    }, [data]);

    const onChangeField = (e: any) => {
        const newRecord = Object.assign({}, record);
        newRecord[e.target.name] = e.target.value;
        setRecord(newRecord);
    };

    const onChangeCheckField = (e: any) => {
        const checked = e.target.checked;
        const name = e.target.name;
        setRecord({...record, [name]: `${checked}`});
        onSetCheckField(name, checked)
    };

    const onSetCurrencyField = (e: any) => {
        const value = e.target.value.replace(/\./g, '').replace(',', '.');
        setField({
            variables: {
                report: props.report.id,
                name: e.target.name,
                value,
            }
        });
    };

    const onSetCheckField = (name: string, checked: any) => {
        setField({
            variables: {
                report: props.report.id,
                value: `${checked}`,
                name: name
            }
        });
    };

    const onSetField = (e: any) => {
        setField({
            variables: {
                report: props.report.id,
                value: e.target.value,
                name: e.target.name
            }
        });
    };

    if(error) {
        return <div>Ocorreu algum erro ao carregar os dados.</div>
    }
	
	return (
		<Container className={classes.root}>
			<Grid container spacing={0} xs={12} className={classes.rootGridContainer}>
				<Grid item xs={3}>
					<img alt="Logo Concílio" src={logo} className={classes.logo} />
				</Grid>
				<Grid item xs={9}>
					<Grid container>
						<Grid item xs={12} className={classes.title}
							container
							direction="column"
							justify="center"
							alignItems="center"
						>
							<Typography variant="h1" align="center">
								RELATÓRIO DO MINISTRO PRESBITERIANO
							</Typography>
							<Typography>
								<b>ANO: { new Date().getFullYear() }</b>
							</Typography>
						</Grid>

						{/* <Grid item xs={4}>
						</Grid>
						<Grid item xs={5}>
						</Grid>
						<Grid item xs={3}>
							<Typography align="right">
								SIGLAS:
							</Typography>
						</Grid> */}

						{/* <Grid item xs={3}>
							SÍNODO: 
						</Grid> */}
						<Grid item xs={9}>
                            <TextField fullWidth disabled={isDisabled} label="SÍNODO" name="sinodo" value={record?.sinodo || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={3}>
                            <TextField fullWidth disabled={isDisabled} label="SIGLA" name="sinodo_sigla" value={record?.sinodo_sigla || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>

						<Grid item xs={9}>
                            <TextField fullWidth disabled={isDisabled} size="small" label="PRESBITÉRIO" name="presbitero" value={record?.presbitero || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={3}>
                            <TextField fullWidth disabled={isDisabled} size="small" label="SIGLA" name="presbitero_sigla" value={record?.presbitero_sigla || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} className={classes.sectionTitle}>
					I - Identificação do Ministro																													
				</Grid>

				{/* <Grid item xs={1} className={classes.subSectionTitle}>
					Nome:																												
				</Grid> */}
				<Grid item xs={12}>
					<TextField fullWidth disabled={isDisabled} label="Nome" name="i_nome" value={record?.i_nome || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>

				{/* <Grid item xs={1}>
					Filiação:
				</Grid> */}
				<Grid item xs={6}>
					<TextField fullWidth disabled={isDisabled} label="Filiação" name="i_filiacao" value={record?.i_filiacao || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={1}>
					e:
				</Grid> */}
				<Grid item xs={6}>
					<TextField fullWidth disabled={isDisabled} label="e" name="i_filiacao_e" value={record?.i_filiacao_e || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				{/* <Grid item xs={2}>
					Data de nascimento:
				</Grid> */}
				<Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} placeholder="dd/mm/aaaa" label="Data de nascimento" name="i_data_nascimento" value={record?.i_data_nascimento || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={1}>
					Local:
				</Grid> */}
				<Grid item xs={6}>
					<TextField fullWidth disabled={isDisabled} label="Local" name="i_local_nascimento" value={record?.i_local_nascimento || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={1}>
					UF:																										
				</Grid> */}
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} label="UF" name="i_uf_nascimento" value={record?.i_uf_nascimento || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				<Grid item xs={3}>
					<Grid container>
						{/* <Grid item xs={4}>
							RG:
						</Grid> */}
						<Grid item xs={12}>
							<TextField fullWidth disabled={isDisabled} label="RG" name="i_rg" value={record?.i_rg || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<Grid container>
						{/* <Grid item xs={6}>
							Órgão emissor:
						</Grid> */}
						<Grid item xs={12}>
							<TextField fullWidth disabled={isDisabled} label="Órgão emissor" name="i_org_emissor" value={record?.i_org_emissor || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<Grid container>
						{/* <Grid item xs={4}>
							CPF:
					</Grid> */}
						<Grid item xs={12}>
							<TextField fullWidth disabled={isDisabled} label="CPF" name="i_cpf" value={record?.i_cpf || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<Grid container>
						{/* <Grid item xs={5}>
							Estado Civil:
						</Grid> */}
						<Grid item xs={12}>
							<TextField fullWidth disabled={isDisabled} label="Estado Civil" name="i_estado_civil" value={record?.i_estado_civil || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>


				<Grid item xs={6}>
					<Grid container>
						{/* <Grid item xs={4}>
							Conjuge:
						</Grid> */}
						<Grid item xs={12}>
							<TextField fullWidth disabled={isDisabled} label="Conjuge" name="i_conjugue" value={record?.i_conjugue || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container>
						{/* <Grid item xs={4}>
							Data de Aniversário:
						</Grid> */}
						<Grid item xs={6}>
							<TextField fullWidth disabled={isDisabled} label="Data de Aniversário" name="i_data_aniversario" value={record?.i_data_aniversario || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						{/* <Grid item xs={4}>
							Nº de Dependentes:
						</Grid> */}
						<Grid item xs={6}>
							<TextField fullWidth disabled={isDisabled} label="Nº de Dependentes" name="i_n_dependentes" value={record?.i_n_dependentes || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>

				
				{/* <Grid item xs={2}>
					Nome dos filhos:
				</Grid> */}
				<Grid item xs={12}>
					<TextField fullWidth disabled={isDisabled} label="Nome dos filhos" name="i_nome_filhos" value={record?.i_nome_filhos || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				{/* <Grid item xs={2}>
					Endereço:
				</Grid> */}
				<Grid item xs={7}>
					<TextField fullWidth disabled={isDisabled} label="Endereço" name="i_endereco" value={record?.i_endereco || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={1}>
					Nº:
				</Grid> */}
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} label="Número" name="i_numero" value={record?.i_numero || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={1}>
					Comp.:
				</Grid> */}
				<Grid item xs={3}>
					<TextField fullWidth disabled={isDisabled} label="Complemento" name="i_complemento" value={record?.i_complemento || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				<Grid item xs={6}>
					<Grid container>
						{/* <Grid item xs={2}>
							Bairro:
						</Grid> */}
						<Grid item xs={6}>
							<TextField fullWidth disabled={isDisabled} label="Bairro" name="i_bairro" value={record?.i_bairro || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						{/* <Grid item xs={2}>
							Cidade:
						</Grid> */}
						<Grid item xs={6}>
							<TextField fullWidth disabled={isDisabled} label="Cidade" name="i_cidade" value={record?.i_cidade || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container>
						{/* <Grid item xs={1}>
							UF:
						</Grid> */}
						<Grid item xs={3}>
							<TextField fullWidth disabled={isDisabled} label="UF" name="i_uf" value={record?.i_uf || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						{/* <Grid item xs={1}>
							CEP:
						</Grid> */}
						<Grid item xs={4}>
							<TextField fullWidth disabled={isDisabled} label="CEP" name="i_cep" value={record?.i_cep || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						{/* <Grid item xs={2}>
							Cx. P:
						</Grid> */}
						<Grid item xs={4}>
							<TextField fullWidth disabled={isDisabled} label="Caixa Postal" name="i_cx_postal" value={record?.i_cx_postal || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={1} className={classes.labelPhones}>
					Telefones:
				</Grid>
				<Grid item xs={1}>
					<TextField fullWidth disabled={isDisabled} label="Cód. Área" name="i_tel_cod_area" value={record?.i_tel_cod_area || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={1}>
					Celular:
				</Grid> */}
				<Grid item xs={3}>
					<TextField fullWidth disabled={isDisabled} label="Celular" name="i_tel_celular" value={record?.i_tel_celular || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={1}>
					Residência:
				</Grid> */}
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} label="Residência" name="i_tel_residencia" value={record?.i_tel_residencia || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={1}>
					Igreja:
				</Grid> */}
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} label="Igreja" name="i_tel_igreja" value={record?.i_tel_igreja || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={1}>
					E-mail:
				</Grid> */}
				<Grid item xs={3}>
					<TextField fullWidth disabled={isDisabled} label="E-mail" name="i_email" value={record?.i_email || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				<div className={classes.verticalSpace}></div>
				<Grid item xs={1}>
					<Grid container>
						<Grid item xs={12}>
							Condições de Moradia
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={11}>
					<Grid container>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
							<FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Casa/Apto Pastoral"
                                name="i_moradia_casa_pastoral"
                                checked={record?.i_moradia_casa_pastoral === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
							<FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Aluguel pago pela igreja"
                                name="i_moradia_aluguel_pago_igreja"
                                checked={record?.i_moradia_aluguel_pago_igreja === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
							<FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Aluguel pago pelo ministro"
                                name="i_moradia_aluguel_pago_ministro"
                                checked={record?.i_moradia_aluguel_pago_ministro === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						<Grid item xs={1}>	
						</Grid>
						<Grid item xs={2}>
						</Grid>


						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
							<FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Moradia própria quitada"
                                name="i_moradia_quidata"
                                checked={record?.i_moradia_quidata === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
							<FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Moradia própria financiada"
                                name="i_moradia_propria_financiada"
                                checked={record?.i_moradia_propria_financiada === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
							<FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Nos limites do campo"
                                name="i_moradia_limites_campo"
                                checked={record?.i_moradia_limites_campo === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
							<FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Fora do campo"
                                name="i_moradia_aluguel_fora_campo"
                                checked={record?.i_moradia_aluguel_fora_campo === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
					</Grid>
				</Grid>

				<div className={classes.verticalSpace}></div>


				{/* <Grid item xs={2}>
					Data de Ordenação:
				</Grid> */}
				<Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} label="Data de Ordenação" name="i_data_ordenacacao" value={record?.i_data_ordenacacao || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				{/* <Grid item xs={2}>
					Presbitério
				</Grid> */}
				<Grid item xs={8}>
					<TextField fullWidth disabled={isDisabled} label="Presbitério" name="i_presbiterio" value={record?.i_presbiterio || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				<div className={classes.verticalSpace}></div>
				

				<Grid item xs={4}>
					<Grid container>
						<Grid item xs={6}>
							Dedicação ao ministério:
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Integral"
                                name="i_dedicacao_integral"
                                checked={record?.i_dedicacao_integral === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={2}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Parcial"
                                name="i_dedicacao_parcial"
                                checked={record?.i_dedicacao_parcial === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container>
						<Grid item xs={2}>
							Férias:
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Regulares"
                                name="i_ferias_regulares"
                                checked={record?.i_ferias_regulares === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Parciais"
                                name="i_ferias_parciais"
                                checked={record?.i_ferias_parciais === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={3}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Não teve"
                                name="i_ferias_nao_teve"
                                checked={record?.i_ferias_nao_teve === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={2}>
					<Grid container>
						{/* <Grid item xs={4}>
							Côngruas R$:
						</Grid> */}
						<Grid item xs={12}>
                            <TextField
                                label="R$"
                                value={record?.i_congruas || '0.00'}
                                onChange={onChangeField}
                                onBlur={(e) => {onSetCurrencyField(e)}}
                                name="i_congruas"
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                            />
						</Grid>
					</Grid>
				</Grid>


				<Grid item xs={4}>
					<Grid container>
						<Grid item xs={8}>
							Tem aposentadoria pública?
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={2}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Sim"
                                name="i_aposentadoria_publica_sim"
                                checked={record?.i_aposentadoria_publica_sim === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={2}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Não"
                                name="i_aposentadoria_publica_nao"
                                checked={record?.i_aposentadoria_publica_nao === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={4}>
					<Grid container>
						<Grid item xs={8}>
							Tem aposentadoria privada?
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={2}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Sim"
                                name="i_aposentadoria_privada_sim"
                                checked={record?.i_aposentadoria_privada_sim === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={2}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Não"
                                name="i_aposentadoria_privada_nao"
                                checked={record?.i_aposentadoria_privada_nao === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={4}>
					<Grid container>
						<Grid item xs={8}>
							Plano de saúde?
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={2}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Sim"
                                name="i_plano_saude_sim"
                                checked={record?.i_plano_saude_sim === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
						{/* <Grid item xs={1}>
							<Checkbox disabled={isDisabled} />
						</Grid> */}
						<Grid item xs={2}>
                            <FormControlLabel
								control={<Checkbox disabled={isDisabled}/>}
                                label="Não"
                                name="i_plano_saude_nao"
                                checked={record?.i_plano_saude_nao === 'true' || false} onChange={onChangeCheckField}
							/>
						</Grid>
					</Grid>
				</Grid>

				
				<div className={classes.verticalSpace}></div>


				<Grid item xs={4}>
					Contribui para o INSS sobre o valor das Côngruas?
				</Grid>
				{/* <Grid item xs={1}>
					<Checkbox disabled={isDisabled} />
				</Grid> */}
				<Grid item xs={2}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="i_inss_sim"
                        checked={record?.i_inss_sim === 'true' || false} onChange={onChangeCheckField}
                    />
				</Grid>
				{/* <Grid item xs={1}>
					<Checkbox disabled={isDisabled} />
				</Grid> */}
				<Grid item xs={2}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="i_inss_nao"
                        checked={record?.i_inss_nao === 'true' || false} onChange={onChangeCheckField}
                    />
				</Grid>
				<Grid item xs={3}>
					Valor da Contribuição INSS:
				</Grid>
				<Grid item xs={1}>
                    <TextField
                        label="R$"
                        value={record?.i_valor_inss || '0.00'}
                        onChange={onChangeField}
                        onBlur={(e) => {onSetCurrencyField(e)}}
                        name="i_valor_inss"
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                        }}
                    />
				</Grid>



				<Grid item xs={4}>
					Contribui para Previdência Privada?
				</Grid>
				{/* <Grid item xs={1}>
					<Checkbox disabled={isDisabled} />
				</Grid> */}
				<Grid item xs={2}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="i_previdencia_privada_sim"
                        checked={record?.i_previdencia_privada_sim === 'true' || false} onChange={onChangeCheckField}
                    />
				</Grid>
				{/* <Grid item xs={1}>
					<Checkbox disabled={isDisabled} />
				</Grid> */}
				<Grid item xs={2}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="i_previdencia_privada_nao"
                        checked={record?.i_previdencia_privada_nao === 'true' || false} onChange={onChangeCheckField}
                    />
				</Grid>



				<Grid item xs={12} className={classes.sectionTitle}>
					II - Campo de Trabalho
				</Grid>


				{/* <Grid item xs={1} className={classes.subSectionTitle}>
					Igrejas:																												
				</Grid> */}
				<Grid item xs={12} className={classes.subSectionTitle}>
                    <TextField fullWidth disabled={isDisabled} label="Igrejas" name="ii_igrejas" value={record?.ii_igrejas || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>

                <Grid container className={classes.pageBreak}>
                    <Grid item xs={12} className={classes.sectionTitle}>
                        III - Atuação Ministerial
                    </Grid>


                    <Grid item xs={4} className={classes.subSectionTitle}>
                        1. DOUTRINAÇÃO:
                    </Grid>
                    <Grid item xs={2} className={classes.subSectionTitle}>
                        No Campo e fora do Campo
                    </Grid>	
                    <Grid item xs={4} className={classes.subSectionTitle}>
                    </Grid>
                    <Grid item xs={2} className={classes.subSectionTitle}>
                        No Campo e fora do Campo
                    </Grid>	


                    <Grid item xs={4}>
                        Pregações:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_1_pregacao" value={record?.iii_1_pregacao || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	
                    <Grid item xs={4}>
                        Palestras, preleções especiais:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_1_palestra_prelecoes" value={record?.iii_1_palestra_prelecoes || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	


                    <Grid item xs={4}>
                        Aulas de Escola Dominical:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_1_aulas_escola_dominical" value={record?.iii_1_aulas_escola_dominical || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	
                    <Grid item xs={4}>
                        Mensagens - Rádio/TV:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_1_mensagem_radio" value={record?.iii_1_mensagem_radio || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>


                    <Grid item xs={4}>
                        Trabalhos de Evangelização:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_1_tabalhos_evangelizacao" value={record?.iii_1_tabalhos_evangelizacao || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	
                    <Grid item xs={4}>
                        Artigos-jornais, boletins, revistas:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_1_artigos_jornais" value={record?.iii_1_artigos_jornais || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>


                    <Grid item xs={4}>
                        Estudos Bíblicos:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number"  name="iii_1_estudo_biblico" value={record?.iii_1_estudo_biblico || ''} onChange={onChangeField} onBlur={onSetField}/>
                    </Grid>	
                    <Grid item xs={4}>
                        Entrevistas:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_1_entrevista" value={record?.iii_1_entrevista || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>


                    <Grid item xs={12} className={classes.marginPrint100} ></Grid>
                    <Grid item xs={4} className={classes.subSectionTitle}>
                        2. ATOS PASTORAIS:
                    </Grid>
                    <Grid item xs={2} className={classes.subSectionTitle}>
                        No Campo e fora do Campo
                    </Grid>	
                    <Grid item xs={4} className={classes.subSectionTitle}>
                    </Grid>
                    <Grid item xs={2} className={classes.subSectionTitle}>
                        No Campo e fora do Campo
                    </Grid>	


                    <Grid item xs={4}>
                        Santas Ceias:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_2_santa_ceia" value={record?.iii_2_santa_ceia || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	
                    <Grid item xs={4}>
                        Batismos Infantis:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_2_batismo" value={record?.iii_2_batismo || ''} onChange={onChangeField} onBlur={onSetField}/>
                    </Grid>	


                    <Grid item xs={4}>
                        Bênçãos Nupciais (com ou sem efeito civil):
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_2_bencaos_nupiciais" value={record?.iii_2_bencaos_nupiciais || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	
                    <Grid item xs={4}>
                        Profissões de Fé:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number"name="iii_2_profissaoes_fe" value={record?.iii_2_profissaoes_fe || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	


                    <Grid item xs={4}>
                        Funerais:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number"name="iii_2_funerais" value={record?.iii_2_funerais || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	
                    <Grid item xs={4}>
                        Profissões de Fé & Batismos:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number"name="iii_2_profissao_fe_batismo" value={record?.iii_2_profissao_fe_batismo || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	

                    <Grid item xs={4} className={classes.subSectionTitle}>
                        3. ASSISTÊNCIA PASTORAL
                    </Grid>
                    <Grid item xs={2} className={classes.subSectionTitle}>
                        No Campo e fora do Campo
                    </Grid>	
                    <Grid item xs={4} className={classes.subSectionTitle}>
                    </Grid>
                    <Grid item xs={2} className={classes.subSectionTitle}>
                        
                    </Grid>	


                    <Grid item xs={4}>
                        Aconselhamentos/Orientações:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_3_aconselhamentos" value={record?.iii_3_aconselhamentos || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>


                    <Grid item xs={4}>
                        Evangélicos ou não:<br/>
                        (considerar as visitas feitas nos lares como uma visita.)
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_3_evangelicos_ou_nao" value={record?.iii_3_evangelicos_ou_nao || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>


                    <Grid item xs={4}>
                        Pontos de Pregação, Congregações ou Campos Missionários:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number" name="iii_3_ponto_pregacao" value={record?.iii_3_ponto_pregacao || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>	
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>


                    <Grid item xs={4}>
                        Departamentos Internos:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField fullWidth disabled={isDisabled} type="number"  name="iii_3_departamento_interno" value={record?.iii_3_departamento_interno || ''} onChange={onChangeField} onBlur={onSetField}/>
                    </Grid>	
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>


                    <Grid item xs={12} className={classes.subSectionTitle}>
                        4. MINISTÉRIO DESIGNADO NOS TERMOS DO ARTIGO 37 DA CI/IPB
                    </Grid>
                    <Grid item xs={12}>
                        Descrição das atividades:
                    </Grid>
                    <Grid item xs={12}>
                        <TextareaAutosize disabled={isDisabled} rowsMin={8}  style={{width: '100%'}}  name="iv_descricao_atividades" value={record?.iv_descricao_atividades || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                </Grid>


				<Grid item xs={12} className={classes.sectionTitle}>
					IPB - Relatório Ministerial - 2
				</Grid>


				<Grid item xs={12} className={classes.sectionTitle}>
					IV - Atuação Conciliar
				</Grid>


				<Grid item xs={4} className={classes.subSectionTitle}>
					1. CONCÍLIOS DA IPB
				</Grid>
				<Grid item xs={2} className={classes.subSectionTitle}>
					No Campo e fora do Campo
				</Grid>	
				<Grid item xs={4} className={classes.subSectionTitle}>
				</Grid>
				<Grid item xs={2} className={classes.subSectionTitle}>
					No Campo e fora do Campo
				</Grid>	


				<Grid item xs={4}>
					Reuniões do Conselho:
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} type="number" name="iv_reunioes_conselho" value={record?.iv_reunioes_conselho || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>	
				<Grid item xs={4}>
					Diáconos Ordenados e/ou Investidos:
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} type="number" name="iv_diaconos_ordenados" value={record?.iv_diaconos_ordenados || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>	


				<Grid item xs={4}>
					Assembleias Gerais da Igreja:
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} type="number" name="iv_assembleias_gerais" value={record?.iv_assembleias_gerais || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>	
				<Grid item xs={4}>
					Presbíteros Ordenados e/ou Investidos:
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} type="number" name="iv_presbiteros_ordenados" value={record?.iv_presbiteros_ordenados || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>	



				<Grid item xs={4}>
					<Grid container>
						<Grid item xs={5}>
							Reuniões do Presbitério:
						</Grid>
						<Grid item xs={2}>
							<TextField fullWidth disabled={isDisabled} type="number" name="iv_reunioes_presbiterio" value={record?.iv_reunioes_presbiterio || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={5}>
							Reuniões do Sínodo:
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} type="number"  name="iv_reunioes_sinodo" value={record?.iv_reunioes_sinodo || ''} onChange={onChangeField} onBlur={onSetField}/>
				</Grid>	
				<Grid item xs={4}>
					Reuniões do Supremo Concílio da IPB:
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} type="number" name="iv_reunioes_supremo_concilio" value={record?.iv_reunioes_supremo_concilio || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>	



				<Grid item xs={12}>
					Comentários:
				</Grid>
				<Grid item xs={12}>
					<TextareaAutosize disabled={isDisabled} rowsMin={5}  style={{width: '100%'}}  name="iv_comentarios" value={record?.iv_comentarios || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>



				<Grid item xs={12} className={classes.subSectionTitle}>
					2. CARGOS EM COMISSÕES EXECUTIVAS DOS CONCÍLIOS, SECRETARIAS DE CAUSAS, JUNTAS, COMISSÕES E AUTARQUIAS*
				</Grid>


				{/* <Grid item xs={4}>
					No Presbitério:
				</Grid> */}
				<Grid item xs={12}>
					<TextField fullWidth disabled={isDisabled} label="No Presbitério" name="iv_2_presbiterio" value={record?.iv_2_presbiterio || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				{/* <Grid item xs={4}>
					No Sínodo:
				</Grid> */}
				<Grid item xs={12}>
					<TextField fullWidth disabled={isDisabled} label="No Sínodo" name="iv_2_sinodo" value={record?.iv_2_sinodo || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				{/* <Grid item xs={4}>
					No Supremo Concílio:
				</Grid> */}
				<Grid item xs={12}>
					<TextField fullWidth disabled={isDisabled} label="No Supremo Concílio" name="iv_2_supremo_concilio" value={record?.iv_2_supremo_concilio || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				{/* <Grid item xs={4}>
					Em Juntas, Comissões e Autarquias da IPB:
				</Grid> */}
				<Grid item xs={12}>
					<TextField fullWidth disabled={isDisabled} label="Em Juntas, Comissões e Autarquias da IPB" name="iv_2_juntas" value={record?.iv_2_juntas || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				<Grid item xs={12}>
					Texto Complementar:
				</Grid>
				<Grid item xs={12}>
					<TextareaAutosize disabled={isDisabled} rowsMin={5}  style={{width: '100%'}} name="iv_2_texto_complementar" value={record?.iv_2_texto_complementar || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				<Grid item xs={12}>
					<Typography variant="caption">
						(*) Mencionar as funções exercidas nos diversos níveis conciliares da IPB e o número de reuniões durante o ano.
					</Typography>
				</Grid>



				<Grid item xs={12} className={classes.sectionTitle}>
					V. Outras Atividades*
				</Grid>


				<Grid item xs={12}>
					1. Atualização e Aperfeiçoamento:
				</Grid>
				<Grid item xs={12}>
					<TextareaAutosize disabled={isDisabled} rowsMin={5}  style={{width: '100%'}} name="v_1" value={record?.v_1 || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>



				<Grid item xs={12}>
					2. Atividades em entidades para-eclesiásticas:
				</Grid>
				<Grid item xs={12}>
					<TextareaAutosize disabled={isDisabled} rowsMin={5}  style={{width: '100%'}} name="v_2" value={record?.v_2 || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


                <Grid item xs={12} className={classes.marginPrint100} ></Grid>
				<Grid item xs={12}>
					3. Atividades Extra Ministeriais:
				</Grid>
				<Grid item xs={12}>
					<TextareaAutosize disabled={isDisabled} rowsMin={5}  style={{width: '100%'}} name="v_3" value={record?.v_3 || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


				<Grid item xs={12}>
					Outras:
				</Grid>
				<Grid item xs={12}>
					<TextareaAutosize disabled={isDisabled} rowsMin={5}  style={{width: '100%'}} name="v_outras" value={record?.v_outras || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				<Grid item xs={12}>
					<Typography variant="caption">
					(*) Mencionar, estatisticamente, realizações no ano não previstas nos campos precedentes, dadas ou recebidas (cursos, leituras, encontros de estudos, congressos, etc.), bem como outras atividades paralelas ao ministério pastoral (ex.: advocacia, jornalismo, magistério, etc.).
					</Typography>
				</Grid>



			</Grid>

			
		</Container>
    );
}

export default ReportMinistro;