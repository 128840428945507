import { gql, useMutation } from '@apollo/client';
import { Avatar, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { 
    Folder as FolderIcon,
    Description as DescriptionIcon,
    ViewModule as ViewModuleIcon,
    HowToVote as HowToVoteIcon,
    Drafts as DraftsIcon,
    FindInPage as FindInPageIcon,
    Gavel,
    AssignmentInd,
    AttachMoney,
    KeyboardBackspace,
    Settings,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import MyBackdrop from 'src/components/Backdrop';
import Eleicao from '../../components/eleicao';
import HtmlView from '../../components/htmlView';
import useStyles from './styles';

// const MUTATION_COMPILE_ATA = gql`
//   mutation CompileAta($step: String!) {
//     compileAta(step: $step) {
//       id
//       name
//       ata
//       ataStatus
//       footer
//     }
//   }
// `;

const MUTATION_COMPILE_ATA = gql`
  mutation CompileAtaByType($meeting: String!, $type: String!) {
    compileAtaByType(meeting: $meeting, type: $type) {
      id
      name
      ata
      ataCompiled
      ataStatus
    }
  }
`;

function Atas({...props}) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentStep, setCurrentStep] = useState<any>();
    const [showAta, setShowAta] = useState(false);
    const [ compileAta, { data: dataCompileAta, loading: loadingCompileAta, error: errorCompileAta }] = useMutation(MUTATION_COMPILE_ATA, {
        onCompleted: () => {
          setShowAta(true)
        }
    });

    const onCompileAta = (step: any) => {
        setCurrentStep(step);
        compileAta({
          variables: { type: step.code, meeting: props.meeting.id }
        })
      }

    const buildEditor = (value: string, footer?: string, header?: string) => {
        return <HtmlView value={value} footer={footer} header={header} disabled />
    }

    return (
        <Container maxWidth="xl">
            {
                (showAta)
                ? (
                    <Dialog
                    open={showAta}
                    onClose={() => setShowAta(false)}
                    maxWidth="lg"
                    fullWidth
                    className={matchesSm ? classes.htmlViewMobile :classes.htmlView}
                    >
                    <DialogTitle id="alert-dialog-slide-title">
                        Ata - { currentStep?.name || ''}
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText>
                        {buildEditor(dataCompileAta?.compileAtaByType?.ataCompiled || dataCompileAta?.compileAtaByType?.ata, dataCompileAta?.compileAtaByType?.footer )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => setShowAta(false)} color="primary">
                        Fechar
                    </Button>
                    </DialogActions>
                    </Dialog>
                ) : (null)
            }
            {loadingCompileAta && <MyBackdrop open message="Carregando a ATA" />}
            {
                !props.clean && <Link to="/app/ordinaria" className={classes.link}>
                    <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                    <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Você está em ATAS</Button>
                </Link>
            }
            <Grid container spacing={3}>
                <Grid item xs={matchesSm ? 12 : 3}>
                    <Paper className={classes.paper} onClick={() => onCompileAta(props.steps[0])}>
                        <Avatar className={classes.avatarSmall}>
                            <DescriptionIcon />
                        </Avatar>
                        <Typography>
                            Verificação de Poderes
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={matchesSm ? 12 : 3}>
                    <Paper className={classes.paper} onClick={() => onCompileAta(props.steps[1])}>
                        <Avatar className={classes.avatarSmall}>
                            <DescriptionIcon />
                        </Avatar>
                        <Typography>
                            Sessão Preparatória
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={matchesSm ? 12 : 3}>
                    <Paper className={classes.paper} onClick={() => onCompileAta(props.steps[2])}>
                        <Avatar className={classes.avatarSmall}>
                            <DescriptionIcon />
                        </Avatar>
                        <Typography>
                            1ª Sessão <br/> Regular
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={matchesSm ? 12 : 3}>
                    <Paper className={classes.paper} onClick={() => onCompileAta(props.steps[3])}>
                        <Avatar className={classes.avatarSmall}>
                            <DescriptionIcon />
                        </Avatar>
                        <Typography>
                            2ª Sessão <br/> Regular
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Atas;