import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import DraftsIcon from '@material-ui/icons/Drafts';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import CheckIcon from '@material-ui/icons/Check';
import PlayListCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DescriptionIcon from '@material-ui/icons/Description';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import FolderIcon from '@material-ui/icons/Folder';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { StepIconProps } from '@material-ui/core/StepIcon';
import Chamada from './components/chamada';
import { useDispatch } from 'react-redux';
import { nextStep } from '../../store/ducks/Steps/actions';
import {
  QontoConnector,
  useQontoStepIconStyles,
  ColorlibConnector,
  useColorlibStepIconStyles,
  useStyles,
} from './step-styles';
import OracaoSaudacao from './components/oracao-saudacao';
import ComposicaoMesa from './components/composicao-mesa';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Grid } from '@material-ui/core';
import { NavigateBefore, NavigateNext, OpenInBrowser, FindInPage } from '@material-ui/icons';
import Encerramento from './components/encerramento';
import { gql, useMutation, useQuery } from '@apollo/client';
import GenericFields from './components/generic-fields';
import HtmlView from './components/htmlView';
import MyBackdrop from '../../components/Backdrop';
import { TransitionProps } from '@material-ui/core/transitions';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const QUERY_SUB_STEPS = gql`
  query SubStep($step: String!) {
    listSubStep(step: $step) {
      id
      name
      order
    }
  }
`;

const MUTATION_SET_SUB_STEP = gql`
  mutation SetCurrentSubStep($stepId: String!, $subStepId: String!) {
    setCurrentSubStep(stepId: $stepId, subStepId: $subStepId) {
      id
      name
      currentSubStep
    }
  }
`;

const MUTATION_COMPILE_ATA = gql`
  mutation CompileAta($step: String!) {
    compileAta(step: $step) {
      id
      name
      ata
      ataCompiled
      ataStatus
      footer
    }
  }
`;


function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupIcon />,
    3: <DraftsIcon />,
    4: <HowToRegIcon />,
    5: <PlayListCheckIcon />,
    6: <CheckIcon />,
    7: <ViewModuleIcon />,
    8: <FolderIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function getStepContent(step: number, classes: any, subSteps?: any, meeting?: any) {

    switch (step) {
    case 0:
        return <GenericFields subStepId={subSteps[0].id} />;
    case 1:
      return <GenericFields subStepId={subSteps[1].id} />;
    case 2:
      return (<HtmlView disabled value={meeting?.convocation} />);
    case 3:
      return (<Chamada subStep={subSteps[3]} title="Marque as pessoas que estão presente." />);
    case 4:
      return (<Chamada subStep={subSteps[4]} type="churches" title="Marque as igrejas com representantes." />);
    case 5:
      return <GenericFields subStepId={subSteps[5].id} />;
    default:
      return 'Etapa desconhecida';
  }
}

export default function StepVerificacaoPoderes({...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(props.currentSubStep || 0);
  const [ setCurrentSubStep, { data: dataSetCurrentSubStep, loading: loadingSetCurrentSubStep, error: errorSetCurrentSubStep }] = useMutation(MUTATION_SET_SUB_STEP);
  const [showAta, setShowAta] = useState(false);
  const [ compileAta, { data: dataCompileAta, loading: loadingCompileAta, error: errorCompileAta }] = useMutation(MUTATION_COMPILE_ATA, {
    onCompleted: () => {
      setShowAta(true)
    }
  });
  let steps = [];
  const { loading, error, data } = useQuery(QUERY_SUB_STEPS, {
    variables: { step: props.stepId },
  });
  
  const handleNext = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
  };

  const goTo = (idx: number) => {
    setActiveStep(idx);
    setCurrentSubStep({
      variables: {stepId: props.stepId, subStepId: data.listSubStep[idx]?.id}
    });
  };

  const handleReset = () => {
    dispatch(nextStep(1));
  };

  // useEffect(() => {
  //   if((activeStep || activeStep == 0 ) && data && data.listSubStep && data.listSubStep.length && data.listSubStep[activeStep]) {
  //     setCurrentSubStep({
  //       variables: {stepId: props.stepId, subStepId: data.listSubStep[activeStep].id}
  //     });
  //   }
  // }, [activeStep, data, props.stepId, setCurrentSubStep]);

  useEffect(() => {
    if(data && data.listSubStep && data.listSubStep?.length && props.currentSubStep) {
      data.listSubStep.map((item: any, idx: number) => {
        if(item.id == props.currentSubStep) {
          setActiveStep(idx);
        }
      });
    }
  }, [data, props.currentSubStep]);

  const onCompileAta = () => {
    compileAta({
      variables: { step: props.stepId }
    })
  }

  const buildEditor = (value: string, footer?: string, header?: string) => {
    return <HtmlView value={value} footer={footer} header={header} disabled />
  }

  if(loading) {
    return <div>carregando...</div>
  }

  if(error) {
    return <div>Erro!</div>
  }

  if(data) {
    steps = data.listSubStep.map((item: any) => item.name)
  }

  return (
    <div className={classes.root}>
      {
        (loadingCompileAta) ? <MyBackdrop message="Carregando a ATA" open={true} /> : (null)
      }
      <Stepper className={classes.header} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label: any, idx: any) => (
                <Step className={classes.cursor}  onClick={() => goTo(idx)} key={label}>
                    <StepLabel className={classes.cursor}  onClick={() => goTo(idx)} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
            ))}
      </Stepper>
      <div className={classes.body}>
        {activeStep === steps.length ? (
          <div className={classes.content}>
            <Typography className={classes.instructions}>
              A etapa de Verificação de poderes foi finalizada
            </Typography>
            <Button onClick={handleReset} color="primary" variant="contained" className={classes.button}>
                Avançar para a Sessão Preparatória
            </Button>
          </div>
        ) : (
            <div className={classes.body}>
                <div className={classes.content}>
                    {getStepContent(activeStep, classes, data.listSubStep, props.meeting)}
                </div>
                <div>
                    <div className={classes.footer}>
                      <Grid container>
                          <Grid item xs={3} style={{textAlign: 'left'}}>
                          <a style={{textDecoration: 'none'}} href="https://storage.googleapis.com/concilio-app.appspot.com/documentos/Manual-Presbiteriano.pdf" target="_blank" rel="noopener noreferrer">
                              <Button startIcon={<OpenInBrowser/>}>
                                Abrir constituição
                              </Button>
                            </a>
                          </Grid>
                          <Grid item xs={6}>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button} startIcon={<NavigateBefore/>}>
                                Anterior
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                                endIcon={<NavigateNext/>}
                            >
                                {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                            </Button>
                          </Grid>
                          <Grid item xs={3} style={{textAlign: 'right'}}>
                            <Button endIcon={<FindInPage />} onClick={() => onCompileAta()}>
                              Visualizar a ATA
                            </Button>
                          </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )}
      </div>
      {
        (showAta)
          ? (
            <Dialog
              open={showAta}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setShowAta(false)}
              maxWidth="lg"
              fullWidth
              className={classes.htmlView}
            >
              <DialogTitle id="alert-dialog-slide-title">
                Ata de Verificação de Poderes
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText>
                  {buildEditor(dataCompileAta?.compileAta?.ataCompiled || dataCompileAta?.compileAta?.ata, dataCompileAta?.compileAta?.footer )}
                </DialogContentText>
              </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowAta(false)} color="primary">
                Fechar
              </Button>
            </DialogActions>
            </Dialog>
          ) : (null)
      }
    </div>
  );
}