import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& h1': {
            flex: 1,
            fontWeight: 900,
            marginLeft: 10,
            letterSpacing: 2,
            fontSize: 22
        }
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    main: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        // marginTop: 65,
        display: 'flex',
        flexDirection: 'column'
    },
    contentBg: {
        display: 'flex',
        flex: 1,
        backgroundImage: `url('${require('../../assets/bg_home.jpg')}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom'
    },
    contentBgMobile: {
        display: 'flex',
        flex: 1,
        backgroundImage: `url('${require('../../assets/bg_home_mobile.jpg')}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '-138px bottom',
        backgroundAttachment: 'fixed',
    },
    content: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255,255,255,0.2)',
    },
    contentClean: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255,255,255,0.2)',
    },
    contentCleanLogo: {
        display: 'flex',
        flex: 1,
        alignItems: 'start',
        justifyContent: 'center',
        background: 'rgba(255,255,255,0.05)',
    },
    contentCleanMobile: {
        display: 'flex',
        flex: 1,
        alignItems: 'start',
        justifyContent: 'center',
        background: 'rgba(255,255,255,0.2)',
        flexDirection: 'column',
        paddingTop: 40,
        paddingBottom: 20,
    },
    breadcrumbs: {
        padding: '5px 15px',
        backgroundColor: '#eee',
        '& li *': {
            fontSize: 12,
        },
        '& > * + *': {
            marginTop: theme.spacing(2),
        }
    },
    textWelcome: {
        fontSize: 24,
        fontWeight: 'bolder',
        paddingLeft: 70,
        opacity: 0.6,
        marginBottom: 50
    },
    itemPaper: {
        minHeight: 100,
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    itemIcon: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        color: 'white'
    },
    itemIconGreen: {
        backgroundColor: 'green'
    },
    itemIconRed: {
        backgroundColor: 'red'
    },
    itemIconBlue: {
        backgroundColor: 'blue'
    },
    itemIconOrange: {
        backgroundColor: 'orange'
    },
    itemTitle: {
        flexGrow: 1,
        textAlign: 'center',
        fontSize: 15,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    }
}));

export default useStyles;