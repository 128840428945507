import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Alert from '@material-ui/lab/Alert';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import GroupIcon from '@material-ui/icons/Group';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import DescriptionIcon from '@material-ui/icons/Description';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import FolderIcon from '@material-ui/icons/Folder';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { useDispatch } from 'react-redux';
import { nextStep } from '../../store/ducks/Steps/actions';
import {
  ColorlibConnector,
  useColorlibStepIconStyles,
  useStyles,
} from './step-styles';
import Eleicao from './components/eleicao';
import TextEditor from '../../components/TextEditor';
import { gql, useMutation, useQuery } from '@apollo/client';
import GenericFields from './components/generic-fields';
import MyBackdrop from '../../components/Backdrop';
import { TransitionProps } from '@material-ui/core/transitions';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Slide } from '@material-ui/core';
import HtmlView from './components/htmlView';
import { NavigateBefore, NavigateNext, OpenInBrowser, FindInPage } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});


const QUERY_SUB_STEPS = gql`
  query SubStep($step: String!) {
    listSubStep(step: $step) {
      id
      name
      order
    }
  }
`;

const MUTATION_SET_SUB_STEP = gql`
  mutation SetCurrentSubStep($stepId: String!, $subStepId: String!) {
    setCurrentSubStep(stepId: $stepId, subStepId: $subStepId) {
      id
      name
      currentSubStep
    }
  }
`;

const MUTATION_COMPILE_ATA = gql`
  mutation CompileAta($step: String!) {
    compileAta(step: $step) {
      id
      name
      ata
      ataStatus
      ataCompiled
      footer
    }
  }
`;

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupIcon />,
    // 3: <HowToRegIcon />,
    // 4: <PlayListCheckIcon />,
    3: <DescriptionIcon />,
    4: <HowToVoteIcon />,
    5: <ViewModuleIcon />,
    6: <FolderIcon />,
    7: <CheckIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}


function getStepContent(step: number, classes: any, subSteps?: any) {
    switch (step) {
    case 0:
        return <>
          {/* //TODO */}
          <Alert severity="success">Houve quorum para o funcionamento legal do Presbitério</Alert>
          <Typography variant="caption">
            Art.86 - Três ministros e dois presbíteros constituirão o quorum para o funcionamento legal do Presbitério.
          </Typography>
          <GenericFields subStepId={subSteps[0].id} />
        </>;
    case 1:
      return <GenericFields subStepId={subSteps[1].id} />;
    // case 2:
    //   return (<Chamada subStep={subSteps[2]} title="Marque as pessoas que estão presente." />);
    // case 3:
    //   return (<Chamada subStep={subSteps[3]} type="churches" title="Marque as igrejas com representantes." />);
    case 2:
      return <GenericFields subStepId={subSteps[step].id} />;
    case 3:
      return <Eleicao subStepId={subSteps[step].id} />;
    case 4:
      return <GenericFields subStepId={subSteps[step].id} />;
    case 5: 
        return <TextEditor
            initialValue = {`
              <h1 style="text-align: center;"><img style="float: left;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR9EBAdk6S5OSo5yb9kZn2y-AHVsdDPJCKWCpWS4gSeEIIusMuu&amp;usqp=CAU" alt="" width="153" height="105" /><span style="font-size: 14pt;">PRESBIT&Eacute;RIO DE IMBITUVA - PRIM</span></h1>
              <p style="text-align: center;"><span style="font-size: 12pt;">SEDE: IMBITUVA - S&Iacute;NODO VALE DO TIBAGE (SVT)</span></p>
              <pre style="text-align: center; padding-left: 120px;"><span style="font-size: 10pt; font-family: arial, helvetica, sans-serif;"><strong><br /><span style="font-size: 8pt;">PRESIDENTE: </span></strong><span style="font-size: 8pt;">Informa&ccedil;&otilde;es sobre</span></span><br /><span style="font-size: 8pt; font-family: arial, helvetica, sans-serif;">Outras informa&ccedil;&otilde;es, endere&ccedil;o, telefone, etc...</span><br /><br /><span style="font-size: 8pt; font-family: arial, helvetica, sans-serif;"><strong>SECRET&Aacute;RIO EXECUTIVO: </strong>Informa&ccedil;&otilde;es sobre</span><br /><span style="font-size: 8pt; font-family: arial, helvetica, sans-serif;">Outras informa&ccedil;&otilde;es, endere&ccedil;o, telefone, etc...</span></pre>
              <p class="p1" style="text-align: center;">&nbsp;</p>
              <p class="p1"><span style="font-size: 10pt;"><strong>Sexta 11 de dezembro de 2020.</strong></span></p>
              <p class="p1"><span style="font-size: 10pt;">19H00M Verifica&ccedil;&atilde;o de Poderes;</span></p>
              <p class="p1"><span style="font-size: 10pt;">19H30M &ndash; Sess&atilde;o Preparat&oacute;ria</span></p>
              <p class="p1"><span style="font-size: 10pt;">21H30M &ndash;<span class="Apple-converted-space">&nbsp; </span>1&ordf; Sess&atilde;o Regular</span></p>
              <p class="p1"><span style="font-size: 10pt;">22H00M &ndash; Trabalho das comiss&otilde;es</span></p>
              <p class="p1"><span style="font-size: 10pt;">23H30M &ndash; Plen&aacute;rio</span></p>
              <p class="p1"><span style="font-size: 10pt;">23H59M &ndash; Descanso</span></p>
              <p class="p1"><span style="font-size: 10pt;"><strong>S&aacute;bado 12 de dezembro de 2020.</strong></span></p>
              <p class="p1"><span style="font-size: 10pt;">08H00M &ndash; Exerc&iacute;cio Devocional;</span></p>
              <p class="p1"><span style="font-size: 10pt;">09H00M &ndash; 2&ordf; Sess&atilde;o Regular;</span></p>
              <p class="p1"><span style="font-size: 10pt;">09H30M &ndash; Trabalho das comiss&otilde;es;</span></p>
              <p class="p1"><span style="font-size: 10pt;">11H30M &ndash; Plen&aacute;rio</span></p>
              <p class="p1"><span style="font-size: 10pt;">12H05M &ndash; Encerramento</span></p>
              <p class="p1">&nbsp;</p>
              <p class="p1"><span style="font-size: 10pt;">Sala das Sess&otilde;es 11 de Dezembro de 2020.</span></p>
              <p class="p2">&nbsp;</p>
              <p class="p1"><span style="font-size: 10pt;"><strong>Relator:</strong>&nbsp;</span></p>
              <p class="p2">&nbsp;</p>
              <p class="p1"><span style="font-size: 10pt;"><strong>Vogal:</strong>&nbsp;</span></p>
            `}
            onChange={(e: any) => { /** TODO: SALVAR HORÁRIO REGIMENTAL */ }}
        />
    case 6:
      return <GenericFields subStepId={subSteps[step].id} />;
    default:
      return 'Etapa desconhecida';
  }
}

export default function StepSessaoPreparatoria({...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(props.currentSubStep || 0);
  const [showAta, setShowAta] = useState(false);
  const [showConfigAta, setShowConfigAta] = useState(false);
  const [ compileAta, { data: dataCompileAta, loading: loadingCompileAta, error: errorCompileAta }] = useMutation(MUTATION_COMPILE_ATA, {
    onCompleted: () => {
      setShowAta(true)
    }
  });
  const [ setCurrentSubStep, { data: dataSetCurrentSubStep, loading: loadingSetCurrentSubStep, error: errorSetCurrentSubStep }] = useMutation(MUTATION_SET_SUB_STEP);
  let steps: any[] = [];
  const { loading, error, data } = useQuery(QUERY_SUB_STEPS, {
    variables: { step: props.stepId },
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
  };

  const handleReset = () => {
    dispatch(nextStep(2));
  };

  const goTo = (idx: number) => {
    setActiveStep(idx);
    setCurrentSubStep({
      variables: {stepId: props.stepId, subStepId: data.listSubStep[idx]?.id}
    });
  };

  // useEffect(() => {
  //   if((activeStep || activeStep === 0 ) && data && data.listSubStep && data.listSubStep.length && data.listSubStep[activeStep]) {
  //     setCurrentSubStep({
  //       variables: {stepId: props.stepId, subStepId: data.listSubStep[activeStep].id}
  //     });
  //   }
  // }, [activeStep, data, props.stepId, setCurrentSubStep]);

  useEffect(() => {
    if(data && data.listSubStep && data.listSubStep?.length && props.currentSubStep) {
      data.listSubStep.map((item: any, idx: number) => {
        if(item.id == props.currentSubStep) {
          setActiveStep(idx);
        }
      });
    }
  }, [data, props.currentSubStep]);


  const onCompileAta = () => {
    compileAta({
      variables: { step: props.stepId }
    })
  }

  const onConfigAta = () => {
    setShowConfigAta(true);
  }

  const buildEditor = (value: string, footer?: string, header?: string, disabled = true) => {
    return <HtmlView value={value} footer={footer} header={header} disabled={disabled} />
  }

  if(loading) {
    return <div>carregando...</div>
  }

  if(error) {
    return <div>Erro!</div>
  }

  if(data) {
    steps = data.listSubStep.map((item: any) => item.name)
  }

  return (
    <div className={classes.root}>
      {
        (loadingCompileAta) ? <MyBackdrop message="Carregando a ATA" open={true} /> : (null)
      }
      {
        (loadingSetCurrentSubStep) ? (<MyBackdrop open />) : (null)
      }
      <Stepper className={classes.header} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label: any, idx: any) => (
                <Step key={label} onClick={() => goTo(idx)}>
                    <StepLabel onClick={() => goTo(idx)} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
            ))}
      </Stepper>
      <div className={classes.body}>
        {activeStep === steps.length ? (
          <div className={classes.content}>
            <Typography className={classes.instructions}>
              A etapa da Sessão Preparatória foi finalizada.
            </Typography>
            <Button onClick={handleReset} color="primary" variant="contained" className={classes.button}>
                Avançar para 1ª Sessão Regular
            </Button>
          </div>
        ) : (
            <div className={classes.body}>
                <div className={classes.content1}>
                  {getStepContent(activeStep, classes, data.listSubStep)}
                </div>
                <div>
                    <div className={classes.footer}>
                      <Grid container>
                        <Grid item xs={3} style={{textAlign: 'left'}}>
                          <a style={{textDecoration: 'none'}} href="https://storage.googleapis.com/concilio-app.appspot.com/documentos/Manual-Presbiteriano.pdf" target="_blank" rel="noopener noreferrer">
                            <Button startIcon={<OpenInBrowser/>}>
                              Abrir constituição
                            </Button>
                          </a>
                        </Grid>
                        <Grid item xs={6}>
                          <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button} startIcon={<NavigateBefore/>}>
                              Anterior
                          </Button>
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              className={classes.button}
                              endIcon={<NavigateNext/>}
                          >
                              {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                          </Button>
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                          <Button endIcon={<FindInPage />} onClick={() => onCompileAta()}>
                            Visualizar a ATA
                          </Button>
                          <IconButton size="small" onClick={() => onConfigAta()} title="Configurar Ata"><SettingsIcon /></IconButton>
                        </Grid>
                      </Grid>
                    </div>
                </div>
            </div>
        )}
      </div>
      {
        (showAta)
          ? (
            <Dialog
              open={showAta}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setShowAta(false)}
              maxWidth="lg"
              fullWidth
              className={classes.htmlView}
            >
              <DialogTitle id="alert-dialog-slide-title">
                Ata da Sessão Preparatória
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText>
                  {buildEditor(dataCompileAta?.compileAta?.ataCompiled || dataCompileAta?.compileAta?.ata, dataCompileAta?.compileAta?.footer, )}
                </DialogContentText>
              </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowAta(false)} color="primary">
                Fechar
              </Button>
            </DialogActions>
            </Dialog>
          ) : (null)
      }
      {
        (showConfigAta)
          ? (
            <Dialog
              open={showConfigAta}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setShowConfigAta(false)}
              maxWidth="lg"
              fullWidth
              className={classes.htmlView}
            >
              <DialogTitle id="alert-dialog-slide-title">
                Configuração da Ata da Sessão Preparatória <br/>
                <p style={{fontSize: 12}}>
                  { props.step.ataFields.split(';').map((field: any, idx: any) => {
                    return <span key={idx}> [{field}] </span>
                  }) }
                </p>
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText>
                  {buildEditor(props.step.ata, '', '', false)}
                </DialogContentText>
              </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowConfigAta(false)} color="primary">
                Fechar
              </Button>
              <Button endIcon={<SaveIcon/>} onClick={() => setShowConfigAta(false)} color="primary">
                Salvar
              </Button>
            </DialogActions>
            </Dialog>
          ) : (null)
      }
    </div>
  );
}