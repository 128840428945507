import React from 'react';
import {
    Backdrop,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import styles from './styles';

function MyBackdrop({...props}) {
    const classes = styles();
    return (
        <Backdrop className={classes.backdrop} open={!!props.open}>
            <CircularProgress color="inherit" />
            <Typography>
                {props.message || ''}
            </Typography>
        </Backdrop>
    )
}

export default MyBackdrop;