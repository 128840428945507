import { gql } from '@apollo/client';

export const QUERY_USERES = gql`
	query Users {
		getUsers(all: "true") {
			id
			name
			email
			rg
			cpf
			phone
			address
			type
			confirmed
			role
			executiveCommission
			church {
				id
				name
			}
		}
	}
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
		$name:String,
		$cpf:String,
		$rg:String,
		$avatar:String,
		$address:String,
		$phone:String,
		$email:String,
		$password: String,
		$church:String,
		$type:String,
		$executiveCommission:String,
		$confirmed: Boolean,
	) {
    createUser(
			input: { 
				name: $name,
				cpf: $cpf,
				rg: $rg,
				avatar: $avatar,
				address: $address,
				phone: $phone,
				email: $email,
				password: $password,
				church: $church,
				type: $type,
				executiveCommission: $executiveCommission,
				confirmed: $confirmed
			}
		) {
      id
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $name:String, $email:String, $cpf:String, $rg:String, $type:String, $church:String, $avatar:String, $address:String, $phone:String, $confirmed: Boolean, $executiveCommission:String, $password: String) {
    updateUser(id: $id, input: {name: $name, email: $email, cpf: $cpf, rg: $rg, type: $type, church: $church, avatar: $avatar, address: $address, phone: $phone, confirmed: $confirmed, executiveCommission: $executiveCommission, password: $password }) {
      id
      name
    }
  }
`;

export const QUERY_CHURCHES = gql`
  query Churches {
    listChurch {
      id
      name
    }
  }
`;