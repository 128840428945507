import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation, useQuery } from '@apollo/client';
import { Button, IconButton } from '@material-ui/core';

import styles from './styles';
import UsersForm from './form';
import { DELETE_USER, QUERY_USERES } from './graphql';


function Users() {
	const classes = styles();
	const [ openDialog, setOpenDialog ] = useState(false);
	const [ currentRecord, setCurrentRecord ] = useState({});
	const { loading, error, data: rows, refetch } = useQuery(QUERY_USERES);
	const [ deleteUser ] = useMutation(DELETE_USER);

	if(loading) {
		return <div>Carregando...</div>
	}

	if(error) {
		return <div>Erro!</div>
	}

	const handleClose = (data?: any) => {
		if(data) {
			refetch();
		}

		setOpenDialog(false)
	}

	return (
		<div className={classes.containerPage}>
			<TableContainer className={classes.tableContainer} component={Paper}>
				<Button onClick={async () => {
					setCurrentRecord({});
					setOpenDialog(true);
				}} color="primary" variant="contained">
					Adicionar novo usuário
				</Button>
				<Table className={classes.table} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>Nome</TableCell>
						<TableCell>Tipo</TableCell>
						<TableCell>Igreja</TableCell>
						<TableCell>Status</TableCell>
						<TableCell></TableCell>
					</TableRow>
					</TableHead>
					<TableBody>
					{rows.getUsers.map((row: any) => (
						<TableRow key={row.name}>
							<TableCell component="th" scope="row"  onClick={() => {
								setCurrentRecord(row);
								setOpenDialog(true);
							}}>
								{row.name}
							</TableCell>
							<TableCell component="th" scope="row"  onClick={() => {
								setCurrentRecord(row);
								setOpenDialog(true);
							}}>
								{row.type}
							</TableCell>
							<TableCell component="th" scope="row"  onClick={() => {
								setCurrentRecord(row);
								setOpenDialog(true);
							}}>
								{row.church.name}
							</TableCell>
							<TableCell component="th" scope="row"  onClick={() => {
								setCurrentRecord(row);
								setOpenDialog(true);
							}}>
								{row.confirmed ? 'OK' : 'Pendente'}
							</TableCell>
							<TableCell align="right">
								<IconButton aria-label="delete" size="small">
									<DeleteIcon fontSize="small" onClick={() => {deleteUser({ variables: { id: row.id } }).then(() => refetch())}} />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>
			
			{
				openDialog 
					? (
						<UsersForm
							open={openDialog}
							handleClose={handleClose}
							currentRecord={currentRecord}
						/>
					) : null
			}
		</div>
  );
}

export default Users;