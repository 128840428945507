import { AppBar, Button, Grid, IconButton, Paper, Toolbar, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import styles from './styles';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import MyBackdrop from '../../components/Backdrop';
import ReportConselho from './conselho/conselho';
import { useReactToPrint } from 'react-to-print';
import { Print } from '@material-ui/icons';
import ReportToPrint from './reportToPrint';

const QUERY_MEETINGS = gql`
  query ListMeetings {
    listMeeting(filter: "Criada,Convocada,Iniciada") {
	  id
	  name
	  date
	  convocation
	  status
    }
  }
`;

const MUTATION_SET_REPORT = gql`
	mutation SetReport($meeting: String!, $type: String!) {
		setReport(meeting: $meeting, type: $type) {
			id
			type
			year
		}
	}
`;

function ConselhoDashboard({...props}) {
    const classes = styles();
    const componentRef = useRef<any>();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	const [ currentMeeting, setCurrentMeeting ] = useState<any>();
	const { loading: loadingMeetings, error: errorMeetings , data: dataMeetings } = useQuery(QUERY_MEETINGS);
	const [ setReport, { loading: loadingReport, data: dataReport, error: errorReport } ] = useMutation(MUTATION_SET_REPORT);

    if(loadingMeetings) {
		return <MyBackdrop open message="Carregando reuniões" />
	}

	if(loadingReport) {
		return <MyBackdrop open message="Carregando relatório" />
	}
	
	if(errorMeetings) {
		return <div>Erro ao carregar as reuniões!</div>
	}

	if(errorReport) {
		return <div>Erro ao carregar o relatório!</div>
	}

    const createListMeetings = () => {
		return dataMeetings.listMeeting.map((item: any, idx: any) => {
			return <Grid item key={idx} className={classes.gridItem}>
				<Paper
					onClick={() => {
						setReport({ variables: { type: 'conselho', meeting: item.id } });
						setCurrentMeeting({ id: item.id, name: item.name, date: item.date, convocation: item.convocation, status: item.status });
					}}
					className={classes.paperItem}
				>
				<Typography variant="h5">{item.name}</Typography>
				<Typography variant="h6"><span>Status:</span> {item.status}</Typography>	
				<Typography variant="h6"><span>Data:</span> {item.date}</Typography>	
			</Paper></Grid>
		});
	}

	if(dataMeetings?.listMeeting?.length === 0) {
		return <Alert severity="warning">Não existe nenhuma reunião disponível para inserir o relatório.</Alert>
	}

    if(dataMeetings?.listMeeting.length && !currentMeeting ) {
		return (
			<div className={classes.rootCentered}>
			<Grid container>
				<Grid item xs={12} className={classes.gridItem}>
					<Typography variant="h6">Reuniões disponíveis para preencher o relatório:</Typography>
				</Grid>
				{ createListMeetings() }
			</Grid>
			</div>
		);
	}

    return currentMeeting && dataReport ? ( 
		<>	
			<ReportToPrint ref={componentRef}>
                <ReportConselho meeting={currentMeeting} report={dataReport.setReport} />
			</ReportToPrint>
			<IconButton className={classes.btnPrint} color="primary" onClick={handlePrint}><Print /></IconButton>
		</>
	) : (null);
}

export default ConselhoDashboard;