import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Slide, TextField } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { gql, useMutation, useQuery } from '@apollo/client';
import { CREATE_USER, QUERY_CHURCHES, UPDATE_USER } from './graphql';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UsersForm({...props}) {
    const [ currentRecord, setCurrentRecord ] = useState(props.currentRecord);
    const [ createUser, { data: dataCreate, loading: loadingCreate, error: errorCreate }] = useMutation(CREATE_USER);
    const [ updateUser, { data: dataUpdate, loading: loadingUpdate, error: errorUpdate }] = useMutation(UPDATE_USER);
    const { loading: loadingChurch, error: errorChurch, data: dataChurch } = useQuery(QUERY_CHURCHES);

    const _onSave = async () => {
        console.log('currentRecord', currentRecord);
        currentRecord && currentRecord.id 
            ? await updateUser({ variables: { ...currentRecord, name: currentRecord.name?.replace('Rev.', '').replace('Pbº', ''), church: currentRecord.church?.id || currentRecord.church}}).then((res) => props.handleClose(res))
            : await createUser({ variables: { ...currentRecord, church: currentRecord.church, id: null }}).then((res) => props.handleClose(res));
    }

    useEffect(() => {
        setCurrentRecord(props.currentRecord)
    }, [props.currentRecord])


    const isLoading = loadingCreate || loadingUpdate;

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClose}
            fullWidth
        >
            <DialogTitle>
                {currentRecord.id ? 'Editar Usuário' : 'Novo Usuário'}
            </DialogTitle>
            <DialogContent dividers>
                <TextField 
                        label="Nome Completo"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        autoFocus
                        onChange={(e) => setCurrentRecord({...currentRecord, name: e.target.value})}
                        value={currentRecord.name?.replace('Rev.', '').replace('Pbº', '') || ''}
                    />
                    <TextField 
                        label="RG"
                        fullWidth
                        type="number"
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setCurrentRecord({...currentRecord, rg: e.target.value})}
                        value={currentRecord.rg || ''}
                    />
                    <TextField 
                        label="CPF"
                        fullWidth
                        type="number"
                        margin="normal"
                        inputProps={{
                            maxlength: 5
                        }}
                        variant="outlined"
                        onChange={(e) => setCurrentRecord({...currentRecord, cpf: e.target.value})}
                        value={currentRecord.cpf || ''}
                    />
                    <TextField 
                        label="Telefone"
                        fullWidth
                        type="number"
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setCurrentRecord({...currentRecord, phone: e.target.value})}
                        value={currentRecord.phone || ''}
                    />
                    <TextField 
                        label="Endereço"
                        fullWidth
                        type="text"
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setCurrentRecord({...currentRecord, address: e.target.value})}
                        value={currentRecord.address || ''}
                    />
                    <TextField 
                        label="Email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setCurrentRecord({...currentRecord, email: e.target.value})}
                        value={currentRecord.email || ''}
                    />
                    <TextField 
                        label="Nova senha"
                        fullWidth
                        placeholder="Deixe em branco para não alterar"
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setCurrentRecord({...currentRecord, password: e.target.value})}
                        value={currentRecord.password || ''}
                    />
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel id="select-igreja">Igreja</InputLabel>
                        <Select
                            value={currentRecord.church?.id || currentRecord.church}
                            fullWidth
                            onChange={(e: any) => {
                                setCurrentRecord({...currentRecord, church: e.target.value})
                            }}
                        >
                            {
                                dataChurch?.listChurch.map((church: any) => (
                                    <MenuItem key={church.id} value={church.id}>{church.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                        <Select
                            value={currentRecord.type || 'Presbítero'}
                            fullWidth
                            onChange={(e: any) => {
                                setCurrentRecord({...currentRecord, type: e.target.value})
                            }}
                        >
                            <MenuItem key="presbitero" value="Presbítero">Presbítero</MenuItem>
                            <MenuItem key="reverendo" value="Reverendo">Reverendo</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>Cargo</InputLabel>
                        <Select
                            value={currentRecord.executiveCommission || ''}
                            fullWidth
                            onChange={(e: any) => {
                                setCurrentRecord({...currentRecord, executiveCommission: e.target.value})
                            }}
                        >
                            <MenuItem key="presidente" value="">-- Nenhum --</MenuItem>
                            <MenuItem key="presidente" value="Presidente">Presidente</MenuItem>
                            <MenuItem key="vice-presidente" value="Vice-presidente">Vice-presidente</MenuItem>
                            <MenuItem key="secretario-executivo" value="Secretário executivo">Secretário executivo</MenuItem>
                            <MenuItem key="secretario-atas" value="Secretário de atas">Secretário de atas</MenuItem>
                            <MenuItem key="secretario-protocolo" value="Secretário de protocolo">Secretário de protocolo</MenuItem>
                            <MenuItem key="tesoureiro" value="Tesoureiro">Tesoureiro</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        currentRecord?.role?.includes('master')
                            ? (null)
                            : (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={currentRecord?.confirmed || false}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentRecord({...currentRecord, confirmed: e.target.checked})
                                            }}
                                        />
                                    }
                                    label="Acesso liberado?"
                                />
                            )
                    }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancelar
                </Button>
                <Button disabled={isLoading} onClick={() => _onSave()} variant="contained" color="primary">
                    { isLoading ? 'Salvando' : 'Salvar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UsersForm;