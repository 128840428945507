import React, { useEffect, useState } from 'react';
import styles from './styles';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Backdrop, Button, CircularProgress, Container, Grid, Paper, TextField, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, Slide} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

const QUERY_CHURCH = gql`
	query GetUsersByChuch {
		me {
			id
			church {
				id
				name
				meetingAgent {
					id
					name
				}
				meetingAgentBook
				meetingAgentAta
				meetingAgentDate
			}
		}
		getUsersByChurch {
			id
			name
			email
			type
			phone
			address
			rg
			cpf
		}
	}
`;

const MUTATION_MEETING_AGENT = gql`
	mutation SetChurchMeetingAgent($church: String!, $user: String!, $book: String!, $ata: String!, $date: String!) {
		setChurchMeetingAgent(church: $church, user: $user, book: $book, ata: $ata, date: $date) {
			id
		}
	}
`;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MyChurch({...props}) {
	const classes = styles();
	const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
	const [ currentRecord, setCurrentRecord ] = useState<any>({});
	const [ currentAgent, setCurrentAgent ] = useState<any>({});
	const [ dialogAgentOpen, setDialogAgentOpen ] = useState(false);
	const { loading, error, data, refetch } = useQuery(QUERY_CHURCH);
	const [ setChurchMeetingAgent, { loading: setLoading, error: errorLoading } ] = useMutation(MUTATION_MEETING_AGENT, {
		onCompleted: () => {
			refetch();
			setCurrentRecord({});
			setCurrentAgent({});
			setDialogAgentOpen(false);
		}
	});

	useEffect(() => {
		data && setCurrentRecord({...data.me});
	}, [data]);

	const onSave = () => {
		if(!currentAgent.book || !currentAgent.ata || !currentAgent.date) {
			return alert('Todos os campos devem ser preenchidos.');
		}
		setDialogAgentOpen(false);
		setChurchMeetingAgent({
			variables: { ...currentAgent, user: currentRecord.id, church: data?.me?.church?.id }
		});
	}

	const onClose = () => {
		setDialogAgentOpen(false);
		setCurrentRecord({});
		setCurrentAgent({});
	}

	if(error) {
		return <div>Erro!</div>
	}

	return (
		<div className={classes.containerPage}>
			<Dialog
				open={dialogAgentOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				fullWidth
			>
				<DialogTitle>
					Definindo o usuário {currentRecord.name} como representante da igreja.
				</DialogTitle>
				<DialogContent dividers>
					<TextField 
                        label="Livro"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        autoFocus
                        onChange={(e) => setCurrentAgent({...currentAgent, book: e.target.value})}
                        value={currentAgent.book || ''}
                    />
					<TextField 
                        label="Ata"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        autoFocus
                        onChange={(e) => setCurrentAgent({...currentAgent, ata: e.target.value})}
                        value={currentAgent.ata || ''}
                    />
					<TextField 
                        label="Data"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        autoFocus
                        onChange={(e) => setCurrentAgent({...currentAgent, date: e.target.value})}
                        value={currentAgent.date || ''}
                    />
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						Cancelar
					</Button>
					<Button disabled={loading} onClick={() => onSave()} variant="contained" color="primary">
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
			{
				(loading || setLoading)
					? (
						<Backdrop className={classes.backdrop} open={true}>
							<CircularProgress color="inherit" />
							<Typography>Carregando</Typography>
						</Backdrop>
					) : (null)
			}
			<Container maxWidth="lg">
				<Grid container direction={matchesSm ? 'column' : 'row'}>
					<Grid item xs={12}>
						<Typography variant="h3" className={classes.titlePage}>
							{data?.me?.church?.name}
						</Typography>
						<hr/>
						{ 
							(data?.me?.church?.meetingAgent?.id)
								? (
									<Typography variant="h6">
										<b>Informações do representante:</b> Livro {data?.me?.church?.meetingAgentBook}, Ata {data?.me?.church?.meetingAgentAta}, Data {data?.me?.church?.meetingAgentDate}.
									</Typography>
								) : (null)
							
						}
						<Typography variant="h6">
							<b>Usuários cadastrados:</b>
						</Typography>
					</Grid>
					{
						data?.getUsersByChurch?.map((item: any, idx: number) => {
							return <Grid item xs={matchesSm ? 12 : 4} key={idx}><Paper className={classes.paperItem}>
								<Typography className={classes.titleh5} variant="h5"><span>{item.type}</span>{item.name}</Typography>
								<p><b>Telefone: </b>{item.phone}</p>
								<p><b>Endereço: </b>{item.address}</p>
								<p><b>CPF: </b>{item.cpf}</p>
								<p><b>RG: </b>{item.rg}</p>
								<p><b>Email: </b>{item.email}</p>
								{
									data?.me?.church?.meetingAgent?.id == item.id 
									? <p className={classes.currentAgent}>Atual representante</p>
									: item.type != 'Reverendo'
										? (
											<Button 
												variant="contained"
												fullWidth
												onClick={() => {
													setCurrentRecord(item);
													setDialogAgentOpen(true); 
												}}
											>
												Definir como representante
											</Button>
										) : (null)
								}
							</Paper></Grid>
						})
					}
				</Grid>
			</Container>
		</div>
  );
}

export default MyChurch;