import { gql } from '@apollo/client';

export const QUERY_FIELDS = gql`
  query Churches {
    listChurch {
      id
      name
    }
  }
`;

export const DO_LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      id
      name
      email 
      accessToken
      role
      executiveCommission
      type
      avatar
      church {
          id
          name
          documentHeader
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($name:String!,$email:String!,$password:String!,$cpf:String!,$rg:String!,$type:String!,$church:String!,$avatar:String,$address:String,$phone:String, $executiveCommission:String) {
    signUp(input: { name: $name, email: $email, password: $password, cpf: $cpf, rg: $rg, type: $type, church: $church, avatar: $avatar, address: $address, phone: $phone, executiveCommission: $executiveCommission }) {
      id
      name
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(input: { email: $email }) {
        id
    }
  }
`;