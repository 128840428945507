import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Editor } from '@tinymce/tinymce-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flex: 1,
    },
    formControl: {
      margin: theme.spacing(3),
    },
  }),
);

export default function HtmlViewL({...props}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Editor
                   apiKey="qm93a283zhyqhzhvof0vm0oxualwlxt4ldsv4j3n7lf85i8d"
                   disabled={!!props.disabled}
                    // initialValue={initialValue}
                    value={props.value || ''}
                    onInit={function(ed: any) {
                      var header = `<header>${props.header || ''}</header>`,
                      footer = `<footer>${props.footer ? '<div class="footer-divider"></div>' : ''} ${props.footer || ''}</footer>`,
                      content = ed.target.getContent();

                      content = header + content + footer + '<div class="content-readonly"></div>';
                      ed.target.setContent(content);
                  }}
                // onChange={props.onChange}
                init={{
                    height: '100%',
                    // width: '100%',
                    menubar: false,
                    readOnly: true,
                    contentEditable: false,
                    content_editable: false,
                    disabled: true,
                    bodyDisabled: true,
                    language: 'pt_BR',
                    images_upload_url: 'postAcceptor.php',
                    content_css: '/custom-tinymc-l.css',
                    plugins: [
                        'fullscreen preview print noneditable'
                    ],
                    noneditable_noneditable_class: 'mce-content-body',
                    toolbar: props.disabled ? '' : 'fullscreen print' 
                }}
                onEditorChange={() => {}}
            />
        </div>
  );
}