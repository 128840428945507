import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        flex: 1,
    },
    rootCentered: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
    },
    formContent: {
        width: '100%',
        height: 'calc(100% - 50px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
    },
    gridItem: {
        margin: 10
    },
    paperItem: {
        padding: 10,
        cursor: 'pointer',
        background: '#efefef',
        '& h5': {
            fontSize: 16,
            fontWeight: 'bold'
        },
        '& h6': {
            fontSize: 12,
            fontWeight: 'normal',
            '& span': {
                fontWeight: 'bold'
            }
        }
    },
    btnAdd: {
    },
    basePaperMargin: {
        margin: 10,
        width: 'calc(100% - 20px)'
    },
    formTitle: {
        paddingTop: 10,
        paddingLeft: 10,
    },
    gridContainer: {
        padding: 15
    },
    textLink: {
        cursor: 'pointer',
        textAlign: 'center',
        opacity: 0.6,
        fontSize: 12
    },
    footer: {
        top: 'auto',
        bottom: 0,
        width: 'calc(100% - 240px)',
        flexDirection: 'row-reverse',
        height: 50,
        '& div': {
            minHeight: 30,
            paddingRight: 0,
        },
        '& button': {
            borderRadius: 0,
            padding: '5px 10px',
            margin: '0 15px 0 0',
        }
    },
}));

export default useStyles;