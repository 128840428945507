import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 275;
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 'auto',
        top: 'auto',
        width: 275,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        // marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        width: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbar: {
        flexDirection: 'column',
        padding: '5px 5px 10px'
    },
    title: {
        flexGrow: 1,
    },
    boxUser: {
        maxWidth: drawerWidth,
        overflow: 'hidden'
    },
    avatar: {
        width: theme.spacing(11),
        height: theme.spacing(11),
        border: '2px solid rgba(255,255,255,0.6)',
        ':hover': {
            backgroundColor: 'rgba(255,255,255,0.6) !important'
        }
    }
}));

export default useStyles;