import { Avatar, Button, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { 
    Folder as FolderIcon,
    Description as DescriptionIcon,
    ViewModule as ViewModuleIcon,
    HowToVote as HowToVoteIcon,
    Drafts as DraftsIcon,
    FindInPage as FindInPageIcon,
    Gavel,
    AssignmentInd,
    AttachMoney,
    KeyboardBackspace,
} from '@material-ui/icons';
import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import Eleicao from '../../components/eleicao';
import useStyles from './styles';

function Eleicoes({...props}) {
    const classes = useStyles();
    let match = useRouteMatch();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Switch>
            <Route path={`${match.path}/mesa-diretora`}>
                <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                    <Link to="/app/ordinaria/eleicoes" className={classes.link}>
                        <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                    <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Eleição mesa diretora</Button>
                    </Link>
                    <Paper className={matchesSm ? classes.paperInternoMobile : classes.paperInterno}>
                        <Eleicao subStepId={props.steps[1]?.subSteps[3].id} />
                    </Paper>
                </Container>
            </Route>
            <Route path={`${match.path}/tesoureiro`}>
                <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                    <Link to="/app/ordinaria/eleicoes" className={classes.link}>
                        <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                        <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Eleição para tesoureiro</Button>
                    </Link>
                    <Paper className={matchesSm ? classes.paperInternoMobile : classes.paperInterno}>
                        <Eleicao subStepId={props.steps[3]?.subSteps[8].id} />
                    </Paper>
                </Container>
            </Route>
            <Route path={`${match.path}/secretarios`}>
                <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                    <Link to="/app/ordinaria/eleicoes" className={classes.link}>
                        <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                        <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Elieção para secretários</Button>
                    </Link>
                    <Paper className={matchesSm ? classes.paperInternoMobile : classes.paperInterno}>
                        <Eleicao subStepId={props.steps[3]?.subSteps[9].id} />
                    </Paper>
                </Container>
            </Route>
            <Route path={`${match.path}`}>
                <Container maxWidth="xl">
                    <Link to="/app/ordinaria" className={classes.link}>
                        <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                        <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Você está em eleições</Button>
                    </Link>
                    <Grid container spacing={3}>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/eleicoes/mesa-diretora" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <Gavel />
                                    </Avatar>
                                    <Typography>
                                        Mesa Diretora
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/eleicoes/tesoureiro" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <AttachMoney />
                                    </Avatar>
                                    <Typography>
                                        Tesoureiro
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/eleicoes/secretarios" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <AssignmentInd />
                                    </Avatar>
                                    <Typography>
                                        Secretários
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Route>
        </Switch>
    );
}

export default Eleicoes;