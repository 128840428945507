import { action } from 'typesafe-actions';
import { UsersTypes } from './types';

export const loginRequest = () => action(UsersTypes.LOGIN_REQUEST);
export const loginSuccess = () => action(UsersTypes.LOGIN_SUCCESS);
export const loginFailure = () => action(UsersTypes.LOGIN_FAILURE);
export const signupRequest = () => action(UsersTypes.SIGNUP_REQUEST);
export const signupSuccess = () => action(UsersTypes.SIGNUP_SUCCESS);
export const signupFailure = () => action(UsersTypes.SIGNUP_FAILURE);
export const updateForm = (field: any, value: any, form: 'form' | 'signUpForm' = 'form') => action(UsersTypes.UPDATE_FORM, {field, value, form});
export const validateForm = (form: 'form' | 'signUpForm' = 'form', errors: any) => action(UsersTypes.VALIDATE_FORM, { form, errors });
export const logOut = () => action(UsersTypes.LOGOUT);
export const logOutSuccess = () => action(UsersTypes.LOGOUT_SUCCESS);