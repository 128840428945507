import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& h1': {
            flex: 1,
            fontWeight: 900,
            marginLeft: 10,
            letterSpacing: 2,
            fontSize: 22
        }
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    main: {
        flexGrow: 1,
        height: 'calc(100vh - 65px)',
        overflow: 'auto',
        marginTop: 65,
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff'
    },
    breadcrumbs: {
        padding: '5px 15px',
        backgroundColor: '#eee',
        '& li *': {
            fontSize: 12,
        },
        '& > * + *': {
            marginTop: theme.spacing(2),
        }
    },
    textWelcome: {
        fontSize: 20,
        fontWeight: 'bolder',
        opacity: 0.6
    },
    containerPage: {
        width: '100%',
        height: '100%',
        background: '#fff',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    footer: {
        top: 'auto',
        bottom: 0,
        width: 'calc(100% - 240px)',
        flexDirection: 'row-reverse',
        height: 50,
        '& div': {
            minHeight: 30,
            paddingRight: 0
        },
        '& button': {
            borderRadius: 0,
            padding: '5px 10px',
            margin: '0 15px 0 0'
        }
    },
    textEditor: {
        margin: '0 auto',
        maxWidth: 1024,
        height: 'calc(100% - 130px)'
    },
    formContent: {
        width: '100%',
        height: 'calc(100% - 50px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    formContentFull: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    textField: {
        width: 300
    },
    button: {
        marginTop: 30
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: 'calc(100vw - 295px)'
    },
    formControlDateTime: {
        margin: theme.spacing(1),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
        top: 30
    },
    gridContainer: {
        padding: 15
    },
    basePaperMargin: {
        margin: 10,
        width: 'calc(100% - 20px)'
    }
}));

export default useStyles;