import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormControl, Select, MenuItem, Button, Typography, TextField, IconButton, FormControlLabel, Checkbox } from '@material-ui/core';
import { AddCircle, Close as CloseIcon } from '@material-ui/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import MyBackdrop from '../../../components/Backdrop';

const QUERY_LIST_COMMISSIONS = gql`
  query LIST_COMMISSIONS($meeting: String!) {
	getUsers {
		id
		name
		church {
			name
		}
	}
	getUsersWithoutCommission(meeting: $meeting) {
		id
		name
		church {
			name
		}
	}
    listCommissions(meeting: $meeting) {
      id
	  name
	  minRoles
      commissionRoles {
		  id
		  name
		  user {
			  id
			  name
		  }
	  }
    }
  }
`;

const MUTATION_CREATE_COMMISSION_ROLE = gql`
  mutation CREATE_COMMISSION_ROLE ($meeting: String!, $user: String!, $commission: String!, $name: String!, $order: String!) {
		createCommissionRole(input: { meeting: $meeting, user: $user, commission: $commission, name: $name, order: $order }) {
			id
			name
		}
  }
`;

const MUTATION_UPDATE_COMMISSION_ROLE = gql`
  mutation CREATE_COMMISSION_ROLE ($id: String!, $user: String!) {
		updateCommissionRole(id: $id, input: { user: $user }) {
			id
			name
		}
  }
`;

const MUTATION_DELETE_COMMISSION_ROLE = gql`
  mutation DELETE_COMMISSION_ROLE ($id: String!) {
		deleteCommissionRole(id: $id) {
			id
		}
  }
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			width: '100%',
			padding: 20,
			'& hr': {
				opacity: 0.1,
				borderBottom: 0,
				marginLeft: -15,
				marginRight: -15,
				paddingBottom: 10,
			}
		},
		formControl: {
			width: '100%'
		},
		docTitle: {
			paddingTop: '30px !important'
		},
		textJustify: {
			textAlign: 'justify'
		},
		doTitleLink: {
			cursor: 'pointer'
		},
		boxUserSelected: {
			'& button': {
				marginTop: 18
			}
		}
	}),
);

export default function NomeacaoComissoes({...props}) {
	const classes = useStyles();
	const [ aditionalRoles, setAditionalRoles ] = useState<any>({})
	const { loading, error , data, refetch } = useQuery(QUERY_LIST_COMMISSIONS, { variables: { meeting: props.meeting?.id } });
	const [ createCommissionRole, { loading: loadingCreateCommissionRole, error: errorCreateCommissionRole } ]  = useMutation(MUTATION_CREATE_COMMISSION_ROLE, {
		onCompleted: () => refetch()
	});
	const [ updateCommissionRole, { loading: loadingUpdateCommissionRole, error: errorUpdateCommissionRole } ]  = useMutation(MUTATION_UPDATE_COMMISSION_ROLE, {
		onCompleted: () => refetch()
	});
	const [ deleteCommissionRole, { loading: loadingDeleteCommissionRole, error: errorDeleteCommissionRole } ]  = useMutation(MUTATION_DELETE_COMMISSION_ROLE, {
		onCompleted: () => refetch()
	});

	if(error) {
		return <div>Ocorreu algum erro ao carregar as commissões.</div>
	}
	
	if(errorCreateCommissionRole || errorUpdateCommissionRole) {
		return <div>Ocorreu algum ao salvar.</div>
	}

	const onCreate = (item: any) =>  {
		createCommissionRole({
			variables: {...item, meeting: props.meeting?.id}
		});
	}

	const onUpdate = (item: any) =>  {
		updateCommissionRole({
			variables: {...item}
		});
	}

	const onDelete = (item: any) =>  {
		deleteCommissionRole({
			variables: { id: item.id }
		});
	}

	const buildAditionalRoles = (item: any) => {
		let fields = [];

		for (let i = 0; i < aditionalRoles[item.name]; i++) {
			fields.push(
				<Grid item xs={6} key={i}>
					<FormControl className={classes.formControl}>
						<InputLabel>Integrante</InputLabel>
						<Select
							value=""
							onChange={(e) => {
								setAditionalRoles({...aditionalRoles, [item.name]: (aditionalRoles[item.name] || 0) - 1}) 
								onCreate({
									name: 'Integrante',
									commission: item.id,
									user: e.target.value,
									order: '2'
								});
							}}
						>
							<MenuItem value={''} key={`user-${i}-null`}>
								-- Deixar em branco -- 
							</MenuItem>
							{
								(data?.getUsers || []).map((itemUser: any, idxUser: number) => (
									<MenuItem value={itemUser.id} key={`user-${i}-${idxUser}`}>
										{ itemUser.name } ({ itemUser.church.name })
									</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
			);
		}

		return fields
	}

	return (
		<div className={classes.root}>
			{
				(loading || loadingCreateCommissionRole || loadingUpdateCommissionRole)
					? (<MyBackdrop open={true} message={loading ? 'Carregando' : 'Salvando'} />)
					: (null)
			}
			{
				(data?.listCommissions || []).map((item: any, idx: number) => (
					<Grid container spacing={3} key={idx}>
						<Grid item xs={3} className={classes.docTitle}>
							<Typography className={classes.doTitleLink}>
								<b>{item.name}</b> 
							</Typography>
						</Grid>
						<Grid xs={7}>
							<Grid container spacing={3}>
							{
								item.commissionRoles?.map((itemRole: any, idxRole: number) => (
									<Grid item xs={6}>
											{/* <InputLabel>{itemRole.name}</InputLabel> */}
											<Grid container spacing={0} className={classes.boxUserSelected}>
												<Grid item>
													<IconButton size="small" onClick={() => onDelete(itemRole)}><CloseIcon fontSize="small" /></IconButton>
												</Grid>
												<Grid item xs>
													<FormControl fullWidth className={classes.formControl}>
														<TextField label={itemRole.name === 'Suplente' ? 'Vogal' : itemRole.name} disabled value={itemRole.user?.name} fullWidth />
													</FormControl>
												</Grid>
												</Grid>
												{/* <Select
												value={itemRole.user?.id || ''}
												onChange={(e) => {
													onUpdate({
														id: itemRole.id,
														user: e.target.value
													})
												}}
											>
												<MenuItem value={''} key={`user-${idx}-null`}>
													-- Deixar em branco -- 
												</MenuItem>
												{
													(data?.getUsersWithoutCommission || []).map((itemUser: any, idxUser: number) => (
														<MenuItem value={itemUser.id} key={`user-${idx}-${idxUser}`}>
															{ itemUser.name } ({ itemUser.church.name })
														</MenuItem>
													))
												}
											</Select> */}
									</Grid>
								))
							}
							{
								(item.commissionRoles?.length < 1) 
									? (
										<Grid item xs={6}>
											<FormControl className={classes.formControl}>
												<InputLabel id="demo-simple-select-label">Relator</InputLabel>
												<Select
													value=""
													onChange={(e) => {
														onCreate({
															name: 'Relator',
															commission: item.id,
															user: e.target.value,
															order: '0',
														});
													}}
												>
													<MenuItem value={''} key={`user-${idx}-null`}>
														-- Deixar em branco -- 
													</MenuItem>
													{
														(data?.getUsers || []).map((itemUser: any, idxUser: number) => (
															<MenuItem value={itemUser.id} key={`user-${idx}-${idxUser}`}>
																{ itemUser.name } ({ itemUser.church.name })
															</MenuItem>
														))
													}
												</Select>
											</FormControl>
										</Grid>
									) : (null)
							}
							{
								(item.minRoles === 2 && item.commissionRoles?.length < 2) 
									? (
										<Grid item xs={6}>
											<FormControl className={classes.formControl}>
												<InputLabel id="demo-simple-select-label">Vogal</InputLabel>
												<Select
													value=""
													onChange={(e) => {
														onCreate({
															name: 'Vogal',
															commission: item.id,
															user: e.target.value,
															order: '1',
														});
													}}
												>
													<MenuItem value={''} key={`user-${idx}-null`}>
														-- Deixar em branco -- 
													</MenuItem>
													{
														(data?.getUsers || []).map((itemUser: any, idxUser: number) => (
															<MenuItem value={itemUser.id} key={`user-${idx}-${idxUser}`}>
																{ itemUser.name } ({ itemUser.church.name })
															</MenuItem>
														))
													}
												</Select>
											</FormControl>
										</Grid>
									) : (null)
							}

							{
								aditionalRoles[item.name] ? buildAditionalRoles(item) :(null)
							}
							</Grid>
						</Grid>
						<Grid item xs={2}>
							<Button
								startIcon={<AddCircle />}
								onClick={() => { 
									setAditionalRoles({...aditionalRoles, [item.name]: (aditionalRoles[item.name] || 0) + 1}) 
								}}
								color="primary"
							> 
								Adicionar integrante
							</Button>
						</Grid>
						<Grid item xs={12}>
							<hr/>
						</Grid>
					</Grid>
				))
			}
		</div>
	);
}