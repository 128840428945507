import { gql } from '@apollo/client';

export const QUERY_CHURCHES = gql`
	query Churches {
		listChurch {
			id
			name
			documentHeader
		}
	}
`;

export const DELETE_CHURCH = gql`
  mutation DeleteChurch($id: String!) {
    deleteChurch(id: $id) {
      id
    }
  }
`;

export const CREATE_CHURCH = gql`
  mutation CreateChurch($name: String!, $documentHeader: String) {
    createChurch(input: { name: $name, documentHeader: $documentHeader }) {
      id
      name
      documentHeader
    }
  }
`;

export const UPDATE_CHURCH = gql`
  mutation CreateChurch($id: String!, $name: String!, $documentHeader: String) {
    updateChurch(id: $id, input: { name: $name, documentHeader: $documentHeader }) {
      id
      name
      documentHeader
    }
  }
`;