/**
 * Action types
 */

export enum MainTypes {
    TOGGLE_DRAWER = '@main/TOGGLE_DRAWER',
    GO_TO = '@main/GO_TO',
    GO_BACK = '@main/GO_BACK'
}

export enum ExecutiveCommissionToRole {
    'Presidente' = 'presidente',
    'Vice-presidente' = 'vice_presidente',
    'Tesoureiro' = 'tesoureiro',
    'Secretário de protocolo' = 'secretario_protocolo',
    'Secretário executivo' = 'secretario_executivo',
    'Secretário de atas' = 'secretario_atas',
    'Reverendo' = 'reverendo',
    'Presbítero' = 'presbitero',
}

export enum ExecutiveCommissionEnum {
    'Presidente' = 'Presidente',
    'Vice-presidente' = 'Vice-presidente',
    'Tesoureiro' = 'Tesoureiro',
    'Secretário de protocolo' = 'Secretário de protocolo',
    'Secretário executivo' = 'Secretário executivo',
    'Secretário de atas' = 'Secretário de atas',
    'Reverendo' = 'Reverendo',
    'Presbítero' = 'Presbítero',
}

/**
 * Data types
 */
interface BreadCrumbs {
    text: string,
    href: string | undefined | null
}

export interface Menu {
    title: string,
    level: number,
    parent: Menu | undefined | null,
    route: string,
    menu?: Menu[] | undefined | null,
    role?: String[]
}

/**
 * State type
 */
export interface MainState {
    readonly drawerOpen: boolean,
    readonly breadcrumbs: BreadCrumbs[],
    readonly listCurrentMenu: any,
    readonly selectedMenu: Menu | {},
    readonly backMenu: Array<Menu[]>,
    readonly menu: Menu[]
}