import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { InsertDriveFile, Clear as RemoveIcon, PictureAsPdf } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { TextField, InputLabel, FormControl, Select, MenuItem, DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions, Button, Slide, Typography, CircularProgress, Backdrop, IconButton, Container } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import HtmlView from './htmlView';
import HtmlViewL from './htmlViewL';
import ReportConselho from 'src/pages/Reports/conselho/conselho';
import ReportCongregacao from 'src/pages/Reports/congregacao/congregacao';
import ReportEstatistica from 'src/pages/Reports/estatistica/estatistica';
import ReportMinistro from 'src/pages/Reports/ministro/ministro';
import DisplayPdf from 'src/components/DisplayPdf';
import { getMe } from 'src/services/auth';

const QUERY_DOCUMENTS = gql`
  query ListDocumentsAll($meeting: String!) {
    listDocuments(meeting: $meeting, all: "entrada-docs") {
      id
      title
      text
      number
      report
      status
      createdAt
	  inputStep
	  approvedStep
	  type
      user {
        id
        name
	  }
	  commission {
		  id
		  name
	  }
	}
	listReports(meeting: $meeting) {
		id
		name
		type
		number
		text
		inputStep
	  	approvedStep
		church {
			id
			name
		}
		commission {
			id
			name
		}
	}
	listCommissions(meeting: $meeting) {
		id
		name
	},
	compileReportTable(meeting: $meeting, type: "ministro") {
		title
		type
		table
	}
  }
`;

const MUTATION_UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($id: String!, $title: String!, $text: String!, $meeting: String!, $number: String, $status: String, $report: String, $commission: String, $step: String) {
    updateDocument(id: $id, input: {title: $title, text: $text, meeting: $meeting, number: $number, status: $status, report: $report, commission: $commission, step: $step}) {
		id
		title
		text
		number
		report
		status
		createdAt
		user {
		  id
		  name
		}
		commission {
			id
		}
    }
  }
`;

const MUTATION_UPDATE_REPORT = gql`
  mutation UpdateReport($id: String!, $number: String, $commission: String, $step: String) {
    updateReport(id: $id, input: {number: $number, commission: $commission, step: $step}) {
		id
		name
		type
		number
		church {
			id
			name
		}
		commission {
			id
			name
		}
    }
  }
`;

const MUTATION_UPDATE_MEETING = gql`
  mutation SetMeetingNumber($meeting: String!, $number: String) {
    setMeetingNumber(meeting: $meeting, number: $number) {
		id
		estatisticaNumber
    }
  }
`;

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			width: '80%',
			padding: 20,
		},
		formControl: {
			width: '100%'
		},
		formControlSelect: {
			width: `80%`
		},
		docTitle: {
			paddingTop: '30px !important',
			cursor: 'pointer',
		},
		textJustify: {
			textAlign: 'justify'
		},
		doTitleLink: {
			cursor: 'pointer'
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 999,
			color: '#fff',
		},
		iconButtonRemove: {
			marginTop: 10
		},
		htmlView: {
			'& .MuiDialog-paper': {
			  height: '100%',
			  '& .MuiDialogContent-dividers': {
				display: 'flex',
				flexDirection: 'column',
				'& > p': {
				  flex: 1,
				  display: 'flex',
				}
			  }
			}
		},
		textUserInfo: {
			fontSize: 11,
			opacity: 0.5
		},
		reportTile: {
			marginTop: 10,
		},
		titleReportEstatistica: {
			marginTop: 18,
		},
		displayPdf: {
			background: '#eee',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
			alignItems: 'center'
		}
	}),
);

export const mapReportTitle: any = {
	conselho: 'Relatório do Conselho',
	congregacao: 'Relatório da Congregação',
	estatistica: 'Informações cadastrais e estatísticas',
	ministro: 'Relatório do Ministro Presbiteriano',
	permanencia_pastoral: 'Documentos de Permanência Pastoral',
}

export default function EntradaDocumento({...props}) {
	const classes = useStyles();
	const me = getMe();
	const [ documents, setDocuments ] = useState<any[]>([]);
	const [ reports, setReports ] = useState<any[]>([]);
	const [ tables, setTables ] = useState<any[]>([]);
	const [ estatisticaNumber, setEstatisticaNumber ] = useState<any>(props.meeting?.estatisticaNumber);
	const [ currentDocument, setCurrentDocument ] = useState<any>({});
	const [ currentReport, setCurrentReport ] = useState<any>({});
	const [ currentTable, setCurrentTable ] = useState<any>({});
	const [ open, setOpen ] = React.useState(false);
	const [ reportOpen, setReportOpen ] = React.useState(false);
	const [ reportTable, setTableOpen ] = React.useState(false);
	const { loading: loadingDocuments, error: errorDocuments , data: dataDocuments, refetch: refetchDocuments } = useQuery(QUERY_DOCUMENTS, { variables: { meeting: props.meeting?.id }});
	const [ updateDocument, { data: dataUpdate, loading: loadingUpdate, error: errorUpdate }] = useMutation(MUTATION_UPDATE_DOCUMENT, {
		onCompleted: () => {
			refetchDocuments();
		}
	});
	const [ updateReport, { data: dataUpdateReport, loading: loadingUpdateReport, error: errorUpdateReport }] = useMutation(MUTATION_UPDATE_REPORT, {
		onCompleted: () => {
			refetchDocuments();
		}
	});
	const [ updateMeeting, { data: dataUpdateMeeting, loading: loadingUpdateMeeting, error: errorUpdateMeeting }] = useMutation(MUTATION_UPDATE_MEETING);

	useEffect(() => {
		dataDocuments?.listDocuments && setDocuments(dataDocuments.listDocuments);
		dataDocuments?.listReports && setReports(dataDocuments.listReports);
		dataDocuments?.compileReportTable && setTables(dataDocuments.compileReportTable);
	}, [dataDocuments]);

	const handleClickOpen = (item: any) => {
		setCurrentDocument(item);
		setOpen(true);
	};

	const handleReportClickOpen = (item: any) => {
		setCurrentReport(item);
		setReportOpen(true);
	};

	const handleClickOpenTable = (item: any) => {
		setCurrentTable(item);
		setTableOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setReportOpen(false);
		setTableOpen(false);
	};

	const doUpdateDocument = (document: any) => {
		updateDocument({ variables: {...document, meeting: props.meeting.id, commission: document.commission?.id || document.commission, step: props.step.code }});
	}

	const doUpdateReport = (report: any) => {
		updateReport({ variables: { id: report.id, meeting: props.meeting.id, number: report.number, commission: report.commission?.id || report.commission, step: props.step.code  }});
	}

	const doUpdateMeeting = (report: any) => {
		updateMeeting({ variables: { meeting: props.meeting.id, number: report.number }});
	}

	const buildEditor = (value: string) => {
		return <HtmlView value={value} disabled />
	}

	const buildReportView = (report: any) => {
		switch(report.type) {
			case 'congregacao':
				return (<ReportCongregacao meeting={props.meeting} report={report} readOnly />)
			case 'conselho':
				return (<ReportConselho meeting={props.meeting} report={report} readOnly />)
			case 'estatistica':
				return (<ReportEstatistica meeting={props.meeting} report={report} readOnly />)
			case 'ministro':
				return (<ReportMinistro meeting={props.meeting} report={report} readOnly />)
			case 'permanencia_pastoral':
				return (<HtmlView value={report.text}/>)
		}
	}

	const buildReportViewL = (table: any) => {
		let value = '';

		(tables || []).map((table) => {
			value += table.table;
			value += '<div class="page-break"></div>';
		})
		
		return (<HtmlViewL value={value}/>)
	}

	if(loadingDocuments) {
		return <div>carregando documentos...</div>
	}

	return (
		<div className={classes.root}>
			{
				(loadingDocuments || loadingUpdate || loadingUpdateReport || loadingUpdateMeeting) ? (<Backdrop className={classes.backdrop} open={true}> <CircularProgress color="inherit" /> </Backdrop>) : (null)
			}
			{/* {
				PDF OU DOCUMENTO NORMAL
			} */}
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				maxWidth="lg"
				fullWidth
				className={classes.htmlView}
			>
				<DialogTitle id="alert-dialog-slide-title">
					{currentDocument.title}
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText id="alert-dialog-slide-description" className={classes.textJustify}>
						{ currentDocument.type === 'pdf' ? (
							<Container className={classes.displayPdf}>
								<DisplayPdf url={currentDocument.text} />
							</Container>
						) : buildEditor(currentDocument.text) }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Fechar
					</Button>
				</DialogActions>
			</Dialog>



			<Dialog
				open={reportOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				maxWidth="lg"
				fullWidth
				className={classes.htmlView}
			>
				<DialogTitle id="alert-dialog-slide-title">
					{ mapReportTitle[currentReport.type] || currentReport.name }
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText id="alert-dialog-slide-description" className={classes.textJustify}>
						{ buildReportView(currentReport) }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Fechar
					</Button>
				</DialogActions>
			</Dialog>



			<Dialog
				open={reportTable}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				maxWidth="lg"
				fullWidth
				className={classes.htmlView}
			>
				<DialogTitle>
					Relatório de Estatísticas
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText id="alert-dialog-slide-description" className={classes.textJustify}>
						{ buildReportViewL(currentTable) }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Fechar
					</Button>
				</DialogActions>
			</Dialog>





			<Grid container spacing={3}>
				<Grid item xs={12} className={classes.docTitle}>
					{/* <Button startIcon={<AddCircle />} variant="contained" color="primary"> Adicionar novo documento</Button> */}
				</Grid>
			</Grid>
				{
					!(documents?.length || reports?.length || tables?.length) ? (
						<Alert severity="info">Ainda não foi adicionado nenhum documento para essa reunião.</Alert>
					) : (
						<Grid container>
							<Grid item xs={1}><b>Ver</b></Grid>
							<Grid item xs={6}><b>Título</b></Grid>
							<Grid item xs={1}><b>Número</b></Grid>
							<Grid item xs={4}><b>Comissão</b></Grid>
						</Grid>
					)
				}

				{
					documents?.map((item: any, idx: number) => {
						if(item.inputStep && item.inputStep !== props.step.code) {
							return (null);
						}
						return (
							<Grid container key={`document-${idx}`} spacing={3}>
								<Grid item xs={1} className={classes.docTitle} onClick={() => handleClickOpen(item)}>
									{item.type === 'pdf' ? <PictureAsPdf/> : <InsertDriveFile/>}
								</Grid>
								<Grid item xs={6}>
									{
										(me.role.includes('admin') || me.role.includes('master')) ? (
											<TextField
												label="Título"
												value={item.title || ''}
												className={classes.formControl}
												disabled={loadingUpdate}
												onChange={(e) => {
													const value = e.target.value;
													setDocuments((state) => state.map((itemState) => {
														const {...newItemState} = itemState;
														if(newItemState.id === item.id) {
															newItemState.title = value;
														}

														return newItemState;
													}));	
												}}
												onBlur={(e) => {
													doUpdateDocument({...item, title: e.target.value})
												}}
											/>
										) : (
											<Typography className={classes.titleReportEstatistica}>
												{item.title || ''}
											</Typography>
										)
									}
									{
										item.user ? (
											<Typography className={classes.textUserInfo}>
												{ `${item.user?.type === 'Reverendo' ? 'Rev. ' : 'Pbº '} ${item.user?.name}` }
											</Typography>
										) : (null)
									}
								</Grid>
								<Grid item xs={1}>
									{
										(me.role.includes('admin') || me.role.includes('master')) ? (
											<TextField
												label="Número"
												type="number"
												value={item.number || ''}
												className={classes.formControl}
												disabled={loadingUpdate}
												onChange={(e) => {
													const value = e.target.value;
													setDocuments((state) => state.map((itemState) => {
														const {...newItemState} = itemState;
														if(newItemState.id === item.id) {
															newItemState.number = value;
														}

														return newItemState;
													}));
												}}
												onBlur={(e) => {
													doUpdateDocument({...item, number: e.target.value})
												}}
											/>
										) : (
											<Typography className={classes.titleReportEstatistica}>
												{ item.number }
											</Typography>
										)
									}
								</Grid>
								<Grid item xs={4}>
								{
									(me.role.includes('admin') || me.role.includes('master')) ? (
										<>
											<FormControl className={classes.formControlSelect}>
												<InputLabel>Destino</InputLabel>
												<Select
													value={item.commission?.id || ''}
													disabled={loadingUpdate}
													onChange={(e) => {
														doUpdateDocument({...item, commission: e.target.value})
													}}
												>
													{
														dataDocuments?.listCommissions?.map((commission: any, idx: number) => (
															<MenuItem key={idx} value={commission.id}>{commission.name}</MenuItem>
														))
													}
												</Select>
											</FormControl>
											{
												(item.commission?.id) ? (
													<IconButton className={classes.iconButtonRemove} color="secondary" component="span" onClick={() => {
														doUpdateDocument({...item, commission: null})
													}}>
														<RemoveIcon />
													</IconButton>
												) : (null)
											}
										</>
									) : (
										<Typography className={classes.titleReportEstatistica}>
											{item.commission?.name || 'Não definida'}
										</Typography>
									)
								}
								</Grid>	
							</Grid>
						)}
					)
				}

				{
					reports?.map((item: any, idx: number) => {
						if(item.inputStep && item.inputStep !== props.step.code) {
							return (null);
						}
						return (
							<Grid container key={`document-${idx}`} spacing={3}>
								<Grid item xs={1} className={classes.docTitle} onClick={() => handleReportClickOpen(item)}>
									<InsertDriveFile/>
								</Grid>
								<Grid item xs={6} className={classes.reportTile}>
									<Typography>
										{ mapReportTitle[item.type] || item.name }
									</Typography>
									<Typography className={classes.textUserInfo}>
										{ item.church.name }
									</Typography>
								</Grid>
								<Grid item xs={1}>
									{
										(me.role.includes('admin') || me.role.includes('master')) ? (
											<TextField
												label="Número"
												type="number"
												value={item.number || ''}
												className={classes.formControl}
												disabled={loadingUpdate}
												onChange={(e) => {
													const value = e.target.value;
													setReports((state) => state.map((itemState) => {
														const {...newItemState} = itemState;
														if(newItemState.id === item.id) {
															newItemState.number = value;
														}

														return newItemState;
													}));
												}}
												onBlur={(e) => {
													doUpdateReport({...item, number: e.target.value})
												}}
											/>
										) : (
											<Typography className={classes.titleReportEstatistica}>{item.number}</Typography>
										)
									}
								</Grid>
								<Grid item xs={4}>
								{
									(me.role.includes('admin') || me.role.includes('master')) ? (
										<>
										<FormControl className={classes.formControlSelect}>
											<InputLabel>Destino</InputLabel>
											<Select
												value={item.commission?.id || ''}
												disabled={loadingUpdate}
												onChange={(e) => {
													doUpdateReport({...item, commission: e.target.value})
												}}
											>
												{
													dataDocuments?.listCommissions?.map((commission: any, idx: number) => (
														<MenuItem key={idx} value={commission.id}>{commission.name}</MenuItem>
													))
												}
											</Select>
										</FormControl>
										{
											(item.commission?.id) ? (
												<IconButton className={classes.iconButtonRemove} color="secondary" component="span" onClick={() => {
													doUpdateReport({...item, commission: null})
												}}>
													<RemoveIcon />
												</IconButton>
											) : (null)
										}
										</>
									) : (
										<Typography className={classes.titleReportEstatistica}>
											{item.commission?.name || 'Não definida'}
										</Typography>
									)
								}
									</Grid>
							</Grid>
						)}
					)
				}

				{
					tables && tables.length ? (
						<Grid container spacing={3}>
							<Grid item xs={1} className={classes.docTitle} onClick={() => handleClickOpenTable({})}>
								<InsertDriveFile/>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.titleReportEstatistica}>
									Relatórios de estatísticas
								</Typography>
							</Grid>
							<Grid item xs={1}>
								{
									(me.role.includes('admin') || me.role.includes('master')) ? (
										<TextField
											label="Número"
											type="number"
											value={estatisticaNumber || ''}
											className={classes.formControl}
											disabled={loadingUpdate}
											onChange={(e) => {
												const value = e.target.value;
												setEstatisticaNumber(value);
											}}
											onBlur={(e) => {
												doUpdateMeeting({number: e.target.value})
											}}
										/>
									) : (
										<Typography className={classes.titleReportEstatistica}>
											{estatisticaNumber || ''}
										</Typography>
									)
								}
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.titleReportEstatistica}>
									Estatísticas
								</Typography>
							</Grid>
						</Grid>
					) : (null)
				}	
		</div>
	);
}