import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'flex-start',
        flex: 1,
        '& p': {
            fontSize: 12,
        },
        '& .MuiCheckbox-root, .MuiRadio-root': {
            padding: 0,
            marginRight: 5
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
            borderBottomColor: 'rgba(0, 0, 0, 0.10)',
        },
        '& .MuiInputBase-input': {
            color: '#555'
        },
        '& .MuiFormControlLabel-label': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        '& label, .MuiGrid-root, .MuiFormControlLabel-label': {
            fontSize: 12
        },
        '& textarea': {
            borderColor: '#e5e5e5',
            fontFamily: 'Arial',
        },
        '& input': {
            padding: '4px 0px 5px'
        },
    },
    rootCentered: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
    },
    rootGridContainer: {
        // borderTop: '0.5px solid #eee',
        // borderLeft: '0.5px solid #eee',
        '& > .MuiGrid-item': {
            padding: 5,
            // borderBottom: '0.5px solid #eee',
            // borderRight: '0.5px solid #eee',
            '& .MuiGrid-item': {
                padding: 5,
                // borderBottom: '0.5px solid #eee',
                // borderRight: '0.5px solid #eee',
                '& .MuiGrid-item': {
                    padding: 5,
                    // borderBottom: '0.5px solid #eee',
                    // borderRight: '0.5px solid #eee',
                }
            }
        }
    },
    logo: {
        width: '70%',
        marginTop: 15,
    },
    title: {
        minHeight: 50,
        marginTop: 10,
        '& h1': {
            fontSize: 24,
            fontWeight: 'bold',
        },
        '& h2': {
            fontSize: 18,
            fontWeight: 'bold',
        }
    },
    sectionTitle: { 
        background: '#3f51b5',
        color: '#fff',
        fontWeight: 'bold',
        borderRadius: '5px 5px 0px 0px',
    },
    subSectionTitle: { 
        background: '#F5F5F5',
        padding: 3
    },
    verticalSpace: {
        minHeight: 40,
        width: '100%',
    },
    labelPhones: {
        paddingTop: '24px !important',
    },
    textInfo: {
        opacity: 0.5,
        fontSize: 11,
    },
    textInfoBox: {
        opacity: 0.5,
        fontSize: 11,
        borderBottom: '1px solid #e8e8e8',
        marginTop: 5,
    },
    hightlightBlue: {
        background: '#8790c5',
        padding: 3,
        color: '#fff',
        marginBottom: 5,
        '& label': {
            color: '#fff'
        }
    },
    hightlightRed: {
        background: '#c58787',
        padding: 3,
        color: '#fff',
        marginBottom: 5,
        '& label': {
            color: '#fff'
        }
    },
    hightlightGray: {
        background: '#F7F7F7',
        padding: 3,
        marginBottom: 5,
    },
    gridItem: {
        margin: 10
    },
    paperItem: {
        padding: 10,
        cursor: 'pointer',
        background: '#efefef',
        '& h5': {
            fontSize: 16,
            fontWeight: 'bold'
        },
        '& h6': {
            fontSize: 12,
            fontWeight: 'normal',
            '& span': {
                fontWeight: 'bold'
            }
        }
    },
    labelPlain: {
        paddingTop: '26px !important'
    },
    labelPlainSmall: {
        paddingTop: '20px !important'
    },
    financeiroSummario: {
        paddingTop: 10
    },
    emptyRowHeight: {
        minHeight: 41.67
    },
    pageBreak: {
        '@media print' : {
            pageBreakBefore: 'always !important',
            // marginTop: 200
        }
    },
    marginPrint50: {
        '@media print' : {
            marginTop: 50
        }
    }, 
    marginPrint100: {
        '@media print' : {
            marginTop: 100
        }
    }, 
    marginPrint200: {
        '@media print' : {
            marginTop: 200
        }
    }, 
    marginPrint280: {
        '@media print' : {
            marginTop: 280
        }
    }, 
    marginPrint300: {
        '@media print' : {
            marginTop: 300
        }
    }, 
    marginPrint500: {
        '@media print' : {
            marginTop: 500
        }
    },  
    btnPrint: {
        position: 'fixed',
        right: 0,
        bottom: 0
    },
    textLink: {
        cursor: 'pointer',
        textAlign: 'center',
        opacity: 0.6,
        fontSize: 12
    },
}));

export default useStyles;