import { gql, useMutation, useQuery } from '@apollo/client';
import { Checkbox, Container, Grid, Typography, TextareaAutosize, TextField, FormControlLabel, Radio } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MyBackdrop from '../../../components/Backdrop';
import styles from '../styles';
import EstruturaComunidadePresbiteriana from './EstruturaComunidadePresbiteriana';
import InformacoesFinais from './InformacoesFinais';
import InformacoesFinanceiras from './InformacoesFinanceiras';
import RolMembros from './RolMembros';

const logo = require('../../../assets/ipb_logo.png');

const QUERY_REPORT_FIELDS = gql`
  query GetReportFields($report: String!) {
    getReportFields(report: $report) {
	  id
	  name
	  label
      type
      value
    }
  }
`;

const MUTATION_SET_FIELD = gql`
	mutation SetField($report: String!, $value: String!, $name: String!) {
		setField(report: $report, value: $value, name: $name) {
			id
			name
			value
		}
	}
`;

function ReportEstatistica({...props}) {
	const classes = styles();
	const isDisabled = (props.readOnly === true);
    const [ record, setRecord ] = useState<any>(null);
    const { loading, error , data } = useQuery(QUERY_REPORT_FIELDS, { variables: { report: props.report.id } });
    const [ setField, { loading: loadingField } ] = useMutation(MUTATION_SET_FIELD);

    useEffect(() => {
        if(!data) return;

        const newRecord: any =  {};
        data.getReportFields?.map((item: any) => {
            newRecord[item.name] = item.value;
        });
        setRecord(newRecord);
    }, [data]);

    const onChangeField = (e: any) => {
        const newRecord = Object.assign({}, record);
        newRecord[e.target.name] = e.target.value;
        setRecord(newRecord);
    };

    const onSetCurrencyField = (e: any) => {
        const value = e.target.value.replace(/\./g, '').replace(',', '.');
        setField({
            variables: {
                report: props.report.id,
                name: e.target.name,
                value,
            }
        });
    };

    const onSetField = (e: any) => {
        setField({
            variables: {
                report: props.report.id,
                value: e.target.value,
                name: e.target.name
            }
        });
    };

    if(error) {
        return <div>Ocorreu algum erro ao carregar os dados.</div>
    }

    
	return (
		<Container className={classes.root}>
            {
                (loading) ? <MyBackdrop open message="Carregando dados" /> : null
            }
			<Grid container spacing={0} xs={12} className={classes.rootGridContainer}>
				<Grid item xs={3}>
					<img alt="Logo Concílio" src={logo} className={classes.logo} />
				</Grid>
				<Grid item xs={9}>
					<Grid container>
						<Grid item xs={12} className={classes.title}
							container
							direction="column"
							justify="center"
							alignItems="center"
						>
							<Typography variant="h1" align="center">
                                INFORMAÇÕES CADASTRAIS E ESTATÍSTICAS				
							</Typography>
                            <Typography variant="h1" align="center">
                                DE COMUNIDADE PRESBITERIANA
							</Typography>
							<Typography>
								<b>ANO: { props.report.year }</b>
							</Typography>
						</Grid>

						<Grid item xs={9}>
							<TextField fullWidth disabled={isDisabled} label="SÍNODO" name="sinodo" value={record?.sinodo || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={3}>
							<TextField fullWidth disabled={isDisabled} label="SIGLA" name="sinodo_sigla" value={record?.sinodo_sigla || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>

						<Grid item xs={9}>
							<TextField fullWidth disabled={isDisabled} size="small" label="PRESBITÉRIO" name="presbitero" value={record?.presbitero || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={3}>
							<TextField fullWidth disabled={isDisabled} size="small" label="SIGLA" name="presbitero_sigla" value={record?.presbitero_sigla || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} className={classes.sectionTitle}>
                    I - Identificação da Igreja / Congregação Presbiterial
				</Grid>

				<Grid item xs={12}>
					<TextField fullWidth disabled={isDisabled} label="Nome (Igreja/Congregação)" name="igreja" value={record?.igreja || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>

				<Grid item xs={7}>
					<TextField fullWidth disabled={isDisabled} label="Endereço" name="endereco" value={record?.endereco || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} label="Número" name="numero" value={record?.numero || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				<Grid item xs={3}>
					<TextField fullWidth disabled={isDisabled} label="Complemento" name="complemento" value={record?.complemento || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>

				<Grid item xs={6}>
					<Grid container>
						<Grid item xs={6}>
							<TextField fullWidth disabled={isDisabled} label="Bairro" name="bairro" value={record?.bairro || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={6}>
							<TextField fullWidth disabled={isDisabled} label="Cidade" name="cidade" value={record?.cidade || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container>
						<Grid item xs={4}>
							<TextField fullWidth disabled={isDisabled} label="UF" name="uf" value={record?.uf || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={4}>
							<TextField fullWidth disabled={isDisabled} label="CEP" name="cep" value={record?.cep || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={4}>
							<TextField fullWidth disabled={isDisabled} label="Caixa Postal" name="caixa_postal" value={record?.caixa_postal || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} label="Telefone" name="telefone" value={record?.telefone || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				<Grid item xs={8}>
					<TextField fullWidth disabled={isDisabled} label="E-mail" name="email" value={record?.email || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>

                <Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} label="Data organização" name="data_organizacao" value={record?.data_organizacao || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
				<Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} label="CNPJ" name="cnpj" value={record?.cnpj || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>
                <Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} label="Site" name="site" value={record?.site || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>


                <EstruturaComunidadePresbiteriana readOnly={isDisabled} record={record} onSetField={onSetField} onChangeField={onChangeField} />

				<Grid item xs={12} className={classes.marginPrint200} ></Grid>
				<RolMembros readOnly={isDisabled} record={record} onSetField={onSetField} onChangeField={onChangeField} />

				<Grid item xs={12} className={classes.marginPrint280} ></Grid>
                <InformacoesFinanceiras readOnly={isDisabled}  record={record} onSetField={onSetField} onSetCurrencyField={onSetCurrencyField} onChangeField={onChangeField} />

				<Grid item xs={12} className={classes.marginPrint50} ></Grid>
                <InformacoesFinais readOnly={isDisabled}  record={record} onSetField={onSetField} onChangeField={onChangeField} />
				

                <div className={classes.verticalSpace}></div>

			</Grid>

			
		</Container>
	);
}

export default ReportEstatistica;