import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Routes from './routes';
import { ApolloProvider, split } from '@apollo/client';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from './services/auth';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';

const token = getToken();

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL || 'https://lionfish-app-j7z9z.ondigitalocean.app/graphql',
  credentials: 'include'
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_WS_URL || 'wss://lionfish-app-j7z9z.ondigitalocean.app/graphql',
  options: {
    reconnect: true,
    // connectionParams: () => {
    //   const token = getToken();
    //   return {
    //     headers: {
    //       Authorization: token ? `Bearer ${token}` : "",
    //     },
    //   };
    // },
  },
});

const authLink = setContext((_, { headers }) => {
  const currentToken = getToken();
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: currentToken ? `Bearer ${currentToken}` : "",
    }
  }
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL || 'https://lionfish-app-j7z9z.ondigitalocean.app/graphql',
});



const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: authLink.concat(uploadLink.concat(splitLink)),
  cache: new InMemoryCache(),
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
  },
  // onError: ({ graphQLErrors: any, networkError: any }) => {
  //   if (graphQLErrors)
  //     graphQLErrors.map(({ message, locations, path }) =>
  //       console.log(
  //         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //       )
  //     );
  //   if (networkError) console.log(`[Network error]: ${networkError}`);
  // },
});

// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache()
// });


function App() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
          <Routes />
      </Provider>
    </ApolloProvider>
  );
}

export default App;


