import { gql, useMutation, useQuery } from '@apollo/client';
import { Checkbox, Container, Grid, Typography, TextareaAutosize, TextField, FormControlLabel, Radio } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MyBackdrop from 'src/components/Backdrop';
import styles from '../styles';

const logo = require('../../../assets/ipb_logo.png').default;

const QUERY_REPORT_FIELDS = gql`
  query GetReportFields($report: String!) {
    getReportFields(report: $report) {
	  id
	  name
	  label
      type
      value
    }
  }
`;

const MUTATION_SET_FIELD = gql`
	mutation SetField($report: String!, $value: String!, $name: String!) {
		setField(report: $report, value: $value, name: $name) {
			id
			name
			value
		}
	}
`;

function ReportConselho({...props}) {
    const classes = styles();
    const isDisabled = (props.readOnly === true);
    const [ record, setRecord ] = useState<any>(null);
    const { loading, error , data } = useQuery(QUERY_REPORT_FIELDS, { variables: { report: props.report.id } });
    const [ setField, { loading: loadingField } ] = useMutation(MUTATION_SET_FIELD);

    useEffect(() => {
        if(!data) return;

        const newRecord: any =  {};
        data.getReportFields?.map((item: any) => {
            newRecord[item.name] = item.value;
        });
        setRecord(newRecord);
    }, [data]);

    const onChangeField = (e: any) => {
        const newRecord = Object.assign({}, record);
        newRecord[e.target.name] = e.target.value;
        setRecord(newRecord);
    };

    const onChangeCheckField = (e: any) => {
        const checked = e.target.checked;
        const name = e.target.name;
        console.log('name-->', name, checked);
        setRecord({...record, [name]: `${checked}`});
        onSetCheckField(name, checked)
    };

    const onSetCurrencyField = (e: any) => {
        const value = e.target.value.replace(/\./g, '').replace(',', '.');
        setField({
            variables: {
                report: props.report.id,
                name: e.target.name,
                value,
            }
        });
    };

    const onSetCheckField = (name: string, checked: any) => {
        console.log('çhecked--->', `${checked}`);
        setField({
            variables: {
                report: props.report.id,
                value: `${checked}`,
                name: name
            }
        });
    };

    const onSetField = (e: any) => {
        setField({
            variables: {
                report: props.report.id,
                value: e.target.value,
                name: e.target.name
            }
        });
    };

    if(error) {
        return <div>Ocorreu algum erro ao carregar os dados.</div>
    }
	
	return (
		<Container className={classes.root}>
            {
                (loading) ? <MyBackdrop open message="Carregando dados" /> : null
            }
			<Grid container spacing={0} xs={12} className={classes.rootGridContainer}>
				<Grid item xs={3}>
					<img alt="Logo Concílio" src={logo} className={classes.logo} />
				</Grid>
				<Grid item xs={9}>
					<Grid container>
						<Grid item xs={12} className={classes.title}
							container
							direction="column"
							justify="center"
							alignItems="center"
						>
							<Typography variant="h1" align="center">
								RELATÓRIO DO CONSELHO
							</Typography>
                            <Typography variant="h2" align="center">
								OU MESA ADMINISTRATIVA
							</Typography>
							<Typography>
                                <b>ANO: { props.report.year }</b>
							</Typography>
						</Grid>

						<Grid item xs={9}>
                            <TextField fullWidth disabled={isDisabled} label="SÍNODO" name="sinodo" value={record?.sinodo || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={3}>
                            <TextField fullWidth disabled={isDisabled} label="SIGLA" name="sinodo_sigla" value={record?.sinodo_sigla || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>

						<Grid item xs={9}>
                            <TextField fullWidth disabled={isDisabled} size="small" label="PRESBITÉRIO" name="presbitero" value={record?.presbitero || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
						<Grid item xs={3}>
                            <TextField fullWidth disabled={isDisabled} size="small" label="SIGLA" name="presbitero_sigla" value={record?.presbitero_sigla || ''} onChange={onChangeField} onBlur={onSetField} />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} className={classes.sectionTitle}>
                    I - Identificação da Igreja / Congregação Presbiterial
				</Grid>

				<Grid item xs={12}>
					<TextField fullWidth disabled={isDisabled} label="Nome (Igreja/Congregação)"  name="i_igreja" value={record?.i_igreja || ''} onChange={onChangeField} onBlur={onSetField} />
				</Grid>

				<Grid item xs={7}>
					<TextField fullWidth disabled={isDisabled} label="Endereço" name="i_endereco" value={record?.i_endereco || ''} onChange={onChangeField} onBlur={onSetField}  />
				</Grid>
				<Grid item xs={2}>
					<TextField fullWidth disabled={isDisabled} label="Número" name="i_numero" value={record?.i_numero || ''} onChange={onChangeField} onBlur={onSetField}  />
				</Grid>
				<Grid item xs={3}>
					<TextField fullWidth disabled={isDisabled} label="Complemento" name="i_complemento" value={record?.i_complemento || ''} onChange={onChangeField} onBlur={onSetField}  />
				</Grid>

				<Grid item xs={6}>
					<Grid container>
						<Grid item xs={6}>
							<TextField fullWidth disabled={isDisabled} label="Bairro" name="i_bairro" value={record?.i_bairro || ''} onChange={onChangeField} onBlur={onSetField}  />
						</Grid>
						<Grid item xs={6}>
							<TextField fullWidth disabled={isDisabled} label="Cidade" name="i_cidade" value={record?.i_cidade || ''} onChange={onChangeField} onBlur={onSetField}  />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container>
						<Grid item xs={3}>
							<TextField fullWidth disabled={isDisabled} label="UF" name="i_uf" value={record?.i_uf || ''} onChange={onChangeField} onBlur={onSetField}  />
						</Grid>
						<Grid item xs={4}>
							<TextField fullWidth disabled={isDisabled} label="CEP" name="i_cep" value={record?.i_cep || ''} onChange={onChangeField} onBlur={onSetField}  />
						</Grid>
						<Grid item xs={5}>
							<TextField fullWidth disabled={isDisabled} label="Caixa Postal" name="i_cx_postal" value={record?.i_cx_postal || ''} onChange={onChangeField} onBlur={onSetField}  />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} label="Telefone" name="i_telefone" value={record?.i_telefone || ''} onChange={onChangeField} onBlur={onSetField}  />
				</Grid>
				<Grid item xs={8}>
					<TextField fullWidth disabled={isDisabled} label="E-mail" name="i_email" value={record?.i_email || ''} onChange={onChangeField} onBlur={onSetField}  />
				</Grid>

                <Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} label="Data organização" name="i_data_organizacao" value={record?.i_data_organizacao || ''} onChange={onChangeField} onBlur={onSetField}  />
				</Grid>
				<Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} label="CNPJ" name="i_cnpj" value={record?.i_cnpj || ''} onChange={onChangeField} onBlur={onSetField}  />
				</Grid>
                <Grid item xs={4}>
					<TextField fullWidth disabled={isDisabled} label="Site" name="i_site" value={record?.i_site || ''} onChange={onChangeField} onBlur={onSetField}  />
				</Grid>


				<Grid item xs={12} className={classes.sectionTitle}>
                    II - Informações do Trabalho
				</Grid>
                <Grid item xs={12} className={classes.subSectionTitle}>
					1. ORGANIZAÇÃO:
				</Grid>

                <Grid item xs={3}>
                    1.1. Os imóveis estão documentados?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_1_11_sim" checked={record?.ii_1_11_sim === 'true' || false} onChange={onChangeCheckField}   
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_1_11_nao"
                        checked={record?.ii_1_11_nao === 'true' || false} onChange={onChangeCheckField}
                    />
                </Grid>
                <Grid item xs={3}>
                    1.4. Apresentou declarações do ano anterior?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="IRenda"
                        name="ii_1_14_irenda" checked={record?.ii_1_14_irenda === 'true' || false} onChange={onChangeCheckField}   
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="RAIS"
                        name="ii_1_14_rais" checked={record?.ii_1_14_rais === 'true' || false} onChange={onChangeCheckField}   
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="DIRF"
                        name="ii_1_14_dirf" checked={record?.ii_1_14_dirf === 'true' || false} onChange={onChangeCheckField}   
                    />
                </Grid>


                <Grid item xs={3}>
                    1.2. Há inventário dos móveis e utensílios?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_1_12_sim" checked={record?.ii_1_12_sim === 'true' || false} onChange={onChangeCheckField}   
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_1_12_nao" checked={record?.ii_1_12_nao === 'true' || false} onChange={onChangeCheckField}   
                    />
                </Grid>
                <Grid item xs={3}>
                    1.5. Inventário atualizado?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_1_15_sim" checked={record?.ii_1_15_sim === 'true' || false} onChange={onChangeCheckField}   
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_1_15_nao" checked={record?.ii_1_15_nao === 'true' || false} onChange={onChangeCheckField}   
                    />
                </Grid>
                

                <Grid item xs={3}>
                    1.3. Róis de Membros estão atualizados?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        value="true"
                        name="ii_1_13_sim" checked={record?.ii_1_13_sim === 'true'} onChange={onChangeCheckField}
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        value="false"
                        name="ii_1_13_nao" checked={record?.ii_1_13_nao === 'true'} onChange={onChangeCheckField}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth disabled={isDisabled} label="1.6. Quantas Congregações da Igreja?" type="number" name="ii_1_6" value={record?.ii_1_6 || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>



                <Grid item xs={12} className={classes.subSectionTitle}>
                    2. SUPERVISÃO ESPIRITUAL
				</Grid>
                <Grid item xs={12}>
                    <b>2.1. Adoração e Comunhão</b>
                </Grid>


                <Grid item xs={4}>
                    <TextField fullWidth disabled={isDisabled} label="2.1.1. Nº de celebrações Santa Ceia em Grupos:" type="number" name="ii_2_1_1" value={record?.ii_2_1_1 || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth disabled={isDisabled} label="Nº de ministrações Santa Ceia a indivíduos:" type="number" name="ii_2_1_1_santa_ceia" value={record?.ii_2_1_1_santa_ceia || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth disabled={isDisabled} label="Total Geral:" type="number" name="ii_2_1_1_total_geral" value={record?.ii_2_1_1_total_geral || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>

                <Grid item xs={12}>
                    2.1.2. Sociedade Interna (S.I.) existente na Igreja:
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth disabled={isDisabled} label="UCP" type="number" name="ii_2_1_2_ucp" value={record?.ii_2_1_2_ucp || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth disabled={isDisabled} label="UPA" type="number" name="ii_2_1_2_upa" value={record?.ii_2_1_2_upa || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth disabled={isDisabled} label="UMP" type="number" name="ii_2_1_2_ump" value={record?.ii_2_1_2_ump || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth disabled={isDisabled} label="SAF" type="number" name="ii_2_1_2_saf" value={record?.ii_2_1_2_saf || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth disabled={isDisabled} label="UPH" type="number" name="ii_2_1_2_uph" value={record?.ii_2_1_2_uph || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth disabled={isDisabled} label="Outra" type="number" name="ii_2_1_2_outra" value={record?.ii_2_1_2_outra || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>

                <Grid item xs={4}>
                    2.1.3. Se não há, porque motivo?
                </Grid>
                <Grid item xs={1}>
                    <Typography>UCP</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography>UPA</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography>UMP</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography>SAF</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography>UPH</Typography>
                </Grid>
                <Grid item xs={3}></Grid>

                <Grid item xs={4}>
                    1).	Existe na igreja um ministério equivalente:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_1_ucp"
                        checked={record?.ii_2_1_3_1_ucp === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_1_upa"
                        checked={record?.ii_2_1_3_1_upa === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_1_ump"
                        checked={record?.ii_2_1_3_1_ump === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_1_saf"
                        checked={record?.ii_2_1_3_1_saf === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_1_uph"
                        checked={record?.ii_2_1_3_1_uph === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid> 
                <Grid item xs={3}></Grid>


                <Grid item xs={4}>
                    2).	Não existem membros suficientes:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_2_ucp"
                        checked={record?.ii_2_1_3_2_ucp === 'true' || false} onChange={onChangeCheckField} 
                        
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_2_upa"
                        checked={record?.ii_2_1_3_2_upa === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_2_ump"
                        checked={record?.ii_2_1_3_2_ump === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_2_saf"
                        checked={record?.ii_2_1_3_2_saf === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_2_uph"
                        checked={record?.ii_2_1_3_2_uph === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid> 
                <Grid item xs={3}></Grid>


                <Grid item xs={4}>
                    3).	Não existem lideres capacitados:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_3_ucp"
                        checked={record?.ii_2_1_3_3_ucp === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_3_upa"
                        checked={record?.ii_2_1_3_3_upa === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_3_ump"
                        checked={record?.ii_2_1_3_3_ump === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_3_saf"
                        checked={record?.ii_2_1_3_3_saf === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_3_uph"
                        checked={record?.ii_2_1_3_3_uph === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid> 
                <Grid item xs={3}></Grid>


                <Grid item xs={4}>
                    4).	Não há interesse na igreja:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_4_ucp"
                        checked={record?.ii_2_1_3_4_ucp === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_4_upa"
                        checked={record?.ii_2_1_3_4_upa === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_4_ump"
                        checked={record?.ii_2_1_3_4_ump === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_4_saf"
                        checked={record?.ii_2_1_3_4_saf === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_4_uph"
                        checked={record?.ii_2_1_3_4_uph === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid> 
                <Grid item xs={3}></Grid>


                <Grid item xs={4}>
                    5).	Consideram ultrapassado o modelo da S.I.:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_5_ucp"
                        checked={record?.ii_2_1_3_5_ucp === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_5_upa"
                        checked={record?.ii_2_1_3_5_upa === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_5_ump"
                        checked={record?.ii_2_1_3_5_ump === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_5_saf"
                        checked={record?.ii_2_1_3_5_saf === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_5_uph"
                        checked={record?.ii_2_1_3_5_uph === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid> 
                <Grid item xs={3}></Grid>



                <Grid item xs={4}>
                    6). Filosofia ministerial da Igreja não comporta S.I.:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_6_ucp"
                        checked={record?.ii_2_1_3_6_ucp === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_6_upa"
                        checked={record?.ii_2_1_3_6_upa === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_6_ump"
                        checked={record?.ii_2_1_3_6_ump === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_6_saf"
                        checked={record?.ii_2_1_3_6_saf === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label=""
                        name="ii_2_1_3_6_uph"
                        checked={record?.ii_2_1_3_6_uph === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid> 
                <Grid item xs={3}></Grid>


                <Grid item xs={4}>
                    7). Outra justificativa:
                </Grid> 
                <Grid item xs={8}>
                    <TextField fullWidth disabled={isDisabled} label="" name="ii_2_1_3_7_outra" value={record?.ii_2_1_3_7_outra || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>


                <Grid item xs={12}>
                    <b>2.2. Evangelização e Missões</b>
                </Grid>

                <Grid item xs={4}>
                    <TextField fullWidth disabled={isDisabled} label="2.2.1. Nº de atividades  evangelísticas:*" type="number" name="ii_2_2_1" value={record?.ii_2_2_1 || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={8}>
                    <Typography className={classes.textInfo}>(* Em templos e residências; recintos públicos e ao ar livre; pela imprensa escrita, rádio e TV etc.)</Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography>
                        2.2.2.Textos distribuídos:
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <TextField fullWidth disabled={isDisabled} label="Bíblias" type="number" name="ii_2_2_2_biblias" value={record?.ii_2_2_2_biblias || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth disabled={isDisabled} label="Novos Testamentos" type="number" name="ii_2_2_2_novos_testamentos" value={record?.ii_2_2_2_novos_testamentos || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth disabled={isDisabled} label="Folhetos" type="number" name="ii_2_2_2_folhetos" value={record?.ii_2_2_2_folhetos || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth disabled={isDisabled} label="Outras Literaturas" type="number" name="ii_2_2_2_outras_literaturas" value={record?.ii_2_2_2_outras_literaturas || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={1}>
                    <TextField fullWidth disabled={isDisabled} label="Total Geral:" type="number"  name="ii_2_2_2_total_geral" value={record?.ii_2_2_2_total_geral || ''} onChange={onChangeField} onBlur={onSetField}/>
                </Grid>

                <Grid item xs={4}>
                    <Typography>
                        2.2.3.Trabalho missionário com:
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="JMN"
                        name="ii_2_2_3_jmn"
                        checked={record?.ii_2_2_3_jmn === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid> 
                <Grid item xs={2}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="APMT"
                        name="ii_2_2_3_apmt"
                        checked={record?.ii_2_2_3_apmt === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid> 
                <Grid item xs={2}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Parceria com o PMC"
                        name="ii_2_2_3_pmc"
                        checked={record?.ii_2_2_3_pmc === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Plantação de Igrejas"
                        name="ii_2_2_3_plantacao_igrejas"
                        checked={record?.ii_2_2_3_plantacao_igrejas === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>

                <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} label="2.2.4.Outra participação missionária:" name="ii_2_2_4" value={record?.ii_2_2_4 || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>


                
                <Grid item xs={12}>
                    <b>2.3. Educação e Aperfeiçoamento</b>
                </Grid>
                
                <Grid item xs={3}>
                    2.3.1. Para professores de Escola Dominical:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_2_3_1_sim"
                        checked={record?.ii_2_3_1_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_2_3_1_nao"
                        checked={record?.ii_2_3_1_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth disabled={isDisabled} label="2.3.5. Nº de Grupos Corais:" name="ii_2_3_5" value={record?.ii_2_3_5 || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>

                <Grid item xs={12} className={classes.marginPrint100} ></Grid>
                <Grid item xs={3}>
                    2.3.2. Para Equipe de Música:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_2_3_2_sim"
                        checked={record?.ii_2_3_2_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_2_3_2_nao"
                        checked={record?.ii_2_3_2_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth disabled={isDisabled} label="2.3.6. Nº de Conjuntos Musicais:" name="ii_2_3_6" value={record?.ii_2_3_6 || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>

                <Grid item xs={3}>
                    2.3.3. Para Oficiais (Presbíteros e Diáconos)
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_2_3_3_sim"
                        checked={record?.ii_2_3_3_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_2_3_3_nao"
                        checked={record?.ii_2_3_3_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={6}>
                </Grid>

                <Grid item xs={3}>
                    2.3.4. Para Liderança:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_2_3_4_sim"
                        checked={record?.ii_2_3_4_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_2_3_4_nao"
                        checked={record?.ii_2_3_4_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={6}>
                </Grid>
                



                <Grid item xs={12}>
                    <b>2.4. Ação Social e Visitação</b>
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth disabled={isDisabled} label="2.4.1.Nº de atos beneficentes realizados pela Junta Diaconal" type="number"  name="ii_2_4_1" value={record?.ii_2_4_1 || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth disabled={isDisabled} label="Por outros departamentos" type="number" name="ii_2_4_1_outros" value={record?.ii_2_4_1_outros || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>
                <Grid item xs={3}>
                    { Number(record?.ii_2_4_1 || 0) + Number(record?.ii_2_4_1_outros || 0) }
                </Grid>

                <Grid item xs={6}>
                    <TextField fullWidth disabled={isDisabled} label="2.4.2.Nº de visitas feitas por presbíteros e diáconos" type="number" name="ii_2_4_2" value={record?.ii_2_4_2 || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth disabled={isDisabled} label="Por outros departamentos" type="number" name="ii_2_4_2_outros" value={record?.ii_2_4_2_outros || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>
                <Grid item xs={3}>
                    { Number(record?.ii_2_4_2 || 0) + Number(record?.ii_2_4_2_outros || 0) }
                </Grid>


                <Grid item xs={12} className={classes.subSectionTitle}>
                    3. SUPERVISÃO ADMINISTRATIVA
				</Grid>

                <Grid item xs={6}>
                    3.1. A Igreja enviou fielmente os Dízimos dos Dízimos à Tesouraria IPB
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_3_1_sim"
                        checked={record?.ii_3_1_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_3_1_nao"
                        checked={record?.ii_3_1_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={4}>
                </Grid>

                <Grid item xs={12}>
                    <b>Reuniões:</b>
                </Grid>


                <Grid item xs={3}>
                    3.2. Conselho:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Igreja"
                        name="ii_3_2_igreja"
                        checked={record?.ii_3_2_igreja === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Congregação Presbiterial"
                        name="ii_3_2_congregacao"
                        checked={record?.ii_3_2_congregacao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Ambas"
                        name="ii_3_2_ambas"
                        checked={record?.ii_3_2_ambas === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={3}></Grid>

                <Grid item xs={3}>
                    3.4. Assembléia Geral:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Igreja"
                        name="ii_3_4_igreja"
                        checked={record?.ii_3_4_igreja === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Congregação Presbiterial"
                        name="ii_3_4_congregacao"
                        checked={record?.ii_3_4_congregacao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Ambas"
                        name="ii_3_4_ambas"
                        checked={record?.ii_3_4_ambas === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={2}></Grid>

                <Grid item xs={3}>
                    3.5. Mesa Administrativa da Congregação Presbiterial:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Igreja"
                        name="ii_3_5_igreja"
                        checked={record?.ii_3_5_igreja === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Congregação Presbiterial"
                        name="ii_3_5_congregacao"
                        checked={record?.ii_3_5_congregacao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Ambas"
                        name="ii_3_5_ambas"
                        checked={record?.ii_3_5_ambas === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={3}></Grid>

                <Grid item xs={3}>
                    3.6. Comissão de Exame de Contas da Tesouraria:
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Igreja"
                        name="ii_3_6_igreja"
                        checked={record?.ii_3_6_igreja === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Congregação Presbiterial"
                        name="ii_3_6_congregacao"
                        checked={record?.ii_3_6_congregacao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Ambas"
                        name="ii_3_6_ambas"
                        checked={record?.ii_3_6_ambas === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={3}></Grid>  



                <Grid item xs={4}>
                    3.7. Houve exame/aprovação Balancetes da Tesouraria?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_3_7_sim"
                        checked={record?.ii_3_7_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_3_7_nao"
                        checked={record?.ii_3_7_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={4}>
                    3.8. Há oficiais com mandato a vencer no ano a seguir?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_3_8_sim"
                        checked={record?.ii_3_8_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_3_8_nao"
                        checked={record?.ii_3_8_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>

                <Grid item xs={4}>
                    3.9. Idem, dos Livros e Relatórios das sociedades?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_3_9_sim"
                        checked={record?.ii_3_9_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_3_9_nao"
                        checked={record?.ii_3_9_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth disabled={isDisabled} label="Presbíteros: nº" type="number" name="ii_3_num_presbiteros" value={record?.ii_3_num_presbiteros || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>
                <Grid item xs={3}>
                    <TextField fullWidth disabled={isDisabled} label="Diáconos: nº" type="number"name="ii_3_num_diaconos" value={record?.ii_3_num_diaconos || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>

                <Grid item xs={4}>
                    3.10. Houve nomeação de Conselheiros às Sociedades?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_3_10_sim"
                        checked={record?.ii_3_10_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_3_10_nao"
                        checked={record?.ii_3_10_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth disabled={isDisabled} label="Quais?" name="ii_3_10_quais" value={record?.ii_3_10_quais || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>

                <Grid item xs={4}>
                    3.11. Houve contribuição para Causas extra-locais?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_3_11_sim"
                        checked={record?.ii_3_11_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_3_11_nao"
                        checked={record?.ii_3_11_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={6}>
                </Grid>

                <Grid item xs={4}>
                    3.12. Contribuiu com Previdência Social do(s) Pastor(es)?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_3_12_sim"
                        checked={record?.ii_3_12_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_3_12_nao"
                        checked={record?.ii_3_12_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <Typography>FAP?</Typography>
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_3_12_fap_sim"
                        checked={record?.ii_3_12_fap_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_3_12_fap_nao"
                        checked={record?.ii_3_12_fap_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <Typography>IPB-PREV?</Typography>
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="ii_3_12_ipbprev_sim"
                        checked={record?.ii_3_12_ipbprev_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="ii_3_12_ipbprev_nao"
                        checked={record?.ii_3_12_ipbprev_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField fullWidth disabled={isDisabled} label="3.13. Reforma e/ou Construção em projeto:" name="ii_3_13" value={record?.ii_3_13 || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth disabled={isDisabled} label="3.14. Reforma e/ou Construção em andamento:" name="ii_3_14" value={record?.ii_3_14 || ''} onChange={onChangeField} onBlur={onSetField}  />
                </Grid>


                <Grid item xs={12} className={classes.subSectionTitle}>
                    4. PLANEJAMENTO ESTRATÉGICO
				</Grid>

                <Grid item xs={6}>
                    4.1 A Igreja tem Planejamento Estratégico? 
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="iv_4_1_sim"
                        checked={record?.iv_4_1_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="iv_4_1_nao"
                        checked={record?.iv_4_1_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={4}>
                </Grid>

                <Grid item xs={12}>
                    4.2. Quais os objetivos propostos e alcançados?
                </Grid>
                <Grid xs={12}>
                    <TextareaAutosize disabled={isDisabled} rowsMin={8}  style={{width: '100%'}} name="iv_4_2" value={record?.iv_4_2 || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>

                <Grid item xs={12}>
                    4.3. Quais os objetivos propostos e NÃO alcançados? Identificar as dificuldades.
                </Grid>
                <Grid xs={12}>
                    <TextareaAutosize disabled={isDisabled} rowsMin={8}  style={{width: '100%'}} name="iv_4_3" value={record?.iv_4_3 || ''} onChange={onChangeField} onBlur={onSetField} />
                </Grid>




                <Grid item xs={12} className={classes.subSectionTitle}>
                    5. PATRIMÔNIO:
				</Grid>

                <Grid item xs={4}>
                    5.1. A Igreja tem Seguro do bem patrimonial?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="v_5_1_sim"
                        checked={record?.v_5_1_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="v_5_1_nao"
                        checked={record?.v_5_1_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>

                <Grid item xs={4}>
                    5.3. Licença do Corpo de Bombeiros em dia?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="v_5_3_sim"
                        checked={record?.v_5_3_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="v_5_3_nao"
                        checked={record?.v_5_3_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>


                <Grid item xs={4}>
                    5.2. Tem Alvará de Funcionamento?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="v_5_2_sim"
                        checked={record?.v_5_2_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="v_5_2_nao"
                        checked={record?.v_5_2_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>



                <Grid item xs={4}>
                    5.4. Tem Certificado Digital?
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Sim"
                        name="v_5_4_sim"
                        checked={record?.v_5_4_sim === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox disabled={isDisabled}/>}
                        label="Não"
                        name="v_5_4_nao"
                        checked={record?.v_5_4_nao === 'true' || false} onChange={onChangeCheckField} 
                    />
                </Grid>



                <div className={classes.verticalSpace}></div>

			</Grid>





			
		</Container>
	);
}

export default ReportConselho;