import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, TextField } from '@material-ui/core';
import { AttachFile, AttachFileOutlined } from '@material-ui/icons';

const UploadDocument = ({...props}) => {
  const [fileName, setFileName] = useState('')
    // const [mutate, { loading, error }] = useMutation(SINGLE_UPLOAD);
    const onChange = ({
      target: {
        validity,
        files: [file]
      }
    }: any) => {
      if(validity.valid) {
        setFileName(file.name);
        props.onChange(file);
      }
    };
  
    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>{JSON.stringify(error, null, 2)}</div>;
  
    return (
        <>
          <input
            color="primary"
            accept="application/pdf"
            type="file"
            onChange={onChange}
            id="document-file"
            style={{ display: 'none', }}
          />
          <label htmlFor="document-file">
            <Button
              variant="contained"
              component="span"
              // className={classes.button}
              size="large"
              color={fileName ? "primary" : 'secondary'}
              startIcon={fileName ? <AttachFileOutlined/> : <AttachFile />}
            >
              {fileName ? fileName : 'Selecione um arquivo .pdf'}
            </Button>
          </label>
        </>
    );
  };

export default UploadDocument;