import React from 'react';
import Atalhos from './Atalhos';

function AtalhosDashboard() {

    return (
        <Atalhos />
    );
}

export default AtalhosDashboard;