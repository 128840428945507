import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

const QUERY_REVERENDOS = gql`
  query GET_REVERENDOS($subStep: String!) {
    getAllReverendos {
      id
      name
    }
    getListCall(subStep: $subStep) {
      id
      presence
      user {
        id
      }
    }
  }
`;

const QUERY_CHURCHES = gql`
  query GET_CHURCHES($subStep: String!) {
    getAllChurchesToCall {
      id
      name
      meetingAgent {
        id
        name
      }
    }
    getListCall(subStep: $subStep) {
      id
      presence
      user {
        id
      }
      church {
        id
      }
    }
  }
`;

const MUTATION_CALL_REVERENDO = gql`
  mutation CallReverendo($user: String!, $subStep: String!, $presence: Boolean!) {
    callReverendo(user: $user, subStep: $subStep, presence: $presence) {
      id
      presence
    }
  }
`;

const MUTATION_CALL_CHURCHES = gql`
  mutation CallChurches($church: String!, $subStep: String!, $presence: Boolean!) {
    callChurch(church: $church, subStep: $subStep, presence: $presence) {
      id
      presence
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 999,
      color: '#fff',
    },
  }),
);

export default function Chamada({...props}) {
  const classes = useStyles();
  const [ getReverendos, { loading: loadingReverendos, error: errorReverendos , data: dataReverendos, refetch: refetchReverendos }] = useLazyQuery(QUERY_REVERENDOS);
  const [ getChurches, { loading: loadingChurches, error: errorChurches , data: dataChurches, refetch: refetchChurches }] = useLazyQuery(QUERY_CHURCHES);
  const [ callReverendo, { data: dataCreate, loading: loadingCreate, error: errorCreate }] = useMutation(MUTATION_CALL_REVERENDO, {
    onCompleted: () => refetchReverendos && refetchReverendos({variables: {
      subStep: props?.subStep?.id
    }})
  });
  const [ callChurches, { data: dataCallChurch, loading: loadingCallChurch, error: errorCallChurch }] = useMutation(MUTATION_CALL_CHURCHES, {
    onCompleted: () => refetchChurches && refetchChurches({variables: {
      subStep: props?.subStep?.id
    }})
  });

  useEffect(() => {
    const options = {variables: {subStep: props?.subStep?.id}};
    props.type == 'churches' ? getChurches(options) :  getReverendos(options);
  }, [props.type])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
  };

  if(errorReverendos) {
    return <div> Ocorreu algum erro ao carregar a lista... </div>
  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{props.title}</FormLabel>
        <FormGroup>
          {
            ((!(dataReverendos?.getAllReverendos.length) && !(dataChurches?.getAllChurchesToCall.length)) || loadingReverendos || loadingCreate || loadingCallChurch || loadingChurches) ? (<Backdrop className={classes.backdrop} open={true}> <CircularProgress color="inherit" /> </Backdrop>) : (null)
          }
          {
            ((props.type == 'churches' ? dataChurches?.getAllChurchesToCall : dataReverendos?.getAllReverendos) || []).map((item: any, idx: number) => (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    checked={
                      props.type == 'churches' 
                        ? (
                          !!(dataChurches?.getListCall?.filter((callItem: any) => {
                            return callItem?.church?.id == item.id && callItem?.presence == true;
                          })).length
                        ) : (
                          !!(dataReverendos?.getListCall?.filter((callItem: any) => {
                            return callItem?.user?.id == item.id && callItem?.presence == true;
                          })).length
                        )
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      props.type == 'churches'
                      ? callChurches({
                          variables: {
                            church: item.id,
                            subStep: props.subStep?.id,
                            presence: e.target.checked
                          }
                        })
                      : callReverendo({
                          variables: {
                            user: item.id,
                            subStep: props.subStep?.id,
                            presence: e.target.checked
                          }
                        })
                    }}
                    name="item.id" 
                  />
                }
                label={props.type == 'churches' ? `${item.name} (${item.meetingAgent?.name || 'Representante não informado'})` : item.name}
                color="primary"
              />
            ))
          }
        </FormGroup>
        <FormHelperText>Usuários não marcados serão mostrados como ausentes nas atas.</FormHelperText>
        <Typography variant="caption">
            Art.86 - Três ministros e dois presbíteros constituirão o quorum para o funcionamento legal do Presbitério.
        </Typography>
      </FormControl>
    </div>
  );
}