import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar as ToolbarCmp,
    IconButton,
    Typography,
    Badge,
    Avatar,
    Box,
    Button,
    Popper,
    MenuItem,
    Grow,
    Paper,
    MenuList,
    ClickAwayListener
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    Notifications as NotificationsIcon
} from '@material-ui/icons';
import styles from './styles';
import { getMe } from '../../services/auth';
import * as appAction from '../../store/ducks/Main/actions';

const avatarImg = require('../../assets/users/default-user.png');

function Toolbar() {
    const [open, setOpen] = useState(false);
    const drawerOpen = useSelector(state => state.app.drawerOpen);
    // const currentMenu = useSelector(state => state.app.currentMenu);
    const dispatch = useDispatch();
    const classes = styles();
    const anchorRef = useRef(null);

    const me = getMe();
    console.log('me1-->', me);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <AppBar
            className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
            position="absolute"
        >
            <ToolbarCmp className={classes.toolbar}>
                <div>
                    <IconButton>
                        <Avatar
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            className={classes.avatar} 
                            src={me.avatar || avatarImg}
                        />
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <Link to="/app/perfil" onClick={() => { handleClose(); dispatch(appAction.goTo({
                                            title: 'Meu perfil',
                                            level: 0,
                                            parent: null,
                                            route: '/perfil',
                                        })); }}>
                                            <MenuItem>Meu Perfil</MenuItem>
                                        </Link>
                                        <Link to="/app/minhaIgreja" onClick={() => { handleClose(); dispatch(appAction.goTo({
                                            title: 'Minha Igreja',
                                            level: 0,
                                            parent: null,
                                            route: '/minhaIgreja',
                                        })); }}>
                                            <MenuItem onClick={handleClose}>Minha Igreja</MenuItem>
                                        </Link>
                                        <Link to="/" onClick={() => { handleClose(); }}>
                                            <MenuItem onClick={handleClose}>Sair</MenuItem>
                                        </Link>
                                    </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                                </Grow>
                            )}
                            </Popper>
                        </IconButton>
                </div>
                <Box className={classes.boxUser}>
                    <Typography component="h5" align="center">
                        <b>{ me.name }</b>
                    </Typography>
                    <Typography component="h6" align="center">
                        <b>{ me.role.includes('master') ? 'PRIM' : me.church?.name }</b>
                    </Typography>
                </Box>
            </ToolbarCmp>
        </AppBar>
    );
}

export default Toolbar;