import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import DraftsIcon from '@material-ui/icons/Drafts';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import DescriptionIcon from '@material-ui/icons/Description';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import FolderIcon from '@material-ui/icons/Folder';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepSessaoRegular2 from './steps-sessao-regular-2';
import StepSessaoPreparatoria from './steps-sessao-preparatoria';
import StepVerificacaoPoderes from './steps-verificacao-poderes';
import StepSessaoRegular from './steps-sessao-regular-1';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import { Backdrop, CircularProgress, Container, Grid, Paper } from '@material-ui/core';

const QUERY_STEPS = gql`
  query steps($meeting: String!) {
    listStep(meeting: $meeting) {
      id
      name
      order
      currentSubStep
      code
      ata
      ataFields
      meeting {
        id
        name
        estatisticaNumber
        estatisticaPlenario
        estatisticaStatus
      }
    }
  }
`;

const QUERY_ACTIVE_MEETING = gql`
  query GetActiveMeeting {
    getActiveMeeting(type: "Ordinária") {
      id
      currentStep
      currentSubStep
      convocation
      estatisticaNumber
      estatisticaPlenario
      estatisticaStatus
    }
    listMeeting(filter: "Convocada") {
      id
      name
      date
      convocation
      status
      estatisticaNumber
      estatisticaPlenario
      estatisticaStatus
    }
  }
`;

const MUTATION_START_MEETING = gql`
  mutation StartMeeting($id: String!) {
    startMeeting(id: $id) {
      id
      name
      status
    }
  }
`;

const MUTATION_SET_STEP = gql`
  mutation SetCurrentStep($meetingId: String!, $stepId: String!) {
    setCurrentStep(meetingId: $meetingId, stepId: $stepId) {
      id
      name
      currentStep
      currentSubStep
    }
  }
`;

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
  icon: {
    fontSize: 12
  }
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon className={classes.icon} />,
    2: <DraftsIcon className={classes.icon} />,
    3: <HowToRegIcon className={classes.icon} />,
    4: <HowToVoteIcon className={classes.icon} />,
    5: <DescriptionIcon className={classes.icon} />,
    6: <ViewModuleIcon className={classes.icon} />,
    7: <FolderIcon className={classes.icon} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    body: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'auto'
    },
    contentBox: {
        flex: 1,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    header: {
        backgroundColor: 'rgb(247, 247, 247)',
        background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(247, 247, 247) 100%)',
        borderBottom: '1px solid #ccc',
        boxShadow: '0px 0px 5px #ccc',
        padding: 7,
        label: {
          fontSize: 12,
        },
    },
    label: {
      fontSize: '12px !important',
    },
    footer: {
        textAlign: 'center',
        padding: 5,
        backgroundColor: 'rgb(247, 247, 247)',
        background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(247, 247, 247) 100%)',
        borderTop: '1px solid #ccc',
        boxShadow: '0px 0px 5px #ccc'
    },
    cursor: {
      cursor: 'pointer',
    },
    gridItem: {
      margin: 10
    },
    paperItem: {
        padding: 10,
        cursor: 'pointer',
        background: '#efefef',
        '& h5': {
            fontSize: 16,
            fontWeight: 'bold'
        },
        '& h6': {
            fontSize: 12,
            fontWeight: 'normal',
            '& span': {
                fontWeight: 'bold'
            }
        }
    },
    gridListMeeting: {
      justifyContent: 'center'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

function getSteps() {
  return [
      'Verificação de Poderes',
      'Sessão Preparatória',
      '1ª Sessão Regular',
      '2ª Sessão Regular',
    ];
}

function getStepContent(step: number, classes: any, listStep: any, meeting: any) {
    switch (step) {
    case 0:
        return <StepVerificacaoPoderes step={listStep[0]} meeting={meeting} stepId={listStep[0].id} currentSubStep={listStep[0].currentSubStep}/>
    case 1:
        return <StepSessaoPreparatoria step={listStep[1]} stepId={listStep[1].id} currentSubStep={listStep[1].currentSubStep}/>
    case 2:
      return <StepSessaoRegular step={listStep[2]} stepId={listStep[2].id} meeting={meeting} currentSubStep={listStep[2].currentSubStep}/>
    case 3:
      return <StepSessaoRegular2 step={listStep[3]} stepId={listStep[3].id} meeting={meeting} currentSubStep={listStep[3].currentSubStep}/>
    default:
      return 'Etapa desconhecida';
  }
}

export default function CustomizedSteppers() {
  const classes = useStyles();
  const currentStep = useSelector((state: AppState)  => state.steps.data.current);
  const [activeStep, setActiveStep] = React.useState(currentStep);
  const { loading: loadingActiveMeeting, error: errorActiveMeeting, data: dataActiveMeeting, refetch: refetchAvtiveMeeting } = useQuery(QUERY_ACTIVE_MEETING);
  const { loading, error, data, refetch } = useQuery(QUERY_STEPS, { variables: { meeting: `${(dataActiveMeeting && dataActiveMeeting.getActiveMeeting) ? dataActiveMeeting.getActiveMeeting.id : ''}` } });
  const [ startMeeting, { data: dataStartMeeting, loading: loadingStartMeeting, error: errorStartMeeting }] = useMutation(MUTATION_START_MEETING);
  const [ setCurrentStep, { data: dataSetCurrentStep, loading: loadingSetCurrentStep, error: errorSetCurrentStep }] = useMutation(MUTATION_SET_STEP);
  
  
  let steps = [];
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const goTo = (idx: number) => {
    setActiveStep(idx);
    setCurrentStep({
      variables: { meetingId: dataActiveMeeting.getActiveMeeting?.id, stepId: data.listStep[idx].id }
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    refetch({ meeting: `${(dataActiveMeeting && dataActiveMeeting.getActiveMeeting) ? dataActiveMeeting.getActiveMeeting.id : ''}` });
  }, [dataActiveMeeting, refetch]);

  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    if(data && data.listStep && data.listStep?.length && dataActiveMeeting && dataActiveMeeting.getActiveMeeting?.currentStep) {
      data.listStep.map((item: any, idx: number) => {
        if(item.id == dataActiveMeeting.getActiveMeeting?.currentStep) {
          setActiveStep(idx);
        }
      });
    }
  }, [data, dataActiveMeeting]);

  const createListMeetings = () => {
		return dataActiveMeeting.listMeeting.map((item: any, idx: any) => {
			return <Grid item key={idx} className={classes.gridItem}>
				<Paper
					onClick={() => {
						startMeeting({
              variables: {
                id: item.id
              }
            }).then((res) => {
              refetchAvtiveMeeting();
            }).catch((err) => {
              console.log(JSON.stringify(err));
            });;
					}}
					className={classes.paperItem}
				>
				<Typography variant="h5">{item.name}</Typography>
				<Typography variant="h6"><span>Status:</span> {item.status}</Typography>	
				<Typography variant="h6"><span>Data:</span> {item.date}</Typography>	
			</Paper></Grid>
		});
	}

  if(loading || loadingActiveMeeting || loadingStartMeeting) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if(errorActiveMeeting) {
    return <div>Erro reunião!</div>
  }

  if(!dataActiveMeeting || !dataActiveMeeting.getActiveMeeting) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error">Não existe nenhuma reunião em andamento.</Alert>
        {
          dataActiveMeeting && dataActiveMeeting.listMeeting && dataActiveMeeting.listMeeting.length
            ? <> 
                {
                  dataActiveMeeting.listMeeting.length > 1
                    ? <p>As reuniões abaixo já foram convocadas, mas ainda não foram iniciadas. Clique nelas para iniciar.</p>
                    : <p>A reunião abaixo já foi convocada, mas ainda não foi iniciada. Clique nela para iniciar.</p>
                }
                <Grid className={classes.gridListMeeting} container>{createListMeetings()}</Grid>
              </>
            : <p>{'Vá até o menu "Ordinária -> Convocação da R.O." para iniciar.'}</p>
        }
      </Container>
    );
  }

  if(error) {
    return <div>Erro!</div>
  }

  if(data) {
    steps = data.listStep.map((item: any) => item.name)
  }

  return (
    <div className={classes.root}>
      <Stepper className={classes.header} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label: any, idx: number) => (
                <Step onClick={() => goTo(idx)} key={label} className={classes.cursor}>
                    <StepLabel onClick={() => goTo(idx)} className={clsx(classes.cursor, classes.label)}  StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
            ))}
      </Stepper>
      <div className={classes.body}>
        {activeStep === steps.length ? (
          <div className={classes.content}>
            <Typography className={classes.instructions}>
              Todas as etapas foram concluídas.
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
                Voltar para o Início
            </Button>
          </div>
        ) : (
            <div className={classes.body}>
                <div className={classes.content}>
                  {getStepContent(activeStep, classes, data.listStep, dataActiveMeeting.getActiveMeeting)}
                </div>
            </div>
        )}
      </div>
    </div>
  );
}