import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    Breadcrumbs as BreadcrumbsCmp,
    Link, useTheme,
    useMediaQuery,
    Button,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import styles from './styles';
import { toggleDrawer } from '../../store/ducks/Main/actions';

const Breadcrumbs = function() {
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = styles();
    const breadcrumbs = useSelector(state => state.app.breadcrumbs);
    const dispatch = useDispatch();

    return breadcrumbs.length > 1 || matchesSm ? (
        <nav className={classes.breadcrumbs}>
            <BreadcrumbsCmp separator="›" aria-label="breadcrumb">
                {breadcrumbs.map((item, idx) => {
                    if(breadcrumbs.length == idx+1) {
                        return <Typography key={idx} color="textPrimary">{item.text}</Typography>
                    }

                    return (
                        <Link key={idx} color="inherit" href={item.href} onClick={() => {}}>
                            {item.text}
                        </Link>
                    );
                })}
            </BreadcrumbsCmp>
            {
                matchesSm ? (
                    <div className={classes.menuBtn}>
                        <Button startIcon={<MenuIcon />} onClick={() => dispatch(toggleDrawer())}>MENU</Button>
                    </div>
                ) : (null)
            }
        </nav>
    ) : <nav className={classes.breadcrumbs}></nav>;
}

export default Breadcrumbs;