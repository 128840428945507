import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import DraftsIcon from '@material-ui/icons/Drafts';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import GroupIcon from '@material-ui/icons/Group';
import PlayListCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import FolderIcon from '@material-ui/icons/Folder';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { StepIconProps } from '@material-ui/core/StepIcon';
import Chamada from './components/chamada';
import { useDispatch } from 'react-redux';
import { nextStep } from '../../store/ducks/Steps/actions';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import {
    QontoConnector,
    useQontoStepIconStyles,
    ColorlibConnector,
    useColorlibStepIconStyles,
    useStyles,
  } from './step-styles';
import Oracao from './components/oracao';
import ComposicaoMesa from './components/composicao-mesa';
import ExercicioEspiritual from './components/exercicio-espiritual';
import EntradaDocumento from './components/entrada-documento';
import Plenario from './components/plenario';
import PlenarioAprovacao from './components/plenario-aprovacao';
import Encerramento from './components/encerramento';

import { gql, useMutation, useQuery } from '@apollo/client';
import GenericFields from './components/generic-fields';
import { FindInPage, HowToVote, NavigateBefore, NavigateNext, OpenInBrowser } from '@material-ui/icons';
import Eleicao from './components/eleicao';

import MyBackdrop from '../../components/Backdrop';
import { TransitionProps } from '@material-ui/core/transitions';
import HtmlView from './components/htmlView';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide } from '@material-ui/core';
import LeituraAtas from './components/leitura-atas';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const QUERY_SUB_STEPS = gql`
  query SubStep($step: String!) {
    listSubStep(step: $step) {
      id
      name
      order
    }
  }
`;

const MUTATION_SET_SUB_STEP = gql`
  mutation SetCurrentSubStep($stepId: String!, $subStepId: String!) {
    setCurrentSubStep(stepId: $stepId, subStepId: $subStepId) {
      id
      name
      currentSubStep
    }
  }
`;

const MUTATION_COMPILE_ATA = gql`
  mutation CompileAta($step: String!) {
    compileAta(step: $step) {
      id
      name
      ata
      ataCompiled
      ataStatus
    }
  }
`;

const MUTTION_FINALIZE_MEETING = gql`
  mutation FinalizeMeeting($id: String!) {
    finalizeMeeting(id: $id) {
      id
    }
  }
`;

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupIcon />,
    3: <HowToRegIcon />,
    4: <PlayListCheckIcon />,
    5: <DescriptionIcon />,
    6: <FolderIcon />,
    7: <ViewModuleIcon />,
    8: <SpellcheckIcon />,
    9: <HowToVoteIcon />,
    10: <HowToVoteIcon />,
    11: <CheckIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function getStepContent(stepIdx: number, step: any, classes: any, subSteps?: any, meeting?: any) {
    switch (stepIdx) {
    case 0:
      return <GenericFields subStepId={subSteps[0].id} />;
    case 1:
      return <GenericFields subStepId={subSteps[1].id} />;
    case 2:
      return (<Chamada subStep={subSteps[2]} title="Marque as pessoas que estão presente." />);
    case 3:
      return (<Chamada subStep={subSteps[3]} type="churches" title="Marque as igrejas com representantes." />);
    case 4:
      return <GenericFields subStepId={subSteps[4].id} />;
    case 5:
      return <EntradaDocumento step={step} meeting={meeting} />;
    case 6:
      return <Plenario step={step} meeting={meeting} subStep={subSteps[stepIdx]} title="Trabalho e comissões" />;
    case 7:
      return <PlenarioAprovacao step={step} meeting={meeting} subStep={subSteps[stepIdx]}  />
    case 8:
      return <Eleicao subStepId={subSteps[stepIdx].id} electionType="tesoureiro" />;
    case 9:
      return <Eleicao subStepId={subSteps[stepIdx].id} electionType="sec" />;
    case 10:
      return <GenericFields subStepId={subSteps[stepIdx].id} />;
    default:
      return 'Etapa desconhecida';
  }
}

export default function StepSessaoRegular2({...props}) {
    const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(props.currentSubStep || 0);
  const [ setCurrentSubStep, { data: dataSetCurrentSubStep, loading: loadingSetCurrentSubStep, error: errorSetCurrentSubStep }] = useMutation(MUTATION_SET_SUB_STEP);
  const [ doFinalizeMeeting, { data: dataFinalizeMeeting, loading: loadingFinalizeMeeting }] = useMutation(MUTTION_FINALIZE_MEETING, {
    onCompleted: () => {
      
    }
  });
  const [showAta, setShowAta] = useState(false);
  const [ compileAta, { data: dataCompileAta, loading: loadingCompileAta, error: errorCompileAta }] = useMutation(MUTATION_COMPILE_ATA, {
    onCompleted: () => {
      setShowAta(true)
    }
  });
  let steps = [];
  const { loading, error, data } = useQuery(QUERY_SUB_STEPS, {
    variables: { step: props.stepId },
  });

  const handleNext = (last: boolean = false) => {
    if(last) {
      doFinalizeMeeting({ variables: { id: props.meeting.id } });
    }
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
  };

  const handleReset = () => {
    dispatch(nextStep(0));
  };

  const goTo = (idx: number) => {
    setActiveStep(idx);
    setCurrentSubStep({
      variables: {stepId: props.stepId, subStepId: data.listSubStep[idx]?.id}
    });
  };

  // useEffect(() => {
  //   if((activeStep || activeStep == 0 ) && data && data.listSubStep && data.listSubStep.length && data.listSubStep[activeStep]) {
  //     setCurrentSubStep({
  //       variables: {stepId: props.stepId, subStepId: data.listSubStep[activeStep].id}
  //     });
  //   }
  // }, [activeStep, data, props.stepId, setCurrentSubStep]);

  useEffect(() => {
    if(data && data.listSubStep && data.listSubStep?.length && props.currentSubStep) {
      data.listSubStep.map((item: any, idx: number) => {
        if(item.id == props.currentSubStep) {
          setActiveStep(idx);
        }
      });
    }
  }, [data, props.currentSubStep]);

  const onCompileAta = () => {
    compileAta({
      variables: { step: props.stepId }
    })
  }

  const buildEditor = (value: string) => {
    return <HtmlView value={value} disabled />
  }

  if(loading) {
    return <div>carregando...</div>
  }

  if(error) {
    return <div>Erro!</div>
  }

  if(data) {
    steps = data.listSubStep.map((item: any) => item.name)
  }

  return (
    <div className={classes.root}>
      {
        (loadingCompileAta) ? <MyBackdrop message="Carregando a ATA" open={true} /> : (null)
      }
      <Stepper className={classes.header} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label: any, idx: any) => (
                <Step onClick={() => goTo(idx)} key={label}>
                    <StepLabel onClick={() => goTo(idx)} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
            ))}
      </Stepper>
      <div className={classes.body}>
        {activeStep === steps.length ? (
          <div className={classes.content}>
            <Typography className={classes.instructions}>
              A etapa da 2ª Sessão Regular foi finalizada.
            </Typography>
            <LeituraAtas meeting={props.meeting} title="Ata da 2ª sessão regular" type={'sessao_regular_2'}/>
          </div>
        ) : (
            <div className={classes.body}>
                <div className={classes.content1}>
                  {getStepContent(activeStep, props.step, classes, data.listSubStep, props.meeting)}
                </div>
                <div>
                    <div className={classes.footer}>
                      <Grid container>
                          <Grid item xs={3} style={{textAlign: 'left'}}>
                          <a style={{textDecoration: 'none'}} href="https://storage.googleapis.com/concilio-app.appspot.com/documentos/Manual-Presbiteriano.pdf" target="_blank" rel="noopener noreferrer">
                              <Button startIcon={<OpenInBrowser/>}>
                                Abrir constituição
                              </Button>
                            </a>
                          </Grid>
                          <Grid item xs={6}>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button} startIcon={<NavigateBefore/>}>
                                Anterior
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext((activeStep === steps.length - 1))}
                                className={classes.button}
                                endIcon={<NavigateNext/>}
                            >
                                {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                            </Button>
                          </Grid>
                          <Grid item xs={3} style={{textAlign: 'right'}}>
                            <Button endIcon={<FindInPage />} onClick={() => onCompileAta()}>
                              Visualizar a ATA
                            </Button>
                          </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )}
      </div>
      {
        (showAta)
          ? (
            <Dialog
              open={showAta}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setShowAta(false)}
              maxWidth="lg"
              fullWidth
              className={classes.htmlView}
            >
              <DialogTitle id="alert-dialog-slide-title">
                Ata da 2ª Sessão Regular
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText>
                  {buildEditor(dataCompileAta?.compileAta?.ataCompiled || dataCompileAta?.compileAta?.ata)}
                </DialogContentText>
              </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowAta(false)} color="primary">
                Fechar
              </Button>
            </DialogActions>
            </Dialog>
          ) : (null)
      }
    </div>
  );
}