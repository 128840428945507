import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            left: 0,
            right: 0,
        },
        padding: '5px 15px',
        backgroundColor: '#eee',
        zIndex: 999,
        minHeight: 20,
        '& li *': {
            fontSize: 12,
        },
        '& > * + *': {
            marginTop: theme.spacing(2),
        }
    },
    menuBtn: {
        position: 'fixed',
        right: 0,
        top: 0,
        marginTop: 0,
        marginRight: 5,
        '& button': {
            margin: 0,
            padding: '3px 6px'
        }
    }
}));

export default useStyles;