import React from "react";
import {
    Switch,
    Route,
    useRouteMatch
} from 'react-router-dom';
import {
    CssBaseline,
    Typography,
    Grid,
    Paper,
    Container,
    Avatar,
    Button,
    Zoom,
    Slide,
    Grow,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import {
    Person as PersonIcon,
    Folder as FolderIcon,
    Group as GroupIcon,
    Help as HelpIcon
} from '@material-ui/icons';
import clsx from 'clsx';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styles from './styles';
import Menu from '../../components/Menu';
import Breadcrumbs from '../../components/Breadcrumbs';
import Toolbar from '../../components/Toolbar';
import Ordinaria from "../Ordinaria";
import Lottie from 'react-lottie';
import animation from '../../assets/lottie_home.json';
import homeIcon from '../../assets/icons/home.json';
import Igrejas from "../Igrejas";
import Users from "../Users";
import Profile from "../Users/profile";
import MyChurch from "../Users/myChurch";

const logoBranco = require('../../assets/logo_branca_imbituva.png');

function Dashboard() {
    const classes = styles();
    const { width } = useWindowDimensions();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
    let match = useRouteMatch();


    return (
        <div className={classes.root}>
            <CssBaseline/>
            {/* <Toolbar/> */}
            <Menu />
            <main className={classes.main}>
                <Breadcrumbs />
                <div className={matchesSm ? classes.contentBgMobile : classes.contentBg}>
                    <Switch>
                        <Route path={`${match.path}/igrejas`}>
                            <div className={matchesSm ? classes.contentCleanMobile : classes.contentClean}>
                                <Igrejas />
                            </div>
                        </Route>
                        <Route path={`${match.path}/usuarios`}>
                            <div className={matchesSm ? classes.contentCleanMobile : classes.contentClean}>
                                <Users />
                            </div>
                        </Route>
                        <Route path={`${match.path}/perfil`}>
                            <div className={matchesSm ? classes.contentCleanMobile : classes.contentClean}>
                                <Profile />
                            </div>
                        </Route>
                        <Route path={`${match.path}/minhaIgreja`}>
                            <div className={matchesSm ? classes.contentCleanMobile : classes.contentClean}>
                                <MyChurch />
                            </div>
                        </Route>
                        <Route path={`${match.path}/ordinaria`}>
                            <div className={matchesSm ? classes.contentCleanMobile : classes.contentClean}>
                                <Ordinaria />
                            </div>
                        </Route>
                        <Route path={match.path}>
                            <div className={matchesSm ? classes.contentCleanMobile : classes.contentCleanLogo}>
                                <Grid container spacing={0} style={{padding: 40}}>
                                    <Grid item xs={matchesSm ? 12 : 8} style={{textAlign: 'right'}}>
                                    </Grid>
                                    <Grid item xs={matchesSm ? 12 : 4} style={{textAlign: 'center', fontFamily: 'cursive', fontSize:40, color: '#fff', textShadow: 'rgb(150 145 145) 1px 1px 1px', fontWeight: 'bold'}}>
                                        <Grow in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 2000 } : {})}>
                                            <div><img src={logoBranco} width={300} alt="Presbitério de Imbituva"/></div>
                                        </Grow>
                                        <div>
                                            <Grow in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}><span>B</span></Grow>
                                            <Grow in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 2000 } : {})}><span>E</span></Grow>
                                            <Grow in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 3000 } : {})}><span>M </span></Grow>
                                            <Grow in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 4000 } : {})}><span>V</span></Grow>
                                            <Grow in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 5000 } : {})}><span>I</span></Grow>
                                            <Grow in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 6000 } : {})}><span>N</span></Grow>
                                            <Grow in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 7000 } : {})}><span>D</span></Grow>
                                            <Grow in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 8000 } : {})}><span>O</span></Grow>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Route>
                    </Switch>
                </div>
            </main>
        </div>
    );
}

export default Dashboard;