import { gql, useQuery } from '@apollo/client';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { InsertDriveFile, Clear as RemoveIcon, PictureAsPdf } from '@material-ui/icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MyBackdrop from 'src/components/Backdrop';
import EntradaDocumento from '../../components/entrada-documento';
import HtmlView from '../../components/htmlView';
import useStyles from './styles';

const QUERY_DOCUMENTS = gql`
  query ListDocumentsAll($meeting: String!) {
    listDocuments(meeting: $meeting, all: "entrada-docs") {
      id
      title
      text
      number
      report
      status
      createdAt
	  inputStep
	  approvedStep
	  type
      user {
        id
        name
	  }
	  commission {
		  id
		  name
	  }
	}
	listReports(meeting: $meeting) {
		id
		name
		type
		number
		text
		inputStep
	  	approvedStep
		church {
			id
			name
		}
		commission {
			id
			name
		}
	}
	listCommissions(meeting: $meeting) {
		id
		name
	},
	compileReportTable(meeting: $meeting, type: "ministro") {
		title
		type
		table
	}
  }
`;

function Documentos({...props}) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { loading: loadingDocuments, error: errorDocuments , data: dataDocuments } = useQuery(QUERY_DOCUMENTS, { variables: { meeting: props.meeting?.id }});

    const buildEditor = (value: string, footer?: string, header?: string) => {
        return <HtmlView value={value} footer={footer} header={header} disabled />
    }

    if(errorDocuments) {
        return <div>Ocorreu algum erro ao carregar os ducumentos.</div>
    }

    if(loadingDocuments) {
        return <div>Carregando documentos...</div>
    }
    

    return (
        <Container maxWidth="xl">
            <EntradaDocumento step={props.step} meeting={props.meeting} />
        </Container>
    );
}

export default Documentos;