import React, {useState, useRef} from 'react';
import { Editor } from '@tinymce/tinymce-react';


const TextEditor = ({...props}) => {
    // const editor = useRef(null)
    const componentRef: any = useRef();
    const [content, setContent] = useState('');
    const [currentValue, setCurrentValue] = useState<string>(props.initialValue);

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }
    console.log('----------> EDITOR RENDERIZADO <---------');
    return (
        <Editor
            ref={componentRef} 
            apiKey="qm93a283zhyqhzhvof0vm0oxualwlxt4ldsv4j3n7lf85i8d"
            initialValue={currentValue}
            // value={currentValue}
            onChange={(e) => { console.log('onChangeInterno', e); setCurrentValue(e.target.getContent()); props.onChange(e); }}
            onInit={function(ed: any) {
                var header = `<header class="tiny-header" contenteditable="false">${props.header || ''}</header>`,
                footer = `<footer>${props.footer ? '<div class="footer-divider"></div>' : ''} ${props.footer || ''}</footer>`,
                content = ed.target.getContent();

                if(props.disabled) {
                    ed.target.contentDocument.body.contentEditable = false;
                }
                if(props.zoomOut) {
                    ed.target.contentDocument.body.style.minHeight = '800px';
                    ed.target.contentDocument.body.style.zoom = 0.8;
                }
                
                content = header + '<p class="main-content-document">' + content + '</p>' + footer;
                
                ed.target.setContent(content);

                const main: any = ed.target.getBody().getElementsByClassName('main-content-document')[0];
                main && main.focus();
            }}
            init={{
                height: props.height || 'calc(100% - 50px)',
                // width: '100%',
                // menubar: false,
                language: 'pt_BR',
                content_css: '/custom-tinymc.css',
                automatic_uploads: true,
      
                file_picker_types: 'image',
                file_picker_callback: function (cb: any, value: any, meta: any) {
                    var me = this;
                    var input: any = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                
                    /*
                        Note: In modern browsers input[type="file"] is functional without
                        even adding it to the DOM, but that might not be the case in some older
                        or quirky browsers like IE, so you might want to add it to the DOM
                        just in case, and visually hide it. And do not forget do remove it
                        once you do not need it anymore.
                    */
                
                    input.onchange = function () {
                        var file = this.files[0];
                
                        var reader: any = new FileReader();
                        reader.onload = function () {
                        /*
                            Note: Now we need to register the blob in TinyMCEs image blob
                            registry. In the next release this part hopefully won't be
                            necessary, as we are looking to handle it internally.
                        */
                        var id = 'blobid' + (new Date()).getTime();
                        console.log('me', me);
                        console.log('me.activeEditor', me.activeEditor);
                        console.log('componentRef', componentRef);
                        var blobCache =  componentRef.current.editor.editorUpload.blobCache;
                        var base64 = reader?.result?.split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                
                        /* call the callback and populate the Title field with the file name */
                        cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                    };
                
                    input.click();
                },

                // content_css: 'document',
                selector: 'textarea' as unknown as undefined,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount tabfocus pagebreak'
                ],
                toolbar: 'fullscreen preview print | fontselect fontsizeselect formatselect | bold italic backcolor | image | textcolor |\
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | undo redo | pagebreak | help ' 
            }}
            onEditorChange={(e, a) => {console.log('########################e', e);console.log('########################a', a);}}
        />
    );
}

export default TextEditor;