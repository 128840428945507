import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: '100%',
        flex: 1,
    },
    table: {},
    containerPage: {
        width: '100%',
        height: '100%',
        background: '#fff'
    },
}));

export default useStyles;