import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, IconButton } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';

import styles from './styles';
import IgrejasForm from './form';
import { DELETE_CHURCH, QUERY_CHURCHES } from './graphql';


function Igrejas() {
	const classes = styles();
	const [ openDialog, setOpenDialog ] = useState(false);
	const [ currentRecord, setCurrentRecord ] = useState<any>({});
	const { loading, error, data: rows, refetch } = useQuery(QUERY_CHURCHES);
	const [ deleteChurch ] = useMutation(DELETE_CHURCH);

	useEffect(() => {
		if(currentRecord.id) {
			setOpenDialog(true);
		}
	}, [currentRecord, setOpenDialog]);

	if(loading) {
		return <div>Carregando...</div>
	}

	if(error) {
		return <div>Erro!</div>
	}

	const handleClose = (data?: any) => {
		if(data) {
			refetch();
		}

		setOpenDialog(false)
	}

	return (
		<div className={classes.containerPage}>
			<TableContainer className={classes.tableContainer} component={Paper}>
				<Button onClick={async () => {
					setCurrentRecord({ documentHeader: '' });
					setOpenDialog(true);
				}} color="primary" variant="contained">
					Adicionar nova
				</Button>
				<Table className={classes.table} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>Nome</TableCell>
						<TableCell></TableCell>
					</TableRow>
					</TableHead>
					<TableBody>
					{rows.listChurch.map((row: any) => (
						<TableRow key={row.name}>
							<TableCell component="th" scope="row"  onClick={() => {
							setCurrentRecord(row);
						}}>{row.name}</TableCell>
							<TableCell align="right">
								<IconButton aria-label="delete" size="small">
									<DeleteIcon fontSize="small" onClick={() => {deleteChurch({ variables: { id: row.id } }).then(() => refetch())}} />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>

			{
				openDialog 
				? (
					<IgrejasForm
						open={true}
						handleClose={handleClose}
						currentRecord={currentRecord}
					/>
				) : null
			}
		</div>
  );
}

export default Igrejas;