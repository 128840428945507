import { AppBar, Backdrop, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, Paper, TextField, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    Save as SaveIcon,
	Delete as DeleteIcon,
    Print as PrintIcon,
	Visibility as VisibilityIcon,
	Cancel as CancelIcon,
	Add as AddIcon,
	PictureAsPdf as PdfIcon,
} from '@material-ui/icons';
import TextEditor from '../../components/TextEditor';
import { Alert } from '@material-ui/lab';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Editor } from '@tinymce/tinymce-react';
import UploadDocument from 'src/components/UploadDocument';
import DisplayPdf from 'src/components/DisplayPdf';
import HtmlView from '../Ordinaria/components/htmlView';
import ReportCongregacao from '../Reports/congregacao/congregacao';
import ReportConselho from '../Reports/conselho/conselho';
import ReportEstatistica from '../Reports/estatistica/estatistica';
import ReportMinistro from '../Reports/ministro/ministro';
import { mapReportTitle } from '../Ordinaria/components/entrada-documento';
import { any } from 'prop-types';
import Atas from '../Ordinaria/Pages/Atalhos/Atas';
import HtmlViewL from '../Ordinaria/components/htmlViewL';

const QUERY_MEETINGS = gql`
  query ListMeetings {
    listMeeting(filter: "Finalizada") {
	  id
	  name
	  date
	  convocation
	  status
	  estatisticaNumber
	  estatisticaPlenario
	  estatisticaStatus
    }
  }
`;

const QUERY_DOCUMENTS = gql`
  query ListDocuments($meeting: String!) {
	listStep(meeting: $meeting) {
      id
      name
      order
      currentSubStep
      code
      meeting {
        id
        name
        estatisticaNumber
        estatisticaPlenario
        estatisticaStatus
      }
      subSteps {
          id
          name
      }
    },
    listDocuments(meeting: $meeting, all: "Arquivo") {
	  id
	  title
	  text
	  number
	  numberPlenario
	  report
	  status
	  type
	  createdAt
	  user {
		  id
		  name
	  }
	  commission {
		id
		name
	  }
    },
	listReports(meeting: $meeting) {
		id
		name
		type
		number
		numberPlenario
		reportPlenario
		status
		inputStep
	  	approvedStep
		text
		church {
			id
			name
		}
		commission {
			id
			name
		}
	},
	compileReportTable(meeting: $meeting, type: "ministro") {
		title
		type
		table
	}
  }
`;

const MUTATION_CREATE_DOCUMENT = gql`
  mutation CreateDocument($id: String!, $title: String!, $text: String!, $meeting: String!) {
    createDocument(input: {id: $id, title: $title, text: $text, meeting: $meeting}) {
      id
	  title
	  text
	  meeting {
		  id
		  name
	  }
    }
  }
`;

const MUTATION_DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: String!) {
    deleteDocument(id: $id) {
      id
    }
  }
`;

const SINGLE_UPLOAD = gql`
  mutation($file: Upload!, $title: String!, $meeting: String!) {
    uploadDocument(file: $file, title: $title, meeting: $meeting) {
      filename
      mimetype
      encoding
      url
    }
  }
`;

function Arquivo({...props}) {
	const classes = styles();
	const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
	const [ currentMeeting, setCurrentMeeting ] = useState<any>({});
	const [ currentRecord, setCurrentRecord ] = useState<any>({});
	const [ isPdfUpload, setPdfUpload ] = useState<boolean>(false);
	const [ pdfFile, setPdfFile ] = useState<any>();
	const [ openEstatistica, setOpenEstatistica ] = useState(false);
	const [ alertCancel, setAlertCancel ] = useState(false);
	const [ alertDelete, setAlertDelete ] = useState(false);
	const { loading: loadingMeetings, error: errorMeetings , data: dataMeetings } = useQuery(QUERY_MEETINGS);
	const [ getDocuments, { loading: loadingDocuments, error: errorDocuments , data: dataDocuments, refetch: refetchDocuments }] = useLazyQuery(QUERY_DOCUMENTS);
	const [ deleteDocument, { data: dataDelete, loading: loadingDelete, error: errorDelete }] = useMutation(MUTATION_DELETE_DOCUMENT, {
		onCompleted: () => {
			refetchDocuments && refetchDocuments();
			setCurrentRecord({});
		}
	});
	
	const componentRef: any = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef?.current,
	});
	
	useEffect(() => {
		currentMeeting?.id && getDocuments({ variables: { meeting: currentMeeting.id } })
	}, [currentMeeting, getDocuments]);

    if(loadingMeetings || loadingDocuments || loadingDelete ) {
		return <Backdrop className={classes.backdrop} open><CircularProgress color="inherit" /><Typography>Carregando</Typography></Backdrop>
	}
	
	if(errorMeetings) {
		return <div>Erro ao carregar as reuniões!</div>
	}
	
	if(errorDocuments) {
		return <div>Erro ao carregar os documentos!</div>
	}

	const buildReportViewL = () => {
		let value = '';
		console.log('table', dataDocuments?.compileReportTable);
		(dataDocuments?.compileReportTable || []).map((table: any) => {
			value += table.table;
			value += '<div class="page-break"></div>';
		})
		return (<HtmlViewL disabled value={value}/>)
	}

    const createListMeetings = () => {
		return dataMeetings.listMeeting.map((item: any, idx: any) => {
			return <Grid item key={idx} xs={matchesSm ? 12 : 4} className={classes.gridItem}>
				<Paper
					onClick={() => {
						setCurrentMeeting({ ...item})
					}}
					className={classes.paperItem}
				>
				<Typography variant="h5">{item.name}</Typography>
				<Typography variant="h6"><span>Status:</span> {item.status}</Typography>	
				<Typography variant="h6"><span>Data:</span> {item.date}</Typography>	
			</Paper></Grid>
		});
	}

	const createListDocuments = () => {
		const items: any =  dataDocuments.listDocuments.map((item: any, idx: any) => {
			return <Grid xs={matchesSm ? 12 : 4} item key={idx} className={classes.gridItem}>
				<Paper
					onClick={() => {
						setCurrentRecord({ ...item })
					}}
					className={classes.paperItem}
				>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="h5">{item.type === 'pdf' && <PdfIcon fontSize="small" />} {item.title}</Typography>
					</Grid>
					<Grid item xs={12} className={classes.labelCommission}>
						<Typography variant="h6"><span>Comissão:</span> {item.commission?.name || 'Não definida'}</Typography>
					</Grid>
					<Grid item xs={matchesSm ? 12 : 6}>
						<Typography variant="h6"><span>Número:</span> {item.number || 'Não definido'}</Typography>
					</Grid>
					<Grid item xs={matchesSm ? 12 : 6}>
						<Typography variant="h6"><span>Plenário:</span> {item.numberPlenario || 'Não definido'}</Typography>
					</Grid>
					<Grid item xs={matchesSm ? 12 : 6}>
						<Typography variant="h6"><span>Status:</span> {item.status || 'Não definido'}</Typography>
					</Grid>
				</Grid>
			</Paper></Grid>
		});
		
		const reportsCongregacao = dataDocuments.listReports.filter((report: any) => report.name.includes('congregacao'));
		const reportsConselho = dataDocuments.listReports.filter((report: any) => report.name.includes('conselho'));
		const reportsMinistro = dataDocuments.listReports.filter((report: any) => report.name.includes('ministro'));
		const reportsEstatistica = dataDocuments.listReports.filter((report: any) => report.name.includes('estatistica'));
		const reportsOutros = dataDocuments.listReports.filter((report: any) => !report.name.includes('congregacao') && !report.name.includes('ministro') &&  !report.name.includes('estatistica') && !report.name.includes('conselho'));

		items.push(<Grid item xs={12}><Typography variant="h6" className={classes.categoryName}>Relatório da Congregação</Typography></Grid>);
		items.push(reportsCongregacao.map((item: any, idx: any) => buildReportItems(item, idx)));

		items.push(<Grid item xs={12}><Typography variant="h6" className={classes.categoryName}>Relatório do Conselho</Typography></Grid>);
		items.push(reportsConselho.map((item: any, idx: any) => buildReportItems(item, idx)));

		items.push(<Grid item xs={12}><Typography variant="h6" className={classes.categoryName}>Relatório do Ministro</Typography></Grid>);
		items.push(reportsMinistro.map((item: any, idx: any) => buildReportItems(item, idx)));

		items.push(<Grid item xs={12}><Typography variant="h6" className={classes.categoryName}>Relatório do Ministro</Typography></Grid>);
		items.push(reportsEstatistica.map((item: any, idx: any) => buildReportItems(item, idx)));

		items.push(<Grid item xs={12}><Typography variant="h6" className={classes.categoryName}>Permanência Pastoral e outros</Typography></Grid>);
		items.push(reportsOutros.map((item: any, idx: any) => buildReportItems(item, idx)));

		return items;
	}

	const buildReportItems = (item: any, idx: any) => (
		<Grid xs={matchesSm ? 12 : 4} item key={idx} className={classes.gridItem}>
			<Paper
				onClick={() => {
					setCurrentRecord({ ...item })
				}}
				className={classes.paperItem}
			>
			<Grid container>
			<Grid item xs={12}>
				<Typography variant="h5"> {item.church?.name || 'Igreja não identificada'} </Typography>
			</Grid>
			{/* <Grid item xs={12}>
				<Typography variant="h6"><span>Igreja:</span> {item.church?.name || 'Não identificada'}</Typography>
			</Grid> */}
			<Grid item xs={12} className={classes.labelCommission}>
				<Typography variant="h6"><span>Comissão:</span> {item.commission?.name || 'Não definida'}</Typography>
			</Grid>
			<Grid item xs={matchesSm ? 12 : 6}>
				<Typography variant="h6"><span>Número:</span> {item.number || 'Não definida'}</Typography>
			</Grid>
			<Grid item xs={matchesSm ? 12 : 6}>
				<Typography variant="h6"><span>Plenário:</span> {item.numberPlenario || 'Não definido'}</Typography>
			</Grid>
			<Grid item xs={matchesSm ? 12 : 6}>
				<Typography variant="h6"><span>Status:</span> {item.status || 'Não definido'}</Typography>
			</Grid>
		</Grid>
		</Paper></Grid>
	);

	const buildReportView = (report: any) => {
		switch(report?.type) {
			case 'congregacao':
				return (<ReportCongregacao meeting={props.meeting} report={report} readOnly />)
			case 'conselho':
				return (<ReportConselho meeting={props.meeting} report={report} readOnly />)
			case 'estatistica':
				return (<ReportEstatistica meeting={props.meeting} report={report} readOnly />)
			case 'ministro':
				return (<ReportMinistro meeting={props.meeting} report={report} readOnly />)
			case 'permanencia_pastoral':
				return (<HtmlView width="100%" zoomOut disabled value={report?.text } height = "100%" style={{height: '100%'}} />);
			default:
				return (<Typography>Selecione uma igreja para ver o relatório</Typography>)
		}
	}

	const doDeleteDocument = () => {
		setAlertDelete(false);
		deleteDocument({ variables: { id: currentRecord.id }});
	}

	const doCancel = () => {
		setOpenEstatistica(false);
	}

	const doCancelDocument = () => {
		// setAlertCancel(false);
		refetchDocuments && refetchDocuments();
		setCurrentRecord({});
	}

	if(dataMeetings?.listMeeting?.length === 0) {
		return <Alert severity="warning">Não existe nenhuma reunião disponível para inserir documento.</Alert>
	}

    if(dataMeetings?.listMeeting.length && ( !dataDocuments || !currentMeeting ) && !currentRecord?.id) {
		return (
			<div className={classes.rootCentered}>
			<Grid container>
				<Grid item xs={12} className={classes.gridItem}>
					<Typography variant="h6">Reuniões disponíveis para ver os documentos:</Typography>
				</Grid>
				{ createListMeetings() }
			</Grid>
			</div>
		);
	}

	if(dataDocuments?.listDocuments.length && !currentRecord?.id) {
		return (
			<Container className={classes.containerArquivo} maxWidth="lg">
				<div className={classes.rootCentered}>
					<Grid container spacing={1}>
						<Grid item xs={12} className={classes.gridItem}>
							<Typography variant="h5">
								<b>"{currentMeeting.name}"</b>
								<a className={classes.textLink} onClick={() => { setCurrentRecord(null); setCurrentMeeting(null); }}>
									[trocar de reunião]
								</a>
							</Typography>
							<Typography variant="h6" className={classes.categoryName}>
								Atas
							</Typography>
							<div className={classes.containerAtas}>
								<Atas steps={dataDocuments.listStep} meeting={currentMeeting} clean />
							</div>
							<Typography variant="h6" className={classes.categoryName}>
								Estatísticas
							</Typography>
							<Grid xs={matchesSm ? 12 : 4} item className={classes.gridItem}>
								<Paper
									onClick={() => {
										setOpenEstatistica(true);
									}}
									className={classes.paperItem}
								>
								<Grid container>
									<Grid item xs={12}>
										<Typography variant="h5">Relatório de estatísticas</Typography>
									</Grid>
									<Grid item xs={12} className={classes.labelCommission}>
										<Typography variant="h6"><span>Comissão:</span> Estatísticas</Typography>
									</Grid>
									<Grid item xs={matchesSm ? 12 : 6}>
										<Typography variant="h6"><span>Número:</span> {currentMeeting.estatisticaNumber || 'Não definido'} </Typography>
									</Grid>
									<Grid item xs={matchesSm ? 12 : 6}>
										<Typography variant="h6"><span>Plenário:</span> {currentMeeting.estatisticaPlenario || 'Não definido'}</Typography>
									</Grid>
									<Grid item xs={matchesSm ? 12 : 6}>
										<Typography variant="h6"><span>Status:</span> {currentMeeting.estatisticaStatus || 'Não definido'}</Typography>
									</Grid>
								</Grid>
							</Paper></Grid>
							<Typography variant="h6" className={classes.categoryName}>
								Documentos
							</Typography>
						</Grid>
						{ createListDocuments() }
					</Grid>
				</div>
				<Dialog
					open={openEstatistica}
					keepMounted
					onClose={doCancel}
					maxWidth="lg"
					fullWidth
					className={matchesSm ? classes.htmlViewMobile : classes.htmlView}
				>
					<DialogTitle>
						Relatório de Estatísticas
					</DialogTitle>
					<DialogContent dividers>
						<DialogContentText id="alert-dialog-slide-description" className={classes.textJustify}>
							{ buildReportViewL() }
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={doCancel} color="primary">
							Fechar
						</Button>
					</DialogActions>
				</Dialog>
			</Container>
		);
	}

	if((!dataDocuments || !dataDocuments?.listDocuments || !dataDocuments?.listDocuments?.length) && !currentRecord?.id) {
		return <div className={classes.rootCentered}>
			<Alert severity="info">Você ainda não inseriu nenhum documento para a reunião: <b>"{currentMeeting?.name}"</b>.</Alert>
			<Button  startIcon={<AddIcon />} className={classes.btnAdd} variant="contained" onClick={() => setCurrentRecord({id: 'new', text: ''})}>Inserir documento</Button>
			<p className={classes.textLink} onClick={() => { setCurrentRecord(null); setCurrentMeeting(null); }}>
				Trocar de reunião
			</p>
		</div>
	}

    return (
		<div className={classes.rootArquivo}>
			{
				currentRecord.type === 'pdf' ? (
					<Container className={classes.containerPdf}>
						<DisplayPdf url={currentRecord.text} className={classes.containerPdfCanvas}/>
					</Container>
				) : (
					<Dialog
						open={!!currentRecord.id}
						// TransitionComponent={Transition}
						keepMounted
						onClose={doCancelDocument}
						maxWidth="xl"
						fullWidth
						className={matchesSm ? classes.htmlViewMobile : classes.dialogFull}
					>
						<DialogTitle>
							{currentRecord.title || mapReportTitle[currentRecord.type]}
							{currentRecord.church && ` (${currentRecord.church?.name})` }
						</DialogTitle>
						<DialogContent dividers className={classes.dialogContent} style={{height: '100%'}}>
							<Grid container spacing={0} className={matchesSm ? classes.editGridMobile : classes.editGrid} style={{height: '100%'}}>
								<Grid item xs={6} className={matchesSm ? classes.editColumnMobile : classes.editColumn} style={{height: '100%'}}>
									{/* <DialogContentText className={classes.textJustify}> */}
										{
											currentRecord.type === 'pdf' ? (
												<Container className={classes.displayPdf}>
													<DisplayPdf url={currentRecord.text} />
												</Container>
											) : currentRecord.type === 'document' ? (
												<HtmlView zoomOut disabled value={currentRecord?.text } height = "100%" style={{height: '100%'}} />
											) : (
												<div style={{zoom: 0.8, width: '100%', height: '100%', display: 'flex'}}>
													{buildReportView(currentRecord)}
												</div>
											)
										}
									{/* </DialogContentText> */}
								</Grid>
								<Grid item xs={6} className={matchesSm ? classes.editColumnMobile : classes.editColumn} >
									{
										currentRecord.type === 'pdf' ? (
											<Container className={classes.displayPdf}>
												<HtmlView zoomOut disabled value={currentRecord?.report } height = "100%" style={{height: '100%'}} />
											</Container>
										) : currentRecord.type === 'document' ? (
											<HtmlView zoomOut disabled value={currentRecord?.report } height = "100%" style={{height: '100%'}} />
										) : (
											<HtmlView zoomOut disabled value={currentRecord?.reportPlenario } height = "100%" style={{height: '100%'}} />
										)
									}
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={doCancelDocument} color="primary">
								Fechar
							</Button>
						</DialogActions>
					</Dialog>
				)
			}
			
			<Dialog
				open={alertCancel || alertDelete}
				onClose={doCancelDocument}
			>
				<DialogTitle>{alertDelete ? 'Deletar' : 'Voltar para Lista' } </DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{
						currentRecord?.id
							? alertDelete ? 'Tem certeza que deseja deletar o documento?' : 'Você perderá todas as informações não salvas. Tem certeza?'
							: 'Cancelar edição do documento?'
					}
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => alertDelete ? setAlertDelete(false) : setAlertCancel(false)} color="primary">
						Não
					</Button>
					<Button onClick={alertDelete ? doDeleteDocument : doCancelDocument} color="primary" autoFocus>
						Sim
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default Arquivo;