import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    background: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url("${require('../../assets/bg_login.jpg')}")`,
        backgroundSize: 'cover',
    },
    root: {
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        // background: 'rgba(255,255,255, 0.2)'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginBottom: 15,
        marginRight: 35,
        [theme.breakpoints.down('sm')]: {
            marginRight: 15,
            marginLeft: 15,
        },
    },
    titleBox: {
        flexGrow: 1,
        maxWidth: '100%'
    },
    logoBox: {
        textAlign: 'center',
        maxWidth: 500
    },
    logo: {
        width: 200,
        marginTop: 30,
        alignSelf: 'center'
    },
    logoLoginBox: {
        textAlign: 'center',
        maxWidth: 500
    },
    logoLogin: {
        width: 400,
        marginBottom: 50,
        marginLeft: 50,
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '60%',
            marginLeft: 0,
        },
    },
    card: {
        padding: 20,
        background: 'rgba(255, 255, 255, 0.6)',
        boxShadow: '0px 0px 30px #666',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 20,
        minWidth: 400,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
            padding: 15
        },
        '& input': {
            background: '#fff',
            borderRadius: 5,
        }
    },
    title: {
        fontWeight: 1000,
        fontSize: 19
    },
    button: {
        marginTop: 15
    },
    forgetPassword: {
        display: 'block',
        marginTop: 5,
        cursor: 'pointer',
        textAlign: 'right'
    },
    dialog: {
        padding: 20
    },
    flex: {
        display: 'flex'
    },
    logoSistema: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'flex-end',
        // marginBottom: 15,
        // marginLeft: 15
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
    },
    selectField: {
        '& label': {
            marginTop: -7
        },
        '& div.MuiSelect-selectMenu': {
            paddingTop: 10.5,
            paddingBottom: 10.5,
        }
    },
    dialogTitle: {
        marginBottom: 0,
        paddingBottom: 0,
    }
}));

export default useStyles;