import React from "react";
import {
    Switch,
    Route,
    useRouteMatch
} from 'react-router-dom';
import {
    Typography,
    AppBar,
    Toolbar,
    Button,
    Grid,
    Paper,
    Container,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import { useTheme } from '@material-ui/core/styles';
import {
    Save as SaveIcon,
    Print as PrintIcon,
    Visibility as VisibilityIcon
} from '@material-ui/icons';
import TextEditor from "../../components/TextEditor";

import styles from './styles';
import CustomizedSteppers from "./steps";
import ConvocacaoMeeting from "./components/convocacao";
import Documents from "../Documents";
import MinistroDashboard from "../Reports/ministro";
import CongregacaoDashboard from "../Reports/congregacao";
import ConselhoDashboard from "../Reports/conselho";
import EstatisticaDashboard from "../Reports/estatisticaIndex";
import PermanenciaPastoralDashboard from "../Reports/permanenciaPastoral";
import AtalhosDashboard from "./Pages/Atalhos";
import Arquivo from "../Arquivo";

const factoryTextEditor = ({...props}) => {
    return <TextEditor
        initialValue = {`
            <h1 style="text-align: center;"><img style="float: left;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR9EBAdk6S5OSo5yb9kZn2y-AHVsdDPJCKWCpWS4gSeEIIusMuu&amp;usqp=CAU" alt="" width="316" height="217" />PRESBIT&Eacute;RIO DE IMBITUVA - PRIM</h1>
            <p style="text-align: center;"><span style="font-size: 14pt;">SEDE: IMBITUVA - S&Iacute;NODO VALE DO TIBAGE (SVT)</span></p>
            <p style="text-align: center;">&nbsp;</p>
            <p style="text-align: center;"><span style="font-size: 18pt;">${props.title}</span></p>
            <p style="text-align: center;">&nbsp;</p>
            <p style="text-align: left;"><span style="font-size: 18pt;">&nbsp; &nbsp;PRIM resolve:</span></p>
            <p style="text-align: left;">&nbsp;</p>
            <p style="text-align: left;">&nbsp;</p>
            <p style="text-align: center;">&nbsp;</p>
            <p style="text-align: center;">&nbsp;</p>
            <p style="text-align: center;">____________________________________________________________</p>
            <p style="text-align: center;">Nome do relator</p>
            <p style="text-align: center;">&nbsp;</p>
            <p style="text-align: right;">Sala das Sess&otilde;es, ____ de _______________ de 20 _____&nbsp; &nbsp;</p>
        `}
    />
}

function getAppBar() {
    const classes = styles();

    return <AppBar position="fixed" color="default" className={classes.footer}>
        <Toolbar>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<VisibilityIcon />}
            >
                Visualizar
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<PrintIcon />}
            >
                Imprimir
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<SaveIcon />}
            >
                Savar
            </Button>
        </Toolbar>
    </AppBar>
}



function Ordinaria() {
    const classes = styles();
    let match = useRouteMatch();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Switch>
             <Route path={`${match.path}/nova-ordinaria`}>
                <div className={classes.containerPage}>
                    <div className={classes.formContentFull}>
                        <CustomizedSteppers />
                    </div>
                </div>
            </Route>
            <Route path={`${match.path}/documentos/permanencia-pastoral`}>
                <div className={classes.containerPage}>
                    <div className={classes.formContentFull}>
                        <PermanenciaPastoralDashboard />
                    </div>
                </div>
            </Route>
            <Route path={`${match.path}/documentos`}>
                <div className={classes.containerPage}>
                    <div className={classes.formContentFull}>
                        <Documents />
                    </div>
                </div>
            </Route>
            <Route path={`${match.path}/arquivo`}>
                <div className={classes.containerPage}>
                    <div className={classes.formContentFull}>
                        <Arquivo />
                    </div>
                </div>
            </Route>
            <Route path={`${match.path}/relatorios/ministro`}>
                <div className={classes.containerPage}>
                    <div className={classes.formContentFull}>
                        <MinistroDashboard />
                    </div>
                </div>
            </Route>
            <Route path={`${match.path}/relatorios/conselho`}>
                <div className={classes.containerPage}>
                    <div className={classes.formContentFull}>
                        <ConselhoDashboard />
                    </div>
                </div>
            </Route>
            <Route path={`${match.path}/relatorios/congregacao`}>
                <div className={classes.containerPage}>
                    <div className={classes.formContentFull}>
                        <CongregacaoDashboard />
                    </div>
                </div>
            </Route>
            <Route path={`${match.path}/relatorios/estatistica`}>
                <div className={classes.containerPage}>
                    <div className={classes.formContentFull}>
                        <EstatisticaDashboard />
                    </div>
                </div>
            </Route>
            <Route path={`${match.path}/convocaca-ro`}>
                <div className={classes.containerPage}>
                    <ConvocacaoMeeting />
                </div>
            </Route>
            <Route path={`${match.path}/sessao-preparatoria`}>
                <div className={classes.containerPage}>
                    <div className={classes.textEditor}>
                        {factoryTextEditor({title: 'SESSÃO PREPARATÓRIA'})}
                    </div>
                    {getAppBar()}
                </div>
            </Route>
            <Route path={`${match.path}/1-sessao-regular`}>
                <div className={classes.containerPage}>
                    <div className={classes.textEditor}>
                        {factoryTextEditor({title: '1ª SESSÃO REGULAR'})}
                    </div>
                    {getAppBar()}
                </div>
            </Route>
            <Route path={`${match.path}/2-sessao-regular`}>
                <div className={classes.containerPage}>
                    <div className={classes.textEditor}>
                        {factoryTextEditor({title: '2ª SESSÃO REGULAR'})}
                    </div>
                    {getAppBar()}
                </div>
            </Route>
            <Route path={`${match.path}/sessao-priv-inter`}>
                <div className={classes.containerPage}>
                    <div className={classes.textEditor}>
                        {factoryTextEditor({title: '2ª SESSÃO PRIV/INTER'})}
                    </div>
                    {getAppBar()}
                </div>
            </Route>
            <Route path={`${match.path}/ata-geral`}>
                <div className={classes.containerPage}>
                    <div className={classes.textEditor}>
                        {factoryTextEditor({title: 'ATA GERAL'})}
                    </div>
                    {getAppBar()}
                </div>
            </Route>
            <Route path={`${match.path}/comissoes`}>
                <div className={classes.containerPage}>
                    <div className={classes.textEditor}>
                        {factoryTextEditor({title: 'COMISSÕES'})}
                    </div>
                    {getAppBar()}
                </div>
            </Route>
            <Route path={match.path}>   
                <AtalhosDashboard />
            </Route>
        </Switch>
        </MuiPickersUtilsProvider>
    );
}

export default Ordinaria;