import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';

export const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

export const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

export const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
  },
  body: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
  },
  content: {
      height: 'calc(100vh - 192px)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'auto'
  },
  content1: {
    height: 'calc(100vh - 209px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto'
  },
  content2: {
    height: 'calc(100vh - 210px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto'
  },
  content3: {
    height: 'calc(100vh - 210px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto'
  },
  contentBox: {
      flex: 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
      backgroundColor: 'rgb(247, 247, 247)',
      background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(247, 247, 247) 100%)',
      borderBottom: '1px solid #ccc',
      boxShadow: '0px 0px 5px #ccc',
      padding: 0,
      paddingTop: 3,
      '& .MuiStepLabel-label': {
        marginTop: '3px !important',
        fontSize: '12px !important',
      },
  },
  stepLabel: {
    marginTop: '3px !important',
    fontSize: '12px !important',
  },
  footer: {
      textAlign: 'center',
      padding: 5,
      backgroundColor: 'rgb(247, 247, 247)',
      background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(247, 247, 247) 100%)',
      borderTop: '1px solid #ccc',
      boxShadow: '0px 0px 5px #ccc'
  },
  cursor: {
    cursor: 'pointer'
  },
  htmlView: {
    '& .MuiDialog-paper': {
      height: '100%',
      '& .MuiDialogContent-dividers': {
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
          flex: 1,
          display: 'flex',
        }
      }
    }
  }
}),
);