import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useMutation } from '@apollo/client';

import TextEditor from 'src/components/TextEditor';
import { CREATE_CHURCH, UPDATE_CHURCH } from './graphql';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function IgrejasForm({...props}) {
    const [ currentRecord, setCurrentRecord ] = useState(props.currentRecord);
    const [ createChurch, { loading: loadingCreate  }] = useMutation(CREATE_CHURCH);
    const [ updateChurch, { loading: loadingUpdate }] = useMutation(UPDATE_CHURCH);

    const _onSave = async () => {
        currentRecord.id 
            ? await updateChurch({ variables: { id: currentRecord.id, name: currentRecord.name, documentHeader: currentRecord.documentHeader }}).then((res) => props.handleClose(res))
            : await createChurch({ variables: { name: currentRecord.name, documentHeader: currentRecord.documentHeader }}).then((res) => props.handleClose(res));
    }

    useEffect(() => {
        setCurrentRecord(props.currentRecord)
    }, [props.currentRecord]);

    const buildTextEditor = (value: string) => (
		<TextEditor
			height = "300px"
			initialValue = {value || ''}
			onChange={(e: any) => setCurrentRecord({...currentRecord, documentHeader: e.target.getContent()})}
		/>
	)


    const isLoading = loadingCreate || loadingUpdate;

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClose}
            fullWidth
        >
            <DialogTitle>
                {currentRecord.id ? 'Editar Igreja' : 'Nova Igreja'}
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nome da Igreja"
                    fullWidth
                    disabled={isLoading}
                    value={currentRecord.name || ''}
                    onChange={(e) => setCurrentRecord({...currentRecord, name: e.target.value})}
                />
                <Grid container>
                    <Grid item xs={12}>
                        {props.open ? buildTextEditor(currentRecord.documentHeader) : (null)}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancelar
                </Button>
                <Button disabled={isLoading} onClick={() => _onSave()} variant="contained" color="primary">
                    { isLoading ? 'Salvando' : 'Salvar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default IgrejasForm