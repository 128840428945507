import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    rootArquivo: {
        display: 'flex',
        width: '100%',
        flex: 1,
        '& .MuiDialog-paperFullWidth': {
            height: '100% !important'
        }
    },
    rootMobile: {
        display: 'flex',
        width: '100%',
        flex: 1,
        '& .MuiDialog-paperFullWidth': {
            height: '100% !important'
        }
    },
    rootCentered: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        padding: 5,
    },
    containerArquivo: {
        padding: 5,
    },
    formContent: {
        width: '100%',
        height: 'calc(100% - 50px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
    },
    gridItem: {
        
    },
    paperItem: {
        padding: 10,
        cursor: 'pointer',
        background: '#efefef',
        minHeight: 65,
        '& h5': {
            fontSize: 16,
            fontWeight: 'bold',
            minHeight: 42,
            textOverflow: 'elipse',
            textTransform: 'capitalize',
        },
        '& h6': {
            fontSize: 12,
            opacity: 0.8,   
            fontWeight: 'normal',
            '& span': {
                fontWeight: 'bold'
            }
        }
    },
    btnAdd: {
    },
    basePaperMargin: {
        margin: 10,
        width: 'calc(100% - 20px)'
    },
    formTitle: {
        paddingTop: 10,
        paddingLeft: 10,
    },
    gridContainer: {
        padding: 15
    },
    textLink: {
        cursor: 'pointer',
        textAlign: 'center',
        opacity: 0.6,
        fontSize: 12
    },
    footer: {
        top: 'auto',
        bottom: 0,
        width: 'calc(100% - 240px)',
        flexDirection: 'row-reverse',
        height: 50,
        '& div': {
            minHeight: 30,
            paddingRight: 0,
        },
        '& button': {
            borderRadius: 0,
            padding: '5px 10px',
            margin: '0 15px 0 0',
        }
    },
    containerUpload: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerPdf: {
        background: '#eee',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        flexDirection: 'column',
    },
    containerPdfCanvas: {
        '& .react-pdf__Page': {
            height: '100%',
            marginBottom: 50,
            '& .react-pdf__Page__canvas': {
                border: '1px solid #f00'
            }
        }
    },
    editColumn: {
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
    },
    editColumnMobile: {
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        width: '100%',
        minWidth: '100% !important',
    },
    textJustify: {
        textAlign: 'justify'
    },
    editGrid: {
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
    },
    editGridMobile: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        height: '100%',
    },
    dialogContent: {
        display: 'flex',
        overflowY: 'hidden',
        maxWidth: '100%',
        minHeight: '84%'
    },
    dialogFull: {
        height: '100% !important',
        '& .MuiDialog-paper': {
            height: '100% !important',
        }
    },
    displayPdf: {
        background: '#eee',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center'
    },
    labelCommission: {
        '& h6': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 320,
            paddingRight: 20
        }
    },
    categoryName: {
        marginTop: 15
    },
    containerAtas: {
        marginTop: 20,
        marginBottom: 20,
    },
    htmlView: {
        '& .MuiDialog-paper': {
          height: '100%',
          '& .MuiDialogContent-dividers': {
            display: 'flex',
            flexDirection: 'column',
            '& > p': {
              flex: 1,
              display: 'flex',
            }
          }
        }
    },
    htmlViewMobile: {
        '& .MuiDialog-container': {
            '& .MuiPaper-root': {
                width: '100%',
                maxHeight: '100% !important',
                margin: 0,
                '& .MuiDialogTitle-root': {
                    padding: '0px 5px',
                },
                '& .MuiDialogActions-root': {
                    padding: 0
                },
                '& .MuiDialogContent-root': {
                    '& .MuiTypography-root': {
                        margin: 0
                    }
                }
            },
        },
        '& .MuiDialog-paper': {
          height: '100%',
          '& .MuiDialogContent-dividers': {
            display: 'flex',
            padding: 0,
            flexDirection: 'column',
            '& > p': {
              flex: 1,
              display: 'flex',
            }
          }
        },
    }
}));

export default useStyles;