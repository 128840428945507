import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 275;
const useStyles = makeStyles((theme) => ({
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerDocked: {
        // backgroundImage: 'url("https://cdn.wallpapersafari.com/64/57/6jXAzV.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: '-100px 0px'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        height: 'calc(100% - 160px) !important',
        backgroundColor: 'rgba(22, 25, 43, 0.83)',
        color: '#fff',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& h1': {
            display: 'block',
            flex: 1,
            fontWeight: 900,
            marginLeft: 10,
            letterSpacing: 2,
            fontSize: 22,
            '& span': {
                fontSize: 14,
                opacity: 0.9
            }
        },
        '& path': {
            color: '#fff'
        },
        '& a': {
            textDecoration: 'none',
            color: 'inherit'
        }
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        '& h1': {
            display: 'none'
        }
    },
    backMenu: {
        display: 'flex',
        backgroundColor: 'rgba(255,255,255,0.7)',
        color: '#000',
        borderLeft: '3px solid #fff',
        '& svg': {
            marginTop: 15,
            color: '#000',
        },
        '& p': {
            padding: 15,
            color: '#000',
        }
    },
    selectedMenu: {
        fontWeight: 'bold',
        backgroundColor: '#3f51b5',
        display: 'block'
    },
    boxLogo: {
        textAlign: 'center',
        marginTop: 15,
        marginBottom: 40
    },
    logo: {
        width: 75,
        marginLeft: 50,
        marginTop: 15,
        display: 'inline-block'
    },
    logoLogin: {
        width: 170,
        marginTop: 15,
        marginLeft: -15,
        display: 'inline-block'
    },
    listMenu: {
        // background: 'rgb(50, 52, 66)',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    dividerFlex: {
        flex: 1,
    }
}));

export default useStyles;