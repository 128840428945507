import React, { useState } from 'react';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import {  DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions, Button, Slide, Typography, FormControl, Box, InputLabel, Select, MenuItem, TextField, useMediaQuery } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { gql, useMutation, useQuery } from '@apollo/client';
import MyBackdrop from '../../../components/Backdrop';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { mapReportTitle } from './entrada-documento';
import HtmlView from './htmlView';
import HtmlViewL from './htmlViewL';
import { ExecutiveCommissionToRole } from 'src/store/ducks/Main/types';
import { getMe, IMe } from 'src/services/auth';

const QUERY_DOCUMENTS = gql`
  query ListDocumentsAll($meeting: String!) {
    listDocuments(meeting: $meeting, all: "plenario") {
		id
		title
		text
		number
		numberPlenario
		report
		status
		createdAt
		inputStep
	  	approvedStep
		user {
			id
			name
		}
		commission{
			id
			name
		}
	}
	listReports(meeting: $meeting) {
		id
		name
		type
		number
		numberPlenario
		reportPlenario
		status
		inputStep
	  	approvedStep
		church {
			id
			name
		}
		commission {
			id
			name
		}
	},
	compileReportTable(meeting: $meeting, type: "ministro") {
		title
		type
		table
	}
  }
`;

const QUERY_USERS = gql`
  query Users {
    getUsers {
      id
      name
    }
  }
`;

const MUTATION_UPDATE_DOCUMENT = gql`
  mutation UPDATE_DOCUMENT ($id: String!, $status: String, $numberPlenario: String, $step: String) {
		updateDocument(id: $id, input: { status: $status, numberPlenario: $numberPlenario, step: $step  }) {
			id
		}
  }
`;

const MUTATION_UPDATE_REPORT = gql`
  mutation UPDATE_REPORT ($id: String!, $status: String, $numberPlenario: String, $step: String) {
		updateReport(id: $id, input: { status: $status, numberPlenario: $numberPlenario, step: $step }) {
			id
		}
  }
`;

const MUTATION_FIELD_MANUAL = gql`
  mutation UPDATE_FIELD_MANUAL ($subStep: String!, $fieldName: String!, $value: String!) {
		updateFieldManual(subStep: $subStep, fieldName: $fieldName value: $value) {
			id
		}
  }
`;

const QUERY_FIELDS_MANUAL = gql`
	query FIELDS_MANUAL($subStep: String!) {
		getFieldManual(subStep: $subStep, fieldName: "hora_plenario") {
			id
			value
		}
  	}
`;

const QUERY_FIELDS_MANUAL_ORACAO = gql`
	query FIELDS_MANUAL($subStep: String!) {
		getFieldManual(subStep: $subStep, fieldName: "oracao_plenario") {
			id
			value
			user {
				id
				name
			}
		}
  	}
`;

const MUTATION_UPDATE_MEETING = gql`
  mutation SetMeetingNumber($meeting: String!, $numberPlenario: String, $status: String) {
    setMeetingNumber(meeting: $meeting, numberPlenario: $numberPlenario, status: $status) {
		id
		estatisticaNumber
    }
  }
`;

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			width: '100%',
			padding: 20,
		},
		formControl: {
			width: '100%'
		},
		docTitle: {
			paddingTop: '37px !important'
		},
		textJustify: {
			textAlign: 'justify'
		},
		doTitleLink: {
			cursor: 'pointer'
		},
		dialogContent: {
			display: 'flex',
			overflowY: 'hidden',
		},
		editGrid: {
			display: 'flex'
		},
		editColumn: {
			height: '100%',
			overflowY: 'auto',
		},
		htmlEditor: {
			height: '100%'
		},
		cursorPointer: {
			cursor: 'pointer'
		},
		iconEye: {
			cursor: 'pointer',
			paddingTop: '37px !important'
		},
		iconEyeMobile: {
			cursor: 'pointer',
			paddingTop: '0px',
			textAlign: 'center',
		},
		gridTextCommission: {
			fontSize: 11,
			opacity: 0.7,
			marginTop: -10,
			marginBottom: 10,
			'& p': {
				fontSize: '11px !important'
			}
		},
		gridTitle: {
			textAlign: 'center',
			fontSize: 14,
			fontWeight: 'bold',
			marginBottom: 5
		},
		textInfo: {
			fontSize: 12,
			opacity: 0.5
		},
		btnReject: {
			marginLeft: 10
		},
		columnActions: {
			textAlign: 'right',
			paddingTop: '20px !important'
		},
		columnActionsActive: {
			textAlign: 'right',
			paddingTop: '0px !important'
		},
		formControlFlexForm: {
			margin: theme.spacing(3),
			display: 'flex',
			flexDirection: 'row'
		},
		box: {
			flexGrow: 1,
			display: 'flex',
			marginTop: 16,
			'& > div': {
				flexGrow: 1
			}
		},
		boxOutro: {
			marginTop: 20,
			marginLeft: 20,
			cursor: 'pointer',
			textDecoration: 'underline',
			opacity: 0.4,
		},
		formTop: {
			marginTop: -35,
			paddingTop: 0,
			borderBottom: '1px solid #ccc',
			marginBottom: 10,
			paddingBottom: 0,
		},
		gridPlainText: {
			paddingTop: '37px !important',
			'& p': {
				fontSize: 12
			}
		},
		gridPlainTitleMobile: {
			borderTop: '1px solid #ddd',
			paddingTop: '10px !important',
		},
		htmlView: {
			'& .MuiDialog-paper': {
			  height: '100%',
			  '& .MuiDialogContent-dividers': {
				display: 'flex',
				flexDirection: 'column',
				'& > p': {
				  flex: 1,
				  display: 'flex',
				}
			  }
			}
		},
	}),
);

export default function PlenarioAprovacao({...props}) {
	const classes = useStyles();
	const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
	const me: IMe = getMe();
	const reportHistory: any = {};
	const [ fieldManual, setFieldManual ] = useState<any>({});
	const [ stateNumberPlenario, setStateNumberPlenario ] = useState<any>({});
	const [ stateNumberMeeting, setStateNumberMeeting ] = useState<string>(props.meeting.estatisticaPlenario);
	const [ estatisticaStatus, setEstatisticaStatus ] = useState<string>(props.meeting.estatisticaStatus);
	const [ oracaoText, setOracaoText ] = useState<any>(false);
	const [ oracaoFeitaPor, setOracaoFeitaPor ] = useState<any>(null);
	const { data: dataFieldManual } = useQuery(QUERY_FIELDS_MANUAL, { variables: { subStep: props.subStep?.id } });
	const { data: dataFieldManualOracao } = useQuery(QUERY_FIELDS_MANUAL_ORACAO, { variables: { subStep: props.subStep?.id } });
	const { loading: loadingUsers, error: errorUsers, data: dataUsers } = useQuery(QUERY_USERS);
	const { loading, error , data, refetch } = useQuery(QUERY_DOCUMENTS, { variables: { meeting: props.meeting?.id }});
	const [ updateFieldManual ] = useMutation(MUTATION_FIELD_MANUAL);
	const [ updateMeeting, { data: dataUpdateMeeting, loading: loadingUpdateMeeting, error: errorUpdateMeeting }] = useMutation(MUTATION_UPDATE_MEETING);
	const [ updateDocument, { loading: loadingUpdateDocument, error: errorUpdateDocument } ]  = useMutation(MUTATION_UPDATE_DOCUMENT, {
		onCompleted: () => {
			setCurrentDocument({});
			refetch();
			setOpen(false);
		}
	});
	const [ updateReport, { loading: loadingUpdateReport, error: errorUpdateReport } ]  = useMutation(MUTATION_UPDATE_REPORT, {
		onCompleted: () => {
			setCurrentDocument({});
			refetch();
			setOpen(false);
		}
	});
	const [open, setOpen] = React.useState(false);
	const [openEstatistica, setOpenEstatistica] = React.useState(false);
	const [currentDocument, setCurrentDocument] = React.useState<any>({});
	const isPlenarioAdmin = ['presidente','vice_presidente','secretario_protocolo'].includes(ExecutiveCommissionToRole[me.executiveCommission]) || ['admin', 'master'].includes(me.role);
 
	const handleClickOpen = (document: any ) => {
		setCurrentDocument(document);
		setOpen(true);
	};

	const handleClickOpenEstatistica = () => {
		setOpenEstatistica(true);
	};

	const handleClose = () => {
		setCurrentDocument({});
		setOpen(false);
		setOpenEstatistica(false);
	};

	const onAccept = (document: any) => {
		updateDocument({
			variables: {id: document.id, status: 'Aprovado', step: props.step.code}
		});
	}

	const onReject = (document: any) => {
		updateDocument({
			variables: {id: document.id, status: 'Rejeitado'}
		});
	}

	const onNumPlenario = (document: any) => {
		updateDocument({
			variables: {id: document.id, numberPlenario: document.numberPlenario }
		});
	}

	const onNumPlenarioReport = (report: any) => {
		updateReport({
			variables: {id: report.id, numberPlenario: report.numberPlenario }
		});
	}

	const onAcceptReport = (report: any) => {
		updateReport({
			variables: {id: report.id, status: 'Aprovado', step: props.step.code }
		});
	}

	const onRejectReport = (report: any) => {
		updateReport({
			variables: {id: report.id, status: 'Rejeitado'}
		});
	}

	const onNumPlenarioMeeting = (report: any) => {
		updateMeeting({ variables: { meeting: props.meeting.id, numberPlenario: report.numberPlenario }});
	}

	const onAcceptEstatistica = () => {
		setEstatisticaStatus('Aprovado');
		updateMeeting({ variables: { meeting: props.meeting.id, status: 'Aprovado' }});
	}

	const onRejectEstatistica = () => {
		setEstatisticaStatus('Rejeitado');
		updateMeeting({ variables: { meeting: props.meeting.id, status: 'Rejeitado' }});
	}

	const buildEditor = (value: string, footer?: string, header?: string) => {
		return <HtmlView disabled value={value} />
	}

	const buildReportViewL = () => {
		let value = '';

		(data?.compileReportTable || []).map((table: any) => {
			value += table.table;
			value += '<div class="page-break"></div>';
		})
		
		return (<HtmlViewL disabled value={value}/>)
	}

	if(error) {
		return <div>Ocorreu algum erro ao carregar os documentos.</div>
	}

	if(errorUpdateDocument) {
		return <div>Ocorreu algum erro ao aprovar/rejeitar o documento.</div>
	}

	return (
		<div className={classes.root}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}> 
			{
				(loading || loadingUpdateDocument || loadingUpdateReport || loadingUpdateMeeting)
					? (<MyBackdrop open={true} message={loading ? 'Carregando' : 'Salvando'} />)
					: (null)
			}
			{
				currentDocument?.id ? (
					<Dialog
						open={open}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleClose}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
						maxWidth="xl"
						fullWidth
						className={classes.htmlView}
					>
						<DialogTitle id="alert-dialog-slide-title">
							{ currentDocument.title || mapReportTitle[currentDocument.type] }
						</DialogTitle>
						<DialogContent dividers className={classes.dialogContent}>
							{/* <DialogContentText className={classes.textJustify}> */}
							{ buildEditor(currentDocument.report || currentDocument.reportPlenario) }
							{/* </DialogContentText> */}
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color="secondary">
								Fechar
							</Button>
							{
								isPlenarioAdmin ? (
									<Button variant="contained" onClick={() => onAccept(currentDocument)} color="primary">
										Aprovar
									</Button>
								) : (null)
							}
						</DialogActions>
					</Dialog>
				) : (null)
			}


			{/* { Relatório de Estatísticas } */}
			<Dialog
				open={openEstatistica}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				maxWidth="lg"
				fullWidth
				className={classes.htmlView}
			>
				<DialogTitle>
					Relatório de Estatísticas
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText id="alert-dialog-slide-description" className={classes.textJustify}>
						{ buildReportViewL() }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Fechar
					</Button>
				</DialogActions>
			</Dialog>




			{
				isPlenarioAdmin ? (
					<Grid container spacing={3} className={classes.formTop}>
						<Grid item xs={4}>
							<FormControl className={classes.formControl}>
								<TimePicker
									value={fieldManual?.hora_plenario || dataFieldManual?.getFieldManual?.value|| null}
									label="Horário do retorno"
									margin="normal"
									ampm={false}
									format="H:mm"
									autoFocus
									onChange={(value) => {
										setFieldManual({...fieldManual, hora_plenario: value});
										updateFieldManual({
											variables: { subStep: props.subStep.id, fieldName: 'hora_plenario', value: value }
										});
									}}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
								<Grid container>
									<Grid xs={8}>
										{
											!oracaoText ? (
												<Box className={classes.box}>
													<FormControl className={classes.formControl}>
													<InputLabel>Oração feita por</InputLabel>
													<Select
														value={oracaoFeitaPor || dataFieldManualOracao?.getFieldManual?.user?.id || ''}
														onChange={(e) => {
															const value = e.target.value;
															updateFieldManual({
																variables: { subStep: props.subStep.id, fieldName: 'oracao_plenario', value: value }
															});
															setOracaoFeitaPor(value);
														}}
													>
														{
															dataUsers?.getUsers?.map((user:any, idx: number) => <MenuItem key={`user-${idx}`} value={user.id}>{user.name}</MenuItem>)
														}
													</Select>
													</FormControl>
												</Box>
											) : (
												<TextField label="Oração feita por" value={''} onChange={(e) => {
													// const value = e.target.value;
													// setFieldsSatate({
													// 	...fieldsState,
													// 	[field.id]: value
													// });
													// updateField({ variables: {field: field.id, value: value, raw: true } })
												}}  />
											)

										}
									</Grid>
									<Grid xs={4}>
										<Box onClick={() => {
												// updateField({ variables: {field: field.id, value: null, raw: true } })
												setOracaoText(!oracaoText);
										}} className={classes.boxOutro}>{oracaoText ? 'selecionar' : 'digitar'}</Box>
									</Grid>
								</Grid>
						</Grid>
					</Grid>
				) : (null)
			}

			<Grid container spacing={1}>
				{ matchesSm ? (
					<Grid className={matchesSm ? classes.gridTitle : classes.gridPlainText} xs={12}>Documentos</Grid>	
				) : (
					<>
							<Grid className={matchesSm ? '' : classes.gridPlainText} xs={1}> </Grid>
							<Grid className={matchesSm ? '' : classes.gridPlainText} xs={
								5
							}><b>Título</b></Grid>
							{/* <Grid className={matchesSm ? '' : classes.gridPlainText} xs={1}><b>Nº</b></Grid> */}
							<Grid className={matchesSm ? '' : classes.gridPlainText} xs={3}><b>Comissão</b></Grid>
							<Grid className={matchesSm ? '' : classes.gridPlainText} xs={1}><b>Nº Plenário</b></Grid>
							<Grid className={matchesSm ? '' : classes.gridPlainText} xs={2}><b>Status</b> </Grid>
					</>
				)}

			{
				(data?.listDocuments || []).map((item: any, idx: number) => {
					if(item.approvedStep && item.approvedStep !== props.step.code) {
						return (null);
					}
					return (
						<>
							{
								matchesSm ? (null) : (
									<Grid item xs={matchesSm ? 12 : 1} onClick={() => handleClickOpen(item)} className={matchesSm ? classes.iconEyeMobile : classes.iconEye}>
										<Visibility/>
									</Grid>
								)
							}
							<Grid item xs={
								matchesSm ? 12 : 5
							} className={matchesSm ? classes.gridPlainTitleMobile : classes.gridPlainText}>
								<Typography onClick={() => handleClickOpen(item)}  className={classes.cursorPointer}>
									{item.title}
								</Typography>
							</Grid>
							{/* <Grid item xs={1} className={matchesSm ? '' : classes.gridPlainText}>
								<Typography onClick={() => handleClickOpen(item)}  className={classes.cursorPointer}>
									Doc: {item.number}
								</Typography>
							</Grid> */}
							<Grid item xs={matchesSm ? 12 : 3} className={matchesSm ? classes.gridTextCommission : classes.gridPlainText}>
								<Typography onClick={() => handleClickOpen(item)}  className={classes.cursorPointer}>
									{item.commission?.name || 'Comissão não identificada'}
								</Typography>
							</Grid>
							{
								isPlenarioAdmin ? (
									<Grid item xs={1}>
										<TextField
											label="Nº" value={typeof stateNumberPlenario[item.id] === 'string' ? stateNumberPlenario[item.id] : (item.numberPlenario || '')}
											onBlur={(e) => onNumPlenario({...item, numberPlenario: e.target.value})} 
											onChange={(e) => setStateNumberPlenario({...stateNumberPlenario, [item.id]: e.target.value})}
										/>
									</Grid>
								) : (
									<Grid item xs={1} className={matchesSm ? classes.gridTextCommission : classes.gridPlainText}>
										<Typography>{item.numberPlenario || ''}</Typography>
									</Grid>
								)
							}

							{
								isPlenarioAdmin ? (
									<Grid item xs={2} className={item.status === 'Aprovado'? classes.columnActionsActive : classes.columnActions}>
										{
											(item.status === 'Aprovado') 
												? (
													<>
														<span className={classes.textInfo}>Doc. aprovado</span>
														<Button className={classes.btnReject} color="secondary" variant="contained" onClick={() => onReject(item)}>
															REJEITAR
														</Button>
													</>
												) : (
													<Button color="primary" variant="contained" onClick={() => onAccept(item)}>
														APROVAR
													</Button>
												)}
									</Grid>
								) : (
									<Grid item xs={2} className={matchesSm ? classes.gridTextCommission : classes.gridPlainText}>
										{
											(item.status === 'Aprovado') ? (
												<Typography>Documento aprovado</Typography>
											) : (
												<Typography>Aguardando aprovação</Typography>
											)
										}
									</Grid>
								)}
						</>
					)}
				)
			}
			
			</Grid>






			{
				(data?.listReports || []).map((itemReport: any, idx: number) => {
					if(itemReport.approvedStep && itemReport.approvedStep !== props.step.code) {
						return (null);
					}
					if(!reportHistory[itemReport.type]) {
						reportHistory[itemReport.type] = [];
						reportHistory[itemReport.type].push(itemReport);
					}
					else {
						reportHistory[itemReport.type].push(itemReport);
						return (null);
					}

					return (
						<Grid container spacing={1}>
							{
								matchesSm ? (null) : (
									<Grid item xs={1} onClick={() => handleClickOpen(itemReport)} className={matchesSm ? classes.iconEyeMobile : classes.iconEye}>
										<Visibility/>
									</Grid>
								)
							}
							<Grid item xs={
								matchesSm ? 12 : 5
							} className={matchesSm ? classes.gridPlainTitleMobile : classes.gridPlainText}>
								<Typography onClick={() => handleClickOpen(itemReport)}  className={classes.cursorPointer}>
									{mapReportTitle[itemReport.type]}
								</Typography>
							</Grid>
							{/* <Grid item xs={1} className={matchesSm ? '' : classes.gridPlainText}>
								<Typography onClick={() => handleClickOpen(itemReport)}  className={classes.cursorPointer}>
									Docs: ...
								</Typography>
							</Grid> */}
							<Grid item xs={matchesSm ? 12 : 3} className={matchesSm ? classes.gridTextCommission : classes.gridPlainText}>
								<Typography onClick={() => handleClickOpen(itemReport)}  className={classes.cursorPointer}>
									{itemReport.commission?.name || 'Comissão não identificada'}
								</Typography>
							</Grid>
							{
								isPlenarioAdmin ? (
									<Grid item xs={1}>
										<TextField
											label="Nº" value={typeof stateNumberPlenario[itemReport.id] === 'string' ? stateNumberPlenario[itemReport.id] : (itemReport.numberPlenario || '')}
											onBlur={(e) => onNumPlenarioReport({...itemReport, numberPlenario: e.target.value})} 
											onChange={(e) => setStateNumberPlenario({...stateNumberPlenario, [itemReport.id]: e.target.value})}
										/>
									</Grid>
								) : (
									<Grid item xs={1} className={matchesSm ? classes.gridTextCommission : classes.gridPlainText}>
										<Typography>{itemReport.numberPlenario || ''}</Typography>
									</Grid>
								)
							}


							{
								isPlenarioAdmin ? (
									<Grid item xs={2} className={itemReport.status === 'Aprovado'? classes.columnActionsActive : classes.columnActions}>
										{
											(itemReport.status === 'Aprovado') 
												? (
													<>
														<span className={classes.textInfo}>Doc. aprovado</span>
														<Button className={classes.btnReject} color="secondary" variant="contained" onClick={() => onRejectReport(itemReport)}>
															REJEITAR
														</Button>
													</>
												) : (
													<Button color="primary" variant="contained" onClick={() => onAcceptReport(itemReport)}>
														APROVAR
													</Button>
												)}
									</Grid>
								) : (
									<Grid item xs={2} className={matchesSm ? classes.gridTextCommission : classes.gridPlainText}>
										{
											(itemReport.status === 'Aprovado') ? (
												<Typography>Documento aprovado</Typography>
											) : (
												<Typography>Aguardando aprovação</Typography>
											)
										}
									</Grid>
								)
							}
						</Grid>
					);
				})
			}

			<Grid container spacing={1}>
				{
					matchesSm ? (null) : (
						<Grid item xs={matchesSm ? 12 : 1} onClick={() => handleClickOpenEstatistica()} className={classes.iconEye}>
							<Visibility/>
						</Grid>
					)
				}
				<Grid item xs={
					matchesSm ? 12 : 5
				} className={matchesSm ? classes.gridPlainTitleMobile : classes.gridPlainText}>
					<Typography onClick={() => handleClickOpenEstatistica()}  className={classes.cursorPointer}>
						Relatórios de estatísticas
					</Typography>
				</Grid>
				<Grid item xs={matchesSm ? 12 : 3} className={matchesSm ? classes.gridTextCommission : classes.gridPlainText}>
					<Typography onClick={() => handleClickOpenEstatistica()}  className={classes.cursorPointer}>
						Estatísticas
					</Typography>
				</Grid>

				{
					isPlenarioAdmin ? (
						<Grid item xs={1}>
							<TextField
								label="Nº" value={stateNumberMeeting}
								onBlur={(e) => onNumPlenarioMeeting({numberPlenario: e.target.value})} 
								onChange={(e) => setStateNumberMeeting(e.target.value)}
							/>
						</Grid>
					) : (
						<Grid item xs={1} className={matchesSm ? classes.gridTextCommission : classes.gridPlainText}>
							<Typography>{stateNumberMeeting || ''}</Typography>
						</Grid>
					)
				}

				{
					isPlenarioAdmin ? (
						<Grid item xs={2} className={estatisticaStatus === 'Aprovado'? classes.columnActionsActive : classes.columnActions}>
							{
								(estatisticaStatus === 'Aprovado') 
									? (
										<>
											<span className={classes.textInfo}>Doc. aprovado</span>
											<Button className={classes.btnReject} color="secondary" variant="contained" onClick={() => onRejectEstatistica()}>
												REJEITAR
											</Button>
										</>
									) : (
										<Button color="primary" variant="contained" onClick={() => onAcceptEstatistica()}>
											APROVAR
										</Button>
									)}
						</Grid>
					) : (
						<Grid item xs={2} className={matchesSm ? classes.gridTextCommission : classes.gridPlainText}>
							{
								(estatisticaStatus === 'Aprovado') ? (
									<Typography>Documento aprovado</Typography>
								) : (
									<Typography>Aguardando aprovação</Typography>
								)
							}
						</Grid>
					)
				}
			</Grid>
			</MuiPickersUtilsProvider>
		</div>
	);
}