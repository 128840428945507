import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: '100%',
        flex: 1,
    },
    table: {},
    containerPage: {
        width: '100%',
        height: '100%',
        background: '#fff',
        padding: 20
    },
    btnActions: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paperItem: {
        margin: 10,
        padding: 10,
        border: '1px solid #eee'
    },
    titlePage: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        },
    },
    titleh5: {
        '& span': {
            fontSize: 14,
            opacity: 0.5,
            display: 'block'
        }
    },
    currentAgent: {
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: 10,
        paddingBottom: 0,
        marginBottom: 6,
        fontSize: 14,
    }
}));

export default useStyles;