import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { gql, useMutation, useQuery } from '@apollo/client';
import { AppBar, Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Input, InputLabel, MenuItem, Paper, Select, TextField, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBrLocale from "date-fns/locale/pt-BR";
import styles from './convocacao.style';
import TextEditor from '../../../components/TextEditor';
import {
    Save as SaveIcon,
    Send as SendIcon,
    Print as PrintIcon,
	Visibility as VisibilityIcon,
	Cancel as CancelIcon,
	Add as AddIcon
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const QUERY_USERS = gql`
  query Users {
    getUsers(all: "true") {
      id
      name
    }
  }
`;

const QUERY_MEETINGS = gql`
  query ListMeetings {
    listMeeting(filter: "Criada,Convocada") {
	  id
	  name
	  date
	  convocation
	  status
    }
  }
`;

const MUTATION_CREATE_MEETING = gql`
  mutation CreateMeeting($id: String, $name: String!, $date: DateTime!, $meetingType: String!, $summoned: String!, $convocation: String, $sendEmail: Boolean) {
    createMeeting(input: {id: $id, name: $name, date: $date, meetingType: $meetingType, summoned: $summoned, convocation: $convocation, sendEmail: $sendEmail}) {
      id
      name
    }
  }
`;

interface ICurrentConvocacao {id: string, name: string, convocados: string[], date: Date, convocation: string, status: string}

export default function ConvocacaoMeeting({...props}) {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = styles();
	const dispatch = useDispatch();
	const [currentConvocacao, setCurrentConcovacao] = useState<any>({});
	const [openDialog, setOpenDialog] = useState(false);
	const [openDialogSend, setOpenDialogSend] = useState(false);
	const { loading: loadingMeetings, error: errorMeeting , data: dataMeeting, refetch} = useQuery(QUERY_MEETINGS);
	const { loading: loadingUser, error: errorUser, data: dataUser } = useQuery(QUERY_USERS);
	const [ createMeeting, { data: dataCreate, loading: loadingCreate, error: errorCreate }] = useMutation(MUTATION_CREATE_MEETING, {
		onCompleted: () => {
			setCurrentConcovacao({});
			refetch();
		}
	});

	const newConvocacao = () => {
		setCurrentConcovacao({ id: 'new', name: '', convocados: [], date: new Date(), status: 'Nova', convocation: `
		<h1 style="text-align: center;"><img style="float: left;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR9EBAdk6S5OSo5yb9kZn2y-AHVsdDPJCKWCpWS4gSeEIIusMuu&amp;usqp=CAU" alt="" width="153" height="105" /><span style="font-size: 14pt;">PRESBIT&Eacute;RIO DE IMBITUVA - PRIM</span></h1>
		<p style="text-align: center;"><span style="font-size: 12pt;">SEDE: IMBITUVA - S&Iacute;NODO VALE DO TIBAGE (SVT)</span></p>
		<pre style="text-align: center; padding-left: 120px;"><span style="font-size: 10pt; font-family: arial, helvetica, sans-serif;"><strong><br /><br /><span style="font-size: 8pt;">PRESIDENTE:&nbsp;</span></strong><span style="font-size: 8pt;">Informa&ccedil;&otilde;es sobre</span></span><br /><span style="font-size: 8pt; font-family: arial, helvetica, sans-serif;">Outras informa&ccedil;&otilde;es, endere&ccedil;o, telefone, etc...</span><br /><br /><span style="font-size: 8pt; font-family: arial, helvetica, sans-serif;"><strong>SECRET&Aacute;RIO EXECUTIVO: </strong>Informa&ccedil;&otilde;es sobre</span><br /><span style="font-size: 8pt; font-family: arial, helvetica, sans-serif;">Outras informa&ccedil;&otilde;es, endere&ccedil;o, telefone, etc...</span></pre>
		<p class="p1"><span style="font-size: 10pt;">Da: CE/PRIM.</span></p>
		<p class="p1"><span style="font-size: 10pt;">Para: Conselhos e Pastores do PRIM</span></p>
		<p class="p2" style="text-align: center;"><span style="font-size: 10pt;"><strong>CONVOCA&Ccedil;&Atilde;O PARA XVII REUNI&Atilde;O ORDIN&Aacute;RIA DO PRESBIT&Eacute;RIO DE IMBITUVA</strong></span></p>
		<p class="p3"><span style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; Venho mui respeitosamente, no cumprimento dos meus deveres, e por ordem do <span style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">senhor Presidente Rev. Thiago Pinheiro Gomes, convocar o estimado conc&iacute;lio para comparecer &agrave; XVII Reuni&atilde;o Ordin&aacute;ria do PRIM, nos dias </span><strong style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">11 e 12 de dezembro de 2020, no Acampamento da Igreja Presbiteriana de Reserva,</strong><span style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;"> sito Rod. Vacilio Boiko, Km 224, Reserva&ndash;PR. Esta XVII Reuni&atilde;o Ordin&aacute;ria do PRIM ter&aacute; in&iacute;cio &agrave;s 19h, sexta-feira, dia 11 de dezembro de 2020, com a verifica&ccedil;&atilde;o de poderes.</span></span></p>
		<p class="p3"><span style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; Solicitamos aos conciliares o preenchimento correto, impress&atilde;o e entrega dos seguintes documentos nesta XVII R.O:</span></p>
		<p class="p4"><span style="font-size: 10pt;"><strong>Relat&oacute;rio Ministerial</strong>: &Eacute; o relat&oacute;rio das atividades desenvolvidas pelo ministro (Pastor) ao longo do ano eclesi&aacute;stico de 2020.</span></p>
		<p class="p4"><span style="font-size: 10pt;"><strong>Estat&iacute;stica:</strong> Informa&ccedil;&otilde;es cadastrais e Estat&iacute;sticas do ano eclesi&aacute;stico de 2020. Uma via &eacute; para o PRIM e a outra fica no arquivo local.</span></p>
		<p class="p4"><span style="font-size: 10pt;"><strong>Relat&oacute;rio do Conselho:</strong> Cont&eacute;m informa&ccedil;&otilde;es das atividades espirituais e administrativas desenvolvidas pelo Conselho da Igreja local e/ou congrega&ccedil;&atilde;o durante o ano eclesi&aacute;stico de 2020. O relat&oacute;rio da congrega&ccedil;&atilde;o deve ser anexado ao relat&oacute;rio do conselho. Uma via &eacute; para o PRIM e a outra fica no arquivo local.</span></p>
		<p class="p3"><span style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; Qualquer outro documento para entrar nesta XVII R.O deve ser entregue impresso e digitalizado.</span></p>
		<p class="p3"><span style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; Solicitamos que os conciliares levem para esta XVII R.O seus notebooks e pen drives para as comiss&otilde;es digitarem seus relat&oacute;rios; assim, facilita e agiliza muito a produ&ccedil;&atilde;o das atas.&nbsp;</span></p>
		<p class="p3"><span style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; Lembramos aos pastores que levem suas carteiras de ministros. Os presb&iacute;teros representantes das igrejas devem levar suas carteiras de presb&iacute;tero com as devidas anota&ccedil;&otilde;es; ou a credencial expedida pelo Conselho da Igreja e assinada pelo secret&aacute;rio da mesma, sem a qual, a igreja ficar&aacute; sem representa&ccedil;&atilde;o no plen&aacute;rio.</span></p>
		<p class="p3"><span style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; Sem mais para o momento, rogamos ao nosso Deus que esta XVII R.O seja para sua gl&oacute;ria e crescimento de sua igreja.</span></p>
		<p class="p3"><span style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; Para melhor comunica&ccedil;&atilde;o, solicitamos que acusem o recebimento desta convoca&ccedil;&atilde;o para confirma&ccedil;&atilde;o.</span></p>
		<p class="p5"><span style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; Em Cristo, nosso supremo Pastor</span></p>
		<p style="text-align: center;">&nbsp;</p>
		<p style="text-align: center;"><span style="font-size: 10pt;">____________________________________________________________</span></p>
		<p class="p1" style="text-align: center;"><span style="font-size: 10pt;">Rev. Roberto Faust</span></p>
		<p class="p1" style="text-align: center;"><span style="font-size: 10pt;">(Secret&aacute;rio Executivo do PRIM 2019 - 2020)</span></p>
		` });
	}

	const cancelConvocacao = () => {
		setOpenDialog(true);
	}

	const doCancelConvocacao = () => {
		setCurrentConcovacao(null);
		setOpenDialog(false);
	}

	const sendEmail = () => {
		setOpenDialogSend(true);
	}

	const doSendEmail = () => {
		createMeeting({
			variables: {
				id: currentConvocacao.id,
				name: currentConvocacao.name,
				date: currentConvocacao.date,
				meetingType: 'Ordinária',
				summoned: currentConvocacao.convocados.join(';'),
				convocation: currentConvocacao.convocation,
				sendEmail: true
			}
		});
		setOpenDialogSend(false);
	}

	const doCancelSendEmail = () => {
		//TODO
		//chamar mutation para salvar e enviar email
		setOpenDialogSend(false);
	}

	const doSave = () => {
		createMeeting({
			variables: {
				id: currentConvocacao.id,
				name: currentConvocacao.name,
				status: currentConvocacao.name,
				date: currentConvocacao.date,
				meetingType: 'Ordinária',
				summoned: currentConvocacao.convocados.join(';'),
				convocation: currentConvocacao.convocation
			}
		});
	}

	const getAppBarConvocacaoRO = () => {
		return <AppBar position="fixed" color="default" className={classes.footer}>
			<Toolbar>
				<Button
					variant="contained"
					color="default"
					size="small"
					// className={classes.button}
					startIcon={<SaveIcon />}
					onClick={doSave}
				>
					{
						matchesSm
							? (null)
							: 'Salvar'
					}
				</Button>
				{/* <Button
					variant="contained"
					color="default"
					size="small"
					// className={classes.button}
					startIcon={<PrintIcon />}
				>
					Imprimir
				</Button> */}
				{/* <Button
					variant="contained"
					color="default"
					size="small"
					// className={classes.button}
					startIcon={<VisibilityIcon />}
				>
					Visualizar
				</Button> */}
				<Button
					variant="contained"
					color="secondary"
					size="small"
					// className={classes.button}
					startIcon={<CancelIcon />}
					onClick={cancelConvocacao}
				>
					{
						matchesSm
							? (null)
							: 'Cancelar'
					}
				</Button>
				<Button
					variant="contained"
					color={currentConvocacao.status == 'Convocada'  ? 'secondary' : 'primary'}
					size="small"
					// className={classes.button}
					startIcon={<SendIcon />}
					onClick={sendEmail}
				>
					{
						matchesSm 
						? (null)
						: currentConvocacao.status == 'Convocada' 
							? 'Salvar e Enviar Retificação por e-mail'
					   		: 'Salvar e Enviar por email'
					}
				</Button>
			</Toolbar>
		</AppBar>
	}
	
	if(loadingUser || loadingMeetings) {
		return <div>carregando...</div>
	}
	
	if(errorUser) {
		return <div>Erro ao carregar os usuários!</div>
	}

	if((!dataMeeting || !dataMeeting.listMeeting || !dataMeeting.listMeeting.length) && !currentConvocacao?.id ) {
		return <div className={classes.rootCentered}>
			<Alert severity="info">Não existem convocações abertas.</Alert>
			<Button  startIcon={<AddIcon />} className={classes.btnAdd} variant="contained" onClick={() => newConvocacao()}>Criar nova convocação</Button>
		</div>
	}

	const buildDate = (dateString: any) => {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.getMonth()+1;
		const minutes = date.getDate();
		return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${date.getFullYear()} ${date.getHours()}:${minutes < 10 ? '0' : ''}${minutes}`;
	}

	const createListMeetings = () => {
		return dataMeeting.listMeeting.map((item: any, idx: any) => {
			return <Grid item key={idx} className={classes.gridItem}>
				<Paper
					onClick={() => {
						setCurrentConcovacao({ id: item.id, name: item.name, convocados: [], date: item.date, convocation: item.convocation, status: item.status })
					}}
					className={classes.paperItem}
				>
				<Typography variant="h5">{item.name}</Typography>
				<Typography variant="h6"><span>Status:</span> {item.status}</Typography>	
				<Typography variant="h6"><span>Data:</span> {item.date ? buildDate(item.date) : 'Não definida'}</Typography>	
			</Paper></Grid>
		});
	}

	const buildTextEditor = () => (
		<TextEditor
			// initialValue=""
			initialValue={currentConvocacao.convocation}
			onChange={(e: any) => {setCurrentConcovacao({...currentConvocacao, convocation: e.target.getContent()});}}
		/>
	)

	if(dataMeeting?.listMeeting.length && !currentConvocacao?.id) {
		return (
			<div className={classes.rootCentered}>
			<Grid container>
				<Grid item xs={12}>
					<Button startIcon={<AddIcon />} color="primary"  className={classes.btnAdd} variant="contained" onClick={() => newConvocacao()}>Criar nova convocação</Button>
				</Grid>
				<Grid item xs={12} className={classes.gridItem}>
					<hr/>
					<Typography variant="h6">Convocações criadas:</Typography>
				</Grid>
				{ createListMeetings() }
			</Grid>
			</div>
		);
	}

	return (
		<div className={classes.root}>
			<Backdrop className={classes.backdrop} open={loadingMeetings || loadingCreate || loadingUser}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className={classes.formContent}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}> 
				<Paper className={classes.basePaperMargin}>
					<Grid item xs={12}>
						<Typography className={classes.formTitle} variant="h6">Configuração da Reunião Ordinária</Typography>
					</Grid>
					<Grid className={classes.gridContainer} container spacing={1}>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<TextField 
									margin="none" 
									autoFocus 
									label="Nome da ordinária"
									placeholder="Exemplo: Reunião Ordinária de 2020"
									value={currentConvocacao?.name || ''}
									onChange={(e) => {setCurrentConcovacao({...currentConvocacao, name: e.target.value})}}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<DateTimePicker
									value={currentConvocacao.date || new Date()}
									onChange={(value) => {setCurrentConcovacao({...currentConvocacao, date: value})}}
									label="Data e hora da Ordinária"
									margin="none"
									ampm={false}
                        			format="dd/MM/yyyy H:mm"
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
									<InputLabel id="demo-mutiple-name-label">Convocados</InputLabel>
									<Select
									labelId="demo-mutiple-name-label"
									id="demo-mutiple-name"
									multiple
									className={classes.gusestInput}
									value={currentConvocacao.convocados}
									onChange={(e) => setCurrentConcovacao({...currentConvocacao, convocados: e.target.value })}
									input={<Input />}
									margin="none" 
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 48 * 4.5 + 8,
												width: 250,
												maxWidth: 250,
												marginTop: 50
											},
										},
									}}
									>	
										{
											dataUser.getUsers.map((item: any, idx: any) => (
												<MenuItem key={idx} value={item.id} >
													{item.name}
												</MenuItem>
											))
										}
									</Select>
								</FormControl>
						</Grid>
					</Grid>
				</Paper>
				{
					buildTextEditor()
				}
				</MuiPickersUtilsProvider>
			</div>
			<Dialog
				open={openDialog}
				onClose={cancelConvocacao}
			>
				<DialogTitle>Cancelar</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{
						currentConvocacao?.id == 'new'
							? 'Cancelar convocação?'

							: 'Cancelar edição da convocação?'
					}
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDialog(false)} color="primary">
						Voltar
					</Button>
					<Button onClick={doCancelConvocacao} color="primary" autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openDialogSend}
				onClose={() => setOpenDialogSend(false)}
			>
				<DialogTitle>Salvar e Enviar?</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Tem certeza que deseja salvar e enviar a convocação por e-mail para todos os usuários selecionados?
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDialogSend(false)} color="primary">
						Cancelar
					</Button>
					<Button onClick={doSendEmail} color="primary" autoFocus>
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
			{getAppBarConvocacaoRO()}
		</div>
	);
}