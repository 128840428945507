import React, { useEffect, useState } from 'react';
import styles from './styles';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Backdrop, Button, CircularProgress, Container, Grid, TextField, Typography } from '@material-ui/core';
import AvatarUpload from 'src/components/Avatar';

const QUERY_USERES = gql`
	query User {
		me {
			id
			name
			email
			rg
			cpf
			type
			confirmed
			role
			phone
			address
			avatar
			church {
				id
				name
			}
		}
	}
`;

const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $name: String, $rg: String, $cpf: String, $phone: String, $address: String, $email: String, $avatar: String, $isUser: Boolean, $church: String) {
    updateUser(id: $id, input: { name: $name, rg: $rg, cpf: $cpf, phone: $phone, address: $address, email: $email, avatar: $avatar, isUser: $isUser, church: $church }) {
      id
      name
    }
  }
`;


function Profile({...props}) {
	const classes = styles();
	const [ currentRecord, setCurrentRecord ] = useState<any>({});
	const [ updateUser, { data: dataUpdate, loading: loadingUpdate, error: errorUpdate }] = useMutation(UPDATE_USER);
	const { loading, error, data, refetch } = useQuery(QUERY_USERES);

	useEffect(() => {
		data && setCurrentRecord({...data.me});
	}, [data]);

	const onSave = () => {
		updateUser({
			variables: {...currentRecord, church: currentRecord.church.id}
		});
	}

	if(error) {
		return <div>Erro!</div>
	}

	return (
		<div className={classes.containerPage}>
			{
				(loading || loadingUpdate)
					? (
						<Backdrop className={classes.backdrop} open={true}>
							<CircularProgress color="inherit" />
							<Typography>Carregando</Typography>
						</Backdrop>
					) : (null)
			}
			<Container maxWidth="sm">
				<Typography variant="h3">Meu perfil</Typography>

				<AvatarUpload currentAvatar={currentRecord.avatar} onChange={(avatar: string) => setCurrentRecord({...currentRecord, avatar})} />

				<TextField 
					label="Nome Completo"
					fullWidth
					margin="normal"
					variant="outlined"
					autoFocus
					onChange={(e) => setCurrentRecord({...currentRecord, name: e.target.value})}
					value={currentRecord.name || ''}
				/>
				<TextField 
					label="RG"
					fullWidth
					type="number"
					margin="normal"
					variant="outlined"
					onChange={(e) => setCurrentRecord({...currentRecord, rg: e.target.value})}
					value={currentRecord.rg || ''}
				/>
				<TextField 
					label="CPF"
					fullWidth
					type="text"
					margin="normal"
					inputProps={{
						maxlength: 11
					}}
					variant="outlined"
					onChange={(e) => setCurrentRecord({...currentRecord, cpf: e.target.value})}
					value={currentRecord.cpf || ''}
				/>
				<TextField 
					label="Telefone"
					fullWidth
					margin="normal"
					variant="outlined"
					onChange={(e) => setCurrentRecord({...currentRecord, phone: e.target.value})}
					value={currentRecord.phone || ''}
				/>
				<TextField 
					label="Endereço"
					fullWidth
					margin="normal"
					variant="outlined"
					onChange={(e) => setCurrentRecord({...currentRecord, address: e.target.value})}
					value={currentRecord.address || ''}
				/>
				<TextField 
					label="Email"
					fullWidth
					margin="normal"
					variant="outlined"
					onChange={(e) => setCurrentRecord({...currentRecord, email: e.target.value})}
					value={currentRecord.email || ''}
				/>
				<TextField 
					label="Igreja"
					fullWidth
					margin="normal"
					variant="outlined"
					disabled
					value={currentRecord.church?.name || ''}
				/>
				<TextField 
					label="Tipo"
					fullWidth
					margin="normal"
					variant="outlined"
					disabled
					value={currentRecord.type || ''}
				/>
				
				<Grid container>
					<Grid item xs={8} alignContent="flex-start">
						<Button variant="contained" onClick={() => {alert('Ainda não é possível alterar a senha! Entre em contato com o adminstrador.')}}>
							Alterar a senha
						</Button>
					</Grid>
					<Grid item xs={4} className={classes.btnActions}>
						<Button variant="contained" color="primary" onClick={onSave}>
							Salvar
						</Button>
					</Grid>
				</Grid>
			</Container>
		</div>
  );
}

export default Profile;