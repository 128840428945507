import { combineReducers } from 'redux';
import app from './Main';
import items from './Items';
import users from './Users';
import steps from './Steps';

export default combineReducers({
    app,
    items,
    users,
    steps,
});