import { Avatar, Button, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { 
    InsertDriveFile as FileIcon,
    Image as ImageIcon,
    KeyboardBackspace,
} from '@material-ui/icons';
import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import useStyles from './styles';

function Downloads({...props}) {
    const classes = useStyles();
    let match = useRouteMatch();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Switch>
            <Route path={`${match.path}`}>
                <Container maxWidth="xl">
                    <Link to="/app/ordinaria" className={classes.link}>
                        <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                        <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Você está downloads</Button>
                    </Link>
                    <Grid container spacing={3}>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/documentos/Manual-Presbiteriano.pdf" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <FileIcon />
                                    </Avatar>
                                    <Typography>
                                        Manual Presbiteriano
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/documentos/Confissao-de-Fe.pdf" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <FileIcon />
                                    </Avatar>
                                    <Typography>
                                        Confissão de fé
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <a href="https://storage.googleapis.com/concilio-app.appspot.com/documentos/Carterinhas-do-PRIM.docx" target="_blank" rel="noopener noreferrer" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <FileIcon />
                                    </Avatar>
                                    <Typography>
                                        Carterinhas do PRIM
                                    </Typography>
                                </Paper>
                            </a>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/downloads/images" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <ImageIcon />
                                    </Avatar>
                                    <Typography>
                                        Imagens
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Route>
        </Switch>
    );
}

export default Downloads;