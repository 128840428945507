import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
    Link
} from 'react-router-dom';
import {
    Drawer,
    ListItemText,
    List,
    ListItem,
    Divider,
    Typography,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import {
    ArrowBack as ArrowBackIcon,
    Launch as LaunchIcon,
} from '@material-ui/icons';
import styles from './styles';
import * as appAction from '../../store/ducks/Main/actions';
import Toolbar from '../Toolbar';
import { toggleDrawer } from '../../store/ducks/Main/actions';
import { getMe } from 'src/services/auth';
import { ExecutiveCommissionToRole } from 'src/store/ducks/Main/types';

const logoLogin = require('../../assets/logo_login.png');
function Menu() {
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
    const drawerOpen = useSelector(state => state.app.drawerOpen);
    const listCurrentMenu = useSelector(state => state.app.listCurrentMenu);
    
    const selectedMenu = useSelector(state => state.app.selectedMenu);
    const backMenu = useSelector(state => state.app.backMenu);
    const menu = useSelector(state => state.app.menu);
    const dispatch = useDispatch();
    const classes = styles();
    const me = getMe();

    let currentMenu = {};

    if(Array.isArray(listCurrentMenu)) {
        currentMenu = {...listCurrentMenu[listCurrentMenu.length-1]};
    }

    return (
        <Drawer
            classes={{
                paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
                docked: classes.drawerDocked
            }}
            variant={matchesSm ? 'temporary' : 'permanent'}
            datatype
            open={drawerOpen}
            onClose={() => dispatch(toggleDrawer())}
        >
            <div className={classes.boxLogo}>
                <Link to="/app" onClick={() => {
                    dispatch(appAction.goTo({
                        title: 'Início',
                        level: 0,
                        parent: null,
                        route: '/app',
                    }));
                }}>
                    <img alt="Logo Concílio" src={logoLogin} className={classes.logoLogin} />
                </Link>

            </div>

            <Divider />
            {
                currentMenu.title ? (
                    <>
                        <div className={classes.backMenu}>
                                {/*TODO: atualizar o link de retorno para ficar dinamico*/}
                                <Link to="/app" onClick={() => dispatch(appAction.goBack(backMenu[backMenu.length-1]))}>
                                    <ArrowBackIcon />
                                </Link>
                            <Typography>{currentMenu.title}</Typography>
                        </div>
                        <Divider />
                    </>
                ) : null
            }
            <List className={classes.listMenu}>
                {
                    menu.map((item, idx) => {
                        if(
                            item.role && (
                                !item.role.includes(me.role) && 
                                !item.role.includes(ExecutiveCommissionToRole[me.executiveCommission]) &&
                                !item.role.includes(ExecutiveCommissionToRole[me.type])
                            )
                        ) {
                            return (null);
                        }

                        return (
                            <Link
                                key={idx}
                                to={item.route}
                                onClick={() => dispatch(appAction.goTo(item))}
                                className={(item.title === selectedMenu.title) ? classes.selectedMenu : null}
                            >
                                <ListItem button>
                                    {/*<ListItemIcon>*/}
                                    {/*    <DashboardIcon />*/}
                                    {/*</ListItemIcon>*/}
                                    <ListItemText
                                    key={0}
                                    primary={item.title}
                                />
                                </ListItem>
                            </Link>
                        )
                    })
                }
                <div className={classes.dividerFlex}></div>
                <a href="https://storage.googleapis.com/concilio-app.appspot.com/documentos/Manual-Presbiteriano.pdf" target="_blank" rel="noopener noreferrer">
                    <ListItem button>
                        <ListItemText key={0} primary="Manual Presbiteriano"/> <LaunchIcon />
                    </ListItem>
                </a>
            </List>
            <Divider />
            <Toolbar/>
        </Drawer>
    );
}

export default Menu;