/**
 * Action types
 */

export enum UsersTypes {
    LOGIN_REQUEST = '@users/LOGIN_REQUEST',
    LOGIN_SUCCESS = '@users/LOGIN_SUCCESS',
    LOGIN_FAILURE = '@users/LOGIN_FAILURE',
    SIGNUP_REQUEST = '@users/SIGNUP_REQUEST',
    SIGNUP_SUCCESS = '@users/SIGNUP_SUCCESS',
    SIGNUP_FAILURE = '@users/SIGNUP_FAILURE',
    LOGOUT = '@users/LOGOUT',
    LOGOUT_SUCCESS = '@users/LOGOUT_SUCCESS',
    UPDATE_FORM = '@users/UPDATE_FORM',
    VALIDATE_FORM = '@items/VALIDATE_FORM',
    VALIDATE_FORM_SIGNUP = '@items/VALIDATE_FORM_SIGNUP'
}


/**
 * Data types
 */
export interface User {
    id: string,
    email: string
}

export interface UserValidator {
    email?: string,
    password?: string,
    errors?: {
        email?: string | null | undefined,
        password?: string | null | undefined
    }
}

export interface SignUpValidator {
    fullname?: string,
    email?: string,
    password?: string,
    errors?: {
        fullname?: string | null | undefined,
        email?: string | null | undefined,
        password?: string | null | undefined
    }
}

/**
 * State type
 */
export interface UsersState {
    readonly data: User[],
    readonly loading: boolean,
    readonly error: boolean,
    readonly logged: boolean,
    readonly msgAlert?: string,
    readonly form: UserValidator,
    readonly signUpForm?: SignUpValidator
}