import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { gql, useMutation, useQuery } from '@apollo/client';
import MyBackdrop from '../../../components/Backdrop';
import TextEditor from 'src/components/TextEditor';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textJustify: {
      textAlign: 'justify'
	},
	htmlView: {
		'& .MuiDialog-paper': {
		  height: '100%',
		  '& .MuiDialogContent-dividers': {
			display: 'flex',
			flexDirection: 'column',
			'& > p': {
			  flex: 1,
			  display: 'flex',
			}
		  }
		}
	  }
  }),
);

const MUTATION_COMPILE_ATA = gql`
  mutation CompileAtaByType($meeting: String!, $type: String!) {
    compileAtaByType(meeting: $meeting, type: $type) {
      id
      name
      ata
      ataStatus
			ataCompiled
    }
  }
`;

const MUTATION_UPDATE_ATA = gql`
  mutation UpdateAta($step: String!, $ata: String!) {
    updateAta(step: $step, ata: $ata) {
      id
      name
      ata
      ataCompiled
    }
  }
`;

const QUERY_FIND_STEP = gql`
  query GetStep{
    getStep {
      id
      name
      ata
      ataCompiled
	  code
    }
  }
`;


export default function LeituraAtas({...props}) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [ atas, setAtas ] = React.useState<any>(null);
	const [ currentAta, setCurrentAta ] = React.useState<any>(null);
	const [ currentAtaType, setCurrentAtaType ] = React.useState<any>('');
	const { data, loading, error, } = useQuery(QUERY_FIND_STEP);
	const [ compileAta, { data: dataCompileAta, loading: loadingCompileAta, error: errorCompileAta }] = useMutation(MUTATION_COMPILE_ATA, {
		onCompleted: () => {
			console.log('compileAta', data);
			setAtas(data?.getStep);
			setOpen(true);
		}
	});

	const [ updateAta, { data: dataUpdateAta, loading: loadingUpdateAta, error: errorUpdateAta }] = useMutation(MUTATION_UPDATE_ATA, {
		onCompleted: () => {
			setOpen(false)
		}
	});

	const handleClose = () => {
		setOpen(false);
	};

	const buildEditor = (value: string, atas: any) => {
		const filter = atas.filter((ata: any) => ata.code == currentAtaType);
		const steps: any = {
			verificacao_poderes: `
				<p><sup>1</sup> Ata da Sessão Preparatória da XVII Reunião Ordinária do PRIM. Início às [HORA].</p>
				<p><sup>2</sup> Presentes.</p>
				<p><sup>3</sup> Exercício Espiritual.</p>
				<p><sup>4</sup> Eleição da mesa para 2021.</p>
				<p><sup>5</sup> Ata da Sessão Preparatória da XVII Reunião Ordinária do PRIM. Término às [HORA_FINAL].</p>
			`,
			sessao_preparatoria: `
				<p><sup>1</sup> Ata  da 1ª Sessão Regular  da XVII Reunião Ordinária do PRIM. Início às [HORA].</p>
				<p><sup>2</sup> Chamada dos Pastores</p>
				<p><sup>3</sup> Chamada dos Representantes das Igrejas</p>
				<p><sup>4</sup> Nomeação das comissões</p>
				<p><sup>5</sup> Entrada de Documentos</p>
				<p><sup>6</sup> Ata da 1° sessão regular da XVII da RO/PRIM. Termino às [HORA_FINAL]</p>
			`,
			sessao_regular_2: '',
			sessao_regular_1: '',
		}
		
		return <TextEditor
			 initialValue={value ? value : filter.length && filter[0].ataCompiled? filter[0].ataCompiled : 'Ocorreu algum problema! Entre em contato com o administrador.'} 
			 onChange={(e: any) => setCurrentAta(e.target.getContent())}
			 footer={filter.length && filter[0].ataCompiled ? '' : steps[currentAtaType]}
		/>
	}

	//TODO: automatizar isso
	const onSave = () => {
		const steps: any = {
			verificacao_poderes: 'b16882fd-7acd-4114-9b6e-6c404d33728c',
			sessao_preparatoria: '3fdfa6a2-beab-4580-baa4-333ea1d6332f',
			sessao_regular_1: 'a4922933-db18-4d1d-8a64-caee362d6009',
			sessao_regular_2: 'fae5c968-9f9e-4155-a7ac-489e71584b05',
		}
		updateAta({
			variables: {
				step: steps[currentAtaType],
				ata: currentAta
			}
		})
	}

	const onReset = () => {
		//TODO: deixar id dinamico
		const steps: any = {
			verificacao_poderes: 'b16882fd-7acd-4114-9b6e-6c404d33728c',
			sessao_preparatoria: '3fdfa6a2-beab-4580-baa4-333ea1d6332f',
			sessao_regular_1: 'a4922933-db18-4d1d-8a64-caee362d6009',
			sessao_regular_2: 'fae5c968-9f9e-4155-a7ac-489e71584b05', //6b2ed596-31de-44ab-9e80-b16f1f679017
		}
		updateAta({
			variables: {
				step: steps[currentAtaType],
				ata: ''
			}
		})
	}

	return (
		<div>
			{
				(loadingCompileAta || loadingUpdateAta) ? <MyBackdrop message="Carregando a ATA" open={true} /> : (null)
			}
			{
				props.type === 'sessao_regular_1' ? (
					<Button variant="outlined" color="primary" onClick={() => { setCurrentAtaType('sessao_regular_1'); compileAta({ variables: { meeting: props.meeting.id, type: 'sessao_regular_1' } })}}>
						ATA DA 1ª SESSÃO REGULAR
					</Button>
				) : (null)
			}
			{
				props.type === 'sessao_regular_2' ? (
					<Button variant="outlined" color="primary" onClick={() => { setCurrentAtaType('sessao_regular_2'); compileAta({ variables: { meeting: props.meeting.id, type: 'sessao_regular_2' } })}}>
						ATA DA 2ª SESSÃO REGULAR
					</Button>
				) : (null)
			}
			{
				!props.type ? (
					<>
						<Button variant="outlined" color="primary" onClick={() => { setCurrentAtaType('verificacao_poderes'); compileAta({ variables: { meeting: props.meeting.id, type: 'verificacao_poderes' } })}}>
							ATA VERIFICAÇÃO DE PODERES
						</Button>
						<Button variant="outlined" color="primary" onClick={() => { setCurrentAtaType('sessao_preparatoria'); compileAta({ variables: { meeting: props.meeting.id, type: 'sessao_preparatoria' } })}}>
							ATA SESSÃO PREPARATÓRIA
						</Button>
					</>
				) : (null)
			}
			{
				open 
					? (
						<Dialog
								open={open}
								TransitionComponent={Transition}
								keepMounted
								onClose={handleClose}
								aria-labelledby="alert-dialog-slide-title"
								aria-describedby="alert-dialog-slide-description"
								maxWidth="lg"
								fullWidth
								className={classes.htmlView}
							>
								<DialogTitle id="alert-dialog-slide-title">
									{ props.title ? props.title : currentAtaType === 'sessao_preparatoria' ? 'Sessão Preparatória' : 'Ata de Verificação de Poderes' }
								</DialogTitle>
								<DialogContent dividers>
									<DialogContentText id="alert-dialog-slide-description" className={classes.textJustify}>
										{atas ? buildEditor(dataCompileAta?.compileAtaByType?.ataCompiled || dataCompileAta?.compileAtaByType?.ata, atas) : (null)}
									</DialogContentText>
								</DialogContent>
							<DialogActions>
								<Button style={{marginRight: 20}} variant="contained" onClick={onReset} color="secondary">
									Resetar
								</Button>
								<Button onClick={handleClose} color="primary">
									Fechar
								</Button>
								<Button variant="contained" onClick={onSave} color="primary">
									Salvar
								</Button>
							</DialogActions>
						</Dialog>
					) : ( null )
			}
	</div>
  );
}