import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import styles from '../styles';

function InformacoesFinais({...props}) {
    const classes = styles();
    const isDisabled = (props.readOnly === true);
    const { record, onSetField, onChangeField } = props;
    // const [ record, setRecord ] = useState<any>(props.record);
    
    // useEffect(() => {
    //     console.log('props---->', props);
    //     setRecord(props.record);
    // }, [props, props.record]);

    // const onChangeField = (e: any) => {
    //     setRecord({...record, [e.target.name]: e.target.value});
    // };
    
	return (
        <>
            <Grid item xs={12} className={classes.sectionTitle}>
                VI - Informações Finais
            </Grid>

            <Grid xs={12}>
                <TextField fullWidth disabled={isDisabled} label="Secretário do Conselho" name="vi_secretario_conselho" value={record?.vi_secretario_conselho || ''} onChange={onChangeField} onBlur={onSetField} />
            </Grid>

            <Grid xs={7}>
                <TextField fullWidth disabled={isDisabled} label="Endereço" name="vi_endereco" value={record?.vi_endereco || ''} onChange={onChangeField} onBlur={onSetField} />
            </Grid>
            <Grid xs={1}>
                <TextField fullWidth disabled={isDisabled} label="Nº" name="vi_numero" value={record?.vi_numero || ''} onChange={onChangeField} onBlur={onSetField} />
            </Grid>
            <Grid xs={4}>
                <TextField fullWidth disabled={isDisabled} label="Complemento" name="vi_complemento" value={record?.vi_complemento || ''} onChange={onChangeField} onBlur={onSetField} />
            </Grid>

            <Grid xs={4}>
                <TextField fullWidth disabled={isDisabled} label="Bairro" name="vi_bairro" value={record?.vi_bairro || ''} onChange={onChangeField} onBlur={onSetField} />
            </Grid>
            <Grid xs={4}>
                <TextField fullWidth disabled={isDisabled} label="Cidade" name="vi_cidade" value={record?.vi_cidade || ''} onChange={onChangeField} onBlur={onSetField} />
            </Grid>
            <Grid xs={1}>
                <TextField fullWidth disabled={isDisabled} label="UF" name="vi_uf" value={record?.vi_uf || ''} onChange={onChangeField} onBlur={onSetField} />
            </Grid>
            <Grid xs={2}>
                <TextField fullWidth disabled={isDisabled} label="CEP" name="vi_cep" value={record?.vi_cep || ''} onChange={onChangeField} onBlur={onSetField} />
            </Grid>
            <Grid xs={1}>
                <TextField fullWidth disabled={isDisabled} label="Cx. Post." name="vi_cx_postal" value={record?.vi_cx_postal || ''} onChange={onChangeField} onBlur={onSetField} />
            </Grid>

            <Grid xs={3}>
                <TextField fullWidth disabled={isDisabled} label="Celular" name="vi_celular" value={record?.vi_celular || ''} onChange={onChangeField} onBlur={onSetField}  />
            </Grid>
            <Grid xs={3}>
                <TextField fullWidth disabled={isDisabled} label="Tel. Residencial"  name="vi_tel_residencial" value={record?.vi_tel_residencial || ''} onChange={onChangeField} onBlur={onSetField}  />
            </Grid>
            <Grid xs={3}>
                <TextField fullWidth disabled={isDisabled} label="Tel. Igreja"  name="vi_tel_igreja" value={record?.vi_tel_igreja || ''} onChange={onChangeField} onBlur={onSetField}  />
            </Grid>
            <Grid xs={3}>
                <TextField fullWidth disabled={isDisabled} label="Email" name="vi_email" value={record?.vi_email || ''} onChange={onChangeField} onBlur={onSetField}  />
            </Grid>
        </>
	);
}

export default InformacoesFinais;