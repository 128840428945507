import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
    },
    containerInterno: {
        height: '90%',
        // display: 'flex',
    },
    textCenter: {
        texstAlign: 'center',
    },
    containerInternoMobile: {
        height: 'auto',
        padding: 2
    },
    paperInterno: {
        padding: 30,
        display: 'flex',
        alignItems: 'center',
        height: '90%',
    },
    paperInternoMobile: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        height: 'auto',
    },
    paper: {
        minHeight: 100,
        padding: 30,
        textAlign: 'center',
        display: 'flex',
        cursor: 'pointer',
        '&:hover': {
            background: '#3f51b5',
            '& *': {
                color: '#fff'
            },
            '& .MuiAvatar-circle': {
                background: '#fff',
                '& svg path': {
                    color: '#3f51b5'
                }
            }
        },
        '& p': {
            textAlign: 'center',
            flex: 1,
            paddingTop: 14,
            fontSize: 20,
        },
        '& .MuiAvatar-circle': {
            background: '#3f51b5'
        }
    },
    avatarLarge: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        '& svg': {
            fontSize: 36,
        }
    },
    avatarSmall: {
        marginTop: 20,
        marginRight: 5,
        width: theme.spacing(5),
        height: theme.spacing(5),
        '& svg': {
            fontSize: 20,
        }
    },
    htmlView: {
        '& .MuiDialog-paper': {
          height: '100%',
          '& .MuiDialogContent-dividers': {
            display: 'flex',
            flexDirection: 'column',
            '& > p': {
              flex: 1,
              display: 'flex',
            }
          }
        },
    },
    htmlViewMobile: {
        '& .MuiDialog-container': {
            '& .MuiPaper-root': {
                width: '100%',
                maxHeight: '100% !important',
                margin: 0,
                '& .MuiDialogTitle-root': {
                    padding: '0px 5px',
                },
                '& .MuiDialogActions-root': {
                    padding: 0
                },
                '& .MuiDialogContent-root': {
                    '& .MuiTypography-root': {
                        margin: 0
                    }
                }
            },
        },
        '& .MuiDialog-paper': {
          height: '100%',
          '& .MuiDialogContent-dividers': {
            display: 'flex',
            padding: 0,
            flexDirection: 'column',
            '& > p': {
              flex: 1,
              display: 'flex',
            }
          }
        },
    }
}));

export default useStyles;