import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import styles from '../styles';

function EstruturaComunidadePresbiteriana({...props}) {
    const classes = styles();
    const isDisabled = (props.readOnly === true);
    const { record, onSetField, onChangeField } = props;
    // const [ record, setRecord ] = useState<any>(props.record);
    
    // useEffect(() => {
    //     console.log('props---->', props);
    //     setRecord(props.record);
    // }, [props, props.record]);

    // const onChangeField = (e: any) => {
    //     setRecord({...record, [e.target.name]: e.target.value});
    // };
    
	return (
        <>
            <Grid item xs={12} className={classes.sectionTitle}>
                III - Estrutura da Comunidade Presbiteriana
            </Grid>

            <Grid item xs={3}>
                <Grid container>
                    <Grid item xs={12} className={classes.subSectionTitle}>
                        LIDERANÇA FORMAL:
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Pastores" name="iii_pastores" value={record?.iii_pastores || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Licenciados" name="iii_licenciados" value={record?.iii_licenciados || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Presbíteros" name="iii_presbiteros" value={record?.iii_presbiteros || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Diáconos" name="iii_diaconos" value={record?.iii_diaconos || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Evangelistas" name="iii_evangelistas" value={record?.iii_evangelistas || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Missionários" name="iii_missionarios" value={record?.iii_missionarios || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Candidatos" name="iii_candidatos" value={record?.iii_candidatos || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
                <Grid container>
                    <Grid item xs={12} className={classes.subSectionTitle}>
                        ESTRUTURA  DO TRABALHO:
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Congregações da Igreja" name="iii_congregacao_igreja" value={record?.iii_congregacao_igreja || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Pontos de Pregação" name="iii_pontos_pregacao" value={record?.iii_pontos_pregacao || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Escolas Dominicais" name="iii_escolas_dominicais" value={record?.iii_escolas_dominicais || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Professores da Escola Dominical" name="iii_prof_escola_dominical" value={record?.iii_prof_escola_dominical || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Alunos Escola Dominical (ano atual)" name="iii_aulos_escola_dominical" value={record?.iii_aulos_escola_dominical || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth disabled={isDisabled} type="number" label="Alunos Esc. Dominical (ano anterior)" name="iii_aluno_escola_dominical_anterior" value={record?.iii_aluno_escola_dominical_anterior || ''} onChange={onChangeField} onBlur={onSetField} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
                    <Grid container>
                        <Grid item xs={12} className={classes.subSectionTitle}>
                            DEPARTAMENTOS INTERNOS:
                        </Grid>
                        <Grid item xs={2} className={classes.labelPlain}>
                            UCP:
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº Deptos." name="iii_ucp_n_deptos" value={record?.iii_ucp_n_deptos || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº de Membros" name="iii_ucp_n_membros" value={record?.iii_ucp_n_membros || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={2} className={classes.labelPlain}>
                            UPA:
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº Deptos." name="iii_upa_n_deptos" value={record?.iii_upa_n_deptos || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº de Membros" name="iii_upa_n_membros" value={record?.iii_upa_n_membros || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={2} className={classes.labelPlain}>
                            UMP:
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº Deptos." name="iii_ump_n_deptos" value={record?.iii_ump_n_deptos || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº de Membros" name="iii_ump_n_membros" value={record?.iii_ump_n_membros || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={2} className={classes.labelPlain}>
                            SAF:
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº Deptos." name="iii_saf_n_deptos" value={record?.iii_saf_n_deptos || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº de Membros" name="iii_saf_n_membros" value={record?.iii_saf_n_membros || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={2} className={classes.labelPlain}>
                            UPH:
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº Deptos." name="iii_uph_n_deptos" value={record?.iii_uph_n_deptos || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº de Membros" name="iii_uph_n_membros" value={record?.iii_uph_n_membros || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={2} className={classes.labelPlain}>
                            Outras:
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº Deptos." name="iii_outras_n_deptos" value={record?.iii_outras_n_deptos || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth disabled={isDisabled} type="number" label="Nº de Membros" name="iii_outras_n_membros" value={record?.iii_outras_n_membros || ''} onChange={onChangeField} onBlur={onSetField} />
                        </Grid>
                        <Grid item xs={3} className={classes.financeiroSummario}>
                            <b>TOTAIS:</b>
                        </Grid>
                        <Grid item xs={4} className={classes.financeiroSummario}>
                            <b>{Number(record?.iii_ucp_n_deptos||0)+Number(record?.iii_upa_n_deptos||0)+Number(record?.iii_ump_n_deptos||0)+Number(record?.iii_saf_n_deptos||0)+Number(record?.iii_uph_n_deptos||0)+Number(record?.iii_outras_n_deptos||0)}</b>
                        </Grid>
                        <Grid item xs={5} className={classes.financeiroSummario}>
                        <b>{Number(record?.iii_ucp_n_membros||0)+Number(record?.iii_upa_n_membros||0)+Number(record?.iii_ump_n_membros||0)+Number(record?.iii_saf_n_membros||0)+Number(record?.iii_uph_n_membros||0)+Number(record?.iii_outras_n_membros||0)}</b>
                        </Grid>
                    </Grid>
                </Grid>
        </>
	);
}

export default EstruturaComunidadePresbiteriana;