import { Reducer } from 'redux';
import { StepsState, StepTypes } from './types';

const INITIAL_STATE: StepsState = {
    data: {
        current: 0
    },
}

const reducer: Reducer<StepsState> = (state= INITIAL_STATE, action) => {
    switch (action.type) {
        case StepTypes.NEXT_STEP:
            return {...state, data: { ...state.data, current: action.payload.step }};
        default:
            return state;
    }
}

export default reducer;