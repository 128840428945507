/**
 * Action types
 */

export enum StepTypes {
    NEXT_STEP = '@steps/NEXT_STEP',
}

/**
 * Data types
 */
export interface Step {
    current: number,
}

/**
 * State type
 */
export interface StepsState {
    readonly data: Step,
}