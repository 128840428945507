import { AppBar, Backdrop, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Paper, TextField, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import styles from './permanenciaPastoral/styles';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    Save as SaveIcon,
	Delete as DeleteIcon,
    Print as PrintIcon,
	Visibility as VisibilityIcon,
	Cancel as CancelIcon,
	Add as AddIcon
} from '@material-ui/icons';
import TextEditor from '../../components/TextEditor';
import { Alert } from '@material-ui/lab';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Editor } from '@tinymce/tinymce-react';
import MyBackdrop from 'src/components/Backdrop';
import { getMe } from 'src/services/auth';

const QUERY_MEETINGS = gql`
  query ListMeetings {
    listMeeting(filter: "Criada,Convocada,Iniciada") {
	  id
	  name
	  date
	  convocation
	  status
    }
  }
`;

const QUERY_REPORTS = gql`
  query ListReportsByType($meeting: String!, $type: String!) {
    listReportsByType(meeting: $meeting, type: $type) {
	  id
	  name
	  type
	  status
	  text
    }
  }
`;

const MUTATION_SET_REPORT = gql`
	mutation SetReport($meeting: String!, $type: String!, $id: String) {
		setReport(meeting: $meeting, type: $type, id: $id) {
			id
			type
			year
			text
			name
		}
	}
`;

const MUTATION_UPDATE_REPORT = gql`
	mutation UpdateReport( $id: String!, $text: String, $name: String ) {
		updateReport(id: $id, input: { text: $text, name: $name }) {
			id
			type
			year
			text
			name
		}
	}
`;

const MUTATION_DELETE_REPORT = gql`
	mutation DeleteReport( $id: String!) {
		deleteReport(id: $id) {
			id
		}
	}
`;

function PermanenciaPastoralDashboard({...props}) {
	const classes = styles();
	const [ currentMeeting, setCurrentMeeting ] = useState<any>({});
	const [ currentRecord, setCurrentRecord ] = useState<any>({});
	const [ alertCancel, setAlertCancel ] = useState(false);
	const [ alertDelete, setAlertDelete ] = useState(false);
	const { loading: loadingMeetings, error: errorMeetings , data: dataMeetings } = useQuery(QUERY_MEETINGS);
	const [ getReports, { loading: loadingReports, error: errorReports , data: dataReports, refetch: refetchDocuments }] = useLazyQuery(QUERY_REPORTS);
	const [ updateReport, { data: dataUpdateReport, loading: loadingUpdateReport, error: errorUpdateReport }] = useMutation(MUTATION_UPDATE_REPORT);
	const [ setReport, { data: dataSetReport, loading: loadingSetReport, error: errorSetReport }] = useMutation(MUTATION_SET_REPORT);
	const [ deleteReport, { data: dataDeleteReport, loading: loadingDeleteReport, error: errorDeleteReport }] = useMutation(MUTATION_DELETE_REPORT, {
		onCompleted: () => {
			refetchDocuments && refetchDocuments();
			setCurrentRecord({});
		}
	});
	const me = getMe();

	const componentRef: any = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef?.current,
	});
	
	useEffect(() => {
		dataSetReport?.setReport?.id && setCurrentRecord(dataSetReport?.setReport);
	}, [dataSetReport]);

	useEffect(() => {
		currentMeeting?.id && getReports({ variables: { meeting: currentMeeting.id, type: 'permanencia_pastoral' } })
	}, [currentMeeting, getReports]);

    if(loadingMeetings || loadingReports || loadingUpdateReport || loadingSetReport) {
		return <MyBackdrop open message=" Salvando..."/>
	}
	
	if(errorMeetings) {
		return <div>Erro ao carregar as reuniões!</div>
	}
	
	if(errorReports) {
		return <div>Erro ao carregar os documentos!</div>
	}

    const createListMeetings = () => {
		return dataMeetings.listMeeting.map((item: any, idx: any) => {
			return <Grid item key={idx} className={classes.gridItem}>
				<Paper
					onClick={() => {
						setCurrentMeeting({ id: item.id, name: item.name, date: item.date, convocation: item.convocation, status: item.status })
					}}
					className={classes.paperItem}
				>
				<Typography variant="h5">{item.name}</Typography>
				<Typography variant="h6"><span>Status:</span> {item.status}</Typography>	
				<Typography variant="h6"><span>Data:</span> {item.date}</Typography>	
			</Paper></Grid>
		});
	}

	const createListReports = () => {
		return dataReports.listReportsByType.map((item: any, idx: any) => {
			return <Grid item key={idx} className={classes.gridItem}>
				<Paper
					onClick={() => {
						setCurrentRecord({ ...item })
					}}
					className={classes.paperItem}
				>
				<Typography variant="h5">{item.name}</Typography>
			</Paper></Grid>
		});
	}

	const doDeleteDocument = () => {
		setAlertDelete(false);
		deleteReport({ variables: { id: currentRecord.id }});
	}

	const doCancelDocument = () => {
		setAlertCancel(false);
		refetchDocuments && refetchDocuments();
		setCurrentRecord({});
	}

	const doCreateReport = () => {
		setReport({
			variables: { meeting: currentMeeting.id, type: 'permanencia_pastoral', id: 'new' }
		})
	}

	const saveDocument = () => {
		updateReport({
			variables: { id: currentRecord.id, name: currentRecord.name, text: currentRecord.text }
		});
	}

	if(dataMeetings?.listMeeting?.length === 0) {
		return <Alert severity="warning">Não existe nenhuma reunião disponível para inserir documento.</Alert>
	}

    if(dataMeetings?.listMeeting.length && ( !dataReports || !currentMeeting ) && !currentRecord?.id) {
		return (
			<div className={classes.rootCentered}>
			<Grid container>
				<Grid item xs={12} className={classes.gridItem}>
					<Typography variant="h6">Reuniões disponíveis para inserir documentos:</Typography>
				</Grid>
				{ createListMeetings() }
			</Grid>
			</div>
		);
	}

	if(dataReports?.listReportsByType.length && !currentRecord?.id) {
		return (
			<Container maxWidth="lg">
			<div className={classes.rootCentered}>
				<Grid container>
					<Grid item xs={12}>
						<Button startIcon={<AddIcon />} color="primary"  variant="contained" onClick={() => doCreateReport()}>Criar novo documento</Button>
					</Grid>
					<hr/>
					<Grid item xs={12} className={classes.gridItem}>
						<Typography variant="h6">
							Documentos inseridos na reunião: <b>"{currentMeeting.name}" </b>
							<a className={classes.textLink} onClick={() => { setCurrentRecord(null); setCurrentMeeting(null); }}>
								[trocar de reunião]
							</a>	
						</Typography>
					</Grid>
					{ createListReports() }
				</Grid>
			</div>
			</Container>
		);
	}

	if((!dataReports || !dataReports?.listReportsByType || !dataReports?.listReportsByType?.length) && !currentRecord?.id) {
		return <div className={classes.rootCentered}>
			<Alert severity="info">Você ainda não inseriu nenhum documento para a reunião: <b>"{currentMeeting?.name}"</b>.</Alert>
			<Button  startIcon={<AddIcon />}  variant="contained" onClick={() => doCreateReport()}>Inserir documento</Button>
			<p className={classes.textLink} onClick={() => { setCurrentRecord(null); setCurrentMeeting(null); }}>
				Trocar de reunião
			</p>
		</div>
	}

	const getAppBarConvocacaoRO = () => {
		return <AppBar position="fixed" color="default" className={classes.footer}>
			<Toolbar>
				<Button
					variant="contained"
					color="secondary"
					size="small"
					startIcon={<CancelIcon />}
					onClick={() => setAlertCancel(true)}
				>
					Fechar
				</Button>
				{
					currentRecord?.id && currentRecord?.id !== 'new'
					? (
						<Button
							variant="contained"
							color="secondary"
							size="small"
							startIcon={<DeleteIcon />}
							onClick={() => setAlertDelete(true)}
						>
							Deletar
						</Button>
					) : (null)
				}
				<Button
					variant="contained"
					color="primary"
					size="small"
					startIcon={<SaveIcon />}
					onClick={saveDocument}
				>
					Salvar
				</Button>
			</Toolbar>
		</AppBar>
	}

    return (
		<div className={classes.root}>
			<div className={classes.formContent}>
				<Paper className={classes.basePaperMargin}>
					<Grid className={classes.gridContainer} container spacing={5}>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<TextField 
									margin="normal" 
									autoFocus 
									label="Nome do documento"
									placeholder="Exemplo: Permanência Pastoral para IPB de..."
									value={currentRecord?.name || ''}
									onChange={(e) => {setCurrentRecord({...currentRecord, name: e.target.value})}}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</Paper>
				<TextEditor
						height = "100%"
						initialValue = {currentRecord.text}
						// value = {currentRecord.text || '<p></p>'}
						onChange={(e: any) => {setCurrentRecord({...currentRecord, text: e.target.getContent()});}}
						header={  currentRecord.text ? '' : me.church?.documentHeader || ''} 
					/>
				{ getAppBarConvocacaoRO() }
			</div>
			<Dialog
				open={alertCancel || alertDelete}
				onClose={doCancelDocument}
			>
				<DialogTitle>{alertDelete ? 'Deletar' : 'Cancelar' } </DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{
						currentRecord?.id
							? alertDelete ? 'Tem certeza que deseja deletar o documento?' : 'Cancelar cadastro do documento?'
							: 'Cancelar edição do documento?'
					}
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => alertDelete ? setAlertDelete(false) : setAlertCancel(false)} color="primary">
						Não
					</Button>
					<Button onClick={alertDelete ? doDeleteDocument : doCancelDocument} color="primary" autoFocus>
						Sim
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default PermanenciaPastoralDashboard;