import { Avatar, Button, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { 
    Folder as FolderIcon,
    Description as DescriptionIcon,
    ViewModule as ViewModuleIcon,
    HowToVote as HowToVoteIcon,
    Drafts as DraftsIcon,
    FindInPage as FindInPageIcon,
    ArrowBack as ArrowBackIcon,
    KeyboardBackspace,
    AttachFile,
} from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import useStyles from './styles';
import { gql, useQuery } from '@apollo/client';
import MyBackdrop from 'src/components/Backdrop';
import Eleicoes from './Eleicao';
import Plenario from '../../components/plenario';
import PlenarioAprovacao from '../../components/plenario-aprovacao';
import Atas from './Atas';
import { Alert } from '@material-ui/lab';
import Documentos from './Documentos';
import Downloads from './Downloads';
import DownloadImages from './DownloadImages';

const QUERY_ACTIVE_MEETING = gql`
  query GetActiveMeeting {
    getActiveMeeting(type: "Ordinária") {
      id
      currentStep
      currentSubStep
      convocation
      estatisticaNumber
      estatisticaPlenario
      estatisticaStatus
    }
  }
`;

const QUERY_STEPS = gql`
  query steps($meeting: String!) {
    listStep(meeting: $meeting) {
      id
      name
      order
      currentSubStep
      code
      meeting {
        id
        name
        estatisticaNumber
        estatisticaPlenario
        estatisticaStatus
      }
      subSteps {
          id
          name
      }
    }
  }
`;

function Atalhos() {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { loading: loadingActiveMeeting, error: errorActiveMeeting, data: dataActiveMeeting, refetch: refetchAvtiveMeeting } = useQuery(QUERY_ACTIVE_MEETING);
    const { loading, error, data, refetch } = useQuery(QUERY_STEPS, { variables: { meeting: `${(dataActiveMeeting && dataActiveMeeting.getActiveMeeting) ? dataActiveMeeting.getActiveMeeting.id : ''}` } });
    let match = useRouteMatch();

    useEffect(() => {
        refetch({ meeting: `${(dataActiveMeeting && dataActiveMeeting.getActiveMeeting) ? dataActiveMeeting.getActiveMeeting.id : ''}` });
    }, [dataActiveMeeting, refetch]);

    if(!dataActiveMeeting || !dataActiveMeeting.getActiveMeeting) {
        return (
            <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                <Alert severity="info">Não existe nenhuma reunião em andamento. Aguarde ou atualize a página.</Alert>
            </Container>
        );
    }

    return (
        <Switch>
            <Route path={`${match.path}/eleicoes`}>
                {
                    (data && data.listStep && data.listStep.length && data?.listStep[1]?.subSteps.length) 
                        ? (
                            <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                                {/* <Paper className={classes.paperInterno}> */}
                                    <Eleicoes steps={data?.listStep} />
                                {/* </Paper> */}
                            </Container>
                        ) : (
                            null
                        )
                }
            </Route>
            <Route path={`${match.path}/trabalho-comissoes`}>
                {
                    (data && data.listStep && data.listStep.length && data?.listStep[1]?.subSteps.length) 
                        ? (
                            <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                                <Link to="/app/ordinaria" className={classes.link}>
                                    <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                                    <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Você está no trabalho das comissões</Button>
                                </Link>
                                <Paper className={matchesSm ? classes.paperInternoMobile : classes.paperInterno}>
                                    <Plenario rootClean step={data?.listStep[2]} meeting={dataActiveMeeting.getActiveMeeting} subStep={data?.listStep[2]?.subSteps[7]} title="Trabalho e comissões" />
                                </Paper>
                            </Container>
                        ) : (
                            null
                        )
                }
            </Route>
            <Route path={`${match.path}/plenario`}>
                {
                    (data && data.listStep && data.listStep.length && data?.listStep[1]?.subSteps.length) 
                        ? (
                            <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                                <Link to="/app/ordinaria" className={classes.link}>
                                    <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                                    <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Você está no plenário</Button>
                                </Link>
                                <Paper className={matchesSm ? classes.paperInternoMobile : classes.paperInterno}>
                                    <PlenarioAprovacao step={data?.listStep[2]} meeting={dataActiveMeeting.getActiveMeeting} subStep={data?.listStep[2]?.subSteps[8]} title="Trabalho e comissões"/>
                                </Paper>
                            </Container>
                        ) : (
                            null
                        )
                }
            </Route>
            <Route path={`${match.path}/atas`}>
                {
                    (data && data.listStep && data.listStep.length && data?.listStep[1]?.subSteps.length) 
                        ? (
                            <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                                <Atas steps={data?.listStep} meeting={dataActiveMeeting.getActiveMeeting}/>
                            </Container>
                        ) : (
                            null
                        )
                }
            </Route>
            <Route path={`${match.path}/downloads/images`}>
                {
                    <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                        <DownloadImages />
                    </Container>
                }
            </Route>
            <Route path={`${match.path}/downloads`}>
                {
                    <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                        <Downloads />
                    </Container>
                }
            </Route>
            <Route path={`${match.path}/ver-documentos`}>
                {
                    (data && data.listStep && data.listStep.length && data?.listStep[2]?.subSteps.length) 
                        ? (
                            <Container className={matchesSm ? classes.containerInternoMobile : classes.containerInterno}>
                                <Link to="/app/ordinaria" className={classes.link}>
                                    <Button variant="contained" color="primary" style={{marginBottom: 40}} startIcon={<KeyboardBackspace/>}>Voltar</Button>
                                    <Button style={{marginBottom: 40, marginLeft: -5}} variant="contained">Você está visualizando documentos</Button>
                                </Link>
                                <Paper className={matchesSm ? classes.paperInternoMobile : classes.paperInterno}>
                                    <Documentos step={data?.listStep[2]} meeting={dataActiveMeeting.getActiveMeeting} />
                                </Paper>
                            </Container>
                        ) : (
                            null
                        )
                }
            </Route>
            <Route path={`${match.path}`}>
                <Container maxWidth="xl">
                    { loadingActiveMeeting && <MyBackdrop />}
                    <Grid container spacing={3}>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/eleicoes" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <HowToVoteIcon />
                                    </Avatar>
                                    <Typography>
                                        Eleições
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/trabalho-comissoes" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <ViewModuleIcon />
                                    </Avatar>
                                    <Typography>
                                        Trabalho das Comissões
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/ver-documentos" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <FindInPageIcon />
                                    </Avatar>
                                    <Typography>
                                        Visualizar Documentos
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/plenario" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <FolderIcon />
                                    </Avatar>
                                    <Typography>
                                        Plenário
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/atas" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <DescriptionIcon />
                                    </Avatar>
                                    <Typography>
                                        Acompanhar Atas
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={matchesSm ? 12 : 4}>
                            <Link to="/app/ordinaria/downloads" className={classes.link}>
                                <Paper className={classes.paper}>
                                    <Avatar className={classes.avatarLarge}>
                                        <AttachFile />
                                    </Avatar>
                                    <Typography>
                                        Downloads
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Route>
        </Switch>
    )
}

export default Atalhos;