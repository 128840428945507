import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Container,
    Card,
    TextField,
    Typography,
    Link,
    Dialog,
    CssBaseline,
    Backdrop,
    CircularProgress,
    Grid,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel, Select, MenuItem, useTheme, useMediaQuery, FormControlLabel, Checkbox
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import { AppState } from '../../store';
import { login as setTokenToCookie, setMe } from '../../services/auth';
import history from '../../history';
import styles from './styles';
import * as UsersActions from '../../store/ducks/Users/actions';
import { DO_LOGIN, QUERY_FIELDS, RESET_PASSWORD, SIGN_UP } from './graphql';

const logo = require('../../assets/logo_menu.png');
const logoLogin = require('../../assets/logo_login.png');
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Login() {
    const theme = useTheme();
    const [resetEmail, setResetEmail] = useState<string>('')
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = React.useState(false);
    const [executiveCommission, setExecutiveCommission] = React.useState(false);
    const [signUp, setSignUp] = React.useState({
        name: '',
        rg: '',
        cpf: '',
        email: '',
        password: '',
        church: '',
        type: '',
        phone: '',
        address: '',
        executiveCommission: '',
    });
    const [dialogShow, setDialogShow ] = useState(false);
    const classes = styles();
    const [ doLogin, { data, loading }] = useMutation(DO_LOGIN, {
        onError: ({ graphQLErrors}) => {
            if (graphQLErrors) {
                for (let err of graphQLErrors) {
                  if (err?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
                    alert(err?.extensions?.exception?.response?.errors?.email || err?.extensions?.exception?.response?.errors?.token);
                  }
                }
            }
        }
    });
    const [ signUpMutation,  { loading: loadingCreate }] = useMutation(SIGN_UP, {
        onCompleted: () => { alert('Cadastro realizado com sucesso! Você precisa aguardar a confirmação do administrador para fazer o login!'); }
    });
    const [ resetPassword ] = useMutation(RESET_PASSWORD, {
        onCompleted: () => { alert('Enviamos um link para o seu e-mail. Utilize ele para resetar a senha'); }
    });
    const users = useSelector((state: AppState) => state.users);
    const dispatch = useDispatch();
    const { loading: loadingChurch, data: dataChurch } = useQuery(QUERY_FIELDS);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = () => {
        signUpMutation({
            variables: {...signUp, executiveCommission: executiveCommission ? signUp.executiveCommission : null},
        }).then(() => {
            setOpen(false);
        });
    };

    const handleReset = () => {
        resetPassword({ variables: { email: resetEmail } });
        setDialogShow(false);
    }

    if(data && data.login && data.login.accessToken) {
        setMe(data.login);
        localStorage.setItem('token', data.login.accessToken);
        setTokenToCookie(data.login.accessToken);
        setTimeout(() => {
            history.push('/app');
        }, (50));
        
        return null;
    }

    return (
        <div className={classes.background}>
            {
                (loading || loadingCreate || loadingChurch)
                    ? <Backdrop className={classes.backdrop} open={true}> <CircularProgress color="inherit" /> </Backdrop>
                    : (null)
            }
            <CssBaseline />
            <Backdrop open={users.loading}>
                <CircularProgress/>
            </Backdrop>
            <div className={classes.root}>
            <Grid container spacing={0}  alignItems="flex-end">
                {
                    matchesSm
                        ? (null) : (
                            <>
                                <Grid item xs={7} className={classes.titleBox}>
                                </Grid>
                                <Grid item xs={5} className={classes.logoBox} >
                                    <img alt="Logo" src={logo} className={classes.logo} />
                                </Grid>
                            </>
                        )
                }
                <Grid item xs={12} className={classes.flex}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="baseline"
                    >
                        <Grid item xs={matchesSm ? 12 : 4} className={classes.logoSistema}>
                            <div className={classes.paper}>
                                <img alt="Logo Concílio" src={logoLogin} className={classes.logoLogin} />
                            </div>
                        </Grid>
                        {
                            matchesSm ? (null) : (<Grid item sm="auto" xs={3}></Grid>)
                        }
                        <Grid item xs={matchesSm ? 12 : 5}>
                            <div className={classes.paper}>
                                <Card className={classes.card}>
                                    <Typography className={classes.title} component="h1" align="center">ENTRAR</Typography>
                                    <TextField 
                                        label="Usuário"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => dispatch(UsersActions.updateForm('email', e.target.value))}
                                        error={!!users.form.errors?.email}
                                        helperText={users.form.errors?.email}
                                    />
                                    <TextField
                                        label="Senha"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        type="password"
                                        onChange={(e) => dispatch(UsersActions.updateForm('password', e.target.value))}
                                        error={!!users.form.errors?.password}
                                        helperText={users.form.errors?.password}
                                    />
                                    <Button 
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        // onClick={() => dispatch(UsersActions.loginRequest())}
                                        onClick={() => doLogin({ variables: { email: users.form.email, password: users.form.password } })}
                                    >
                                        Entrar
                                    </Button>
                                    <Button 
                                        className={classes.button}
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        onClick={() => handleClickOpen()}
                                    >
                                        Cadastre-se
                                    </Button>
                                    <Link className={classes.forgetPassword} onClick={() => setDialogShow(true)}>
                                        Esqueceu a senha?
                                    </Link>
                                </Card>
                                <Dialog open={dialogShow}>
                                    <Container className={classes.dialog}>
                                        <Typography>
                                            Digite seu e-mail cadastrado para recuperar a senha
                                        </Typography>
                                        <TextField label="E-mail" fullWidth margin="normal" variant="outlined" value={resetEmail} onChange={(e) => setResetEmail(e.target.value)} />
                                        <Button className={classes.button} variant="contained" color="primary" fullWidth onClick={() => { handleReset() }}>Recuperar</Button>
                                        <Button className={classes.button} variant="contained" color="secondary" fullWidth onClick={() => setDialogShow(false)}>Cancelar</Button>
                                    </Container>
                                </Dialog>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className={classes.dialogTitle}>
                    Preencha o formulário abaixo para se cadastrar
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <TextField 
                        label="Nome Completo"
                        fullWidth
                        size="small"
                        margin="normal"
                        variant="outlined"
                        autoFocus
                        onChange={(e) => setSignUp({...signUp, name: e.target.value})}
                        error={!!users.form.errors?.email}
                        value={signUp.name}
                        helperText={users.form.errors?.email}
                    />
                    <TextField 
                        label="RG"
                        fullWidth
                        size="small"
                        type="number"
                        margin="none"
                        variant="outlined"
                        value={signUp.rg}
                        onChange={(e) => setSignUp({...signUp, rg: e.target.value})}
                        error={!!users.form.errors?.email}
                        helperText={users.form.errors?.email}
                    />
                    <TextField 
                        label="CPF"
                        fullWidth
                        size="small"
                        type="text"
                        margin="normal"
                        inputProps={{
                            maxLength: 11
                        }}
                        value={signUp.cpf}
                        variant="outlined"
                        onChange={(e) => setSignUp({...signUp, cpf: e.target.value})}
                        error={!!users.form.errors?.email}
                        helperText={users.form.errors?.email}
                    />
                    <TextField 
                        label="Telefone"
                        fullWidth
                        size="small"
                        type="number"
                        margin="none"
                        variant="outlined"
                        value={signUp.phone}
                        onChange={(e) => setSignUp({...signUp, phone: e.target.value})}
                    />
                    <TextField 
                        label="Endereço"
                        fullWidth
                        size="small"
                        type="text"
                        margin="normal"
                        variant="outlined"
                        value={signUp.address}
                        onChange={(e) => setSignUp({...signUp, address: e.target.value})}
                    />
                    <TextField 
                        label="Email"
                        fullWidth
                        size="small"
                        margin="none"
                        variant="outlined"
                        onChange={(e) => setSignUp({...signUp, email: e.target.value})}
                        value={signUp.email}
                        error={!!users.form.errors?.email}
                        helperText={users.form.errors?.email}
                    />
                    <TextField 
                        label="Senha"
                        fullWidth
                        size="small"
                        value={signUp.password}
                        margin="normal"
                        type="password"
                        variant="outlined"
                        onChange={(e) => setSignUp({...signUp, password: e.target.value})}
                        error={!!users.form.errors?.password}
                        helperText={users.form.errors?.password}
                    />
                    <FormControl fullWidth variant="outlined" margin="none" className={classes.selectField}>
                        <InputLabel id="demo-simple-select-label">Igreja</InputLabel>
                        <Select
                            value={signUp.church}
                            fullWidth
                            onChange={(e: any) => {
                                const value: string = e.target.value;
                                setSignUp({...signUp, church: value})
                            }}
                        >
                            { dataChurch && dataChurch.listChurch.map((user:any, idx: number) => <MenuItem key={`user-${idx}`} value={user.id}>{user.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined" margin="normal" className={classes.selectField}>
                        <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                        <Select
                            value={signUp.type}
                            fullWidth
                            onChange={(e: any) => {
                                const value: string = e.target.value;
                                setSignUp({...signUp, type: value})
                            }}
                        >
                            <MenuItem key="presbitero" value="presbitero">Presbítero</MenuItem>
                            <MenuItem key="reverendo" value="reverendo">Reverendo</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={executiveCommission}
                                onChange={() => {
                                    setExecutiveCommission(!executiveCommission)
                                }}
                            />
                        }
                        label="Faz parte da Comissão Executíva?"
                    />
                    {
                        (executiveCommission) ? (
                            <FormControl fullWidth variant="outlined" margin="normal" className={classes.selectField}>
                                <InputLabel>Cargo</InputLabel>
                                <Select
                                    value={signUp.executiveCommission}
                                    fullWidth
                                    onChange={(e: any) => {
                                        const value: string = e.target.value;
                                        setSignUp({...signUp, executiveCommission: value})
                                    }}
                                >
                                    <MenuItem key="presidente" value="Presidente">Presidente</MenuItem>
                                    <MenuItem key="vice-presidente" value="Vice-presidente">Vice-presidente</MenuItem>
                                    <MenuItem key="secretario-executivo" value="Secretário executivo">Secretário executivo</MenuItem>
                                    <MenuItem key="secretario-atas" value="Secretário de atas">Secretário de atas</MenuItem>
                                    <MenuItem key="secretario-protocolo" value="Secretário de protocolo">Secretário de protocolo</MenuItem>
                                    <MenuItem key="tesoureiro" value="Tesoureiro">Tesoureiro</MenuItem>
                                </Select>
                            </FormControl>
                        ) : (null)
                    }
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button variant="contained" onClick={handleSend} color="primary">
                    Enviar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Login;